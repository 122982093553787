import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useProductsListActiveProductsLoadingState from "../products/useProductsListActiveProductsLoadingState";

// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/products/tryProductsListActiveProducts";

export default function useProductsListActiveProductsResult(
  { searchQuery },
  ___options
) {
  const ___products = useSelector((___state) => ___state.products);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const productsListActiveProductsLoadingState =
    useProductsListActiveProductsLoadingState({}, ___options);
  const ___isLoading = productsListActiveProductsLoadingState.get("isLoading");

  useEffect(() => {
    if (___isLoading === false) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.products.listActiveProducts({ searchQuery });
      } else {
        ___actions.products.listActiveProducts({ searchQuery });
      }
    }
  }, []);

  if (___options && ___options.identifier) {
    return (
      ___products.getIn([
        "listActiveProducts",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___products.getIn(["listActiveProducts", "lastResult"]);
}
