/* eslint-disable no-restricted-globals */
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material";
import { useState } from "react";
import useKlavyioSubscribeNewsletterLoadingState from "stateManager/hooks/klavyio/useKlavyioSubscribeNewsletterLoadingState";
import useActions from "stateManager/hooks/useActions";
import isEmail from "validator/lib/isEmail";

export default function Subscribe({}) {
  const theme = useTheme();
  const actions = useActions();
  const subscribeLoadingState = useKlavyioSubscribeNewsletterLoadingState({});
  const [email, setEmail] = useState("");
  const isLoading = subscribeLoadingState.get("isLoading");

  if (isLoading) {
    return <CircularProgress color={"secondary"} />;
  }

  const submit = () => {
    if (isLoading === false && isEmail(email)) {
      actions.klavyio.subscribeNewsletter({ email });
      setEmail("");
    } else {
      actions.globalMessages.addToQueue({
        message: "Please enter a valid email address.",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <TextField
        variant="outlined"
        placeholder={"Enter your email address"}
        sx={{
          width: { xs: "100%", sm: "48%" },
          "& .MuiOutlinedInput-root": {
            borderRadius: { sm: "4px 0px 0px 4px" },
            marginTop: ".7px",
            height: 55.5,
          },
        }}
        type={"email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            submit();
          }
        }}
      />
      <Button
        variant="contained"
        size={"large"}
        endIcon={<ArrowForwardIcon fontSize={"inherit"} />}
        sx={{
          borderRadius: { sm: "0px 4px 4px 0px" },
          height: 56,
          boxShadow: "none",
          textTransform: "none",
          mt: { xs: theme.spacing(1), sm: 0 },
        }}
        disabled={!email || isEmail(email) === false || isLoading}
        onClick={submit}
      >
        Get Deals
      </Button>
    </Box>
  );
}
