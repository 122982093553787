import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React from "react";
import Countdown from "./Countdown";
import Tag from "./Tag";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";

import _get from "lodash.get";
import useActions from "stateManager/hooks/useActions";
import useFavoriteAddLoadingState from "stateManager/hooks/favorite/useFavoriteAddLoadingState";
import useFavoriteRemoveLoadingState from "stateManager/hooks/favorite/useFavoriteRemoveLoadingState";
import useFavoriteListLoadingState from "stateManager/hooks/favorite/useFavoriteListLoadingState";

export default function DealCard({ _product, doShowAddToWishlist = false }) {
  const actions = useActions();

  const favoriteListLoadingState = useFavoriteListLoadingState({});
  const favAddLoadingState = useFavoriteAddLoadingState({});
  const favRemoveLoadingState = useFavoriteRemoveLoadingState({});
  const isLoading =
    favAddLoadingState.get("isLoading") === true ||
    favRemoveLoadingState.get("isLoading") === true ||
    favoriteListLoadingState.get("isLoading") === true;

  let longTag;
  const theme = useTheme();

  let isSoldOut = moment.unix(_product.dealEndedAt).diff(moment()) < 0;
  let isComingSoon = moment.unix(_product.dealStartedAt).diff(moment()) > 0;
  let isEndingSoon = moment.unix(_product.dealEndedAt).diff(moment()) > 0;

  let marketplaceOrFeaturedIcon;

  let productTags = [];

  if (isSoldOut) {
    // no tags for sold out
  } else if (isComingSoon) {
    longTag = <Countdown date={_product.dealStartedAt} chip />;
  } else if (isEndingSoon && _product.doShowProductLabelTime) {
    longTag = (
      <Countdown
        date={_product.dealEndedAt}
        color={theme.palette.primary.main}
        chip
        end
      />
    );
  } else if (_product.productLabel && _product.doShowProductLabel) {
    productTags.push(
      <div key={"label"} style={{ margin: theme.spacing(0.5, 0.5, 0.5, 0) }}>
        <Tag label={_product.productLabel} title={_product.productLabel} />
      </div>
    );
  }

  let firstPlan = null;
  if (_product.plans && _product.plans.length > 0) {
    _product.plans.forEach((_plan) => {
      if (_plan.isActive === true && firstPlan === null) {
        firstPlan = _plan;
      }
    });
  }

  if (productTags.length > 0) {
    productTags = (
      <div style={{ display: "flex", alignItems: "center" }}>{productTags}</div>
    );
  }

  if (_product.isMarketplace) {
    marketplaceOrFeaturedIcon = (
      <Tooltip
        title="Marketplace"
        disableInteractive
        placement="right"
        enterTouchDelay={0}
      >
        <StoreRoundedIcon />
      </Tooltip>
    );
  } else if (!_product.isMarketplace) {
    marketplaceOrFeaturedIcon = (
      <Tooltip
        title="Featured"
        disableInteractive
        placement="right"
        enterTouchDelay={0}
      >
        <StarsRoundedIcon color="primary" />
      </Tooltip>
    );
  }

  return (
    <>
      <Card variant="outlined" key={`card_${_product.slug}`}>
        <div style={{ position: "relative" }}>
          <span style={{ position: "absolute", right: 10, top: 10, zIndex: 2 }}>
            <IconButton
              onClick={() =>
                actions.favorite.remove({ productId: _product.productId })
              }
              sx={{
                backgroundColor: "rgba(35, 31, 31, 0.4)",
                "&:hover": {
                  backgroundColor: "rgba(35, 31, 31, 0.4)",
                },
              }}
            >
              {isLoading ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <FavoriteOutlinedIcon
                  style={{ color: "white", height: "20px", width: "20px" }}
                />
              )}
            </IconButton>
          </span>
        </div>

        <CardActionArea
          href={`https://${process.env.REACT_APP_MAIN_DOMAIN}/products/${_product.slug}`}
        >
          <Box sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              key={`img_${_product.slug}`}
              sx={{ height: { xs: 215, sm: 195 }, borderRadius: "4px 4px 0 0" }}
              image={_get(_product, "images.logo.url.thumb")}
              title={_get(_product, "name")}
              alt={_get(_product, "images.logo.alt")}
            />
            {longTag}
          </Box>

          <CardContent
            sx={{
              padding: { sm: 3 },
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              ...(!isComingSoon ? { height: { sm: 280 } } : {}),
            }}
          >
            <Box sx={{ mb: theme.spacing(3) }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h3"
                  color={"text.primary"}
                  sx={{ marginRight: theme.spacing(0.5) }}
                >
                  {_product.name.length > 18
                    ? _product.name.slice(0, 18).trim() + "..."
                    : _product.name}
                </Typography>
                {marketplaceOrFeaturedIcon}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {productTags}
              </div>

              <Typography
                variant="body0"
                sx={{
                  marginTop: 1,
                }}
                style={{ maxHeight: 110, overflow: "hidden" }}
                color="text.secondary"
                component={"div"}
              >
                {_product.description.length > 110
                  ? _product.description.slice(0, 110).trim() + "..."
                  : _product.description}
              </Typography>
            </Box>

            {isSoldOut ? (
              <Box
                sx={{
                  margin: { xs: theme.spacing(0), sm: theme.spacing(1, 0) },
                  width: "100%",
                }}
              >
                <Tag
                  label="Sold Out"
                  title="Sold Out"
                  color={"#2196f3"}
                  icon={<CheckCircleIcon style={{ color: "#fff" }} />}
                  fullWidth
                />
              </Box>
            ) : isComingSoon ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: { xs: theme.spacing(0), sm: theme.spacing(1, 0) },
                  height: { sm: theme.spacing(4) },
                }}
              >
                <Box>
                  {firstPlan && (
                    <>
                      <Typography
                        variant="h3"
                        color={"primary"}
                        component={"span"}
                      >
                        ${firstPlan.price / 100}
                      </Typography>
                      <Typography variant="body1" component={"span"}>
                        /{firstPlan.type}
                      </Typography>
                      <div>
                        <Typography
                          color={"text.disabled"}
                          variant={"h6"}
                          sx={{
                            fontSize: { xs: 14, md: 18 },
                            textDecoration: "line-through",
                          }}
                          component="span"
                        >
                          {firstPlan.oldPrice}
                        </Typography>
                      </div>
                    </>
                  )}
                </Box>
                <Box>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                  >
                    Learn More
                  </Button>
                </Box>
              </Box>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}
