import call from "../../helpers/call";

function updateAdmin({
  authToken,
  blogId,
  title,
  slug,
  state,
  content,
  featureImage,
  metaTitle,
  metaDescription,
  metaOgLocale,
  metaOgType,
  metaOgTitle,
  metaOgDescription,
  metaOgUrl,
  metaOgImage,
  metaArticleSection,
  metaTwitterCardType,
  metaTwitterTitle,
  metaTwitterDescription,
  metaTwitterImage,
  metaTwitterImageAlt,
  metaTwitterUrl,
  publishedAt,
  authorUserId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/blog/update-admin`,
    payload: {
      blogId, // (Required) String 10-30 in length and starting with "bl-"
      title, // (Optional) Any string of 0-255 symbols in length.
      slug, // (Optional) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
      state, // (Optional) Possible values: public, draft
      content, // (Optional) Any text
      featureImage, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      metaTitle, // (Optional) Any string of 0-255 symbols in length.
      metaDescription, // (Optional) Any string of 0-255 symbols in length.
      metaOgLocale, // (Optional) Valid values: af_ZA, ar_AR, az_AZ, be_BY, bg_BG, bn_IN, bs_BA, ca_ES, cs_CZ, cy_GB, da_DK, de_DE, el_GR, en_GB, en_PI, en_UD, en_US, eo_EO, es_ES, es_LA, et_EE, eu_ES, fa_IR, fb_LT, fi_FI, fo_FO, fr_CA, fr_FR, fy_NL, ga_IE, gl_ES, he_IL, hi_IN, hr_HR, hu_HU, hy_AM, id_ID, is_IS, it_IT, ja_JP, ka_GE, km_KH, ko_KR, ku_TR, la_VA, lt_LT, lv_LV, mk_MK, ml_IN, ms_MY, nb_NO, ne_NP, nl_NL, nn_NO, pa_IN, pl_PL, ps_AF, pt_BR, pt_PT, ro_RO, ru_RU, sk_SK, sl_SI, sq_AL, sr_RS, sv_SE, sw_KE, ta_IN, te_IN, th_TH, tl_PH, tr_TR, uk_UA, vi_VN, zh_CN, zh_HK, zh_TW
      metaOgType, // (Optional) Possible values: article
      metaOgTitle, // (Optional) Any string of 0-255 symbols in length.
      metaOgDescription, // (Optional) Any string of 0-255 symbols in length.
      metaOgUrl, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      metaOgImage, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      metaArticleSection, // (Optional) Any string of 0-255 symbols in length.
      metaTwitterCardType, // (Optional) Valid values: product, summary
      metaTwitterTitle, // (Optional) Any string of 0-255 symbols in length.
      metaTwitterDescription, // (Optional) Any string of 0-255 symbols in length.
      metaTwitterImage, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      metaTwitterImageAlt, // (Optional) Any string of 0-255 symbols in length.
      metaTwitterUrl, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      publishedAt, // (Optional) Timestamp value. Any positive integer.
      authorUserId, // (Optional) String 10-30 in length and starting with "u-"
    },
  });
}

export default updateAdmin;
