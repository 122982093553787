import call from "../../helpers/call";

function upload({ authToken, base64, sort, category }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/image/upload`,
    payload: {
      base64, // (Required) Is base64 image valid
      sort, // (Required) Must be positive integer or zero
      category, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default upload;
