import ReactDOM from "react-dom";
import React from "react";
import "./appFundamentals/index.css";
import reportWebVitals from "./appFundamentals/reportWebVitals";
import Root from "./appFundamentals/Root";

export default function initApp({
  components,
  mountPoint, // e.g. document.getElementById("root")
  options,
  extraGlobalComponents,
}) {
  const optionDefaults = {
    doReportWebVitals: false,
    doRenderRoutes: true,
  };

  components = components || {};

  options = options || {};
  options = {
    ...optionDefaults,
    ...options,
  };

  options.extensions = options.extensions || [];

  if (!mountPoint) {
    // if there is no Mount point available - no need to render any routes.
    // Yet there may be reactjs-portals as extraGlobalComponents so app should still try
    // to render
    options.doRenderRoutes = false;
  }
  components = components || {};

  ReactDOM.render(
    <React.StrictMode>
      <Root
        options={options}
        components={components}
        extraGlobalComponents={extraGlobalComponents}
      />
    </React.StrictMode>,
    mountPoint
  );

  if (options.doReportWebVitals === true) {
    reportWebVitals(console.log);
  }
}
