import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Skeleton from "@mui/material/Skeleton";
import AfterLogin from "jsx/CartLogin/AfterLogin";
import useIsUserGetLoaded from "stateManager/hooks/user/useIsUserGetLoaded";
import useUserGetLoadingState from "stateManager/hooks/user/useUserGetLoadingState";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";

export default function AfterLoginBtns() {
  const activeUser = useUserGetResult({});
  const activeUserLoadingState = useUserGetLoadingState({});
  const isUserLoaded = useIsUserGetLoaded({});
  const isActiveUserLoading =
    isUserLoaded === false || activeUserLoadingState.get("isLoading");
  const isLoggedIn = Boolean(activeUser.getIn(["result", "data", "userId"]));
  const userData = isLoggedIn
    ? activeUser.getIn(["result", "data"]).toJS()
    : null;

  return (
    <>
      {isActiveUserLoading ? (
        <Skeleton
          sx={{ height: "40px", borderRadius: "6px" }}
          width={160}
          variant="rectangular"
        />
      ) : isLoggedIn ? (
        <Fade in={true} timeout={350}>
          <Box
            sx={{
              width: "160px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <AfterLogin user={userData} />
          </Box>
        </Fade>
      ) : null}
    </>
  );
}
