import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const GET_MY_ITEMS_IN_CART_COUNT_TRY = "cart/getMyItemsInCartCount/TRY";
const GET_MY_ITEMS_IN_CART_COUNT_SUCCESS = "cart/getMyItemsInCartCount/SUCCESS";
const GET_MY_ITEMS_IN_CART_COUNT_FAILED = "cart/getMyItemsInCartCount/FAILED";

const GET_MY_ITEMS_IN_CART_COUNT_TRY_BY_IDENTIFIER =
  "cart/getMyItemsInCartCount/TRY_BY_IDENTIFIER";
const GET_MY_ITEMS_IN_CART_COUNT_SUCCESS_BY_IDENTIFIER =
  "cart/getMyItemsInCartCount/SUCCESS_BY_IDENTIFIER";
const GET_MY_ITEMS_IN_CART_COUNT_FAILED_BY_IDENTIFIER =
  "cart/getMyItemsInCartCount/FAILED_BY_IDENTIFIER";

const GET_VIEW_TRACK_TRY = "cart/getViewTrack/TRY";
const GET_VIEW_TRACK_SUCCESS = "cart/getViewTrack/SUCCESS";
const GET_VIEW_TRACK_FAILED = "cart/getViewTrack/FAILED";

const GET_VIEW_TRACK_TRY_BY_IDENTIFIER = "cart/getViewTrack/TRY_BY_IDENTIFIER";
const GET_VIEW_TRACK_SUCCESS_BY_IDENTIFIER =
  "cart/getViewTrack/SUCCESS_BY_IDENTIFIER";
const GET_VIEW_TRACK_FAILED_BY_IDENTIFIER =
  "cart/getViewTrack/FAILED_BY_IDENTIFIER";

const HOW_MUCH_LEFT_TO_GET_SPLIT_TRY = "cart/howMuchLeftToGetSplit/TRY";
const HOW_MUCH_LEFT_TO_GET_SPLIT_SUCCESS = "cart/howMuchLeftToGetSplit/SUCCESS";
const HOW_MUCH_LEFT_TO_GET_SPLIT_FAILED = "cart/howMuchLeftToGetSplit/FAILED";

const HOW_MUCH_LEFT_TO_GET_SPLIT_TRY_BY_IDENTIFIER =
  "cart/howMuchLeftToGetSplit/TRY_BY_IDENTIFIER";
const HOW_MUCH_LEFT_TO_GET_SPLIT_SUCCESS_BY_IDENTIFIER =
  "cart/howMuchLeftToGetSplit/SUCCESS_BY_IDENTIFIER";
const HOW_MUCH_LEFT_TO_GET_SPLIT_FAILED_BY_IDENTIFIER =
  "cart/howMuchLeftToGetSplit/FAILED_BY_IDENTIFIER";

const LIST_MY_ITEMS_TRY = "cart/listMyItems/TRY";
const LIST_MY_ITEMS_SUCCESS = "cart/listMyItems/SUCCESS";
const LIST_MY_ITEMS_FAILED = "cart/listMyItems/FAILED";

const LIST_MY_ITEMS_TRY_BY_IDENTIFIER = "cart/listMyItems/TRY_BY_IDENTIFIER";
const LIST_MY_ITEMS_SUCCESS_BY_IDENTIFIER =
  "cart/listMyItems/SUCCESS_BY_IDENTIFIER";
const LIST_MY_ITEMS_FAILED_BY_IDENTIFIER =
  "cart/listMyItems/FAILED_BY_IDENTIFIER";

const V2_GET_TRY = "cart/v2Get/TRY";
const V2_GET_SUCCESS = "cart/v2Get/SUCCESS";
const V2_GET_FAILED = "cart/v2Get/FAILED";

const V2_GET_TRY_BY_IDENTIFIER = "cart/v2Get/TRY_BY_IDENTIFIER";
const V2_GET_SUCCESS_BY_IDENTIFIER = "cart/v2Get/SUCCESS_BY_IDENTIFIER";
const V2_GET_FAILED_BY_IDENTIFIER = "cart/v2Get/FAILED_BY_IDENTIFIER";

const V2_ITEM_UPDATE_TRY = "cart/v2ItemUpdate/TRY";
const V2_ITEM_UPDATE_SUCCESS = "cart/v2ItemUpdate/SUCCESS";
const V2_ITEM_UPDATE_FAILED = "cart/v2ItemUpdate/FAILED";

const V2_ITEM_UPDATE_TRY_BY_IDENTIFIER = "cart/v2ItemUpdate/TRY_BY_IDENTIFIER";
const V2_ITEM_UPDATE_SUCCESS_BY_IDENTIFIER =
  "cart/v2ItemUpdate/SUCCESS_BY_IDENTIFIER";
const V2_ITEM_UPDATE_FAILED_BY_IDENTIFIER =
  "cart/v2ItemUpdate/FAILED_BY_IDENTIFIER";

const V2_PAY_TRY = "cart/v2Pay/TRY";
const V2_PAY_SUCCESS = "cart/v2Pay/SUCCESS";
const V2_PAY_FAILED = "cart/v2Pay/FAILED";

const V2_PAY_TRY_BY_IDENTIFIER = "cart/v2Pay/TRY_BY_IDENTIFIER";
const V2_PAY_SUCCESS_BY_IDENTIFIER = "cart/v2Pay/SUCCESS_BY_IDENTIFIER";
const V2_PAY_FAILED_BY_IDENTIFIER = "cart/v2Pay/FAILED_BY_IDENTIFIER";

const VIP_DISCOUNT_LIST_TRY = "cart/vipDiscountList/TRY";
const VIP_DISCOUNT_LIST_SUCCESS = "cart/vipDiscountList/SUCCESS";
const VIP_DISCOUNT_LIST_FAILED = "cart/vipDiscountList/FAILED";

const VIP_DISCOUNT_LIST_TRY_BY_IDENTIFIER =
  "cart/vipDiscountList/TRY_BY_IDENTIFIER";
const VIP_DISCOUNT_LIST_SUCCESS_BY_IDENTIFIER =
  "cart/vipDiscountList/SUCCESS_BY_IDENTIFIER";
const VIP_DISCOUNT_LIST_FAILED_BY_IDENTIFIER =
  "cart/vipDiscountList/FAILED_BY_IDENTIFIER";

const initialState = Map({
  getMyItemsInCartCount: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getViewTrack: shape.merge(Map({ dataByIdentifier: Map({}) })),
  howMuchLeftToGetSplit: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listMyItems: shape.merge(Map({ dataByIdentifier: Map({}) })),
  v2Get: shape.merge(Map({ dataByIdentifier: Map({}) })),
  v2ItemUpdate: shape.merge(Map({ dataByIdentifier: Map({}) })),
  v2Pay: shape.merge(Map({ dataByIdentifier: Map({}) })),
  vipDiscountList: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_MY_ITEMS_IN_CART_COUNT_TRY:
    case GET_VIEW_TRACK_TRY:
    case HOW_MUCH_LEFT_TO_GET_SPLIT_TRY:
    case LIST_MY_ITEMS_TRY:
    case V2_GET_TRY:
    case V2_ITEM_UPDATE_TRY:
    case V2_PAY_TRY:
    case VIP_DISCOUNT_LIST_TRY:
      return handleTry({ state, action });
    case GET_MY_ITEMS_IN_CART_COUNT_SUCCESS:
    case GET_VIEW_TRACK_SUCCESS:
    case HOW_MUCH_LEFT_TO_GET_SPLIT_SUCCESS:
    case LIST_MY_ITEMS_SUCCESS:
    case V2_GET_SUCCESS:
    case V2_ITEM_UPDATE_SUCCESS:
    case V2_PAY_SUCCESS:
    case VIP_DISCOUNT_LIST_SUCCESS:
      return handleSuccess({ state, action });
    case GET_MY_ITEMS_IN_CART_COUNT_FAILED:
    case GET_VIEW_TRACK_FAILED:
    case HOW_MUCH_LEFT_TO_GET_SPLIT_FAILED:
    case LIST_MY_ITEMS_FAILED:
    case V2_GET_FAILED:
    case V2_ITEM_UPDATE_FAILED:
    case V2_PAY_FAILED:
    case VIP_DISCOUNT_LIST_FAILED:
      return handleFailed({ state, action });
    case GET_MY_ITEMS_IN_CART_COUNT_TRY_BY_IDENTIFIER:
    case GET_VIEW_TRACK_TRY_BY_IDENTIFIER:
    case HOW_MUCH_LEFT_TO_GET_SPLIT_TRY_BY_IDENTIFIER:
    case LIST_MY_ITEMS_TRY_BY_IDENTIFIER:
    case V2_GET_TRY_BY_IDENTIFIER:
    case V2_ITEM_UPDATE_TRY_BY_IDENTIFIER:
    case V2_PAY_TRY_BY_IDENTIFIER:
    case VIP_DISCOUNT_LIST_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case GET_MY_ITEMS_IN_CART_COUNT_SUCCESS_BY_IDENTIFIER:
    case GET_VIEW_TRACK_SUCCESS_BY_IDENTIFIER:
    case HOW_MUCH_LEFT_TO_GET_SPLIT_SUCCESS_BY_IDENTIFIER:
    case LIST_MY_ITEMS_SUCCESS_BY_IDENTIFIER:
    case V2_GET_SUCCESS_BY_IDENTIFIER:
    case V2_ITEM_UPDATE_SUCCESS_BY_IDENTIFIER:
    case V2_PAY_SUCCESS_BY_IDENTIFIER:
    case VIP_DISCOUNT_LIST_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case GET_MY_ITEMS_IN_CART_COUNT_FAILED_BY_IDENTIFIER:
    case GET_VIEW_TRACK_FAILED_BY_IDENTIFIER:
    case HOW_MUCH_LEFT_TO_GET_SPLIT_FAILED_BY_IDENTIFIER:
    case LIST_MY_ITEMS_FAILED_BY_IDENTIFIER:
    case V2_GET_FAILED_BY_IDENTIFIER:
    case V2_ITEM_UPDATE_FAILED_BY_IDENTIFIER:
    case V2_PAY_FAILED_BY_IDENTIFIER:
    case VIP_DISCOUNT_LIST_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  GET_MY_ITEMS_IN_CART_COUNT_TRY,
  GET_MY_ITEMS_IN_CART_COUNT_SUCCESS,
  GET_MY_ITEMS_IN_CART_COUNT_FAILED,
  GET_MY_ITEMS_IN_CART_COUNT_TRY_BY_IDENTIFIER,
  GET_MY_ITEMS_IN_CART_COUNT_SUCCESS_BY_IDENTIFIER,
  GET_MY_ITEMS_IN_CART_COUNT_FAILED_BY_IDENTIFIER,

  GET_VIEW_TRACK_TRY,
  GET_VIEW_TRACK_SUCCESS,
  GET_VIEW_TRACK_FAILED,
  GET_VIEW_TRACK_TRY_BY_IDENTIFIER,
  GET_VIEW_TRACK_SUCCESS_BY_IDENTIFIER,
  GET_VIEW_TRACK_FAILED_BY_IDENTIFIER,

  HOW_MUCH_LEFT_TO_GET_SPLIT_TRY,
  HOW_MUCH_LEFT_TO_GET_SPLIT_SUCCESS,
  HOW_MUCH_LEFT_TO_GET_SPLIT_FAILED,
  HOW_MUCH_LEFT_TO_GET_SPLIT_TRY_BY_IDENTIFIER,
  HOW_MUCH_LEFT_TO_GET_SPLIT_SUCCESS_BY_IDENTIFIER,
  HOW_MUCH_LEFT_TO_GET_SPLIT_FAILED_BY_IDENTIFIER,

  LIST_MY_ITEMS_TRY,
  LIST_MY_ITEMS_SUCCESS,
  LIST_MY_ITEMS_FAILED,
  LIST_MY_ITEMS_TRY_BY_IDENTIFIER,
  LIST_MY_ITEMS_SUCCESS_BY_IDENTIFIER,
  LIST_MY_ITEMS_FAILED_BY_IDENTIFIER,

  V2_GET_TRY,
  V2_GET_SUCCESS,
  V2_GET_FAILED,
  V2_GET_TRY_BY_IDENTIFIER,
  V2_GET_SUCCESS_BY_IDENTIFIER,
  V2_GET_FAILED_BY_IDENTIFIER,

  V2_ITEM_UPDATE_TRY,
  V2_ITEM_UPDATE_SUCCESS,
  V2_ITEM_UPDATE_FAILED,
  V2_ITEM_UPDATE_TRY_BY_IDENTIFIER,
  V2_ITEM_UPDATE_SUCCESS_BY_IDENTIFIER,
  V2_ITEM_UPDATE_FAILED_BY_IDENTIFIER,

  V2_PAY_TRY,
  V2_PAY_SUCCESS,
  V2_PAY_FAILED,
  V2_PAY_TRY_BY_IDENTIFIER,
  V2_PAY_SUCCESS_BY_IDENTIFIER,
  V2_PAY_FAILED_BY_IDENTIFIER,

  VIP_DISCOUNT_LIST_TRY,
  VIP_DISCOUNT_LIST_SUCCESS,
  VIP_DISCOUNT_LIST_FAILED,
  VIP_DISCOUNT_LIST_TRY_BY_IDENTIFIER,
  VIP_DISCOUNT_LIST_SUCCESS_BY_IDENTIFIER,
  VIP_DISCOUNT_LIST_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
