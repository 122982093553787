import call from "../../helpers/call";

function splitPaymentToggle({
  authToken,
  invoiceId,
  isEnabled,
  splitPaymentMonths,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/split-payment-toggle`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      isEnabled, // (Required) Value is a boolean. True or false.
      splitPaymentMonths, // (Required) Any positive integer. 0 is not valid.
    },
  });
}

export default splitPaymentToggle;
