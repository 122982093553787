import axios from "axios";
import { put } from "redux-saga/effects";
import authFlow from "../../reducers/authFlow";
import globalMessages from "../../reducers/globalMessages";

const _get = require("lodash.get");

export default function* tryAuthFlowGetAuthorizedUser({ data }) {
  let result = null;

  try {
    result = (yield axios.post(
      `${process.env.REACT_APP_AUTH_API_URL}/get-authorized-user`,
      {},
      {
        headers: {
          "x-auth": data.idToken,
        },
      }
    )).data;
  } catch (e) {
    result = {
      error: {
        code: "#42HGREJRD",
        message: e.message,
      },
    };
  }

  if (result.error === null) {
    // during profile update optimistically show local version of avatar while CDN is invalidating cache.
    if (_get(result, "data.user.picture.full-size")) {
      let optimistic_updated_avatar = localStorage.getItem(
        "authFlow-optimistic_updated_avatar"
      );

      try {
        optimistic_updated_avatar = JSON.parse(optimistic_updated_avatar);
      } catch (e) {
        // user changed structure?
        localStorage.removeItem("authFlow-optimistic_updated_avatar");
      }

      // optimistic avatar is valid for 3h only
      if (
        !_get(optimistic_updated_avatar, "base64") ||
        !_get(optimistic_updated_avatar, "atMs") ||
        Date.now() / 1000 - _get(optimistic_updated_avatar, "atMs") >
          60 * 60 * 3
      ) {
        localStorage.removeItem("authFlow-optimistic_updated_avatar");
      } else {
        result.data.user.picture["full-size"] =
          optimistic_updated_avatar.base64;
      }
    }

    // profile is saved as string at AWS Cognito
    if (
      _get(result, "data.profile") &&
      typeof _get(result, "data.profile") === "string"
    ) {
      try {
        result.data.profile = JSON.parse(result.data.profile);
      } catch (e) {
        result.data.profile = {};
      }
    }

    yield put({
      type: authFlow.actionTypes.GET_AUTHORIZED_USER_SUCCESS,
      data: result,
    });
  } else {
    yield put({
      type: authFlow.actionTypes.GET_AUTHORIZED_USER_FAILED,
      data: result,
    });

    if (_get(result, "error.code") !== "#42HGREJRD") {
      // probably token is expired and needs to refresh
      yield put({
        type: globalMessages.actionTypes.ADD_TO_QUEUE,
        data: { queue: "bottomLeft", message: "Please re-login" },
      });
      setTimeout(() => {
        if (process.env.REACT_APP_AUTH_LOGIN_PAGE_URL) {
          location.href = `${process.env.REACT_APP_AUTH_LOGIN_PAGE_URL}&utm_source=relogin&utm_medium=after-get-authorized-user-try&utm_id=auth`;
        } else {
          const _refreshToken = prompt("Enter refresh token", "");
          if (_refreshToken) {
            location.href = `?authFlow-refresh_token=${_refreshToken}`;
          }
        }
      }, 2000);
    }
  }
}
