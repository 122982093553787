import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import invoice from "../../reducers/invoice";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryInvoiceGet({ data }) {
  const payload = {
    invoiceId: data.invoiceId,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.invoiceGet(payload);
  } catch (e) {
    result = { error: { code: "#2FGA_tryInvoiceGet", message: e.message } };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: invoice.actionTypes.GET_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({ type: invoice.actionTypes.GET_FAILED, data: result.error });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
