import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import user from "../../reducers/user";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryUserBillingDetailsUpdate({ data }) {
  const payload = {
    userBillingDetailsId: data.userBillingDetailsId,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.name !== "undefined") {
    payload.name = data.name;
  }

  if (typeof data.email !== "undefined") {
    payload.email = data.email;
  }

  if (typeof data.address !== "undefined") {
    payload.address = data.address;
  }

  if (typeof data.city !== "undefined") {
    payload.city = data.city;
  }

  if (typeof data.state !== "undefined") {
    payload.state = data.state;
  }

  if (typeof data.postcode !== "undefined") {
    payload.postcode = data.postcode;
  }

  if (typeof data.country !== "undefined") {
    payload.country = data.country;
  }

  if (typeof data.vat !== "undefined") {
    payload.vat = data.vat;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.userBillingDetailsUpdate(payload);
  } catch (e) {
    result = {
      error: { code: "#2FGA_tryUserBillingDetailsUpdate", message: e.message },
    };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: user.actionTypes.BILLING_DETAILS_UPDATE_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Billing Information Saved" },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: user.actionTypes.BILLING_DETAILS_UPDATE_FAILED,
      data: result.error,
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
