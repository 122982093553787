import call from "../../helpers/call";

function v2ItemUpdate({
  authToken,
  productPlanId,
  quantity,
  addQuantity,
  removeQuantity,
  valueCentsPerItem,
  productPlanName,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart/v2-item-update`,
    payload: {
      productPlanId, // (Required) String 10-30 in length and starting with "pp-"
      quantity, // (Required) Must be positive integer or zero
      addQuantity, // (Optional) Must be positive integer or zero
      removeQuantity, // (Optional) Must be positive integer or zero
      valueCentsPerItem, // (Optional) Must be positive integer or zero
      productPlanName, // (Optional) Any text
    },
  });
}

export default v2ItemUpdate;
