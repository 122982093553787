import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useUserGetLoadingState from "../user/useUserGetLoadingState";
import useUserUpdateLoadingState from "../user/useUserUpdateLoadingState";
import useUserUpdateProfileTimezoneLoadingState from "../user/useUserUpdateProfileTimezoneLoadingState";
// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/user/tryUserGet";

export default function useUserGetResult({}, ___options) {
  const ___user = useSelector((___state) => ___state.user);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const userGetLoadingState = useUserGetLoadingState({}, ___options);
  const ___isLoading = userGetLoadingState.get("isLoading");
  const isUserUpdateLoadingState = useUserUpdateLoadingState({}, ___options);
  const isUserUpdateProfileTimezoneLoadingState =
    useUserUpdateProfileTimezoneLoadingState({}, ___options);

  useEffect(() => {
    if (
      ___isLoading === false &&
      isUserUpdateLoadingState.get("isLoading") === false &&
      isUserUpdateProfileTimezoneLoadingState.get("isLoading") === false
    ) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.user.get({});
      } else {
        ___actions.user.get({});
      }
    }
  }, [isUserUpdateLoadingState, isUserUpdateProfileTimezoneLoadingState]);

  if (___options && ___options.identifier) {
    return (
      ___user.getIn([
        "get",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___user.getIn(["get", "lastResult"]);
}
