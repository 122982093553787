import call from "../../helpers/call";

function getDataForUiRender({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/get-data-for-ui-render`,
    payload: {},
  });
}

export default getDataForUiRender;
