import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const CREATE_TRY = "userDraft/create/TRY";
const CREATE_SUCCESS = "userDraft/create/SUCCESS";
const CREATE_FAILED = "userDraft/create/FAILED";

const CREATE_TRY_BY_IDENTIFIER = "userDraft/create/TRY_BY_IDENTIFIER";
const CREATE_SUCCESS_BY_IDENTIFIER = "userDraft/create/SUCCESS_BY_IDENTIFIER";
const CREATE_FAILED_BY_IDENTIFIER = "userDraft/create/FAILED_BY_IDENTIFIER";

const GET_TRY = "userDraft/get/TRY";
const GET_SUCCESS = "userDraft/get/SUCCESS";
const GET_FAILED = "userDraft/get/FAILED";

const GET_TRY_BY_IDENTIFIER = "userDraft/get/TRY_BY_IDENTIFIER";
const GET_SUCCESS_BY_IDENTIFIER = "userDraft/get/SUCCESS_BY_IDENTIFIER";
const GET_FAILED_BY_IDENTIFIER = "userDraft/get/FAILED_BY_IDENTIFIER";

const LIST_TRY = "userDraft/list/TRY";
const LIST_SUCCESS = "userDraft/list/SUCCESS";
const LIST_FAILED = "userDraft/list/FAILED";

const LIST_TRY_BY_IDENTIFIER = "userDraft/list/TRY_BY_IDENTIFIER";
const LIST_SUCCESS_BY_IDENTIFIER = "userDraft/list/SUCCESS_BY_IDENTIFIER";
const LIST_FAILED_BY_IDENTIFIER = "userDraft/list/FAILED_BY_IDENTIFIER";

const REMOVE_TRY = "userDraft/remove/TRY";
const REMOVE_SUCCESS = "userDraft/remove/SUCCESS";
const REMOVE_FAILED = "userDraft/remove/FAILED";

const REMOVE_TRY_BY_IDENTIFIER = "userDraft/remove/TRY_BY_IDENTIFIER";
const REMOVE_SUCCESS_BY_IDENTIFIER = "userDraft/remove/SUCCESS_BY_IDENTIFIER";
const REMOVE_FAILED_BY_IDENTIFIER = "userDraft/remove/FAILED_BY_IDENTIFIER";

const UPDATE_TRY = "userDraft/update/TRY";
const UPDATE_SUCCESS = "userDraft/update/SUCCESS";
const UPDATE_FAILED = "userDraft/update/FAILED";

const UPDATE_TRY_BY_IDENTIFIER = "userDraft/update/TRY_BY_IDENTIFIER";
const UPDATE_SUCCESS_BY_IDENTIFIER = "userDraft/update/SUCCESS_BY_IDENTIFIER";
const UPDATE_FAILED_BY_IDENTIFIER = "userDraft/update/FAILED_BY_IDENTIFIER";

const initialState = Map({
  create: shape.merge(Map({ dataByIdentifier: Map({}) })),
  get: shape.merge(Map({ dataByIdentifier: Map({}) })),
  list: shape.merge(Map({ dataByIdentifier: Map({}) })),
  remove: shape.merge(Map({ dataByIdentifier: Map({}) })),
  update: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_TRY:
    case GET_TRY:
    case LIST_TRY:
    case REMOVE_TRY:
    case UPDATE_TRY:
      return handleTry({ state, action });
    case CREATE_SUCCESS:
    case GET_SUCCESS:
    case LIST_SUCCESS:
    case REMOVE_SUCCESS:
    case UPDATE_SUCCESS:
      return handleSuccess({ state, action });
    case CREATE_FAILED:
    case GET_FAILED:
    case LIST_FAILED:
    case REMOVE_FAILED:
    case UPDATE_FAILED:
      return handleFailed({ state, action });
    case CREATE_TRY_BY_IDENTIFIER:
    case GET_TRY_BY_IDENTIFIER:
    case LIST_TRY_BY_IDENTIFIER:
    case REMOVE_TRY_BY_IDENTIFIER:
    case UPDATE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case CREATE_SUCCESS_BY_IDENTIFIER:
    case GET_SUCCESS_BY_IDENTIFIER:
    case LIST_SUCCESS_BY_IDENTIFIER:
    case REMOVE_SUCCESS_BY_IDENTIFIER:
    case UPDATE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case CREATE_FAILED_BY_IDENTIFIER:
    case GET_FAILED_BY_IDENTIFIER:
    case LIST_FAILED_BY_IDENTIFIER:
    case REMOVE_FAILED_BY_IDENTIFIER:
    case UPDATE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  CREATE_TRY,
  CREATE_SUCCESS,
  CREATE_FAILED,
  CREATE_TRY_BY_IDENTIFIER,
  CREATE_SUCCESS_BY_IDENTIFIER,
  CREATE_FAILED_BY_IDENTIFIER,

  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  GET_TRY_BY_IDENTIFIER,
  GET_SUCCESS_BY_IDENTIFIER,
  GET_FAILED_BY_IDENTIFIER,

  LIST_TRY,
  LIST_SUCCESS,
  LIST_FAILED,
  LIST_TRY_BY_IDENTIFIER,
  LIST_SUCCESS_BY_IDENTIFIER,
  LIST_FAILED_BY_IDENTIFIER,

  REMOVE_TRY,
  REMOVE_SUCCESS,
  REMOVE_FAILED,
  REMOVE_TRY_BY_IDENTIFIER,
  REMOVE_SUCCESS_BY_IDENTIFIER,
  REMOVE_FAILED_BY_IDENTIFIER,

  UPDATE_TRY,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  UPDATE_TRY_BY_IDENTIFIER,
  UPDATE_SUCCESS_BY_IDENTIFIER,
  UPDATE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
