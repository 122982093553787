import call from "../../helpers/call";

function getAdmin({ authToken, noteId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/note/get-admin`,
    payload: {
      noteId, // (Required) String 10-30 in length and starting with "n-"
    },
  });
}

export default getAdmin;
