import call from "../../helpers/call";

function update({ authToken, productSubmittedId, meta }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/submit-product/update`,
    payload: {
      productSubmittedId, // (Required) String 10-30 in length and starting with "ps-"
      meta, // (Required) JS object with at least one key
    },
  });
}

export default update;
