import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useDiscussionListByProductLoadingState from "../discussion/useDiscussionListByProductLoadingState";
import useDiscussionMarkHelpfulLoadingState from "../discussion/useDiscussionMarkHelpfulLoadingState";
import useDiscussionPostNewForProductLoadingState from "../discussion/useDiscussionPostNewForProductLoadingState";
import useDiscussionEditMyDiscussionLoadingState from "../discussion/useDiscussionEditMyDiscussionLoadingState";
import useDiscussionPostNewReplyLoadingState from "../discussion/useDiscussionPostNewReplyLoadingState";
// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/discussion/tryDiscussionListByProduct";

export default function useDiscussionListByProductResult(
  { productId, page, perPage, sortBy, sortDirection },
  ___options
) {
  const ___discussion = useSelector((___state) => ___state.discussion);

  sortBy = sortBy || "createdAt";
  sortDirection = sortDirection || "desc";
  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const discussionListByProductLoadingState =
    useDiscussionListByProductLoadingState({}, ___options);
  const ___isLoading = discussionListByProductLoadingState.get("isLoading");
  const isDiscussionMarkHelpfulLoadingState =
    useDiscussionMarkHelpfulLoadingState({}, ___options);
  const isDiscussionPostNewForProductLoadingState =
    useDiscussionPostNewForProductLoadingState({}, ___options);
  const isDiscussionEditMyDiscussionLoadingState =
    useDiscussionEditMyDiscussionLoadingState({}, ___options);
  const isDiscussionPostNewReplyLoadingState =
    useDiscussionPostNewReplyLoadingState({}, ___options);

  useEffect(() => {
    if (
      ___isLoading === false &&
      isDiscussionMarkHelpfulLoadingState.get("isLoading") === false &&
      isDiscussionPostNewForProductLoadingState.get("isLoading") === false &&
      isDiscussionEditMyDiscussionLoadingState.get("isLoading") === false &&
      isDiscussionPostNewReplyLoadingState.get("isLoading") === false &&
      productId &&
      page &&
      perPage
    ) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.discussion.listByProduct({
          productId,
          page,
          perPage,
          sortBy,
          sortDirection,
        });
      } else {
        ___actions.discussion.listByProduct({
          productId,
          page,
          perPage,
          sortBy,
          sortDirection,
        });
      }
    }
  }, [
    isDiscussionMarkHelpfulLoadingState,
    isDiscussionPostNewForProductLoadingState,
    isDiscussionEditMyDiscussionLoadingState,
    isDiscussionPostNewReplyLoadingState,
    productId,
    page,
    perPage,
    sortBy,
    sortDirection,
  ]);

  if (___options && ___options.identifier) {
    return (
      ___discussion.getIn([
        "listByProduct",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___discussion.getIn(["listByProduct", "lastResult"]);
}
