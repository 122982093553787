import call from "../../helpers/call";

function requestResendEmailConfirmation({ authToken, email, recaptchaToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/request-resend-email-confirmation`,
    payload: {
      email, // (Required) Valid email address
      recaptchaToken, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default requestResendEmailConfirmation;
