import call from "../../helpers/call";

function modifiersListAdmin({ authToken, invoiceId, isArchived }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/modifiers-list-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      isArchived, // (Optional) Value is a boolean. True or false.
    },
  });
}

export default modifiersListAdmin;
