import call from "../../helpers/call";

function remove({ authToken, userDraftId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user-draft/remove`,
    payload: {
      userDraftId, // (Required) String 10-30 in length and starting with "ud-"
    },
  });
}

export default remove;
