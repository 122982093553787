import call from "../../helpers/call";

function imageDelete({ authToken, imageId, category }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/submit-product/image-delete`,
    payload: {
      imageId, // (Required) Is string120 and not empty
      category, // (Required) Is string120 and not empty
    },
  });
}

export default imageDelete;
