import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Countdown from "jsx/partials/Countdown";
import moment from "moment";
import React from "react";
import Emoji from "react-emoji-render";
import useIsNotificationsGetUnreadCountLoaded from "stateManager/hooks/notifications/useIsNotificationsGetUnreadCountLoaded";
import useIsNotificationsListMineLoaded from "stateManager/hooks/notifications/useIsNotificationsListMineLoaded";
import useIsNotificationsMarkAllAsReadAfterError from "stateManager/hooks/notifications/useIsNotificationsMarkAllAsReadAfterError";
import useIsNotificationsMarkAsReadAfterError from "stateManager/hooks/notifications/useIsNotificationsMarkAsReadAfterError";
import useNotificationsGetUnreadCountLoadingState from "stateManager/hooks/notifications/useNotificationsGetUnreadCountLoadingState";
import useNotificationsGetUnreadCountResult from "stateManager/hooks/notifications/useNotificationsGetUnreadCountResult";
import useNotificationsListMineLoadingState from "stateManager/hooks/notifications/useNotificationsListMineLoadingState";
import useNotificationsListMineResult from "stateManager/hooks/notifications/useNotificationsListMineResult";
import useIsNotificationsMarkAsReadAfterSuccess from "stateManager/hooks/notifications/useIsNotificationsMarkAsReadAfterSuccess";
import useActions from "stateManager/hooks/useActions";

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: function (number, withoutSuffix) {
      return withoutSuffix ? "now" : "a few seconds";
    },
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1m",
    MM: "%dM",
    y: "1y",
    yy: "%dy",
  },
});
const useStyles = makeStyles({
  listItemNew: {
    background: "rgba(236, 152, 54, 0.06)",
  },
});

export default function NotificationsPopover({
  id,
  open,
  anchorEl,
  handleClose,
}) {
  const theme = useTheme();
  const actions = useActions();
  const classes = useStyles();

  const isNotificationsListMineLoadingState =
    useNotificationsListMineLoadingState({});
  const isNotificationsListMineLoaded = useIsNotificationsListMineLoaded({});
  const notificationsListMineResult = useNotificationsListMineResult({
    page: 1,
    perPage: 50,
  });
  const isNotificationsListMineLoading =
    isNotificationsListMineLoadingState.get("isLoading") &&
    isNotificationsListMineLoaded === false;

  const unreadNotifications = useNotificationsGetUnreadCountResult({});
  const unreadNotificationsLoaded = useIsNotificationsGetUnreadCountLoaded({});
  const isUnreadNotificationsLoadingState =
    useNotificationsGetUnreadCountLoadingState({});
  const isUnreadNotificationsLoading =
    isUnreadNotificationsLoadingState.get("isLoading") &&
    unreadNotificationsLoaded === false;

  const unreadNotificationsCount =
    unreadNotificationsLoaded &&
    isUnreadNotificationsLoadingState.get("isLoading") === false &&
    unreadNotifications.getIn(["result", "data"]).size > 0
      ? unreadNotifications.getIn(["result", "data"]).toJS()[0].totalUnread
      : 0;

  const isNotificationsMarkAsReadAfterError =
    useIsNotificationsMarkAsReadAfterError({});

  const isNotificationsMarkAllAsReadAfterError =
    useIsNotificationsMarkAllAsReadAfterError({});

  const isNotificationsMarkAsReadAfterSuccess =
    useIsNotificationsMarkAsReadAfterSuccess({});

  const [unreadNotificationIDs, setUnreadNotificationIDs] = React.useState([]);
  const [readAllNotificationIDs, setReadAllNotificationIDs] = React.useState(
    []
  );
  const [currentReadNotificationID, setCurrentReadNotificationID] =
    React.useState("");
  const [currentRedirectUrl, setCurrentRedirectUrl] = React.useState("");

  React.useEffect(() => {
    if (isNotificationsMarkAsReadAfterSuccess && currentRedirectUrl) {
      setCurrentRedirectUrl("");
      handleClose();
      window.location = currentRedirectUrl;
    }
  }, [isNotificationsMarkAsReadAfterSuccess, currentRedirectUrl]);

  React.useEffect(() => {
    if (
      isNotificationsListMineLoaded &&
      notificationsListMineResult.get("wasSuccess")
    ) {
      let ids = [];
      notificationsListMineResult.getIn(["result", "data"]).forEach((each) => {
        if (each.get("readAt") === 0) {
          ids.push(each.get("notificationId"));
        }
      });
      setUnreadNotificationIDs(ids);
    }
  }, [isNotificationsListMineLoaded, notificationsListMineResult]);

  React.useEffect(() => {
    if (isNotificationsMarkAsReadAfterError) {
      setUnreadNotificationIDs([
        ...unreadNotificationIDs,
        currentReadNotificationID,
      ]);
      setCurrentReadNotificationID("");
    }
  }, [isNotificationsMarkAsReadAfterError]);

  React.useEffect(() => {
    if (isNotificationsMarkAllAsReadAfterError) {
      setUnreadNotificationIDs(readAllNotificationIDs);
      setCurrentReadNotificationID("");
      setReadAllNotificationIDs([]);
    }
  }, [isNotificationsMarkAllAsReadAfterError]);

  const rows = [];
  let countRows = 0;
  const earlierTodayRows = [];
  let countEarlierTodayRows = 0;
  if (
    isNotificationsListMineLoaded &&
    notificationsListMineResult.get("wasSuccess")
  ) {
    notificationsListMineResult
      .getIn(["result", "data"])
      .sort((a, b) => b.get("createdAt") - a.get("createdAt"))
      .forEach((notification) => {
        if (notification) {
          const details = {
            avatarUrl:
              notification.getIn(["meta", "author", "avatar"]) ||
              notification.getIn(["meta", "product", "logo", "url"]),
            // avatarInitial: notification.get("product_name")
            //   ? notification
            //       .get("product_name")
            //       .match(/\b\w/g)
            //       .join("")
            //       .slice(0, 2)
            //       .toUpperCase()
            //   : "D", //D=>Disucussion
            msg: notification.get("product_name"),
            timeAgo: moment.unix(notification.get("createdAt")).fromNow(true),
            redirectTo: notification.get("product_slug")
              ? `https://${
                  process.env.REACT_APP_MAIN_DOMAIN
                }/products/${notification.get("product_slug")}`
              : `https://${
                  process.env.REACT_APP_MAIN_DOMAIN
                }/products/${notification.getIn([
                  "meta",
                  "product",
                  "slug",
                ])}/community?discussion-id=${notification.get(
                  "relatedObjectId"
                )}#tabs`,
          };

          if (notification.get("type") === "DEAL_ENDING") {
            details.msg = (
              <>
                Hurry up! {notification.get("product_name")} deal is ending in{" "}
                {notification.get("product_dealEndedAt") && (
                  <Typography variant={"subtitle2"} component={"span"}>
                    <Countdown
                      date={notification.get("product_dealEndedAt")}
                      // date={moment().add(1, "day").unix()}
                      text
                    />
                  </Typography>
                )}
              </>
            );
          }
          if (notification.get("type") === "DEAL_IS_LIVE") {
            details.msg = `${notification.get(
              "product_name"
            )} deal just went live 🚀`;
          }
          if (notification.get("type") === "DISCUSSION_REPLIED") {
            details.msg = (
              <>
                {notification.getIn(["meta", "author", "name"])} replied to your
                question{" "}
                <Typography component={"span"} variant={"subtitle2"}>
                  {`"${notification.get("discussion_comment")}"`}
                </Typography>{" "}
                on {notification.getIn(["meta", "product", "name"])}
              </>
            );
          }
          if (notification.get("type") === "DISCUSSION_REACTED") {
            details.msg = (
              <>
                {notification.getIn(["meta", "author", "name"])} reacted{" "}
                <Emoji text={notification.getIn(["meta", "reaction"])} /> to
                your question{" "}
                <Typography component={"span"} variant={"subtitle2"}>
                  {`"${notification.get("discussion_comment")}"`}
                </Typography>
              </>
            );
          }
          if (notification.get("type") === "DISCUSSION_NEW_QUESTION") {
            details.msg = (
              <>
                {notification.getIn(["meta", "author", "name"])} created a new
                question{" "}
                <Typography component={"span"} variant={"subtitle2"}>
                  {`"${notification.get("discussion_comment")}"`}
                </Typography>{" "}
                on {notification.getIn(["meta", "product", "name"])}
              </>
            );
          }
          if (
            notification.get("createdAt") > moment().subtract(1, "day").unix()
          ) {
            if (notification.get("readAt") === 0) countRows++;
            rows.push(
              <ListItem
                key={notification.get("notificationId")}
                alignItems="flex-start"
                className={
                  unreadNotificationIDs.includes(
                    notification.get("notificationId")
                  )
                    ? classes.listItemNew
                    : ""
                }
                disablePadding
                secondaryAction={
                  <Typography variant={"caption"} color={"text.secondary"}>
                    {details.timeAgo}
                  </Typography>
                }
              >
                <ListItemButton
                  onClick={() =>
                    handleMarkAsRead(
                      notification.get("notificationId"),
                      details.redirectTo
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar alt="" src={details.avatarUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant={"body2"}
                        sx={{ width: { xs: 180, sm: 230 } }}
                      >
                        {details.msg}{" "}
                        {details.redirectTo &&
                          ["DEAL_ENDING", "DEAL_IS_LIVE"].includes(
                            notification.get("type")
                          ) && (
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant={"subtitle2"}
                              color="primary"
                            >
                              View Deal
                            </Typography>
                          )}
                      </Typography>
                    }
                    // secondary={
                    //   <Typography
                    //     sx={{ display: "inline" }}
                    //     component="span"
                    //     variant="body2"
                    //     color="primary"
                    //   >
                    //     View Deal
                    //   </Typography>
                    // }
                  />
                </ListItemButton>
              </ListItem>
            );
          } else {
            if (notification.get("readAt") === 0) countEarlierTodayRows++;
            earlierTodayRows.push(
              <ListItem
                key={notification.get("notificationId")}
                alignItems="flex-start"
                className={
                  notification.get("readAt") === 0 ? classes.listItemNew : ""
                }
                disablePadding
                secondaryAction={
                  <Typography variant={"caption"} color={"text.secondary"}>
                    {details.timeAgo}
                  </Typography>
                }
              >
                <ListItemButton
                  onClick={() =>
                    handleMarkAsRead(
                      notification.get("notificationId"),
                      details.redirectTo
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar alt="" src={details.avatarUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant={"body2"}
                        sx={{ width: { xs: 180, sm: 230 } }}
                      >
                        {details.msg}{" "}
                        {details.redirectTo &&
                          ["DEAL_ENDING", "DEAL_IS_LIVE"].includes(
                            notification.get("type")
                          ) && (
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant={"subtitle2"}
                              color="primary"
                            >
                              View Deal
                            </Typography>
                          )}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        }
      });
  }

  const handleMarkAsRead = (notificationId, redirectTo) => {
    setCurrentReadNotificationID(notificationId);
    const unReads = unreadNotificationIDs.filter(
      (eachId) => eachId !== notificationId
    );
    setUnreadNotificationIDs(unReads);
    actions.notifications.markAsRead({
      notificationId,
    });
    // if (redirectTo) window.location = redirectTo;
    if (redirectTo) {
      setCurrentRedirectUrl(redirectTo);
    }
  };

  const handleMarkAllAsRead = () => {
    actions.notifications.markAllAsRead({});
    setReadAllNotificationIDs(unreadNotificationIDs);
    setUnreadNotificationIDs([]);
    setCurrentReadNotificationID("");
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{ background: "rgba(0, 0, 0, 0.12)" }}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow:
            "0px 3px 8px -2px rgba(0, 0, 0, 0.06), 0px 2px 12px rgba(0, 0, 0, 0.04), 0px 1px 16px rgba(0, 0, 0, 0.02)",
          mt: theme.spacing(4),
          // overflow: "hidden",
          width: 344,
          // maxHeight: 462,
          background: theme.palette.background.default,
          // "&:hover": {
          //   overflowY: "auto",
          //   "&::-webkit-scrollbar": {
          //     width: "6px",
          //   },
          //   "&::-webkit-scrollbar-track": {
          //     // boxShadow: "inset 0 0 5px grey",
          //     // borderRadius: 4,
          //   },
          //   "&::-webkit-scrollbar-thumb": {
          //     borderRadius: "6px",
          //     background: "rgba(0, 0, 0, 0.12)",
          //   },
          // },
          borderRadius: theme.spacing(0.5),
          "& .MuiListItemAvatar-root": {
            minWidth: 0,
          },
          "& .MuiAvatar-root": {
            width: 40,
            height: 40,
            // ml: -0.5,
            mr: 1,
          },
          "& .MuiListItem-root": {
            mb: "2px",
          },
          "& .MuiTypography-body1": {
            fontSize: 14,
          },
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: theme.spacing(1, 2),
        }}
      >
        <Typography variant={"h5"}>Notifications</Typography>
        <Button
          variant={"text"}
          size={"small"}
          color={"inherit"}
          onClick={handleMarkAllAsRead}
          disabled={unreadNotificationsCount === 0}
          endIcon={<CheckRoundedIcon fontSize={"inherit"} />}
        >
          Mark all as read
        </Button>
      </Box>
      {isNotificationsListMineLoading ? (
        <Box sx={{ textAlign: "center", m: theme.spacing(4) }}>
          <CircularProgress color={"secondary"} size={14} />
        </Box>
      ) : (
        <>
          {earlierTodayRows.length === 0 && rows.length === 0 && (
            <Box sx={{ textAlign: "center", m: theme.spacing(2, 0) }}>
              <img src={"/img/no-notification.svg"} alt={""} />
              <Typography variant={"subtitle1"}>
                No notifications yet
              </Typography>
              <Typography variant={"body2"}>
                When you get notifications, they will show up here.
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              overflow: "hidden",
              maxHeight: 450,
              "&:hover": {
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  // boxShadow: "inset 0 0 5px grey",
                  // borderRadius: 4,
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "6px",
                  background: "rgba(0, 0, 0, 0.12)",
                },
              },
            }}
          >
            {unreadNotificationsCount > 0 && (
              <Box
                sx={{
                  padding: theme.spacing(1, 2),
                }}
              >
                <Typography variant={"subtitle1"}>
                  New (
                  {isUnreadNotificationsLoading ? (
                    <CircularProgress color={"secondary"} size={10} />
                  ) : (
                    unreadNotificationsCount
                  )}
                  )
                </Typography>
              </Box>
            )}

            {rows.length > 0 && (
              <List
                sx={{
                  width: "100%",
                  maxWidth: 344,
                  bgcolor: theme.palette.background.default,
                }}
              >
                {rows}
              </List>
            )}

            <Box
              sx={{
                display: "flex",
                m: theme.spacing(0.5, 1, 1),
                p: theme.spacing(1),
                background: "#F5F5F5",
                borderRadius: theme.spacing(0.5),
                alignItems: "start",
              }}
            >
              <Avatar
                src={`https://app.${process.env.REACT_APP_MAIN_DOMAIN}/img/vip-icon.svg`}
              />
              <Box>
                <Typography
                  variant={"body2"}
                  sx={{ width: { xs: 210, sm: 280 } }}
                >
                  Save 10% on all purchases and get more perks with PitchGround
                  VIP Subscription.{" "}
                  <a
                    href={"https://pitchground.com/vip"}
                    target={"new"}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant={"subtitle2"} color="primary">
                      Learn More
                    </Typography>
                  </a>
                </Typography>
              </Box>
            </Box>

            {earlierTodayRows.length > 0 && (
              <>
                <Box
                  sx={{
                    padding: theme.spacing(1, 2, 1),
                  }}
                >
                  <Typography variant={"subtitle1"}>Earlier</Typography>
                </Box>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 344,
                    bgcolor: theme.palette.background.default,
                  }}
                >
                  {earlierTodayRows}
                </List>
              </>
            )}
          </Box>
        </>
      )}
    </Popover>
  );
}
