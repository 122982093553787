import call from "../../helpers/call";

function coinbaseGetChargeInfo({ authToken, invoiceId, chargeId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/coinbase-get-charge-info`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      chargeId, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default coinbaseGetChargeInfo;
