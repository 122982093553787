import call from "../../helpers/call";

function purchasedListFromV1({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/coupons/purchased-list-from-v1`,
    payload: {},
  });
}

export default purchasedListFromV1;
