import axios from "axios";
import { put } from "redux-saga/effects";
import authFlow from "../../reducers/authFlow";
import globalMessages from "../../reducers/globalMessages";

export default function* tryAuthFlowGetTokens({ data }) {
  const result = (yield axios.post(
    `${process.env.REACT_APP_AUTH_API_URL}/exchange-code-for-tokens`,
    {
      code: data.code,
    }
  )).data;

  if (result.error === null) {
    yield put({
      type: authFlow.actionTypes.GET_TOKENS_SUCCESS,
      data: result.data,
    });
  } else {
    yield put({
      type: authFlow.actionTypes.GET_TOKENS_FAILED,
      data: result.error,
    });

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Please re-login" },
    });
    setTimeout(() => {
      if (process.env.REACT_APP_AUTH_LOGIN_PAGE_URL) {
        location.href = `${process.env.REACT_APP_AUTH_LOGIN_PAGE_URL}&utm_source=relogin&utm_medium=after-get-tokens-try&utm_id=auth`;
      } else {
        const _refreshToken = prompt("Enter refresh token", "");
        if (_refreshToken) {
          location.href = `?authFlow-refresh_token=${_refreshToken}`;
        }
      }
    }, 2000);
  }
}
