import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

/*
Second param is optional object with options:

@identifier - to get loading state by single identifier
@doUseFirstLoading - get loading state of first loading item either regular or any identifier
*/

export default function useDiscussionListByProductLoadingState({}, ___options) {
  const discussion = useSelector((___state) => ___state.discussion);
  let result = null;

  if (___options && ___options.identifier) {
    result =
      discussion.getIn([
        "listByProduct",
        "dataByIdentifier",
        ___options.identifier,
        "loading",
      ]) || ___shape.get("loading");
  }

  if (result === null && ___options && ___options.doUseFirstLoading) {
    if (discussion.getIn(["remove", "loading", "isLoading"]) === true) {
      result = discussion.getIn(["remove", "loading"]);
    }

    if (result === null) {
      let stateByIdentifiers = discussion.getIn([
        "listByProduct",
        "dataByIdentifier",
      ]);
      if (stateByIdentifiers && stateByIdentifiers.size > 0) {
        stateByIdentifiers.forEach((byIdentifier, identifierKey) => {
          if (
            discussion.getIn([
              "listByProduct",
              "dataByIdentifier",
              identifierKey,
              "loading",
              "isLoading",
            ]) === true
          ) {
            result = discussion.getIn([
              "listByProduct",
              "dataByIdentifier",
              identifierKey,
              "loading",
            ]);
          }
        });
      }
    }
  }

  if (result === null) {
    result = discussion.getIn(["listByProduct", "loading"]);
  }

  return result;
}
