import { Map } from "immutable";
import { fromJS } from "immutable";

export const shape = Map({
  loading: Map({
    isLoading: false,
    payload: null,
    startedAtMs: 0,
  }),
  lastResult: Map({
    result: null,
    wasSuccess: null,
    atMs: 0,
  }),
  lastSuccess: Map({
    result: null,
    atMs: 0,
  }),
  lastError: Map({
    result: null,
    atMs: 0,
  }),
});

export function handleTry({ state, action }) {
  const actionNamespace = action.type.split("/")[1];
  return state
    .setIn([actionNamespace, "loading", "isLoading"], true)
    .setIn([actionNamespace, "loading", "payload"], fromJS(action.data))
    .setIn([actionNamespace, "loading", "startedAtMs"], Date.now())
    .setIn([actionNamespace, "loading", "finishedAtMs"], 0);
}

export function handleSuccess({ state, action }) {
  const actionNamespace = action.type.split("/")[1];
  return state
    .setIn([actionNamespace, "loading", "isLoading"], false)
    .setIn([actionNamespace, "loading", "finishedAtMs"], Date.now())
    .setIn([actionNamespace, "lastResult", "result"], fromJS(action.data))
    .setIn([actionNamespace, "lastResult", "wasSuccess"], true)
    .setIn([actionNamespace, "lastResult", "atMs"], Date.now())
    .setIn([actionNamespace, "lastSuccess", "result"], fromJS(action.data))
    .setIn([actionNamespace, "lastSuccess", "atMs"], Date.now());
}

export function handleFailed({ state, action }) {
  const actionNamespace = action.type.split("/")[1];
  return state
    .setIn([actionNamespace, "loading", "isLoading"], false)
    .setIn([actionNamespace, "loading", "finishedAtMs"], Date.now())
    .setIn([actionNamespace, "lastResult", "result"], fromJS(action.data))
    .setIn([actionNamespace, "lastResult", "wasSuccess"], false)
    .setIn([actionNamespace, "lastResult", "atMs"], Date.now())
    .setIn([actionNamespace, "lastError", "result"], fromJS(action.data))
    .setIn([actionNamespace, "lastError", "atMs"], Date.now());
}

export function handleTryByIdentifier({ state, action }) {
  const actionNamespace = action.type.split("/")[1];
  const _path = [
    actionNamespace,
    "dataByIdentifier",
    action.data.___identifier,
  ];
  if (!state.getIn(_path)) {
    state = state.setIn(_path, shape);
  }
  return state
    .setIn([..._path, "loading", "isLoading"], true)
    .setIn([..._path, "loading", "payload"], fromJS(action.data.payload))
    .setIn([..._path, "loading", "startedAtMs"], Date.now())
    .setIn([..._path, "loading", "finishedAtMs"], 0);
}

export function handleSuccessByIdentifier({ state, action }) {
  const actionNamespace = action.type.split("/")[1];
  const _tmp = fromJS(action.data.payload);
  const _path = [
    actionNamespace,
    "dataByIdentifier",
    action.data.___identifier,
  ];
  if (!state.getIn(_path)) {
    state = state.setIn(_path, shape);
  }

  return state
    .setIn([..._path, "loading", "isLoading"], false)
    .setIn([..._path, "loading", "finishedAtMs"], Date.now())
    .setIn([..._path, "lastResult", "result"], _tmp)
    .setIn([..._path, "lastResult", "wasSuccess"], true)
    .setIn([..._path, "lastResult", "atMs"], Date.now())
    .setIn([..._path, "lastSuccess", "result"], _tmp)
    .setIn([..._path, "lastSuccess", "atMs"], Date.now());
}

export function handleFailedByIdentifier({ state, action }) {
  const actionNamespace = action.type.split("/")[1];
  const _tmp = fromJS(action.data.payload);
  const _path = [
    actionNamespace,
    "dataByIdentifier",
    action.data.___identifier,
  ];
  if (!state.getIn(_path)) {
    state = state.setIn(_path, shape);
  }
  return state
    .setIn([..._path, "loading", "isLoading"], false)
    .setIn([..._path, "loading", "finishedAtMs"], Date.now())
    .setIn([..._path, "lastResult", "result"], _tmp)
    .setIn([..._path, "lastResult", "wasSuccess"], false)
    .setIn([..._path, "lastResult", "atMs"], Date.now())
    .setIn([..._path, "lastError", "result"], _tmp)
    .setIn([..._path, "lastError", "atMs"], Date.now());
}
