import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import useFavoriteAddLoadingState from "stateManager/hooks/favorite/useFavoriteAddLoadingState";
import useFavoriteListLoadingState from "stateManager/hooks/favorite/useFavoriteListLoadingState";
import useFavoriteListResult from "stateManager/hooks/favorite/useFavoriteListResult";
import useFavoriteRemoveLoadingState from "stateManager/hooks/favorite/useFavoriteRemoveLoadingState";
import useActions from "stateManager/hooks/useActions";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

export default function AddToFavoritesBtn({ productId }) {
  const actions = useActions();

  const favoritesListResult = useFavoriteListResult({});
  const favoritesList = favoritesListResult.getIn(["result", "data"]);

  const favoriteListLoadingState = useFavoriteListLoadingState({});
  const favAddLoadingState = useFavoriteAddLoadingState({});
  const favRemoveLoadingState = useFavoriteRemoveLoadingState({});
  const isLoading =
    favAddLoadingState.get("isLoading") === true ||
    favRemoveLoadingState.get("isLoading") === true ||
    favoriteListLoadingState.get("isLoading") === true;

  const isProductFavorite =
    favoritesList &&
    favoritesList.find((el) => el.get("productId") === productId);

  // auth status
  const user = useUserGetResult({});
  const isLoggedIn =
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";

  const heartIcon = !isProductFavorite ? (
    <FavoriteBorderOutlinedIcon
      style={{
        color: "white",
        height: "20px",
        width: "20px",
      }}
    />
  ) : (
    <FavoriteOutlinedIcon
      style={{ color: "white", height: "20px", width: "20px" }}
    />
  );

  return (
    <IconButton
      sx={{
        backgroundColor: "rgba(35, 31, 31, 0.4)",
        // padding: "3px",
        "&:hover": {
          backgroundColor: "rgba(35, 31, 31, 0.4)",
        },
      }}
      onClick={() => {
        if (!isLoggedIn) {
          actions.globalMessages.addToQueue({
            message: "Need to login first",
          });
          openLoginScreenToContinue();
          return undefined;
        }

        if (isProductFavorite) actions.favorite.remove({ productId });
        else actions.favorite.add({ productId });
      }}
    >
      {isLoading ? <CircularProgress color="secondary" size={20} /> : heartIcon}
    </IconButton>
  );
}
