import call from "../../helpers/call";

function getPre_1575217086StatsAdmin({ authToken, userId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate/get-pre-1575217086-stats-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
    },
  });
}

export default getPre_1575217086StatsAdmin;
