import BtnAddToCart from "jsx/BtnAddToCart";
import React from "react";
import ReactDOM from "react-dom";

export default function AddToCart() {
  const portals = [];
  const btns = document.querySelectorAll(".user-app-btn-add-to-cart");

  for (let i = 0; i < btns.length; i++) {
    const placeholder = btns[i];
    const productPlanId = placeholder.dataset.productPlanId;
    const valueCentsPerItem = placeholder.dataset.valueCentsPerItem;
    const productPlanName = placeholder.dataset.productPlanName;
    // if there was any placeholder content, e.g. loading state - clear it out
    placeholder.innerHTML = "";
    portals.push(
      ReactDOM.createPortal(
        <BtnAddToCart
          productPlanId={productPlanId}
          valueCentsPerItem={valueCentsPerItem}
          productPlanName={productPlanName}
        />,
        placeholder
      )
    );
  }

  return portals;
}
