import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const LIST_PRODUCT_SALES_TRY = "analytics/listProductSales/TRY";
const LIST_PRODUCT_SALES_SUCCESS = "analytics/listProductSales/SUCCESS";
const LIST_PRODUCT_SALES_FAILED = "analytics/listProductSales/FAILED";

const LIST_PRODUCT_SALES_TRY_BY_IDENTIFIER =
  "analytics/listProductSales/TRY_BY_IDENTIFIER";
const LIST_PRODUCT_SALES_SUCCESS_BY_IDENTIFIER =
  "analytics/listProductSales/SUCCESS_BY_IDENTIFIER";
const LIST_PRODUCT_SALES_FAILED_BY_IDENTIFIER =
  "analytics/listProductSales/FAILED_BY_IDENTIFIER";

const initialState = Map({
  listProductSales: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_PRODUCT_SALES_TRY:
      return handleTry({ state, action });
    case LIST_PRODUCT_SALES_SUCCESS:
      return handleSuccess({ state, action });
    case LIST_PRODUCT_SALES_FAILED:
      return handleFailed({ state, action });
    case LIST_PRODUCT_SALES_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case LIST_PRODUCT_SALES_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case LIST_PRODUCT_SALES_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  LIST_PRODUCT_SALES_TRY,
  LIST_PRODUCT_SALES_SUCCESS,
  LIST_PRODUCT_SALES_FAILED,
  LIST_PRODUCT_SALES_TRY_BY_IDENTIFIER,
  LIST_PRODUCT_SALES_SUCCESS_BY_IDENTIFIER,
  LIST_PRODUCT_SALES_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
