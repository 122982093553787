import call from "../../helpers/call";

function confirmEmail({ authToken, emailConfirmationToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/confirm-email`,
    payload: {
      emailConfirmationToken, // (Required) Any text
    },
  });
}

export default confirmEmail;
