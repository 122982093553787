import call from "../../helpers/call";

function coinbaseGetCharge({ authToken, invoiceId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/coinbase-get-charge`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
    },
  });
}

export default coinbaseGetCharge;
