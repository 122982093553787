import call from "../../helpers/call";

function subscriptionList({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product-plan/subscription-list`,
    payload: {},
  });
}

export default subscriptionList;
