import propTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import useActions from "../../hooks/useActions";
import useGetGlobalMessages from "../../hooks/globalMessages/useGetGlobalMessages";
import Snackbar from "@mui/material/Snackbar";
import usePrevious from "hook-use-previous";
import { is } from "immutable";
import Fade from "@mui/material/Fade";

export default function GlobalMessage({ queue }) {
  const actions = useActions();
  const messages = useGetGlobalMessages({ queue });
  const [nextMessage, setNextMessage] = useState(null);
  const [doSwitchToNextMessage, setDoSwitchToNextMessage] = useState(false);
  const [wasRemovedBeforeTimeout, setWasRemovedBeforeTimeout] = useState(false);

  const lastMessage = usePrevious(nextMessage);

  useEffect(() => {
    const _nextMessage = messages ? messages.first() : null;
    if (doSwitchToNextMessage === true) {
      setDoSwitchToNextMessage(false);
    }

    if (_nextMessage && nextMessage) {
      // there is new message yet there is message already visible. To switch messages need to animate out & then in
      if (is(_nextMessage, nextMessage) === false) {
        setNextMessage(null);
        setTimeout(() => {
          setDoSwitchToNextMessage(true);
        }, 300);
      }
    } else {
      if ((_nextMessage && !nextMessage) || (!_nextMessage && nextMessage)) {
        setNextMessage(_nextMessage);
        if (_nextMessage) {
          setTimeout(() => {
            actions.globalMessages.removeFromQueue({
              queue,
              queueItem: _nextMessage,
            });
            // min timeout is 4sec.
            // max timeout is 10 sec.
            // https://material.io/components/snackbars#behavior
          }, Math.min(Math.max(Math.round(_nextMessage.get("timeoutSeconds") * 1000), 4000), 10000));
        }
      }
    }
  }, [messages, doSwitchToNextMessage, wasRemovedBeforeTimeout]);

  let vertical = "bottom";
  let horizontal = "center";

  if (queue === "topLeft") {
    vertical = "top";
    horizontal = "left";
  }
  if (queue === "topCenter") {
    vertical = "top";
    horizontal = "center";
  }
  if (queue === "topRight") {
    vertical = "top";
    horizontal = "right";
  }

  if (queue === "bottomLeft") {
    vertical = "bottom";
    horizontal = "left";
  }
  if (queue === "bottomCenter") {
    vertical = "bottom";
    horizontal = "center";
  }
  if (queue === "bottomRight") {
    vertical = "bottom";
    horizontal = "right";
  }

  let messageText = nextMessage ? nextMessage.get("message") : "";
  let action = nextMessage ? nextMessage.get("action") : null;
  let doHideOnActionClick = nextMessage
    ? nextMessage.get("doHideOnActionClick") !== false
    : true;

  if (!messageText && lastMessage) {
    // during animation last message needs to stay or size changes and animation is not pleasant
    messageText = lastMessage ? lastMessage.get("message") : "";
  }

  if (!action && lastMessage) {
    // during animation last message needs to stay as it was
    action = lastMessage ? lastMessage.get("action") : null;
    doHideOnActionClick = lastMessage
      ? lastMessage.get("doHideOnActionClick") !== false
      : true;
  }

  if (action && doHideOnActionClick === true) {
    action = (
      <span
        onClick={() => {
          setWasRemovedBeforeTimeout(true);
          actions.globalMessages.removeFromQueue({
            queue,
            queueItem: nextMessage,
          });

          setTimeout(() => {
            setWasRemovedBeforeTimeout(false);
            setNextMessage(null);
          }, 300);
        }}
      >
        {action}
      </span>
    );
  }

  return (
    <Snackbar
      TransitionComponent={Fade}
      anchorOrigin={{ vertical, horizontal }}
      open={Boolean(nextMessage) && wasRemovedBeforeTimeout === false}
      message={messageText}
      action={action}
    />
  );
}

GlobalMessage.defaultProps = {
  queue: "bottomCenter",
};

GlobalMessage.propTypes = {
  queue: propTypes.string,
};
