import call from "../../helpers/call";

function updateAdmin({
  authToken,
  productPlanId,
  couponPoolId,
  name,
  type,
  price,
  oldPrice,
  discount,
  couponsToIssue,
  isActive,
  redeemInstructions,
  features,
  label_1,
  label_2,
  label_3,
  label_1_options,
  label_2_options,
  label_3_options,
  sort,
  limitPurchasesPerAccount,
  isOnePlusOneDeal,
  plusOneCouponPoolId,
  plusOneCouponDeliveryTime,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product-plan/update-admin`,
    payload: {
      productPlanId, // (Required) String 10-30 in length and starting with "pp-"
      couponPoolId, // (Optional) String 10-30 in length and starting with "cp-"
      name, // (Optional) String. 1 to 50 symbols in length.
      type, // (Optional) String. One of: lifetime, monthly, yearly, 2years
      price, // (Optional) Must be positive integer or zero
      oldPrice, // (Optional) Any text
      discount, // (Optional) Any text
      couponsToIssue, // (Optional) Must be positive integer or zero
      isActive, // (Optional) Value is a boolean. True or false.
      redeemInstructions, // (Optional) Any text
      features, // (Optional) Any text
      label_1, // (Optional) Any text
      label_2, // (Optional) Any text
      label_3, // (Optional) Any text
      label_1_options, // (Optional) JS object with at least one key
      label_2_options, // (Optional) Any text
      label_3_options, // (Optional) Any text
      sort, // (Optional) Must be positive integer or zero
      limitPurchasesPerAccount, // (Optional) Any positive integer. 0 is not valid.
      isOnePlusOneDeal, // (Optional) Value is a boolean. True or false.
      plusOneCouponPoolId, // (Optional) String 10-30 in length and starting with "cp-"
      plusOneCouponDeliveryTime, // (Optional) Is plus one coupon delivery time valid
    },
  });
}

export default updateAdmin;
