import call from "../../helpers/call";

function updateProfileTimezone({ authToken, timezone }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/update-profile-timezone`,
    payload: {
      timezone, // (Required) One of moment.js timezones: https://stackoverflow.com/a/67105480/1737158
    },
  });
}

export default updateProfileTimezone;
