import call from "../../helpers/call";

function create({ authToken, meta }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/submit-product/create`,
    payload: {
      meta, // (Required) JS object with at least one key
    },
  });
}

export default create;
