import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useProductListMyProductsLoadingState from "../product/useProductListMyProductsLoadingState";

// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/product/tryProductListMyProducts";

export default function useProductListMyProductsResult({}, ___options) {
  const ___product = useSelector((___state) => ___state.product);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const productListMyProductsLoadingState =
    useProductListMyProductsLoadingState({}, ___options);
  const ___isLoading = productListMyProductsLoadingState.get("isLoading");

  useEffect(() => {
    if (___isLoading === false) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.product.listMyProducts({});
      } else {
        ___actions.product.listMyProducts({});
      }
    }
  }, []);

  if (___options && ___options.identifier) {
    return (
      ___product.getIn([
        "listMyProducts",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___product.getIn(["listMyProducts", "lastResult"]);
}
