import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useProductPlansListForPublishedProductLoadingState from "../productPlans/useProductPlansListForPublishedProductLoadingState";

// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/productPlans/tryProductPlansListForPublishedProduct";

export default function useProductPlansListForPublishedProductResult(
  { productId },
  ___options
) {
  const ___productPlans = useSelector((___state) => ___state.productPlans);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const productPlansListForPublishedProductLoadingState =
    useProductPlansListForPublishedProductLoadingState({}, ___options);
  const ___isLoading =
    productPlansListForPublishedProductLoadingState.get("isLoading");

  useEffect(() => {
    if (___isLoading === false && productId) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.productPlans.listForPublishedProduct({
          productId,
        });
      } else {
        ___actions.productPlans.listForPublishedProduct({ productId });
      }
    }
  }, [productId]);

  if (___options && ___options.identifier) {
    return (
      ___productPlans.getIn([
        "listForPublishedProduct",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___productPlans.getIn(["listForPublishedProduct", "lastResult"]);
}
