import configureStore from "./configureStore";

let store = null;
let history = null;
const initialState = {};

export default function getStore({
  options,
  doLogStateActionsToConsole,
  doSupportReduxDevtools,
  maxActionLogsInReduxDevTool,
  homepage,
}) {
  doLogStateActionsToConsole = doLogStateActionsToConsole === true;
  doSupportReduxDevtools = doSupportReduxDevtools === true;
  maxActionLogsInReduxDevTool = maxActionLogsInReduxDevTool || 100;
  homepage = homepage || "/";

  if (store === null) {
    const result = configureStore({
      options,
      initialState,
      doLogStateActionsToConsole,
      doSupportReduxDevtools,
      maxActionLogsInReduxDevTool,
      homepage,
    });
    store = result.store;
    history = result.history;
  }

  return {
    store,
    history,
  };
}
