import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import user from "../../reducers/user";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryUserBillingDetailsUpdateByIdentifier({ data }) {
  const payload = {
    userBillingDetailsId: data.payload.userBillingDetailsId,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.payload.name !== "undefined") {
    payload.name = data.payload.name;
  }

  if (typeof data.payload.email !== "undefined") {
    payload.email = data.payload.email;
  }

  if (typeof data.payload.address !== "undefined") {
    payload.address = data.payload.address;
  }

  if (typeof data.payload.city !== "undefined") {
    payload.city = data.payload.city;
  }

  if (typeof data.payload.state !== "undefined") {
    payload.state = data.payload.state;
  }

  if (typeof data.payload.postcode !== "undefined") {
    payload.postcode = data.payload.postcode;
  }

  if (typeof data.payload.country !== "undefined") {
    payload.country = data.payload.country;
  }

  if (typeof data.payload.vat !== "undefined") {
    payload.vat = data.payload.vat;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  const result = yield sdk.userBillingDetailsUpdate(payload);

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: user.actionTypes.BILLING_DETAILS_UPDATE_SUCCESS_BY_IDENTIFIER,
      data: {
        ___identifier: data.___identifier,
        payload: { data: result.data, meta: result.meta },
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Billing Information Saved" },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: user.actionTypes.BILLING_DETAILS_UPDATE_FAILED_BY_IDENTIFIER,
      data: { ___identifier: data.___identifier, payload: result.error },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
