import React from "react";
import useIsNotificationsGetUnreadCountLoaded from "stateManager/hooks/notifications/useIsNotificationsGetUnreadCountLoaded";
import useNotificationsGetUnreadCountLoadingState from "stateManager/hooks/notifications/useNotificationsGetUnreadCountLoadingState";
import useNotificationsGetUnreadCountResult from "stateManager/hooks/notifications/useNotificationsGetUnreadCountResult";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Badge from "@mui/material/Badge";

export default function UnreadNotificationsCountBadge() {
  const unreadNotifications = useNotificationsGetUnreadCountResult({});
  const unreadNotificationsLoaded = useIsNotificationsGetUnreadCountLoaded({});
  const isUnreadNotificationsLoadingState =
    useNotificationsGetUnreadCountLoadingState({});
  const isUnreadNotificationsLoading =
    isUnreadNotificationsLoadingState.get("isLoading") &&
    unreadNotificationsLoaded === false;

  const unreadNotificationsCount =
    unreadNotificationsLoaded &&
    isUnreadNotificationsLoadingState.get("isLoading") === false &&
    unreadNotifications.getIn(["result", "data"]) &&
    unreadNotifications.getIn(["result", "data"]).size > 0
      ? unreadNotifications.getIn(["result", "data"]).toJS()[0].totalUnread
      : 0;

  // if (
  //   isUnreadNotificationsLoadingState.get("isLoading") &&
  //   !unreadNotificationsLoaded
  // )
  //   return <CircularProgress color={"secondary"} size={14} />;

  return (
    <Badge color={"primary"} badgeContent={unreadNotificationsCount}>
      <NotificationsOutlinedIcon />
    </Badge>
  );
}
