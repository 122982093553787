import call from "../../helpers/call";

function addToCartAdmin({
  authToken,
  productId,
  productPlanId,
  userId,
  quantity,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart-item/add-to-cart-admin`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      productPlanId, // (Required) String 10-30 in length and starting with "pp-"
      userId, // (Required) String 10-30 in length and starting with "u-"
      quantity, // (Required) Any positive integer. 0 is not valid.
    },
  });
}

export default addToCartAdmin;
