import call from "../../helpers/call";

function banDomainAdmin({ authToken, domain }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user-registration/ban-domain-admin`,
    payload: {
      domain, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default banDomainAdmin;
