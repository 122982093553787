import call from "../../helpers/call";

function search({ authToken, query }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription-tracking-company/search`,
    payload: {
      query, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default search;
