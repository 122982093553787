import EmailMeAboutNewDeals from "jsx/EmailMeAboutNewDeals";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalEmailMeAboutNewDeal() {
  const portals = [];
  const places = document.querySelectorAll(".email-me-about-new-deals");

  for (let i = 0; i < places.length; i++) {
    const place = places[i];
    // if there was any place content, e.g. loading state - clear it out
    place.innerHTML = "";
    const productId = place.dataset.productId;
    portals.push(
      ReactDOM.createPortal(
        <EmailMeAboutNewDeals productId={productId} />,
        place
      )
    );
  }

  return portals;
}
