import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useCartV2GetLoadingState from "../cart/useCartV2GetLoadingState";
import useCartV2ItemUpdateLoadingState from "../cart/useCartV2ItemUpdateLoadingState";
import useCartV2PayLoadingState from "../cart/useCartV2PayLoadingState";
// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/cart/tryCartV2Get";

export default function useCartV2GetResult({}, ___options) {
  const ___cart = useSelector((___state) => ___state.cart);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const cartV2GetLoadingState = useCartV2GetLoadingState({}, ___options);
  const ___isLoading = cartV2GetLoadingState.get("isLoading");
  const isCartV2ItemUpdateLoadingState = useCartV2ItemUpdateLoadingState(
    {},
    ___options
  );
  const isCartV2PayLoadingState = useCartV2PayLoadingState({}, ___options);

  useEffect(() => {
    if (
      ___isLoading === false &&
      isCartV2ItemUpdateLoadingState.get("isLoading") === false &&
      isCartV2PayLoadingState.get("isLoading") === false
    ) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.cart.v2Get({});
      } else {
        ___actions.cart.v2Get({});
      }
    }
  }, [isCartV2ItemUpdateLoadingState, isCartV2PayLoadingState]);

  if (___options && ___options.identifier) {
    return (
      ___cart.getIn([
        "v2Get",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___cart.getIn(["v2Get", "lastResult"]);
}
