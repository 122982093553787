import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useUserGetIfUserBoughtProductLoadingState from "../user/useUserGetIfUserBoughtProductLoadingState";

// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/user/tryUserGetIfUserBoughtProduct";

export default function useUserGetIfUserBoughtProductResult(
  { productId },
  ___options
) {
  const ___user = useSelector((___state) => ___state.user);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const userGetIfUserBoughtProductLoadingState =
    useUserGetIfUserBoughtProductLoadingState({}, ___options);
  const ___isLoading = userGetIfUserBoughtProductLoadingState.get("isLoading");

  useEffect(() => {
    if (___isLoading === false && productId) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.user.getIfUserBoughtProduct({ productId });
      } else {
        ___actions.user.getIfUserBoughtProduct({ productId });
      }
    }
  }, [productId]);

  if (___options && ___options.identifier) {
    return (
      ___user.getIn([
        "getIfUserBoughtProduct",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___user.getIn(["getIfUserBoughtProduct", "lastResult"]);
}
