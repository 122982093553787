import call from "../../helpers/call";

function update({ authToken, facebookFbp, facebookFbc }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user-meta/update`,
    payload: {
      facebookFbp, // (Optional) Any string of 0-255 symbols in length.
      facebookFbc, // (Optional) Any string of 0-255 symbols in length.
    },
  });
}

export default update;
