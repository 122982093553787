import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const SEARCH_TRY = "subscriptionTrackingCompany/search/TRY";
const SEARCH_SUCCESS = "subscriptionTrackingCompany/search/SUCCESS";
const SEARCH_FAILED = "subscriptionTrackingCompany/search/FAILED";

const SEARCH_TRY_BY_IDENTIFIER =
  "subscriptionTrackingCompany/search/TRY_BY_IDENTIFIER";
const SEARCH_SUCCESS_BY_IDENTIFIER =
  "subscriptionTrackingCompany/search/SUCCESS_BY_IDENTIFIER";
const SEARCH_FAILED_BY_IDENTIFIER =
  "subscriptionTrackingCompany/search/FAILED_BY_IDENTIFIER";

const initialState = Map({
  search: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_TRY:
      return handleTry({ state, action });
    case SEARCH_SUCCESS:
      return handleSuccess({ state, action });
    case SEARCH_FAILED:
      return handleFailed({ state, action });
    case SEARCH_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case SEARCH_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case SEARCH_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  SEARCH_TRY,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  SEARCH_TRY_BY_IDENTIFIER,
  SEARCH_SUCCESS_BY_IDENTIFIER,
  SEARCH_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
