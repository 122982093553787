import call from "../../helpers/call";

function setStateAdmin({ authToken, invoiceId, state }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/set-state-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      state, // (Required) String. One of: available, disabled, refunded, sold
    },
  });
}

export default setStateAdmin;
