import useProductGetViewTrackResult from "stateManager/hooks/product/useProductGetViewTrackResult";

export default function TrackProductView({ productSlug }) {
  // track product views for marketing
  useProductGetViewTrackResult({
    productSlug,
    at: Math.round(Date.now() / 1000),
  });

  return null;
}
