import call from "../../helpers/call";

function listAdmin({
  authToken,
  searchQuery,
  page,
  perPage,
  orderDirection,
  state,
  couponPoolId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/coupons/list-admin`,
    payload: {
      searchQuery, // (Optional) Any text
      page, // (Optional) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any positive integer. Range: (0,1000]
      orderDirection, // (Optional) Valid values: desc, asc
      state, // (Optional) String. One of: available, disabled, refunded, sold
      couponPoolId, // (Optional) String 10-30 in length and starting with "cp-"
    },
  });
}

export default listAdmin;
