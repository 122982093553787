import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import wallet from "../../reducers/wallet";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";
import Button from "@mui/material/Button";

const numeral = require("numeral");
const changeCase = require("change-case");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryWalletCoverInvoice({ data }) {
  const payload = {
    invoiceId: data.invoiceId,
    amountCents: data.amountCents,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.walletCoverInvoice(payload);
  } catch (e) {
    result = {
      error: { code: "#2FGA_tryWalletCoverInvoice", message: e.message },
    };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        queue: "bottomLeft",
        message: `Covered ${numeral(data.amountCents / 100).format("$0,0.00")}`,
        action: (
          <Button href={`/invoices?invoice-id=${data.invoiceId}`}>
            Invoice
          </Button>
        ),
      },
    });

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: wallet.actionTypes.COVER_INVOICE_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        queue: "bottomLeft",
        message: changeCase.capitalCase(result.error.message),
      },
    });

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: wallet.actionTypes.COVER_INVOICE_FAILED,
      data: result.error,
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
