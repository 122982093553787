import call from "../../helpers/call";

function signUp({
  authToken,
  email,
  password,
  name,
  username,
  emailPublic,
  twitter,
  countryCode,
  dateOfBirth,
  location,
  profileImageBase64,
  biography,
  affiliateUserId,
  productId,
  productPlanId,
  referralMagicCode,
  recaptchaToken,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/sign-up`,
    payload: {
      email, // (Optional) Any text
      password, // (Optional) Any text
      name, // (Optional) Any text
      username, // (Optional) Any text
      emailPublic, // (Optional) Any text
      twitter, // (Optional) Any text
      countryCode, // (Optional) Any text
      dateOfBirth, // (Optional) Any text
      location, // (Optional) Any text
      profileImageBase64, // (Optional) Any text
      biography, // (Optional) Any text
      affiliateUserId, // (Optional) Any text
      productId, // (Optional) Any text
      productPlanId, // (Optional) Any text
      referralMagicCode, // (Optional) Any text
      recaptchaToken, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default signUp;
