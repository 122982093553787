import call from "../../helpers/call";

function getLatestPurchases({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/sales/get-latest-purchases`,
    payload: {},
  });
}

export default getLatestPurchases;
