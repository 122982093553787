import call from "../../helpers/call";

function listStatsAdmin({ authToken, searchQuery, page, perPage }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate/list-stats-admin`,
    payload: {
      searchQuery, // (Optional) Any string of 0-255 symbols in length.
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Must be positive integer or zero
    },
  });
}

export default listStatsAdmin;
