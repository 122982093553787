import call from "../../helpers/call";

function get({ authToken, slug }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/blog/get`,
    payload: {
      slug, // (Required) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
    },
  });
}

export default get;
