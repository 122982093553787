import call from "../../helpers/call";

function createDemoNotification({
  authToken,
  relatedProductId,
  relatedDiscussionId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/notifications/create-demo-notification`,
    payload: {
      relatedProductId, // (Optional) String 10-30 in length and starting with "p-"
      relatedDiscussionId, // (Optional) String 10-30 in length and starting with "d-"
    },
  });
}

export default createDemoNotification;
