import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import propTypes from "prop-types";
import { countryCodeToName } from "utils/countryCodeToName";
import isoCountries from "utils/countryCodeToName";

export default function BillingDetailsFormFields({
  name,
  setName,
  email,
  setEmail,
  address,
  setAddress,
  city,
  setCity,
  state,
  setState,
  postCode,
  setPostCode,
  country,
  setCountry,
  vat,
  setVat,
  onSubmit,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Name*"
          InputLabelProps={{ shrink: true }}
          fullWidth
          type={"text"}
          value={name}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Email*"
          InputLabelProps={{ shrink: true }}
          fullWidth
          type={"email"}
          value={email}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Address*"
          InputLabelProps={{ shrink: true }}
          fullWidth
          type={"text"}
          value={address}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="City*"
          InputLabelProps={{ shrink: true }}
          fullWidth
          type={"text"}
          value={city}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => setCity(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="State*"
          InputLabelProps={{ shrink: true }}
          fullWidth
          type={"text"}
          value={state}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => setState(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Post Code*"
          InputLabelProps={{ shrink: true }}
          fullWidth
          type={"text"}
          value={postCode}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => setPostCode(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id={"outlined-select-currency"}
          select
          label={"Country"}
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
          }}
          SelectProps={{
            native: true,
          }}
        >
          {Object.keys(isoCountries).map((code) => (
            <option key={code} value={code}>
              {countryCodeToName(code)}
            </option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="VAT/GST (Optional)"
          InputLabelProps={{ shrink: true }}
          fullWidth
          type={"text"}
          value={vat}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => setVat(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
BillingDetailsFormFields.propTypes = {
  name: propTypes.string,
  setName: propTypes.func.isRequired,
  email: propTypes.string,
  setEmail: propTypes.func.isRequired,
  address: propTypes.string,
  setAddress: propTypes.func.isRequired,
  city: propTypes.string,
  setCity: propTypes.func.isRequired,
  state: propTypes.string,
  setState: propTypes.func.isRequired,
  postCode: propTypes.string,
  setPostCode: propTypes.func.isRequired,
  country: propTypes.string,
  setCountry: propTypes.func.isRequired,
  vat: propTypes.string,
  setVat: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};
