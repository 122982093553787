import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useState } from "react";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";

const moment = require("moment-timezone");
const startDate = localStorage.getItem("bf_s1tartDate") || "2021-11-26 12:00";
const endDate = localStorage.getItem("bf_en1dDate") || "2021-11-27 12:00";

export default function Hellobar() {
  const [tick, setTick] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setTick(!tick), 333);
    return () => clearTimeout(timeout);
  }, [tick]);

  const user = useUserGetResult({});
  const _timezone =
    user.getIn(["result", "data", "timezone"]) || moment.tz.guess();

  const now = moment().tz(_timezone).unix();
  let percent = 0;
  if (
    now > moment.tz(`${startDate}`, "YYYY-MM-DD HH:mm", _timezone).unix() &&
    now < moment.tz(`${endDate}`, "YYYY-MM-DD HH:mm", _timezone).unix()
  ) {
    for (let i = 0; i < 24; i++) {
      // const date = i < 10 ? `${startDate} 0${i}:00` : `${startDate} ${i}:00`;
      const date = moment
        .tz(startDate, "YYYY-MM-DD HH:mm", _timezone)
        .add(i, "hour")
        .unix();
      if (now > date) {
        percent = 24 - i;
      }
    }
  }

  let message = null;

  if (now < moment.tz(`${startDate}`, "YYYY-MM-DD HH:mm", _timezone).unix()) {
    let hoursTillStart = moment
      .tz(`${startDate}`, "YYYY-MM-DD HH:mm", _timezone)
      .diff(moment.tz(_timezone), "hours");

    let minutesTillStart = moment
      .tz(`${startDate}`, "YYYY-MM-DD HH:mm", _timezone)
      .subtract(hoursTillStart, "hours")
      .diff(moment.tz(_timezone), "minutes");

    let secondsTillStart = moment
      .tz(`${startDate}`, "YYYY-MM-DD HH:mm", _timezone)
      .subtract(hoursTillStart, "hours")
      .subtract(minutesTillStart, "minutes")
      .diff(moment.tz(_timezone), "seconds");

    let hoursLeft = hoursTillStart < 10 ? `0${hoursTillStart}` : hoursTillStart;
    let minutesLeft =
      minutesTillStart < 10 ? `0${minutesTillStart}` : minutesTillStart;
    let secondsLeft =
      secondsTillStart < 10 ? `0${secondsTillStart}` : secondsTillStart;

    message = (
      <>
        24H Black Friday event starts in {hoursLeft}:{minutesLeft}:{secondsLeft}{" "}
        🤯😱🎉
      </>
    );
  }

  if (percent > 0) {
    let minutesLeft = moment()
      .add(1, "hour")
      .startOf("hour")
      .diff(moment(), "minutes");
    let secondsLeft =
      moment().add(1, "hour").startOf("hour").diff(moment(), "seconds") % 60;

    minutesLeft = minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft;
    secondsLeft = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft;

    message = (
      <>
        Current Discount: {percent}%. The discount will reduce in: 00:
        {minutesLeft}:{secondsLeft} Use
        <u style={{ margin: "0 6px" }}>BLACKFRIDAY</u> code on invoice 🤯😱🎉
      </>
    );
  }

  if (!message) {
    return null;
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#212121",
        padding: "16px 12px",
      }}
    >
      <div>
        <Typography
          variant={"body1"}
          color={"#FAFAFA"}
          textAlign={"center"}
          style={{ fontSize: "22px", fontWeight: "bold", lineHeight: "29px" }}
        >
          {message}
        </Typography>
      </div>
    </div>
  );
}
