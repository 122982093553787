import call from "../../helpers/call";

function vendorPercentageSet({ authToken, productId, vendorPercentage }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/vendor-percentage-set`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      vendorPercentage, // (Required) Must be positive integer or zero
    },
  });
}

export default vendorPercentageSet;
