import call from "../../helpers/call";

function refundSingleInvoiceItemAdmin({
  authToken,
  invoiceItemId,
  quantity,
  refundToGateway,
  totalAmountCents,
  refundTransactionId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/refund-single-invoice-item-admin`,
    payload: {
      invoiceItemId, // (Required) String 10-30 in length and starting with "ii-"
      quantity, // (Required) Any positive integer. 0 is not valid.
      refundToGateway, // (Required) Available values: stripe, paypal, wallet
      totalAmountCents, // (Required) Must be positive integer or zero
      refundTransactionId, // (Optional) Any string of 0-255 symbols in length.
    },
  });
}

export default refundSingleInvoiceItemAdmin;
