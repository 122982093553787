import call from "../../helpers/call";

function listActiveProducts({ authToken, searchQuery }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/products/list-active-products`,
    payload: {
      searchQuery, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default listActiveProducts;
