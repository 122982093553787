import call from "../../helpers/call";

function list({
  authToken,
  searchQuery,
  page,
  perPage,
  state,
  type,
  currencyId,
  tag,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription-tracking/list`,
    payload: {
      searchQuery, // (Optional) Any string of 0-120 symbols in length.
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any positive integer. Range: (0,1000]
      state, // (Optional) One of: active, pause, upcomingRenewal, cancel
      type, // (Optional) One of: Lifetime Deal, Subscription, Trial
      currencyId, // (Optional) String 10-30 in length and starting with "cur-"
      tag, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default list;
