import call from "../../helpers/call";

function listMyItems({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart/list-my-items`,
    payload: {},
  });
}

export default listMyItems;
