import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const ANALYTICS_GET_PRODUCT_SALES_STATS_TRY =
  "product/analyticsGetProductSalesStats/TRY";
const ANALYTICS_GET_PRODUCT_SALES_STATS_SUCCESS =
  "product/analyticsGetProductSalesStats/SUCCESS";
const ANALYTICS_GET_PRODUCT_SALES_STATS_FAILED =
  "product/analyticsGetProductSalesStats/FAILED";

const ANALYTICS_GET_PRODUCT_SALES_STATS_TRY_BY_IDENTIFIER =
  "product/analyticsGetProductSalesStats/TRY_BY_IDENTIFIER";
const ANALYTICS_GET_PRODUCT_SALES_STATS_SUCCESS_BY_IDENTIFIER =
  "product/analyticsGetProductSalesStats/SUCCESS_BY_IDENTIFIER";
const ANALYTICS_GET_PRODUCT_SALES_STATS_FAILED_BY_IDENTIFIER =
  "product/analyticsGetProductSalesStats/FAILED_BY_IDENTIFIER";

const GET_FOR_REFUND_TRY = "product/getForRefund/TRY";
const GET_FOR_REFUND_SUCCESS = "product/getForRefund/SUCCESS";
const GET_FOR_REFUND_FAILED = "product/getForRefund/FAILED";

const GET_FOR_REFUND_TRY_BY_IDENTIFIER =
  "product/getForRefund/TRY_BY_IDENTIFIER";
const GET_FOR_REFUND_SUCCESS_BY_IDENTIFIER =
  "product/getForRefund/SUCCESS_BY_IDENTIFIER";
const GET_FOR_REFUND_FAILED_BY_IDENTIFIER =
  "product/getForRefund/FAILED_BY_IDENTIFIER";

const GET_FOR_REVIEW_TRY = "product/getForReview/TRY";
const GET_FOR_REVIEW_SUCCESS = "product/getForReview/SUCCESS";
const GET_FOR_REVIEW_FAILED = "product/getForReview/FAILED";

const GET_FOR_REVIEW_TRY_BY_IDENTIFIER =
  "product/getForReview/TRY_BY_IDENTIFIER";
const GET_FOR_REVIEW_SUCCESS_BY_IDENTIFIER =
  "product/getForReview/SUCCESS_BY_IDENTIFIER";
const GET_FOR_REVIEW_FAILED_BY_IDENTIFIER =
  "product/getForReview/FAILED_BY_IDENTIFIER";

const GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY =
  "product/getProductDetailsForAnalytics/TRY";
const GET_PRODUCT_DETAILS_FOR_ANALYTICS_SUCCESS =
  "product/getProductDetailsForAnalytics/SUCCESS";
const GET_PRODUCT_DETAILS_FOR_ANALYTICS_FAILED =
  "product/getProductDetailsForAnalytics/FAILED";

const GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY_BY_IDENTIFIER =
  "product/getProductDetailsForAnalytics/TRY_BY_IDENTIFIER";
const GET_PRODUCT_DETAILS_FOR_ANALYTICS_SUCCESS_BY_IDENTIFIER =
  "product/getProductDetailsForAnalytics/SUCCESS_BY_IDENTIFIER";
const GET_PRODUCT_DETAILS_FOR_ANALYTICS_FAILED_BY_IDENTIFIER =
  "product/getProductDetailsForAnalytics/FAILED_BY_IDENTIFIER";

const GET_VIEW_TRACK_TRY = "product/getViewTrack/TRY";
const GET_VIEW_TRACK_SUCCESS = "product/getViewTrack/SUCCESS";
const GET_VIEW_TRACK_FAILED = "product/getViewTrack/FAILED";

const GET_VIEW_TRACK_TRY_BY_IDENTIFIER =
  "product/getViewTrack/TRY_BY_IDENTIFIER";
const GET_VIEW_TRACK_SUCCESS_BY_IDENTIFIER =
  "product/getViewTrack/SUCCESS_BY_IDENTIFIER";
const GET_VIEW_TRACK_FAILED_BY_IDENTIFIER =
  "product/getViewTrack/FAILED_BY_IDENTIFIER";

const LIST_MY_PRODUCTS_TRY = "product/listMyProducts/TRY";
const LIST_MY_PRODUCTS_SUCCESS = "product/listMyProducts/SUCCESS";
const LIST_MY_PRODUCTS_FAILED = "product/listMyProducts/FAILED";

const LIST_MY_PRODUCTS_TRY_BY_IDENTIFIER =
  "product/listMyProducts/TRY_BY_IDENTIFIER";
const LIST_MY_PRODUCTS_SUCCESS_BY_IDENTIFIER =
  "product/listMyProducts/SUCCESS_BY_IDENTIFIER";
const LIST_MY_PRODUCTS_FAILED_BY_IDENTIFIER =
  "product/listMyProducts/FAILED_BY_IDENTIFIER";

const initialState = Map({
  analyticsGetProductSalesStats: shape.merge(
    Map({ dataByIdentifier: Map({}) })
  ),
  getForRefund: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getForReview: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getProductDetailsForAnalytics: shape.merge(
    Map({ dataByIdentifier: Map({}) })
  ),
  getViewTrack: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listMyProducts: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ANALYTICS_GET_PRODUCT_SALES_STATS_TRY:
    case GET_FOR_REFUND_TRY:
    case GET_FOR_REVIEW_TRY:
    case GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY:
    case GET_VIEW_TRACK_TRY:
    case LIST_MY_PRODUCTS_TRY:
      return handleTry({ state, action });
    case ANALYTICS_GET_PRODUCT_SALES_STATS_SUCCESS:
    case GET_FOR_REFUND_SUCCESS:
    case GET_FOR_REVIEW_SUCCESS:
    case GET_PRODUCT_DETAILS_FOR_ANALYTICS_SUCCESS:
    case GET_VIEW_TRACK_SUCCESS:
    case LIST_MY_PRODUCTS_SUCCESS:
      return handleSuccess({ state, action });
    case ANALYTICS_GET_PRODUCT_SALES_STATS_FAILED:
    case GET_FOR_REFUND_FAILED:
    case GET_FOR_REVIEW_FAILED:
    case GET_PRODUCT_DETAILS_FOR_ANALYTICS_FAILED:
    case GET_VIEW_TRACK_FAILED:
    case LIST_MY_PRODUCTS_FAILED:
      return handleFailed({ state, action });
    case ANALYTICS_GET_PRODUCT_SALES_STATS_TRY_BY_IDENTIFIER:
    case GET_FOR_REFUND_TRY_BY_IDENTIFIER:
    case GET_FOR_REVIEW_TRY_BY_IDENTIFIER:
    case GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY_BY_IDENTIFIER:
    case GET_VIEW_TRACK_TRY_BY_IDENTIFIER:
    case LIST_MY_PRODUCTS_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case ANALYTICS_GET_PRODUCT_SALES_STATS_SUCCESS_BY_IDENTIFIER:
    case GET_FOR_REFUND_SUCCESS_BY_IDENTIFIER:
    case GET_FOR_REVIEW_SUCCESS_BY_IDENTIFIER:
    case GET_PRODUCT_DETAILS_FOR_ANALYTICS_SUCCESS_BY_IDENTIFIER:
    case GET_VIEW_TRACK_SUCCESS_BY_IDENTIFIER:
    case LIST_MY_PRODUCTS_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case ANALYTICS_GET_PRODUCT_SALES_STATS_FAILED_BY_IDENTIFIER:
    case GET_FOR_REFUND_FAILED_BY_IDENTIFIER:
    case GET_FOR_REVIEW_FAILED_BY_IDENTIFIER:
    case GET_PRODUCT_DETAILS_FOR_ANALYTICS_FAILED_BY_IDENTIFIER:
    case GET_VIEW_TRACK_FAILED_BY_IDENTIFIER:
    case LIST_MY_PRODUCTS_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  ANALYTICS_GET_PRODUCT_SALES_STATS_TRY,
  ANALYTICS_GET_PRODUCT_SALES_STATS_SUCCESS,
  ANALYTICS_GET_PRODUCT_SALES_STATS_FAILED,
  ANALYTICS_GET_PRODUCT_SALES_STATS_TRY_BY_IDENTIFIER,
  ANALYTICS_GET_PRODUCT_SALES_STATS_SUCCESS_BY_IDENTIFIER,
  ANALYTICS_GET_PRODUCT_SALES_STATS_FAILED_BY_IDENTIFIER,

  GET_FOR_REFUND_TRY,
  GET_FOR_REFUND_SUCCESS,
  GET_FOR_REFUND_FAILED,
  GET_FOR_REFUND_TRY_BY_IDENTIFIER,
  GET_FOR_REFUND_SUCCESS_BY_IDENTIFIER,
  GET_FOR_REFUND_FAILED_BY_IDENTIFIER,

  GET_FOR_REVIEW_TRY,
  GET_FOR_REVIEW_SUCCESS,
  GET_FOR_REVIEW_FAILED,
  GET_FOR_REVIEW_TRY_BY_IDENTIFIER,
  GET_FOR_REVIEW_SUCCESS_BY_IDENTIFIER,
  GET_FOR_REVIEW_FAILED_BY_IDENTIFIER,

  GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY,
  GET_PRODUCT_DETAILS_FOR_ANALYTICS_SUCCESS,
  GET_PRODUCT_DETAILS_FOR_ANALYTICS_FAILED,
  GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY_BY_IDENTIFIER,
  GET_PRODUCT_DETAILS_FOR_ANALYTICS_SUCCESS_BY_IDENTIFIER,
  GET_PRODUCT_DETAILS_FOR_ANALYTICS_FAILED_BY_IDENTIFIER,

  GET_VIEW_TRACK_TRY,
  GET_VIEW_TRACK_SUCCESS,
  GET_VIEW_TRACK_FAILED,
  GET_VIEW_TRACK_TRY_BY_IDENTIFIER,
  GET_VIEW_TRACK_SUCCESS_BY_IDENTIFIER,
  GET_VIEW_TRACK_FAILED_BY_IDENTIFIER,

  LIST_MY_PRODUCTS_TRY,
  LIST_MY_PRODUCTS_SUCCESS,
  LIST_MY_PRODUCTS_FAILED,
  LIST_MY_PRODUCTS_TRY_BY_IDENTIFIER,
  LIST_MY_PRODUCTS_SUCCESS_BY_IDENTIFIER,
  LIST_MY_PRODUCTS_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
