import call from "../../helpers/call";

function v2Pay({ authToken, gateway, payload, affiliateUserId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart/v2-pay`,
    payload: {
      gateway, // (Required) Any string of 0-120 symbols in length.
      payload, // (Required) JS object. Not null, could be empty object without any keys.
      affiliateUserId, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default v2Pay;
