import call from "../../helpers/call";

function update({ authToken, userDraftId, meta }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user-draft/update`,
    payload: {
      userDraftId, // (Required) String 10-30 in length and starting with "ud-"
      meta, // (Required) JS object. Not null, could be empty object without any keys.
    },
  });
}

export default update;
