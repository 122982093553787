import usePrevious from "hook-use-previous";
import { useSelector } from "react-redux";
import useNotificationsMarkAllAsReadLoadingState from "stateManager/hooks/notifications/useNotificationsMarkAllAsReadLoadingState";

export default function useIsNotificationsMarkAllAsReadAfterError(
  {},
  ___options
) {
  const loadingState = useNotificationsMarkAllAsReadLoadingState(
    {},
    ___options
  );
  const notifications = useSelector((___state) => ___state.notifications);
  const wasLoading = usePrevious(loadingState.get("isLoading"));

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      notifications.getIn([
        "markAllAsRead",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
        "wasSuccess",
      ]) === false
    );
  } else {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      notifications.getIn(["markAllAsRead", "lastResult", "wasSuccess"]) ===
        false
    );
  }
}
