import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import invoice from "../../reducers/invoice";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";

const changeCase = require("change-case");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryInvoiceCreate({ data }) {
  const payload = {
    buyerName: data.buyerName,
    buyerEmail: data.buyerEmail,
    buyerAddress: data.buyerAddress,
    buyerCity: data.buyerCity,
    buyerState: data.buyerState,
    buyerPostcode: data.buyerPostcode,
    buyerCountry: data.buyerCountry,
    userAgent: data.userAgent,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.buyerVat !== "undefined") {
    payload.buyerVat = data.buyerVat;
  }

  if (typeof data.affiliateId !== "undefined") {
    payload.affiliateId = data.affiliateId;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.invoiceCreate(payload);
  } catch (e) {
    result = { error: { code: "#2FGA_tryInvoiceCreate", message: e.message } };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: invoice.actionTypes.CREATE_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        message: changeCase.capitalCase(
          result.error.message || "Failed to create an invoice"
        ),
      },
    });

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({ type: invoice.actionTypes.CREATE_FAILED, data: result.error });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
