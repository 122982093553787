import { Map, fromJS } from "immutable";

const SET = "customGlobalState/SET";
const REMOVE = "customGlobalState/SET";
const REMOVE_ALL = "customGlobalState/REMOVE_ALL";

const initialState = Map({
  variableValue: Map({}),
});

function reducer(state = initialState, action = {}) {
  let newValue = null;

  switch (action.type) {
    case SET:
      newValue =
        action.data.value &&
        action.data.value.type &&
        action.data.value.type === "raw"
          ? action.data.value
          : fromJS(action.data.value);
      return state.setIn(
        ["variableValue", action.data.globalVariable],
        newValue
      );
    case REMOVE:
      return state.deleteIn(["variableValue", action.data.globalVariable]);

    case REMOVE_ALL:
      return initialState;

    default:
      return state;
  }
}

const actionTypes = {
  SET,
  REMOVE,
  REMOVE_ALL,
};

export default {
  actionTypes,
  reducer,
};
