import call from "../../helpers/call";

function update({ authToken, subscriptionId, title, productPlanId, isActive }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription/update`,
    payload: {
      subscriptionId, // (Required) String 10-30 in length and starting with "su-"
      title, // (Optional) Any text
      productPlanId, // (Optional) String 10-30 in length and starting with "pp-"
      isActive, // (Optional) Value is a boolean. True or false.
    },
  });
}

export default update;
