import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import cart from "../../reducers/cart";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";
import Button from "@mui/material/Button";

const _get = require("lodash.get");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryCartV2ItemUpdateByIdentifier({ data }) {
  const payload = {
    productPlanId: data.payload.productPlanId,
    quantity: data.payload.quantity,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.payload.addQuantity !== "undefined") {
    payload.addQuantity = data.payload.addQuantity;
  }

  if (typeof data.payload.removeQuantity !== "undefined") {
    payload.removeQuantity = data.payload.removeQuantity;
  }

  if (typeof data.payload.valueCentsPerItem !== "undefined") {
    payload.valueCentsPerItem = data.payload.valueCentsPerItem;
  }

  if (typeof data.payload.productPlanName !== "undefined") {
    payload.productPlanName = data.payload.productPlanName;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  if (payload.addQuantity && typeof data.valueCentsPerItem !== "undefined") {
    // facebook pixel event -- START
    if (window.fbq) {
      window.fbq("track", "AddToCart", {
        content_ids: [data.productPlanId],
        content_type: "product",
        value: (
          (data.valueCentsPerItem * payload.addQuantity) /
          100
        ).toString(),
        currency: "USD",
      });
    }
    // facebook pixel event -- END
    // Google pixel event -- START
    if (window.gtag) {
      window.gtag("event", "add_to_cart", {
        send_to: "AW-716606984/At9pCIKg8qcBEIic2tUC",
        value: (data.valueCentsPerItem * payload.addQuantity) / 100,
        currency: "USD",
        items: [
          {
            item_id: data.productPlanId,
            item_name: data.productPlanName || data.productPlanId,
            quantity: data.removeQuantity,
            price: data.valueCentsPerItem / 100,
          },
        ],
      });
    }
    // Google pixel event -- END
  }

  if (data.removeQuantity && typeof data.valueCentsPerItem !== "undefined") {
    // facebook pixel event -- START
    if (window.fbq) {
      window.fbq("track", "RemoveFromCart", {
        content_ids: [data.productPlanId],
        content_type: "product",
        value: (
          (data.valueCentsPerItem * data.removeQuantity) /
          100
        ).toString(),
        currency: "USD",
      });
    }
    // facebook pixel event -- END
    // Google pixel event -- START
    if (window.gtag) {
      window.gtag("event", "remove_from_cart", {
        send_to: "AW-716606984/At9pCIKg8qcBEIic2tUC",
        value: (data.valueCentsPerItem * payload.removeQuantity) / 100,
        currency: "USD",
        items: [
          {
            item_id: data.productPlanId,
            item_name: data.productPlanName || data.productPlanId,
            quantity: data.removeQuantity,
            price: data.valueCentsPerItem / 100,
          },
        ],
      });
    }
    // Google pixel event -- END
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  const result = yield sdk.cartV2ItemUpdate(payload);

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: cart.actionTypes.V2_ITEM_UPDATE_SUCCESS_BY_IDENTIFIER,
      data: {
        ___identifier: data.___identifier,
        payload: { data: result.data, meta: result.meta },
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        queue: "bottomLeft",
        message: "Cart updated",
        action: (
          <Button
            href={`https://app.${process.env.REACT_APP_MAIN_DOMAIN}/cart`}
          >
            Cart
          </Button>
        ),
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        queue: "bottomLeft",
        message: _get(result, "error.message") || JSON.stringify(result.error),
      },
    });

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: cart.actionTypes.V2_ITEM_UPDATE_FAILED_BY_IDENTIFIER,
      data: { ___identifier: data.___identifier, payload: result.error },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
