import call from "../../helpers/call";

function check_3rdParty({
  authToken,
  couponCode,
  couponOwnerUserEmail,
  productSlug,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/coupon/check-3rd-party`,
    payload: {
      couponCode, // (Required) Any string of 0-255 symbols in length.
      couponOwnerUserEmail, // (Required) Valid email address
      productSlug, // (Required) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
    },
  });
}

export default check_3rdParty;
