import call from "../../helpers/call";

function getSsoAuthorizationOneTimeUseCode({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/circleso/get-sso-authorization-one-time-use-code`,
    payload: {},
  });
}

export default getSsoAuthorizationOneTimeUseCode;
