import call from "../../helpers/call";

function create({
  authToken,
  buyerName,
  buyerEmail,
  buyerAddress,
  buyerCity,
  buyerState,
  buyerPostcode,
  buyerCountry,
  buyerVat,
  affiliateId,
  userAgent,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/create`,
    payload: {
      buyerName, // (Required) Any string of 0-120 symbols in length.
      buyerEmail, // (Required) Valid email address
      buyerAddress, // (Required) Any string of 0-255 symbols in length.
      buyerCity, // (Required) Any string of 0-120 symbols in length.
      buyerState, // (Required) Any string of 0-120 symbols in length.
      buyerPostcode, // (Required) Any string of 0-120 symbols in length.
      buyerCountry, // (Required) Any string of 0-120 symbols in length.
      buyerVat, // (Optional) Any string of 0-120 symbols in length.
      affiliateId, // (Optional) Any string of 0-120 symbols in length.
      userAgent, // (Required) Any string of 0-255 symbols in length.
    },
  });
}

export default create;
