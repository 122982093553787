import call from "../../helpers/call";

function createAdmin({ authToken, slug, title }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/blog/create-admin`,
    payload: {
      slug, // (Required) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
      title, // (Required) Any string of 0-255 symbols in length.
    },
  });
}

export default createAdmin;
