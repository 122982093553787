import call from "../../helpers/call";

function splitOptionsList({ authToken, invoiceAmountCents }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/split-options-list`,
    payload: {
      invoiceAmountCents, // (Required) Must be positive integer or zero
    },
  });
}

export default splitOptionsList;
