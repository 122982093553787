import call from "../../helpers/call";

function passwordChangeWithToken({
  authToken,
  passwordRecoveryToken,
  password,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/password-change-with-token`,
    payload: {
      passwordRecoveryToken, // (Required) Any text
      password, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default passwordChangeWithToken;
