import call from "../../helpers/call";

function paymentSend({ authToken, amountCents, ownerUserId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/tipalti/payment-send`,
    payload: {
      amountCents, // (Required) Is integer equal to 1 or more
      ownerUserId, // (Required) String 10-30 in length and starting with "u-"
    },
  });
}

export default paymentSend;
