import call from "../../helpers/call";

function addIncentiveToEmailsAdmin({
  authToken,
  amountCents,
  comment,
  validUntilAt,
  emailsCsv,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/add-incentive-to-emails-admin`,
    payload: {
      amountCents, // (Required) Any positive integer. 0 is not valid.
      comment, // (Required) Any string of 0-120 symbols in length.
      validUntilAt, // (Required) Timestamp value. Any positive integer.
      emailsCsv, // (Required) Any text
    },
  });
}

export default addIncentiveToEmailsAdmin;
