import call from "../../helpers/call";

function setAffiliateAdmin({ authToken, invoiceId, affiliateUserId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/set-affiliate-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      affiliateUserId, // (Required) String 10-30 in length and starting with "u-"
    },
  });
}

export default setAffiliateAdmin;
