import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const GET_LATEST_PURCHASES_TRY = "sales/getLatestPurchases/TRY";
const GET_LATEST_PURCHASES_SUCCESS = "sales/getLatestPurchases/SUCCESS";
const GET_LATEST_PURCHASES_FAILED = "sales/getLatestPurchases/FAILED";

const GET_LATEST_PURCHASES_TRY_BY_IDENTIFIER =
  "sales/getLatestPurchases/TRY_BY_IDENTIFIER";
const GET_LATEST_PURCHASES_SUCCESS_BY_IDENTIFIER =
  "sales/getLatestPurchases/SUCCESS_BY_IDENTIFIER";
const GET_LATEST_PURCHASES_FAILED_BY_IDENTIFIER =
  "sales/getLatestPurchases/FAILED_BY_IDENTIFIER";

const initialState = Map({
  getLatestPurchases: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_LATEST_PURCHASES_TRY:
      return handleTry({ state, action });
    case GET_LATEST_PURCHASES_SUCCESS:
      return handleSuccess({ state, action });
    case GET_LATEST_PURCHASES_FAILED:
      return handleFailed({ state, action });
    case GET_LATEST_PURCHASES_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case GET_LATEST_PURCHASES_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case GET_LATEST_PURCHASES_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  GET_LATEST_PURCHASES_TRY,
  GET_LATEST_PURCHASES_SUCCESS,
  GET_LATEST_PURCHASES_FAILED,
  GET_LATEST_PURCHASES_TRY_BY_IDENTIFIER,
  GET_LATEST_PURCHASES_SUCCESS_BY_IDENTIFIER,
  GET_LATEST_PURCHASES_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
