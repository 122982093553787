import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import useCartGetMyItemsInCartCountResult from "stateManager/hooks/cart/useCartGetMyItemsInCartCountResult";
import useCartGetMyItemsInCartCountLoadingState from "stateManager/hooks/cart/useCartGetMyItemsInCartCountLoadingState";
import useIsCartGetMyItemsInCartCountLoaded from "stateManager/hooks/cart/useIsCartGetMyItemsInCartCountLoaded";
import CircularProgress from "@mui/material/CircularProgress";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useState } from "react";
import SidebarCart from "./SidebarCart";
import { Drawer } from "@mui/material";

const cartDrawerWidth = 552;

export default function SidebarCartBtn({ user }) {
  const itemsInCart = useCartGetMyItemsInCartCountResult({});
  const itemsInCartLoad = useCartGetMyItemsInCartCountLoadingState({});
  const isLoading =
    useIsCartGetMyItemsInCartCountLoaded({}) === false ||
    itemsInCartLoad.get("isLoading") === true;
  const itemsCount =
    isLoading === false ? itemsInCart.getIn(["result", "data", "count"]) : null;

  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);

  const handleCartDrawerToggle = () => {
    setCartDrawerOpen((prev) => !prev);
  };

  const cartDrawer = (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton onClick={() => setCartDrawerOpen(false)}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <div>
        <SidebarCart user={user} />
      </div>
    </>
  );

  return (
    <>
      <IconButton onClick={handleCartDrawerToggle}>
        <Badge
          color={isLoading ? "default" : "primary"}
          badgeContent={
            isLoading ? (
              <CircularProgress size={12} color="primary" />
            ) : (
              itemsCount
            )
          }
        >
          <ShoppingCartOutlinedIcon />
        </Badge>
      </IconButton>

      <Drawer
        keepMounted={true}
        // container={container}
        variant="temporary"
        open={cartDrawerOpen}
        anchor={"right"}
        onClose={handleCartDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: cartDrawerWidth,
            maxWidth: "90%",
          },
        }}
      >
        {cartDrawer}
      </Drawer>
    </>
  );
}
