import call from "../../helpers/call";

function getDetails({ authToken, couponId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/coupon/get-details`,
    payload: {
      couponId, // (Required) String 10-30 in length and starting with "c-"
    },
  });
}

export default getDetails;
