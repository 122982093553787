import call from "../../helpers/call";

function getForRefund({ authToken, productId, couponId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/get-for-refund`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      couponId, // (Required) String 10-30 in length and starting with "c-"
    },
  });
}

export default getForRefund;
