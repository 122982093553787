import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import auth from "../../reducers/auth";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";
import Cookies from "js-cookie";

const changeCase = require("change-case");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryAuthLogin({ data }) {
  const payload = {
    email: data.email,
    password: data.password,
    recaptchaToken: data.recaptchaToken,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.lastUrl !== "undefined") {
    payload.lastUrl = data.lastUrl;
  }

  if (typeof data.keepSessionForDays !== "undefined") {
    payload.keepSessionForDays = data.keepSessionForDays;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.userLogin(payload);
  } catch (e) {
    result = { error: { code: "#2FGA_tryAuthLogin", message: e.message } };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: auth.actionTypes.LOGIN_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    localStorage.setItem("authToken", result.data.authToken);
    // when removing cookie, domain & path must match with this
    Cookies.set("authToken", result.data.authToken, {
      expires: 365,
      path: "/",
      domain:
        // eslint-disable-next-line
        location.host.indexOf("localhost") !== -1
          ? "localhost"
          : `.${process.env.REACT_APP_MAIN_DOMAIN}`,
    });

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Welcome back 🙂" },
    });
    setTimeout(() => {
      if (window.name === "pg-login-window") {
        window.self.close();
      } else {
        window.location.replace("/");
      }
    }, 300);

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({ type: auth.actionTypes.LOGIN_FAILED, data: result.error });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        queue: "bottomLeft",
        message: changeCase.capitalCase(result.error.message),
      },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
