import call from "../../helpers/call";

function updateSortOrderAdmin({ authToken, productId, sortOrder }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/update-sort-order-admin`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      sortOrder, // (Required) Is integer equal to 1 or more
    },
  });
}

export default updateSortOrderAdmin;
