import call from "../../helpers/call";

function stripeCardsList({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/stripe-cards-list`,
    payload: {},
  });
}

export default stripeCardsList;
