import TrackProductView from "jsx/TrackProductView";
import React from "react";
import ReactDOM from "react-dom";

export default function TrackProductViewPortal() {
  const portals = [];
  const btns = document.querySelectorAll(".track-product-view");

  for (let i = 0; i < btns.length; i++) {
    const placeholder = btns[i];
    const productSlug = placeholder.dataset.productSlug;
    // if there was any placeholder content, e.g. loading state - clear it out
    placeholder.innerHTML = "";
    portals.push(
      ReactDOM.createPortal(
        <TrackProductView productSlug={productSlug} />,
        placeholder
      )
    );
  }

  return portals;
}
