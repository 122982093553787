import call from "../../helpers/call";

function updateBillingDetails({
  authToken,
  invoiceId,
  buyerName,
  buyerEmail,
  buyerAddress,
  buyerCity,
  buyerState,
  buyerPostcode,
  buyerCountry,
  buyerVat,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/update-billing-details`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      buyerName, // (Required) Any string of 0-120 symbols in length.
      buyerEmail, // (Required) Any string of 0-120 symbols in length.
      buyerAddress, // (Required) Any string of 0-120 symbols in length.
      buyerCity, // (Required) Any string of 0-120 symbols in length.
      buyerState, // (Required) Any string of 0-120 symbols in length.
      buyerPostcode, // (Required) Any string of 0-120 symbols in length.
      buyerCountry, // (Required) Any string of 0-120 symbols in length.
      buyerVat, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default updateBillingDetails;
