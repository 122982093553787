import call from "../../helpers/call";

function list({ authToken, videoChannelSectionId, page, perPage }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/video/list`,
    payload: {
      videoChannelSectionId, // (Required) String 10-30 in length and starting with "vcs-"
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any positive integer. Range: (0,1000]
    },
  });
}

export default list;
