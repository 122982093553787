import useUserGetIfUserBoughtProductLoadingState from "stateManager/hooks/user/useUserGetIfUserBoughtProductLoadingState";
import useUserGetIfUserBoughtProductResult from "stateManager/hooks/user/useUserGetIfUserBoughtProductResult";

export default function useIsUserGetIfUserBoughtProductLoaded({}, ___options) {
  const loadingState = useUserGetIfUserBoughtProductLoadingState(
    {},
    ___options
  );
  const resultState = useUserGetIfUserBoughtProductResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
