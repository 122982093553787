import call from "../../helpers/call";

function create({
  authToken,
  companyId,
  subscriptionType,
  tags,
  purchasedAt,
  repeatEveryTerm,
  repeatEveryNumber,
  isAutoRenewing,
  cost,
  costCents,
  currencyId,
  planName,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription-tracking/create`,
    payload: {
      companyId, // (Required) Any string of 0-255 symbols in length.
      subscriptionType, // (Required) One of: Lifetime Deal, Subscription, Trial
      tags, // (Required) Check: value && Array.isArray(value)
      purchasedAt, // (Required) Timestamp value. Any positive integer.
      repeatEveryTerm, // (Optional) Is string120 and not empty
      repeatEveryNumber, // (Optional) Is integer equal to 1 or more
      isAutoRenewing, // (Optional) Value is a boolean. True or false.
      cost, // (Required) Is integer equal to 1 or more
      costCents, // (Optional) Must be positive integer or zero
      currencyId, // (Required) String 10-30 in length and starting with "cur-"
      planName, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default create;
