import call from "../../helpers/call";

function createAdmin({
  authToken,
  userId,
  productPlanId,
  title,
  type,
  amount,
  isActive,
  lastPaymentAt,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription/create-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      productPlanId, // (Required) String 10-30 in length and starting with "pp-"
      title, // (Required) String of length from 1 to 250
      type, // (Required) String. One of: monthly, yearly, or 2years
      amount, // (Required) Any positive integer. 0 is not valid.
      isActive, // (Required) Value is a boolean. True or false.
      lastPaymentAt, // (Required) Must be positive integer or zero
    },
  });
}

export default createAdmin;
