import call from "../../helpers/call";

function changePassword({ authToken, password }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/change-password`,
    payload: {
      password, // (Required) Checks if password is 7-50 symbols in length
    },
  });
}

export default changePassword;
