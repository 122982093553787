import call from "../../helpers/call";

function reactionAdd({ authToken, discussionId, reaction }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discussion/reaction-add`,
    payload: {
      discussionId, // (Required) String 10-30 in length and starting with "d-"
      reaction, // (Required) One of: :open_mouth:, :+1:, :heart:, :hugging_face:, :rolling_on_the_floor_laughing:, :disappointed:
    },
  });
}

export default reactionAdd;
