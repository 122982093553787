let defaultSdkUrl = "https://api-prod-us-east-1.pitchground.com/api/v3";

function getSdkEndpoint() {
  let url =
    typeof localStorage === "object" &&
    typeof localStorage.getItem === "function" &&
    localStorage.getItem("___SDK_API_ENDPOINT_OVERRIDE___")
      ? localStorage.getItem("___SDK_API_ENDPOINT_OVERRIDE___")
      : defaultSdkUrl;

  return url;
}

export default getSdkEndpoint;
