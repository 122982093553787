import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import ___analytics from "./analytics";
import ___auth from "./auth";
import ___cart from "./cart";
import ___circleso from "./circleso";
import ___coupon from "./coupon";
import ___coupons from "./coupons";
import ___currency from "./currency";
import ___customGlobalState from "./customGlobalState";
import ___discountCoupon from "./discountCoupon";
import ___discussion from "./discussion";
import ___favorite from "./favorite";
import ___globalMessages from "./globalMessages";
import ___image from "./image";
import ___invoice from "./invoice";
import ___klavyio from "./klavyio";
import ___meta from "./meta";
import ___notifications from "./notifications";
import ___options from "./options";
import ___product from "./product";
import ___productPlan from "./productPlan";
import ___productPlans from "./productPlans";
import ___products from "./products";
import ___review from "./review";
import ___sales from "./sales";
import ___stripeSubscription from "./stripeSubscription";
import ___submitProduct from "./submitProduct";
import ___subscriptionTracking from "./subscriptionTracking";
import ___subscriptionTrackingCompany from "./subscriptionTrackingCompany";
import ___tipalti from "./tipalti";
import ___user from "./user";
import ___userDraft from "./userDraft";
import ___userMeta from "./userMeta";
import ___vendorPaymentInfo from "./vendorPaymentInfo";
import ___vendorProduct from "./vendorProduct";
import ___wallet from "./wallet";

export default ({ history, options }) => {
  const _reducersToCombine = {
    analytics: ___analytics.reducer,
    auth: ___auth.reducer,
    cart: ___cart.reducer,
    circleso: ___circleso.reducer,
    coupon: ___coupon.reducer,
    coupons: ___coupons.reducer,
    currency: ___currency.reducer,
    customGlobalState: ___customGlobalState.reducer,
    discountCoupon: ___discountCoupon.reducer,
    discussion: ___discussion.reducer,
    favorite: ___favorite.reducer,
    globalMessages: ___globalMessages.reducer,
    image: ___image.reducer,
    invoice: ___invoice.reducer,
    klavyio: ___klavyio.reducer,
    meta: ___meta.reducer,
    notifications: ___notifications.reducer,
    options: ___options.reducer,
    product: ___product.reducer,
    productPlan: ___productPlan.reducer,
    productPlans: ___productPlans.reducer,
    products: ___products.reducer,
    review: ___review.reducer,
    router: connectRouter(history),
    sales: ___sales.reducer,
    stripeSubscription: ___stripeSubscription.reducer,
    submitProduct: ___submitProduct.reducer,
    subscriptionTracking: ___subscriptionTracking.reducer,
    subscriptionTrackingCompany: ___subscriptionTrackingCompany.reducer,
    tipalti: ___tipalti.reducer,
    user: ___user.reducer,
    userDraft: ___userDraft.reducer,
    userMeta: ___userMeta.reducer,
    vendorPaymentInfo: ___vendorPaymentInfo.reducer,
    vendorProduct: ___vendorProduct.reducer,
    wallet: ___wallet.reducer,
  };

  if (options.extensions && options.extensions.length > 0) {
    options.extensions.forEach((_extension) => {
      if (_extension.reducer && _extension.namespace) {
        if (typeof _reducersToCombine[_extension.namespace] === "undefined") {
          _reducersToCombine[_extension.namespace] = _extension.reducer;
        }
      }
    });
  }

  return combineReducers(_reducersToCombine);
};
