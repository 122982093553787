import PortalAddToCart from "portals/AddToCart";
import HandlePixels from "portals/HandlePixels";
import PortalAddToFavoritesBtn from "portals/PortalAddToFavoritesBtn";
import PortalAddVipPlanToCart from "portals/PortalAddVipPlanToCart";
import PortalAfterLogin from "portals/PortalAfterLogin";
import PortalBeforeLogin from "portals/PortalBeforeLogin";
import PortalDiscussions from "portals/PortalDiscussions";
import PortalEmailMeAboutNewDeal from "portals/PortalEmailMeAboutNewDeals";
import PortalEmailMeBeforeDealEnds from "portals/PortalEmailMeBeforeDealEnds";
import PortalHellobar from "portals/PortalHellobar";
import PortalSidebarCart from "portals/PortalSidebarCart";
import PortalSidebarCartBtn from "portals/PortalSidebarCartBtn";
import PortalSubscribe from "portals/PortalSubscribe";
import PortalWriteReview from "portals/PortalWriteReview";
import TrackProductViewPortal from "portals/TrackProductViewPortal";
import initApp from "stateManager/initApp";
import saveImpactUrlToCookie from "utils/saveImpactUrlToCookie";

saveImpactUrlToCookie();
const mainMountPoint = document.getElementById("user-app-state-mount");

initApp({
  components: {},
  options: {
    doReportWebVitals: false,
    doRenderRoutes: false,
  },
  mountPoint: mainMountPoint,
  extraGlobalComponents: [
    // <PortalTopMenuCart />,
    <PortalAddToCart />,
    <PortalSubscribe />,
    <PortalDiscussions />,
    <PortalEmailMeBeforeDealEnds />,
    <PortalEmailMeAboutNewDeal />,
    <HandlePixels />,
    <TrackProductViewPortal />,
    <PortalWriteReview />,
    <PortalAddVipPlanToCart />,
    <PortalHellobar />,
    // <PortalNotifications />,
    <PortalSidebarCart />,
    <PortalBeforeLogin />,
    <PortalAfterLogin />,
    <PortalSidebarCartBtn />,
    <PortalAddToFavoritesBtn />,
  ],
});
