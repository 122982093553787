import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
// import useIsProductsListActiveProductsLoaded from "stateManager/hooks/products/useIsProductsListActiveProductsLoaded";
import moment from "moment";
import useProductsListActiveProductsResult from "stateManager/hooks/products/useProductsListActiveProductsResult";
import SingleDeal from "./SingleDeal";

export default function ActiveDeals({ setCurrentView }) {
  const theme = useTheme();
  const isSmUp = useMediaQuery((_theme) => _theme.breakpoints.up("sm"));

  const activeProductsResult = useProductsListActiveProductsResult({});

  const isProductSoldOut = (_product) =>
    moment.unix(_product.get("dealEndedAt")).diff(moment()) < 0;

  const isProductComingSoon = (_product) =>
    moment.unix(_product.get("dealStartedAt")).diff(moment()) > 0;

  // availableProducts = which are neither "sold out" nor under "upcoming deals".
  // not vip or vip+ either
  const availableProducts = activeProductsResult
    .getIn(["result", "data"])
    ?.filter(
      (_product) =>
        !isProductSoldOut(_product) &&
        !isProductComingSoon(_product) &&
        _product.get("name") !== "PG VIP" &&
        _product.get("name") !== "PG VIP+"
    );

  // collecting unique products from plans
  const uniqueAvailableProducts = [];
  availableProducts?.forEach((product) => {
    if (!uniqueAvailableProducts.find((el) => el.name === product.get("name")))
      uniqueAvailableProducts.push({
        name: product.get("name"),
        productId: product.get("productId"),
      });
  });

  return (
    <>
      <Box>
        <Box
          sx={{
            margin: {
              xs: theme.spacing(0, 1, 1, 2),
              sm: theme.spacing(2, 5, 3, 5),
            },
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h2" component="span">
              Active Deals
            </Typography>
            <Button
              onClick={() => setCurrentView("cart")}
              variant={isSmUp ? "outlined" : "text"}
              size="small"
              startIcon={isSmUp ? <ArrowBackRoundedIcon /> : ""}
            >
              Go Back to Cart
            </Button>
          </div>

          <Divider
            sx={{ m: { xs: theme.spacing(2, 0), sm: theme.spacing(3, 0) } }}
          />

          {availableProducts ? (
            uniqueAvailableProducts?.map((_product) => {
              const plans = availableProducts?.filter(
                (_item) => _item.get("name") === _product.name
              );

              return (
                <SingleDeal
                  product={_product}
                  key={_product.productId}
                  plans={plans}
                />
              );
            })
          ) : (
            <div style={{ textAlign: "center", marginTop: theme.spacing(10) }}>
              <CircularProgress color="secondary" />
            </div>
          )}
        </Box>
      </Box>
    </>
  );
}
