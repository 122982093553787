import Hellobar from "jsx/Hellobar";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalHellobar() {
  const portals = [];
  const place = document.getElementById("hellobartop");

  if (place) {
    // if there was any place content, e.g. loading state - clear it out
    place.innerHTML = "";
    portals.push(ReactDOM.createPortal(<Hellobar />, place));
  }

  return portals;
}
