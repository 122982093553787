import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useTheme } from "@mui/material";
import Notifications from "jsx/Notifications";
import React, { useState } from "react";
import useCartGetMyItemsInCartCountLoadingState from "stateManager/hooks/cart/useCartGetMyItemsInCartCountLoadingState";
import useCartGetMyItemsInCartCountResult from "stateManager/hooks/cart/useCartGetMyItemsInCartCountResult";
import useIsCartGetMyItemsInCartCountLoaded from "stateManager/hooks/cart/useIsCartGetMyItemsInCartCountLoaded";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FavoritesNavBtn from "jsx/Favorites/FavoritesNavBtn";
import SidebarCartBtn from "jsx/Cart/Portal/SidebarCartBtn";

export default function AfterLogin(props) {
  const theme = useTheme();
  const selectedPath = window.location.pathname
    ? window.location.pathname
    : "/";
  const isCurrent = (path) => selectedPath.indexOf(path) === 0;

  const itemsInCart = useCartGetMyItemsInCartCountResult({});
  const itemsInCartLoad = useCartGetMyItemsInCartCountLoadingState({});
  const isLoading =
    useIsCartGetMyItemsInCartCountLoaded({}) === false ||
    itemsInCartLoad.get("isLoading") === true;
  const itemsCount =
    isLoading === false ? itemsInCart.getIn(["result", "data", "count"]) : null;

  const [isMountedOnUserApp, setIsMountedOnUserApp] = React.useState();

  React.useEffect(() => {
    const isUserApp = Boolean(
      location.host.startsWith("app") || location.host === "localhost:3729"
    );
    setIsMountedOnUserApp(isUserApp);
  }, []);

  const [avatarUrl, setAvatarUrl] = useState(null);
  React.useEffect(() => {
    if (props.user && props.user.images?.avatar) {
      setAvatarUrl(props.user.images.avatar);
    }
  }, [props.user]);

  return (
    <>
      <FavoritesNavBtn />
      <Notifications />
      <IconButton
        href={
          window.location.host.indexOf("localhost") !== -1
            ? "http://localhost:3729/profile?profile-tab=personal-info"
            : `https://app.${process.env.REACT_APP_MAIN_DOMAIN}/profile?profile-tab=personal-info`
        }
        color={isCurrent("/profile") ? "primary" : "default"}
      >
        <Avatar
          variant={"circular"}
          src={avatarUrl}
          // alt={<PersonOutlineIcon />}
          sx={{ width: 26, height: 26 }}
        >
          <Typography fontSize={"small"}>
            {props.user.name.match(/\b\w/g).join("").slice(0, 2).toUpperCase()}
          </Typography>
        </Avatar>
      </IconButton>
      {isMountedOnUserApp ? (
        <IconButton
          href={
            window.location.host.indexOf("localhost") !== -1
              ? "http://localhost:3729/cart"
              : `https://app.${process.env.REACT_APP_MAIN_DOMAIN}/cart`
          }
          color={isCurrent("/cart") ? "primary" : "default"}
        >
          <Badge
            color={isLoading ? "default" : "primary"}
            badgeContent={
              isLoading ? (
                <CircularProgress size={12} color="primary" />
              ) : (
                itemsCount
              )
            }
          >
            <ShoppingCartOutlinedIcon />
          </Badge>
        </IconButton>
      ) : (
        <SidebarCartBtn user={props.user} />
      )}
    </>
  );
}
