import call from "../../helpers/call";

function list({ authToken, page, state, searchQuery }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/list`,
    payload: {
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      state, // (Optional) One of: paid, pending, refunded, partlyPaid, partlyRefunded, overpaid
      searchQuery, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default list;
