import call from "../../helpers/call";

function create({
  authToken,
  customerRegisteredEarlierThanAt,
  userTotalSpentCents,
  splitPaymentMessageTresholdPercentage,
  splitOptions,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/split-payment-setting/create`,
    payload: {
      customerRegisteredEarlierThanAt, // (Required) Must be positive integer or zero
      userTotalSpentCents, // (Required) Must be positive integer or zero
      splitPaymentMessageTresholdPercentage, // (Required) Must be positive integer or zero
      splitOptions, // (Required) Check: value && Array.isArray(value)
    },
  });
}

export default create;
