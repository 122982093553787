import Cookies from "js-cookie";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useActions from "stateManager/hooks/useActions";
import useIsUserMetaGetLoaded from "stateManager/hooks/userMeta/useIsUserMetaGetLoaded";
import useUserMetaGetResult from "stateManager/hooks/userMeta/useUserMetaGetResult";

export default function useHandlePixelsIntegrations() {
  const actions = useActions();
  const user = useSelector((state) => state.user.getIn(["get", "lastResult"]));
  const userMeta = useUserMetaGetResult({});
  const isUserMetaGetLoaded = useIsUserMetaGetLoaded({});
  const userEmailSha1 = user.getIn(["result", "data", "emailSha1"]);
  const userId = user.getIn(["result", "data", "userId"]);

  useEffect(() => {
    if (isUserMetaGetLoaded && userId) {
      let newFacebookFbc = Cookies.get("_fbc");
      let newFacebookFbp = Cookies.get("_fbp");

      console.log(`#202126618223681 newFacebookFbc: `, newFacebookFbc);
      console.log(`#202126618223731 newFacebookFbp: `, newFacebookFbp);

      const currentFacebookFbp = userMeta.getIn([
        "result",
        "data",
        "facebookFbp",
      ]);
      const currentFacebookFbc = userMeta.getIn([
        "result",
        "data",
        "facebookFbc",
      ]);

      if (
        userId &&
        userMeta.getIn(["result", "data", "userOwnerId"]) === userId
      ) {
        console.log(`#2021266182524465 yes`);
        // user loaded
        if (
          (newFacebookFbc && newFacebookFbc !== currentFacebookFbc) ||
          (newFacebookFbp && newFacebookFbp !== currentFacebookFbp)
        ) {
          // need to update
          console.log(`#2021266182514363 update fbp & fbc`);
          actions.userMeta.update({
            facebookFbc: newFacebookFbc,
            facebookFbp: newFacebookFbp,
          });
        }
      }
    }
  }, [isUserMetaGetLoaded, userId, userMeta]);

  // impact START
  if (userEmailSha1 && userId) {
    if (window.ire) {
      console.log("Impact identify: userId: ", userId);
      console.log("Impact identify: userEmailSha1: ", userEmailSha1);
      window.ire("identify", {
        customerid: userId,
        customeremail: userEmailSha1,
      });
    }
  }
  // impact END
}

/*

import {actionTypes as userMetaTypes} from 'features/userMeta';
import getUser from 'utils/getFromGlobalState/getUser';
import getFromCookie from 'utils/getFromCookie';
import {put} from 'redux-saga/effects';

let lastUpdate = null;

export default function* makeGloballyAvailable({type, data}) {
  const userDetails = getUser({});
  const currentUserMeta = userDetails.userMeta;
  const currentUser = userDetails.user;
  const userId = !currentUser ? null : currentUser.get('userId');
  let newFacebookFbc = getFromCookie('_fbc');
  newFacebookFbc = Array.isArray(newFacebookFbc) ? newFacebookFbc[0] : null;
  let newFacebookFbp = getFromCookie('_fbp');
  newFacebookFbp = Array.isArray(newFacebookFbp) ? newFacebookFbp[0] : null;

  const currentFacebookFbp = !currentUserMeta ? null : currentUserMeta.get('facebookFbp');
  const currentFacebookFbc = !currentUserMeta ? null : currentUserMeta.get('facebookFbc');

  if (userId && currentUserMeta && userId === currentUserMeta.get('userOwnerId')) {
    // user loaded
    if (
      (newFacebookFbc && newFacebookFbc !== currentFacebookFbc)
      || (newFacebookFbp && newFacebookFbp !== currentFacebookFbp)
    ) {
      // need to update
      if (lastUpdate === null || (lastUpdate.facebookFbc !== newFacebookFbc && lastUpdate.facebookFbp === newFacebookFbp)) {
        lastUpdate = {facebookFbc: newFacebookFbc, facebookFbp: newFacebookFbp};
        yield put({type: userMetaTypes.UPDATE_TRY, data: {facebookFbc: newFacebookFbc, facebookFbp: newFacebookFbp}});
      }
    }
  }
}

*/
