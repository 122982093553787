import useProductPlansListForPublishedProductLoadingState from "stateManager/hooks/productPlans/useProductPlansListForPublishedProductLoadingState";
import useProductPlansListForPublishedProductResult from "stateManager/hooks/productPlans/useProductPlansListForPublishedProductResult";

export default function useIsProductPlansListForPublishedProductLoaded(
  {},
  ___options
) {
  const loadingState = useProductPlansListForPublishedProductLoadingState(
    {},
    ___options
  );
  const resultState = useProductPlansListForPublishedProductResult(
    {},
    ___options
  );

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
