import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useNotificationsGetUnreadCountLoadingState from "../notifications/useNotificationsGetUnreadCountLoadingState";
import useNotificationsMarkAllAsReadLoadingState from "../notifications/useNotificationsMarkAllAsReadLoadingState";
import useNotificationsMarkAsReadLoadingState from "../notifications/useNotificationsMarkAsReadLoadingState";
import useNotificationsCreateDemoNotificationLoadingState from "../notifications/useNotificationsCreateDemoNotificationLoadingState";
// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/notifications/tryNotificationsGetUnreadCount";

export default function useNotificationsGetUnreadCountResult({}, ___options) {
  const ___notifications = useSelector((___state) => ___state.notifications);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const notificationsGetUnreadCountLoadingState =
    useNotificationsGetUnreadCountLoadingState({}, ___options);
  const ___isLoading = notificationsGetUnreadCountLoadingState.get("isLoading");
  const isNotificationsMarkAllAsReadLoadingState =
    useNotificationsMarkAllAsReadLoadingState({}, ___options);
  const isNotificationsMarkAsReadLoadingState =
    useNotificationsMarkAsReadLoadingState({}, ___options);
  const isNotificationsCreateDemoNotificationLoadingState =
    useNotificationsCreateDemoNotificationLoadingState({}, ___options);

  useEffect(() => {
    if (
      ___isLoading === false &&
      isNotificationsMarkAllAsReadLoadingState.get("isLoading") === false &&
      isNotificationsMarkAsReadLoadingState.get("isLoading") === false &&
      isNotificationsCreateDemoNotificationLoadingState.get("isLoading") ===
        false
    ) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.notifications.getUnreadCount({});
      } else {
        ___actions.notifications.getUnreadCount({});
      }
    }
  }, [
    isNotificationsMarkAllAsReadLoadingState,
    isNotificationsMarkAsReadLoadingState,
    isNotificationsCreateDemoNotificationLoadingState,
  ]);

  if (___options && ___options.identifier) {
    return (
      ___notifications.getIn([
        "getUnreadCount",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___notifications.getIn(["getUnreadCount", "lastResult"]);
}
