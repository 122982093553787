import affiliateGetAffiliateStatsDetailsAdmin from "./endpoints/affiliate/getAffiliateStatsDetailsAdmin";
import affiliateGetListForPayoutAdmin from "./endpoints/affiliate/getListForPayoutAdmin";
import affiliateGetListOfActiveAffiliates from "./endpoints/affiliate/getListOfActiveAffiliates";
import affiliateGetPre_1575217086Stats from "./endpoints/affiliate/getPre_1575217086Stats";
import affiliateGetPre_1575217086StatsAdmin from "./endpoints/affiliate/getPre_1575217086StatsAdmin";
import affiliateGetReportCsvAdmin from "./endpoints/affiliate/getReportCsvAdmin";
import affiliateGetStatsAdmin from "./endpoints/affiliate/getStatsAdmin";
import affiliateListStatsAdmin from "./endpoints/affiliate/listStatsAdmin";
import affiliateMakePaypalPayoutAdmin from "./endpoints/affiliate/makePaypalPayoutAdmin";
import affiliateSetAffiliateAsPaidAdmin from "./endpoints/affiliate/setAffiliateAsPaidAdmin";
import affiliateBuyLogGetAdmin from "./endpoints/affiliateBuyLog/getAdmin";
import affiliateDetailsCreateAdmin from "./endpoints/affiliateDetails/createAdmin";
import affiliateDetailsGetAdmin from "./endpoints/affiliateDetails/getAdmin";
import affiliateDetailsUpdateAdmin from "./endpoints/affiliateDetails/updateAdmin";
import analyticsListProductSales from "./endpoints/analytics/listProductSales";
import auditLogDiscountCouponList from "./endpoints/auditLog/discountCouponList";
import auditLogListAdmin from "./endpoints/auditLog/listAdmin";
import banListAdmin from "./endpoints/ban/listAdmin";
import banRemoveAdmin from "./endpoints/ban/removeAdmin";
import banUserAdmin from "./endpoints/ban/userAdmin";
import blogCreateAdmin from "./endpoints/blog/createAdmin";
import blogGet from "./endpoints/blog/get";
import blogGetAdmin from "./endpoints/blog/getAdmin";
import blogList from "./endpoints/blog/list";
import blogListAdmin from "./endpoints/blog/listAdmin";
import blogRemoveAdmin from "./endpoints/blog/removeAdmin";
import blogUpdateAdmin from "./endpoints/blog/updateAdmin";
import cartCreateAdmin from "./endpoints/cart/createAdmin";
import cartGetAdmin from "./endpoints/cart/getAdmin";
import cartGetMyItemsInCartCount from "./endpoints/cart/getMyItemsInCartCount";
import cartHowMuchLeftToGetSplit from "./endpoints/cart/howMuchLeftToGetSplit";
import cartListAdmin from "./endpoints/cart/listAdmin";
import cartListMyItems from "./endpoints/cart/listMyItems";
import cartRemoveAdmin from "./endpoints/cart/removeAdmin";
import cartV2Get from "./endpoints/cart/v2Get";
import cartV2ItemUpdate from "./endpoints/cart/v2ItemUpdate";
import cartV2Pay from "./endpoints/cart/v2Pay";
import cartView from "./endpoints/cart/view";
import cartVipDiscountList from "./endpoints/cart/vipDiscountList";
import cartItemAddToCartAdmin from "./endpoints/cartItem/addToCartAdmin";
import cartItemEditAdmin from "./endpoints/cartItem/editAdmin";
import cartItemListAdmin from "./endpoints/cartItem/listAdmin";
import cartItemRemoveAdmin from "./endpoints/cartItem/removeAdmin";
import circlesoAuthorize from "./endpoints/circleso/authorize";
import circlesoGetSsoAuthorizationOneTimeUseCode from "./endpoints/circleso/getSsoAuthorizationOneTimeUseCode";
import circlesoToken from "./endpoints/circleso/token";
import circlesoUser from "./endpoints/circleso/user";
import couponCheck_3rdParty from "./endpoints/coupon/check_3rdParty";
import couponGetDetails from "./endpoints/coupon/getDetails";
import couponGetForRedeem from "./endpoints/coupon/getForRedeem";
import couponMarkAsRedeemed from "./endpoints/coupon/markAsRedeemed";
import couponPoolUpdateStatsAdmin from "./endpoints/couponPool/updateStatsAdmin";
import couponsListAdmin from "./endpoints/coupons/listAdmin";
import couponsListMy from "./endpoints/coupons/listMy";
import couponsPurchasedList from "./endpoints/coupons/purchasedList";
import couponsPurchasedListFromV1 from "./endpoints/coupons/purchasedListFromV1";
import couponsSetStateAdmin from "./endpoints/coupons/setStateAdmin";
import couponsSetUpdatedAtAdmin from "./endpoints/coupons/setUpdatedAtAdmin";
import currencyListAll from "./endpoints/currency/listAll";
import discountCouponApplyToInvoice from "./endpoints/discountCoupon/applyToInvoice";
import discountCouponCreate from "./endpoints/discountCoupon/create";
import discountCouponExpire from "./endpoints/discountCoupon/expire";
import discountCouponExportAdmin from "./endpoints/discountCoupon/exportAdmin";
import discountCouponGet from "./endpoints/discountCoupon/get";
import discountCouponList from "./endpoints/discountCoupon/list";
import discountCouponRemove from "./endpoints/discountCoupon/remove";
import discountCouponRemoveFromInvoice from "./endpoints/discountCoupon/removeFromInvoice";
import discountCouponUpdate from "./endpoints/discountCoupon/update";
import discountCouponCategoryCreate from "./endpoints/discountCouponCategory/create";
import discountCouponCategoryExpireAllCoupons from "./endpoints/discountCouponCategory/expireAllCoupons";
import discountCouponCategoryGet from "./endpoints/discountCouponCategory/get";
import discountCouponCategoryList from "./endpoints/discountCouponCategory/list";
import discountCouponCategoryRemove from "./endpoints/discountCouponCategory/remove";
import discountCouponCategoryUpdate from "./endpoints/discountCouponCategory/update";
import discountCouponCategoryStatsRecalculate from "./endpoints/discountCouponCategoryStats/recalculate";
import discussionEditMyDiscussion from "./endpoints/discussion/editMyDiscussion";
import discussionList from "./endpoints/discussion/list";
import discussionListByProduct from "./endpoints/discussion/listByProduct";
import discussionListRepliesForDiscussion from "./endpoints/discussion/listRepliesForDiscussion";
import discussionMarkHelpful from "./endpoints/discussion/markHelpful";
import discussionPostNewForProduct from "./endpoints/discussion/postNewForProduct";
import discussionPostNewReply from "./endpoints/discussion/postNewReply";
import discussionReactionAdd from "./endpoints/discussion/reactionAdd";
import discussionReactionRemove from "./endpoints/discussion/reactionRemove";
import favoriteAdd from "./endpoints/favorite/add";
import favoriteCount from "./endpoints/favorite/count";
import favoriteGetByProductId from "./endpoints/favorite/getByProductId";
import favoriteList from "./endpoints/favorite/list";
import favoriteRemove from "./endpoints/favorite/remove";
import getStatsDetectSaleIssuesAdmin from "./endpoints/getStats/detectSaleIssuesAdmin";
import imageGet from "./endpoints/image/get";
import imageUpload from "./endpoints/image/upload";
import coinbaseWebhook from "./endpoints/coinbase/webhook";
import invoiceApplyIncentive from "./endpoints/invoice/applyIncentive";
import invoiceAutomatedRefund from "./endpoints/invoice/automatedRefund";
import invoiceCoinbaseGetCharge from "./endpoints/invoice/coinbaseGetCharge";
import invoiceCoinbaseGetChargeInfo from "./endpoints/invoice/coinbaseGetChargeInfo";
import invoiceCreate from "./endpoints/invoice/create";
import invoiceEnableSplit from "./endpoints/invoice/enableSplit";
import invoiceGet from "./endpoints/invoice/get";
import invoiceGetAdmin from "./endpoints/invoice/getAdmin";
import invoiceGetMyRefundsStats from "./endpoints/invoice/getMyRefundsStats";
import invoiceGetPaymentGatewayTransactionUrlAdmin from "./endpoints/invoice/getPaymentGatewayTransactionUrlAdmin";
import invoiceGetStripePayIntent from "./endpoints/invoice/getStripePayIntent";
import invoiceList from "./endpoints/invoice/list";
import invoiceListAdmin from "./endpoints/invoice/listAdmin";
import invoiceListByAffiliateAdmin from "./endpoints/invoice/listByAffiliateAdmin";
import invoiceListMine from "./endpoints/invoice/listMine";
import invoiceListStatsOverviewAdmin from "./endpoints/invoice/listStatsOverviewAdmin";
import invoiceModifierArchiveAdmin from "./endpoints/invoice/modifierArchiveAdmin";
import invoiceModifiersAddAdmin from "./endpoints/invoice/modifiersAddAdmin";
import invoiceModifiersList from "./endpoints/invoice/modifiersList";
import invoiceModifiersListAdmin from "./endpoints/invoice/modifiersListAdmin";
import invoicePaypalClassicGetPaymentUrl from "./endpoints/invoice/paypalClassicGetPaymentUrl";
import invoicePaypalClassicSuccess from "./endpoints/invoice/paypalClassicSuccess";
import invoiceRecountInvoiceTotalAdmin from "./endpoints/invoice/recountInvoiceTotalAdmin";
import invoiceRefundSingleInvoiceItemAdmin from "./endpoints/invoice/refundSingleInvoiceItemAdmin";
import invoiceRefundToWallet from "./endpoints/invoice/refundToWallet";
import invoiceRemoveAffiliateAdmin from "./endpoints/invoice/removeAffiliateAdmin";
import invoiceRemoveFundsAdmin from "./endpoints/invoice/removeFundsAdmin";
import invoiceRemoveWalletFundsFromInvoice from "./endpoints/invoice/removeWalletFundsFromInvoice";
import invoiceSetAffiliateAdmin from "./endpoints/invoice/setAffiliateAdmin";
import invoiceSetIsDiscountCouponAllowed from "./endpoints/invoice/setIsDiscountCouponAllowed";
import invoiceSetIsPaymentEnabledForInactiveProductsAdmin from "./endpoints/invoice/setIsPaymentEnabledForInactiveProductsAdmin";
import invoiceSetIsPaymentsAllowed from "./endpoints/invoice/setIsPaymentsAllowed";
import invoiceSetPartnergroundLinkVisitLogSessionId from "./endpoints/invoice/setPartnergroundLinkVisitLogSessionId";
import invoiceSetStateAdmin from "./endpoints/invoice/setStateAdmin";
import invoiceSplitOptionsList from "./endpoints/invoice/splitOptionsList";
import invoiceSplitPaymentToggle from "./endpoints/invoice/splitPaymentToggle";
import invoiceSplitPaymentUpdateAdmin from "./endpoints/invoice/splitPaymentUpdateAdmin";
import invoiceStripePay from "./endpoints/invoice/stripePay";
import invoiceStripeSplitPayIntent from "./endpoints/invoice/stripeSplitPayIntent";
import invoiceUpdateBillingDetails from "./endpoints/invoice/updateBillingDetails";
import invoiceUpdatePaidAtAdmin from "./endpoints/invoice/updatePaidAtAdmin";
import invoiceVipDiscountsList from "./endpoints/invoice/vipDiscountsList";
import invoiceItemSetRefundQuantityAdmin from "./endpoints/invoiceItem/setRefundQuantityAdmin";
import klavyioEmailMeBeforeDealEnds from "./endpoints/klavyio/emailMeBeforeDealEnds";
import klavyioSubscribeNewsletter from "./endpoints/klavyio/subscribeNewsletter";
import metaGetIpCountry from "./endpoints/meta/getIpCountry";
import noteCreateAdmin from "./endpoints/note/createAdmin";
import noteGetAdmin from "./endpoints/note/getAdmin";
import noteListAdmin from "./endpoints/note/listAdmin";
import notificationsCreateDemoNotification from "./endpoints/notifications/createDemoNotification";
import notificationsGetUnreadCount from "./endpoints/notifications/getUnreadCount";
import notificationsListMine from "./endpoints/notifications/listMine";
import notificationsMarkAllAsRead from "./endpoints/notifications/markAllAsRead";
import notificationsMarkAsRead from "./endpoints/notifications/markAsRead";
import optionsCreatAdmin from "./endpoints/options/creatAdmin";
import optionsGet from "./endpoints/options/get";
import optionsListAdmin from "./endpoints/options/listAdmin";
import optionsRemoveAdmin from "./endpoints/options/removeAdmin";
import optionsUpdateAdmin from "./endpoints/options/updateAdmin";
import paypalWebhook from "./endpoints/paypal/webhook";
import analyticsGetProductSalesStats from "./endpoints/analytics/getProductSalesStats";
import productCreate from "./endpoints/product/create";
import productGet from "./endpoints/product/get";
import productGetAdmin from "./endpoints/product/getAdmin";
import productGetDataForUiRender from "./endpoints/product/getDataForUiRender";
import productGetForRefund from "./endpoints/product/getForRefund";
import productGetForReview from "./endpoints/product/getForReview";
import productGetProductDetailsForAnalytics from "./endpoints/product/getProductDetailsForAnalytics";
import productList from "./endpoints/product/list";
import productListAdmin from "./endpoints/product/listAdmin";
import productListMyProducts from "./endpoints/product/listMyProducts";
import productMakeDraft from "./endpoints/product/makeDraft";
import productPublishAdmin from "./endpoints/product/publishAdmin";
import productRebuildPublicPage from "./endpoints/product/rebuildPublicPage";
import productRemoveAdmin from "./endpoints/product/removeAdmin";
import productTagAdd from "./endpoints/product/tagAdd";
import productTagRemove from "./endpoints/product/tagRemove";
import productTagsList from "./endpoints/product/tagsList";
import productUpdateAdmin from "./endpoints/product/updateAdmin";
import productUpdateSortOrderAdmin from "./endpoints/product/updateSortOrderAdmin";
import productVendorPercentageSet from "./endpoints/product/vendorPercentageSet";
import productView from "./endpoints/product/view";
import productPlanCreateAdmin from "./endpoints/productPlan/createAdmin";
import productPlanDuplicateAdmin from "./endpoints/productPlan/duplicateAdmin";
import productPlanGet from "./endpoints/productPlan/get";
import productPlanGetAdmin from "./endpoints/productPlan/getAdmin";
import productPlanList from "./endpoints/productPlan/list";
import productPlanListAdmin from "./endpoints/productPlan/listAdmin";
import productPlanRemove from "./endpoints/productPlan/remove";
import productPlanSubscriptionList from "./endpoints/productPlan/subscriptionList";
import productPlanUpdateAdmin from "./endpoints/productPlan/updateAdmin";
import productPlansListForPublishedProduct from "./endpoints/productPlans/listForPublishedProduct";
import productsCsvExport_24ge2g42xa from "./endpoints/products/csvExport_24ge2g42xa";
import productsListActiveProducts from "./endpoints/products/listActiveProducts";
import reviewCreate from "./endpoints/review/create";
import reviewGetMyForEdit from "./endpoints/review/getMyForEdit";
import reviewGetSingle from "./endpoints/review/getSingle";
import reviewList from "./endpoints/review/list";
import reviewRemoveMine from "./endpoints/review/removeMine";
import reviewUpdateMine from "./endpoints/review/updateMine";
import salesGetLatestPurchases from "./endpoints/sales/getLatestPurchases";
import splitPaymentSettingCreate from "./endpoints/splitPaymentSetting/create";
import splitPaymentSettingList from "./endpoints/splitPaymentSetting/list";
import splitPaymentSettingUpdate from "./endpoints/splitPaymentSetting/update";
import stripeSubscriptionList from "./endpoints/stripeSubscription/list";
import stripeWebhook from "./endpoints/stripe/webhook";
import stripeCardCreate from "./endpoints/stripeCard/create";
import stripeCardGet from "./endpoints/stripeCard/get";
import stripeCardList from "./endpoints/stripeCard/list";
import stripeCardRemove from "./endpoints/stripeCard/remove";
import submitProductAccept from "./endpoints/submitProduct/accept";
import submitProductAuditLogsList from "./endpoints/submitProduct/auditLogsList";
import submitProductAuditLogsListAdmin from "./endpoints/submitProduct/auditLogsListAdmin";
import submitProductCreate from "./endpoints/submitProduct/create";
import submitProductGet from "./endpoints/submitProduct/get";
import submitProductGetAdmin from "./endpoints/submitProduct/getAdmin";
import submitProductGetCount from "./endpoints/submitProduct/getCount";
import submitProductImageDelete from "./endpoints/submitProduct/imageDelete";
import submitProductImageUpload from "./endpoints/submitProduct/imageUpload";
import submitProductList from "./endpoints/submitProduct/list";
import submitProductListAdmin from "./endpoints/submitProduct/listAdmin";
import submitProductPublishAdmin from "./endpoints/submitProduct/publishAdmin";
import submitProductReject from "./endpoints/submitProduct/reject";
import submitProductRemoveNotPublished from "./endpoints/submitProduct/removeNotPublished";
import submitProductRequestPublish from "./endpoints/submitProduct/requestPublish";
import submitProductRequestUnpublish from "./endpoints/submitProduct/requestUnpublish";
import submitProductStopSales from "./endpoints/submitProduct/stopSales";
import submitProductUpdate from "./endpoints/submitProduct/update";
import subscriptionCreate from "./endpoints/subscription/create";
import subscriptionCreateAdmin from "./endpoints/subscription/createAdmin";
import subscriptionGet from "./endpoints/subscription/get";
import subscriptionList from "./endpoints/subscription/list";
import subscriptionListAdmin from "./endpoints/subscription/listAdmin";
import subscriptionRemove from "./endpoints/subscription/remove";
import subscriptionUpdate from "./endpoints/subscription/update";
import subscriptionTrackingCreate from "./endpoints/subscriptionTracking/create";
import subscriptionTrackingExportCsv from "./endpoints/subscriptionTracking/exportCsv";
import subscriptionTrackingGet from "./endpoints/subscriptionTracking/get";
import subscriptionTrackingList from "./endpoints/subscriptionTracking/list";
import subscriptionTrackingRemove from "./endpoints/subscriptionTracking/remove";
import subscriptionTrackingReports from "./endpoints/subscriptionTracking/reports";
import subscriptionTrackingSpendingsGraphList from "./endpoints/subscriptionTracking/spendingsGraphList";
import subscriptionTrackingSpendingsList from "./endpoints/subscriptionTracking/spendingsList";
import subscriptionTrackingTagList from "./endpoints/subscriptionTracking/tagList";
import subscriptionTrackingUpdate from "./endpoints/subscriptionTracking/update";
import subscriptionTrackingCompanySearch from "./endpoints/subscriptionTrackingCompany/search";
import tagCreate from "./endpoints/tag/create";
import tagGet from "./endpoints/tag/get";
import tagList from "./endpoints/tag/list";
import tagRemove from "./endpoints/tag/remove";
import tagUpdate from "./endpoints/tag/update";
import tipaltiIframeUrlGet from "./endpoints/tipalti/iframeUrlGet";
import tipaltiPaymentSend from "./endpoints/tipalti/paymentSend";
import stripeCardDetach from "./endpoints/stripeCardDetach";
import userAddIncentiveAdmin from "./endpoints/user/addIncentiveAdmin";
import userAddIncentiveToAllAdmin from "./endpoints/user/addIncentiveToAllAdmin";
import userAddIncentiveToEmailsAdmin from "./endpoints/user/addIncentiveToEmailsAdmin";
import userAffiliateFormGet from "./endpoints/user/affiliateFormGet";
import userAffiliateFormGetAdmin from "./endpoints/user/affiliateFormGetAdmin";
import userAffiliateFormListAdmin from "./endpoints/user/affiliateFormListAdmin";
import userAffiliateFormSubmit from "./endpoints/user/affiliateFormSubmit";
import userBillingDetailsGet from "./endpoints/user/billingDetailsGet";
import userBillingDetailsUpdate from "./endpoints/user/billingDetailsUpdate";
import userChangePassword from "./endpoints/user/changePassword";
import userConfirmEmail from "./endpoints/user/confirmEmail";
import userEmailConfirmAdmin from "./endpoints/user/emailConfirmAdmin";
import userGet from "./endpoints/user/get";
import userGetAdmin from "./endpoints/user/getAdmin";
import userGetAuthtokenAdmin from "./endpoints/user/getAuthtokenAdmin";
import userGetHelpscoutSignature from "./endpoints/user/getHelpscoutSignature";
import userGetIfUserBoughtProduct from "./endpoints/user/getIfUserBoughtProduct";
import userGetReferralMagicLink from "./endpoints/user/getReferralMagicLink";
import userGetReferralMagicStats from "./endpoints/user/getReferralMagicStats";
import userGetStats from "./endpoints/user/getStats";
import userGetUserDealsBoughtCount from "./endpoints/user/getUserDealsBoughtCount";
import userIsAffiliateToggleAdmin from "./endpoints/user/isAffiliateToggleAdmin";
import userIsProductBuyer from "./endpoints/user/isProductBuyer";
import userListAdmin from "./endpoints/user/listAdmin";
import userListAvailableIncentives from "./endpoints/user/listAvailableIncentives";
import userListIncentive from "./endpoints/user/listIncentive";
import userListIncentiveAdmin from "./endpoints/user/listIncentiveAdmin";
import userListInvoiceIncentives from "./endpoints/user/listInvoiceIncentives";
import userLogin from "./endpoints/user/login";
import userLogout from "./endpoints/user/logout";
import userPasswordChangeWithToken from "./endpoints/user/passwordChangeWithToken";
import userRemoveIncentiveAdmin from "./endpoints/user/removeIncentiveAdmin";
import userRemovePaypalEmail from "./endpoints/user/removePaypalEmail";
import userRequestPasswordRecovery from "./endpoints/user/requestPasswordRecovery";
import userRequestResendEmailConfirmation from "./endpoints/user/requestResendEmailConfirmation";
import userSendEmailAdmin from "./endpoints/user/sendEmailAdmin";
import userSetPaypalEmail from "./endpoints/user/setPaypalEmail";
import userSignUp from "./endpoints/user/signUp";
import userStripeCardsList from "./endpoints/user/stripeCardsList";
import userUpdate from "./endpoints/user/update";
import userUpdateAdmin from "./endpoints/user/updateAdmin";
import userUpdateProfileTimezone from "./endpoints/user/updateProfileTimezone";
import userUploadProfilePicture from "./endpoints/user/uploadProfilePicture";
import userBillingDetailsCreateAdmin from "./endpoints/userBillingDetails/createAdmin";
import userBillingDetailsGetAdmin from "./endpoints/userBillingDetails/getAdmin";
import userBillingDetailsUpdateAdmin from "./endpoints/userBillingDetails/updateAdmin";
import userDraftCreate from "./endpoints/userDraft/create";
import userDraftGet from "./endpoints/userDraft/get";
import userDraftList from "./endpoints/userDraft/list";
import userDraftRemove from "./endpoints/userDraft/remove";
import userDraftUpdate from "./endpoints/userDraft/update";
import userMetaGet from "./endpoints/userMeta/get";
import userMetaUpdate from "./endpoints/userMeta/update";
import userRegistrationBanDomainAdmin from "./endpoints/userRegistration/banDomainAdmin";
import userRegistrationUnbanDomainAdmin from "./endpoints/userRegistration/unbanDomainAdmin";
import vendorPaymentInfoForceList from "./endpoints/vendorPaymentInfo/forceList";
import vendorProductGetStats from "./endpoints/vendorProduct/getStats";
import vendorSettingsCreateAdmin from "./endpoints/vendorSettings/createAdmin";
import vendorSettingsGetAdmin from "./endpoints/vendorSettings/getAdmin";
import vendorSettingsUpdateAdmin from "./endpoints/vendorSettings/updateAdmin";
import videoCreateAdmin from "./endpoints/video/createAdmin";
import videoGet from "./endpoints/video/get";
import videoGetFullProductData from "./endpoints/video/getFullProductData";
import videoList from "./endpoints/video/list";
import videoRemoveAdmin from "./endpoints/video/removeAdmin";
import videoUpdateAdmin from "./endpoints/video/updateAdmin";
import videoChannelCreateAdmin from "./endpoints/videoChannel/createAdmin";
import videoChannelGetForProduct from "./endpoints/videoChannel/getForProduct";
import videoChannelUpdateAdmin from "./endpoints/videoChannel/updateAdmin";
import videoChannelSectionCreate from "./endpoints/videoChannelSection/create";
import videoChannelSectionList from "./endpoints/videoChannelSection/list";
import videoChannelSectionRemoveAdmin from "./endpoints/videoChannelSection/removeAdmin";
import videoChannelSectionUpdateAdmin from "./endpoints/videoChannelSection/updateAdmin";
import walletAddFundsAdmin from "./endpoints/wallet/addFundsAdmin";
import walletCoverInvoice from "./endpoints/wallet/coverInvoice";
import walletCoverInvoiceAdmin from "./endpoints/wallet/coverInvoiceAdmin";
import walletCreateAdmin from "./endpoints/wallet/createAdmin";
import walletGet from "./endpoints/wallet/get";
import walletGetAdmin from "./endpoints/wallet/getAdmin";
import walletGetMyOverview from "./endpoints/wallet/getMyOverview";
import walletListAdmin from "./endpoints/wallet/listAdmin";
import walletListMyWalletLogs from "./endpoints/wallet/listMyWalletLogs";
import walletRecountBalance from "./endpoints/wallet/recountBalance";
import walletRemoveFundsAdmin from "./endpoints/wallet/removeFundsAdmin";
import walletLogsList from "./endpoints/walletLogs/list";
import walletLogsListAdmin from "./endpoints/walletLogs/listAdmin";
import walletLogsRemoveLogAdmin from "./endpoints/walletLogs/removeLogAdmin";
const methods = {
  affiliateGetAffiliateStatsDetailsAdmin,
  affiliateGetListForPayoutAdmin,
  affiliateGetListOfActiveAffiliates,
  affiliateGetPre_1575217086Stats,
  affiliateGetPre_1575217086StatsAdmin,
  affiliateGetReportCsvAdmin,
  affiliateGetStatsAdmin,
  affiliateListStatsAdmin,
  affiliateMakePaypalPayoutAdmin,
  affiliateSetAffiliateAsPaidAdmin,

  affiliateBuyLogGetAdmin,

  affiliateDetailsCreateAdmin,
  affiliateDetailsGetAdmin,
  affiliateDetailsUpdateAdmin,

  analyticsListProductSales,

  auditLogDiscountCouponList,
  auditLogListAdmin,

  banListAdmin,
  banRemoveAdmin,
  banUserAdmin,

  blogCreateAdmin,
  blogGet,
  blogGetAdmin,
  blogList,
  blogListAdmin,
  blogRemoveAdmin,
  blogUpdateAdmin,

  cartCreateAdmin,
  cartGetAdmin,
  cartGetMyItemsInCartCount,
  cartHowMuchLeftToGetSplit,
  cartListAdmin,
  cartListMyItems,
  cartRemoveAdmin,
  cartV2Get,
  cartV2ItemUpdate,
  cartV2Pay,
  cartView,
  cartVipDiscountList,

  cartItemAddToCartAdmin,
  cartItemEditAdmin,
  cartItemListAdmin,
  cartItemRemoveAdmin,

  circlesoAuthorize,
  circlesoGetSsoAuthorizationOneTimeUseCode,
  circlesoToken,
  circlesoUser,

  couponCheck_3rdParty,
  couponGetDetails,
  couponGetForRedeem,
  couponMarkAsRedeemed,

  couponPoolUpdateStatsAdmin,

  couponsListAdmin,
  couponsListMy,
  couponsPurchasedList,
  couponsPurchasedListFromV1,
  couponsSetStateAdmin,
  couponsSetUpdatedAtAdmin,

  currencyListAll,

  discountCouponApplyToInvoice,
  discountCouponCreate,
  discountCouponExpire,
  discountCouponExportAdmin,
  discountCouponGet,
  discountCouponList,
  discountCouponRemove,
  discountCouponRemoveFromInvoice,
  discountCouponUpdate,

  discountCouponCategoryCreate,
  discountCouponCategoryExpireAllCoupons,
  discountCouponCategoryGet,
  discountCouponCategoryList,
  discountCouponCategoryRemove,
  discountCouponCategoryUpdate,

  discountCouponCategoryStatsRecalculate,

  discussionEditMyDiscussion,
  discussionList,
  discussionListByProduct,
  discussionListRepliesForDiscussion,
  discussionMarkHelpful,
  discussionPostNewForProduct,
  discussionPostNewReply,
  discussionReactionAdd,
  discussionReactionRemove,

  favoriteAdd,
  favoriteCount,
  favoriteGetByProductId,
  favoriteList,
  favoriteRemove,

  getStatsDetectSaleIssuesAdmin,

  imageGet,
  imageUpload,

  coinbaseWebhook,

  invoiceApplyIncentive,
  invoiceAutomatedRefund,
  invoiceCoinbaseGetCharge,
  invoiceCoinbaseGetChargeInfo,
  invoiceCreate,
  invoiceEnableSplit,
  invoiceGet,
  invoiceGetAdmin,
  invoiceGetMyRefundsStats,
  invoiceGetPaymentGatewayTransactionUrlAdmin,
  invoiceGetStripePayIntent,
  invoiceList,
  invoiceListAdmin,
  invoiceListByAffiliateAdmin,
  invoiceListMine,
  invoiceListStatsOverviewAdmin,
  invoiceModifierArchiveAdmin,
  invoiceModifiersAddAdmin,
  invoiceModifiersList,
  invoiceModifiersListAdmin,
  invoicePaypalClassicGetPaymentUrl,
  invoicePaypalClassicSuccess,
  invoiceRecountInvoiceTotalAdmin,
  invoiceRefundSingleInvoiceItemAdmin,
  invoiceRefundToWallet,
  invoiceRemoveAffiliateAdmin,
  invoiceRemoveFundsAdmin,
  invoiceRemoveWalletFundsFromInvoice,
  invoiceSetAffiliateAdmin,
  invoiceSetIsDiscountCouponAllowed,
  invoiceSetIsPaymentEnabledForInactiveProductsAdmin,
  invoiceSetIsPaymentsAllowed,
  invoiceSetPartnergroundLinkVisitLogSessionId,
  invoiceSetStateAdmin,
  invoiceSplitOptionsList,
  invoiceSplitPaymentToggle,
  invoiceSplitPaymentUpdateAdmin,
  invoiceStripePay,
  invoiceStripeSplitPayIntent,
  invoiceUpdateBillingDetails,
  invoiceUpdatePaidAtAdmin,
  invoiceVipDiscountsList,

  invoiceItemSetRefundQuantityAdmin,

  klavyioEmailMeBeforeDealEnds,
  klavyioSubscribeNewsletter,

  metaGetIpCountry,

  noteCreateAdmin,
  noteGetAdmin,
  noteListAdmin,

  notificationsCreateDemoNotification,
  notificationsGetUnreadCount,
  notificationsListMine,
  notificationsMarkAllAsRead,
  notificationsMarkAsRead,

  optionsCreatAdmin,
  optionsGet,
  optionsListAdmin,
  optionsRemoveAdmin,
  optionsUpdateAdmin,

  paypalWebhook,

  analyticsGetProductSalesStats,

  productCreate,
  productGet,
  productGetAdmin,
  productGetDataForUiRender,
  productGetForRefund,
  productGetForReview,
  productGetProductDetailsForAnalytics,
  productList,
  productListAdmin,
  productListMyProducts,
  productMakeDraft,
  productPublishAdmin,
  productRebuildPublicPage,
  productRemoveAdmin,
  productTagAdd,
  productTagRemove,
  productTagsList,
  productUpdateAdmin,
  productUpdateSortOrderAdmin,
  productVendorPercentageSet,
  productView,

  productPlanCreateAdmin,
  productPlanDuplicateAdmin,
  productPlanGet,
  productPlanGetAdmin,
  productPlanList,
  productPlanListAdmin,
  productPlanRemove,
  productPlanSubscriptionList,
  productPlanUpdateAdmin,

  productPlansListForPublishedProduct,

  productsCsvExport_24ge2g42xa,
  productsListActiveProducts,

  reviewCreate,
  reviewGetMyForEdit,
  reviewGetSingle,
  reviewList,
  reviewRemoveMine,
  reviewUpdateMine,

  salesGetLatestPurchases,

  splitPaymentSettingCreate,
  splitPaymentSettingList,
  splitPaymentSettingUpdate,

  stripeSubscriptionList,

  stripeWebhook,

  stripeCardCreate,
  stripeCardGet,
  stripeCardList,
  stripeCardRemove,

  submitProductAccept,
  submitProductAuditLogsList,
  submitProductAuditLogsListAdmin,
  submitProductCreate,
  submitProductGet,
  submitProductGetAdmin,
  submitProductGetCount,
  submitProductImageDelete,
  submitProductImageUpload,
  submitProductList,
  submitProductListAdmin,
  submitProductPublishAdmin,
  submitProductReject,
  submitProductRemoveNotPublished,
  submitProductRequestPublish,
  submitProductRequestUnpublish,
  submitProductStopSales,
  submitProductUpdate,

  subscriptionCreate,
  subscriptionCreateAdmin,
  subscriptionGet,
  subscriptionList,
  subscriptionListAdmin,
  subscriptionRemove,
  subscriptionUpdate,

  subscriptionTrackingCreate,
  subscriptionTrackingExportCsv,
  subscriptionTrackingGet,
  subscriptionTrackingList,
  subscriptionTrackingRemove,
  subscriptionTrackingReports,
  subscriptionTrackingSpendingsGraphList,
  subscriptionTrackingSpendingsList,
  subscriptionTrackingTagList,
  subscriptionTrackingUpdate,

  subscriptionTrackingCompanySearch,

  tagCreate,
  tagGet,
  tagList,
  tagRemove,
  tagUpdate,

  tipaltiIframeUrlGet,
  tipaltiPaymentSend,

  stripeCardDetach,

  userAddIncentiveAdmin,
  userAddIncentiveToAllAdmin,
  userAddIncentiveToEmailsAdmin,
  userAffiliateFormGet,
  userAffiliateFormGetAdmin,
  userAffiliateFormListAdmin,
  userAffiliateFormSubmit,
  userBillingDetailsGet,
  userBillingDetailsUpdate,
  userChangePassword,
  userConfirmEmail,
  userEmailConfirmAdmin,
  userGet,
  userGetAdmin,
  userGetAuthtokenAdmin,
  userGetHelpscoutSignature,
  userGetIfUserBoughtProduct,
  userGetReferralMagicLink,
  userGetReferralMagicStats,
  userGetStats,
  userGetUserDealsBoughtCount,
  userIsAffiliateToggleAdmin,
  userIsProductBuyer,
  userListAdmin,
  userListAvailableIncentives,
  userListIncentive,
  userListIncentiveAdmin,
  userListInvoiceIncentives,
  userLogin,
  userLogout,
  userPasswordChangeWithToken,
  userRemoveIncentiveAdmin,
  userRemovePaypalEmail,
  userRequestPasswordRecovery,
  userRequestResendEmailConfirmation,
  userSendEmailAdmin,
  userSetPaypalEmail,
  userSignUp,
  userStripeCardsList,
  userUpdate,
  userUpdateAdmin,
  userUpdateProfileTimezone,
  userUploadProfilePicture,

  userBillingDetailsCreateAdmin,
  userBillingDetailsGetAdmin,
  userBillingDetailsUpdateAdmin,

  userDraftCreate,
  userDraftGet,
  userDraftList,
  userDraftRemove,
  userDraftUpdate,

  userMetaGet,
  userMetaUpdate,

  userRegistrationBanDomainAdmin,
  userRegistrationUnbanDomainAdmin,

  vendorPaymentInfoForceList,

  vendorProductGetStats,

  vendorSettingsCreateAdmin,
  vendorSettingsGetAdmin,
  vendorSettingsUpdateAdmin,

  videoCreateAdmin,
  videoGet,
  videoGetFullProductData,
  videoList,
  videoRemoveAdmin,
  videoUpdateAdmin,

  videoChannelCreateAdmin,
  videoChannelGetForProduct,
  videoChannelUpdateAdmin,

  videoChannelSectionCreate,
  videoChannelSectionList,
  videoChannelSectionRemoveAdmin,
  videoChannelSectionUpdateAdmin,

  walletAddFundsAdmin,
  walletCoverInvoice,
  walletCoverInvoiceAdmin,
  walletCreateAdmin,
  walletGet,
  walletGetAdmin,
  walletGetMyOverview,
  walletListAdmin,
  walletListMyWalletLogs,
  walletRecountBalance,
  walletRemoveFundsAdmin,

  walletLogsList,
  walletLogsListAdmin,
  walletLogsRemoveLogAdmin,
};

export default methods;
