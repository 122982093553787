import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material";

export default function SidebarEmptyCart({ setCurrentView }) {
  const theme = useTheme();
  // just removed react helmet
  return (
    <div>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          margin: { sm: theme.spacing(6, 0, 2) },
        }}
      >
        <img src={"/img/empty_cart.svg"} />
      </Box>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ textAlign: "center", maxWidth: 500 }}>
          <Typography variant={"h3"} style={{ margin: theme.spacing(2, 0) }}>
            Your cart looks empty
          </Typography>
          <Typography variant={"body1"} sx={{ padding: theme.spacing(0, 1) }}>
            We have a great collection of softwares that can help fuel your
            business growth. You might want to check some.
          </Typography>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: theme.spacing(2, 0) }}>
        <Button
          // href={`https://${process.env.REACT_APP_MAIN_DOMAIN}/deals`}
          variant={"contained"}
          color={"primary"}
          endIcon={<ArrowForwardIcon />}
          onClick={() => setCurrentView("active-deals")}
        >
          Explore Deals
        </Button>
      </div>
    </div>
  );
}
