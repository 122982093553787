/* eslint-disable no-unused-vars */
// #### CUSTOM__AFTER_IMPORTS ----- START ----

import { grey } from "@mui/material/colors";
import { amber } from "@mui/material/colors";
import { green } from "@mui/material/colors";

// #### CUSTOM__AFTER_IMPORTS ----- END ------

let type;
if (["yes", "no"].includes(localStorage.getItem("isDarkMode"))) {
  type = localStorage.getItem("isDarkMode") === "yes" ? "dark" : "dark";
} else {
  const matchMediaResult =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  type = matchMediaResult === true ? "dark" : "light";
}

const customThemeStyles = {
  palette: {},
  overrides: {},
};

// #### CUSTOM__AFTER_DEFINE ----- START ----

customThemeStyles.palette = {
  background: {
    paper: "#FAFAFA",
  },
  primary: {
    light: "#FF6E71",
    main: "#EC3646",
    dark: "#B2001F",
    contrastText: "#FFFFFF",
  },
  secondary: grey,
  warning: {
    light: amber[500],
    main: amber[700],
    dark: amber[900],
    contrastText: "#FFFFFF",
  },
  success: {
    light: green[500],
    main: green[700],
    dark: green[900],
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#231F1F",
    secondary: "#4D4949",
    disabled: "#9E9E9E",
    hint: "#9E9E9E",
  },
  rating: {
    active: "#FFB400",
    inactive: "#E5E1E1",
  },
};

customThemeStyles.typography = {
  body1: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    fontWeight: 300,
    style: "normal",
  },
  body2: {
    fontSize: "14px",
    lineHeight: "20.02px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    style: "normal",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    fontWeight: 400,
    style: "normal",
  },
  overline: {
    // color: "red",
    // fontSize: "99px",
    // lineHeight: "98px",
    // fontWeight: 100,
    // style: "normal",
  },
  button: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    style: "normal",
    letterSpacing: "0.4px",
    textTransform: "none",
  },
  subtitle1: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    fontWeight: 500,
    style: "normal",
  },
  subtitle2: {
    fontSize: "14px",
    lineHeight: "21.98px",
    letterSpacing: "0.1px",
    fontWeight: 500,
    style: "normal",
  },
  h2: {
    fontSize: "36px",
    lineHeight: "44.46px",
    letterSpacing: "-0.5px",
    fontWeight: 900,
    style: "normal",
  },
  h3: {
    fontSize: "24px",
    lineHeight: "32.02px",
    letterSpacing: "0.25px",
    fontWeight: 900,
    style: "normal",
  },
  h4: {
    // fontSize: "20px",
    // lineHeight: "32px",
    // fontWeight: 700,
    // style: "normal",
  },
  h5: {
    fontSize: "20px",
    lineHeight: "32px",
    fontWeight: 700,
    style: "normal",
  },
  h6: {
    fontSize: "18px",
    lineHeight: "28px",
    fontWeight: 500,
    style: "normal",
    letterSpacing: "0.15px",
  },
};

customThemeStyles.components = {
  MuiTab: {
    styleOverrides: {
      root: {
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        padding: "8px 24px",
      },
      sizeSmall: {
        padding: "4px 10px",
        fontSize: "13px",
        letterSpacing: "0.46px",
        weight: 500,
        lineHeight: "22px",
      },
      sizeLarge: {
        padding: "15px 30px",
        fontSize: "15px",
        letterSpacing: "0.46px",
        weight: 500,
        lineHeight: "26px",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        padding: "4px 8px",
        borderRadius: "4px",
      },
      label: {
        fontSize: "12px",
        lineHeight: "14px",
      },
    },
  },
};

// #### CUSTOM__AFTER_DEFINE ----- END ------

function getCustomThemeStyles({ themeMode }) {
  // #### CUSTOM__BEFORE_RETURN ----- START ----

  // customThemeStyles.palette.mode = themeMode;

  // #### CUSTOM__BEFORE_RETURN ----- END ------

  return customThemeStyles;
}

export default getCustomThemeStyles;
