import call from "../../helpers/call";

function list({
  authToken,
  searchQuery,
  page,
  perPage,
  discountCouponCategoryId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discount-coupon/list`,
    payload: {
      searchQuery, // (Optional) Any string of 0-120 symbols in length.
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any positive integer. Range: (0,1000]
      discountCouponCategoryId, // (Required) String 10-30 in length and starting with "dcc-"
    },
  });
}

export default list;
