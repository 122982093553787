import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY =
  "circleso/getSsoAuthorizationOneTimeUseCode/TRY";
const GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_SUCCESS =
  "circleso/getSsoAuthorizationOneTimeUseCode/SUCCESS";
const GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_FAILED =
  "circleso/getSsoAuthorizationOneTimeUseCode/FAILED";

const GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY_BY_IDENTIFIER =
  "circleso/getSsoAuthorizationOneTimeUseCode/TRY_BY_IDENTIFIER";
const GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_SUCCESS_BY_IDENTIFIER =
  "circleso/getSsoAuthorizationOneTimeUseCode/SUCCESS_BY_IDENTIFIER";
const GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_FAILED_BY_IDENTIFIER =
  "circleso/getSsoAuthorizationOneTimeUseCode/FAILED_BY_IDENTIFIER";

const initialState = Map({
  getSsoAuthorizationOneTimeUseCode: shape.merge(
    Map({ dataByIdentifier: Map({}) })
  ),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY:
      return handleTry({ state, action });
    case GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_SUCCESS:
      return handleSuccess({ state, action });
    case GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_FAILED:
      return handleFailed({ state, action });
    case GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY,
  GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_SUCCESS,
  GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_FAILED,
  GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY_BY_IDENTIFIER,
  GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_SUCCESS_BY_IDENTIFIER,
  GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
