import call from "../../helpers/call";

function create({ authToken, meta, identifier }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user-draft/create`,
    payload: {
      meta, // (Required) JS object. Not null, could be empty object without any keys.
      identifier, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default create;
