import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import NotificationsPopover from "jsx/Notifications/NotificationsPopover";
import React from "react";
import UnreadNotificationsCountBadge from "jsx/Notifications/UnreadNotificationsCountBadge";
import useActions from "stateManager/hooks/useActions";

export default function Notifications(props) {
  const theme = useTheme();
  const ___actions = useActions();

  React.useEffect(() => {
    const interval = setInterval(() => {
      ___actions.notifications.getUnreadCount({});
      ___actions.notifications.listMine({
        page: 1,
        perPage: 50,
      });
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // notification popover STARTS
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = open ? "notification-button" : undefined;
  // notification popover ENDS

  return (
    <>
      <IconButton
        aria-describedby={id}
        // aria-controls="basic-menu"
        // aria-haspopup="true"
        // aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color={open ? "primary" : "default"}
        sx={{
          ...(props.sx && props.sx),
        }}
      >
        <UnreadNotificationsCountBadge />
      </IconButton>
      {open && (
        <NotificationsPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
