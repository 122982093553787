import call from "../../helpers/call";

function addIncentiveAdmin({
  authToken,
  ownerUserId,
  amountCents,
  comment,
  validUntilAt,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/add-incentive-admin`,
    payload: {
      ownerUserId, // (Required) String 10-30 in length and starting with "u-"
      amountCents, // (Required) Any positive integer. 0 is not valid.
      comment, // (Required) Any string of 0-120 symbols in length.
      validUntilAt, // (Required) Timestamp value. Any positive integer.
    },
  });
}

export default addIncentiveAdmin;
