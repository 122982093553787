import useCirclesoGetSsoAuthorizationOneTimeUseCodeResult from "stateManager/hooks/circleso/useCirclesoGetSsoAuthorizationOneTimeUseCodeResult";

export default function HandleSsoRedirect() {
  // https://pitchground.com/deals?userflow=login&return-url=https://community.pitchground.com/oauth2/callback
  // https://community.pitchground.com/oauth2/callback
  const ssoCodeResult = useCirclesoGetSsoAuthorizationOneTimeUseCodeResult({});
  const code = ssoCodeResult.getIn(["result", "data", "ssoOneTimeUseCode"]);

  if (code) {
    location.href = `https://community.pitchground.com/oauth2/callback?code=${code}`;
  }

  return null;
}
