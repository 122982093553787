import call from "../../helpers/call";

function listAdmin({ authToken, page, perPage, searchQuery }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/submit-product/list-admin`,
    payload: {
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any positive integer. Range: (0,1000]
      searchQuery, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default listAdmin;
