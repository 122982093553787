import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import notifications from "../../reducers/notifications";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

// Write any custom code here. It won't be overwritten.

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryNotificationsCreateDemoNotificationByIdentifier({
  data,
}) {
  const payload = {};

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.payload.relatedProductId !== "undefined") {
    payload.relatedProductId = data.payload.relatedProductId;
  }

  if (typeof data.payload.relatedDiscussionId !== "undefined") {
    payload.relatedDiscussionId = data.payload.relatedDiscussionId;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  const result = yield sdk.notificationsCreateDemoNotification(payload);

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: notifications.actionTypes
        .CREATE_DEMO_NOTIFICATION_SUCCESS_BY_IDENTIFIER,
      data: {
        ___identifier: data.___identifier,
        payload: { data: result.data, meta: result.meta },
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: notifications.actionTypes
        .CREATE_DEMO_NOTIFICATION_FAILED_BY_IDENTIFIER,
      data: { ___identifier: data.___identifier, payload: result.error },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
