import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const GET_TRY = "userMeta/get/TRY";
const GET_SUCCESS = "userMeta/get/SUCCESS";
const GET_FAILED = "userMeta/get/FAILED";

const GET_TRY_BY_IDENTIFIER = "userMeta/get/TRY_BY_IDENTIFIER";
const GET_SUCCESS_BY_IDENTIFIER = "userMeta/get/SUCCESS_BY_IDENTIFIER";
const GET_FAILED_BY_IDENTIFIER = "userMeta/get/FAILED_BY_IDENTIFIER";

const UPDATE_TRY = "userMeta/update/TRY";
const UPDATE_SUCCESS = "userMeta/update/SUCCESS";
const UPDATE_FAILED = "userMeta/update/FAILED";

const UPDATE_TRY_BY_IDENTIFIER = "userMeta/update/TRY_BY_IDENTIFIER";
const UPDATE_SUCCESS_BY_IDENTIFIER = "userMeta/update/SUCCESS_BY_IDENTIFIER";
const UPDATE_FAILED_BY_IDENTIFIER = "userMeta/update/FAILED_BY_IDENTIFIER";

const initialState = Map({
  get: shape.merge(Map({ dataByIdentifier: Map({}) })),
  update: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TRY:
    case UPDATE_TRY:
      return handleTry({ state, action });
    case GET_SUCCESS:
    case UPDATE_SUCCESS:
      return handleSuccess({ state, action });
    case GET_FAILED:
    case UPDATE_FAILED:
      return handleFailed({ state, action });
    case GET_TRY_BY_IDENTIFIER:
    case UPDATE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case GET_SUCCESS_BY_IDENTIFIER:
    case UPDATE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case GET_FAILED_BY_IDENTIFIER:
    case UPDATE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  GET_TRY_BY_IDENTIFIER,
  GET_SUCCESS_BY_IDENTIFIER,
  GET_FAILED_BY_IDENTIFIER,

  UPDATE_TRY,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  UPDATE_TRY_BY_IDENTIFIER,
  UPDATE_SUCCESS_BY_IDENTIFIER,
  UPDATE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
