import call from "../../helpers/call";

function setPartnergroundLinkVisitLogSessionId({
  authToken,
  invoiceId,
  partnergroundLinkVisitLogSessionId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/set-partnerground-link-visit-log-session-id`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      partnergroundLinkVisitLogSessionId, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default setPartnergroundLinkVisitLogSessionId;
