import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

/*
Second param is optional object with options:

@identifier - to get loading state by single identifier
@doUseFirstLoading - get loading state of first loading item either regular or any identifier
*/

export default function useUserUpdateLoadingState({}, ___options) {
  const user = useSelector((___state) => ___state.user);
  let result = null;

  if (___options && ___options.identifier) {
    result =
      user.getIn([
        "update",
        "dataByIdentifier",
        ___options.identifier,
        "loading",
      ]) || ___shape.get("loading");
  }

  if (result === null && ___options && ___options.doUseFirstLoading) {
    if (user.getIn(["remove", "loading", "isLoading"]) === true) {
      result = user.getIn(["remove", "loading"]);
    }

    if (result === null) {
      let stateByIdentifiers = user.getIn(["update", "dataByIdentifier"]);
      if (stateByIdentifiers && stateByIdentifiers.size > 0) {
        stateByIdentifiers.forEach((byIdentifier, identifierKey) => {
          if (
            user.getIn([
              "update",
              "dataByIdentifier",
              identifierKey,
              "loading",
              "isLoading",
            ]) === true
          ) {
            result = user.getIn([
              "update",
              "dataByIdentifier",
              identifierKey,
              "loading",
            ]);
          }
        });
      }
    }
  }

  if (result === null) {
    result = user.getIn(["update", "loading"]);
  }

  return result;
}
