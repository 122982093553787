/* eslint-disable consistent-return */
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./reducers";
import rootSaga from "./sagas/rootSaga";

let history = null;
let store = null;

export default function configureStore({
  options,
  initialState,
  doLogStateActionsToConsole,
  doSupportReduxDevtools,
  maxActionLogsInReduxDevTool,
  // homepage value should be same as in package.json. Can not include package.json here cause it's outside of src dir
  homepage,
}) {
  if (history === null) {
    history = createBrowserHistory({
      basename: homepage,
    });
  }

  if (store === null) {
    maxActionLogsInReduxDevTool = maxActionLogsInReduxDevTool || 100;

    const isDevToolsAvailable =
      doSupportReduxDevtools === true &&
      typeof window === "object" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    const composeEnchancers = isDevToolsAvailable
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          maxAge: maxActionLogsInReduxDevTool,
        })
      : compose;

    const logger = (_store) => (next) => (action) => {
      if (doLogStateActionsToConsole) {
        const _groupName = `Redux action: ${action.type}`;
        console.group(_groupName);
        console.info("Redux dispatching action: ", action);
        let result = next(action);
        console.log("Redux next state: ", _store.getState());
        console.groupEnd(_groupName);
        return result;
      }
    };

    const sagaMiddleware = createSagaMiddleware();

    if (doLogStateActionsToConsole) {
      store = createStore(
        createRootReducer({ history, options }),
        initialState,
        composeEnchancers(
          applyMiddleware(
            logger,
            routerMiddleware(history), // for dispatching history actions
            sagaMiddleware
          )
        )
      );
    } else {
      store = createStore(
        createRootReducer({ history, options }),
        initialState,
        composeEnchancers(
          applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            sagaMiddleware
          )
        )
      );
    }

    store.runSagaTask = () => {
      store.sagaTask = sagaMiddleware.run(rootSaga({ options }));
    };

    store.runSagaTask();
  }

  return {
    store,
    history,
  };
}
