import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useTheme } from "@mui/material";
import useUrl from "hook-use-url";
import useIsDialogOpen from "hooks/useIsDialogOpen";
import PgDialog from "jsx/partials/PgDialog";
import * as React from "react";
import useKlavyioSubscribeNewsletterLoadingState from "stateManager/hooks/klavyio/useKlavyioSubscribeNewsletterLoadingState";
import useActions from "stateManager/hooks/useActions";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import isEmail from "validator/lib/isEmail";

export default function EmailMeAboutNewDeals({ productId }) {
  const actions = useActions();
  const url = useUrl();
  const theme = useTheme();
  const isDialogOpen = useIsDialogOpen({ dialog: "new-deals-email" });
  const subscribeLoadingState = useKlavyioSubscribeNewsletterLoadingState({});
  const [emailInput, setEmailInput] = React.useState("");
  const isLoading = subscribeLoadingState.get("isLoading");

  const user = useUserGetResult({});

  const isLoggedIn =
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";

  if (isLoading) {
    return <CircularProgress size={12} color={"secondary"} />;
  }

  const handleClick = () => {
    if (isLoggedIn) {
      if (isLoading === false) {
        const userEmail = user.getIn(["result", "data", "email"]);
        actions.klavyio.subscribeNewsletter({
          email: userEmail,
          relatedProductId: productId,
        });
      } else {
        actions.globalMessages.addToQueue({
          message: "Something went wrong. Please try again.",
        });
      }
    } else {
      url.multipleActions({
        arrayAddPairs: [{ variable: "dialog", value: "new-deals-email" }],
      });
    }
  };

  const handleCloseDialog = () => {
    setEmailInput("");
    url.multipleActions({
      arrayRemovePairs: [{ variable: "dialog", value: "new-deals-email" }],
    });
  };

  const handleSubmit = () => {
    if (isLoading === false && isEmail(emailInput)) {
      actions.klavyio.subscribeNewsletter({
        email: emailInput,
        relatedProductId: productId,
      });
      handleCloseDialog();
    } else {
      actions.globalMessages.addToQueue({
        message: "Please enter a valid email address.",
      });
    }
  };

  return (
    <>
      <Button
        size={"medium"}
        color="inherit"
        startIcon={<NotificationsNoneIcon size="inherit" />}
        sx={{ textTransform: "none" }}
        onClick={handleClick}
        fullWidth
      >
        Email Me About New Deals
      </Button>
      {isDialogOpen ? (
        <PgDialog onClose={handleCloseDialog} loading={isLoading}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={"h3"}>Email Me About New Deals</Typography>
            <TextField
              label="Email Address"
              variant="outlined"
              value={emailInput}
              type={"email"}
              sx={{ m: theme.spacing(3, 0, 5) }}
              onChange={(e) => setEmailInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <Button
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: "none" }}
              size={"large"}
              color={"primary"}
              variant={"contained"}
              disabled={
                !emailInput || isEmail(emailInput) === false || isLoading
              }
              onClick={handleSubmit}
            >
              Subscribe
            </Button>
            <Button
              sx={{ mt: theme.spacing(1) }}
              size={"large"}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
          </Box>
        </PgDialog>
      ) : null}
    </>
  );
}
