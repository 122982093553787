import axios from "axios";
import Cookies from "js-cookie";
import { put } from "redux-saga/effects";
import getIdToken from "stateManager/hooks/authFlow/helpers/getIdToken";
import authFlow from "../../reducers/authFlow";
import globalMessages from "../../reducers/globalMessages";

export default function* tryAuthFlowUpdateProfile({ data }) {
  if (data.profile && typeof data.profile !== "string") {
    data.profile = JSON.stringify(data.profile);
  }
  let result = (yield axios.post(
    `${process.env.REACT_APP_AUTH_API_URL}/update-user`,
    data,
    {
      headers: {
        "x-auth": getIdToken({}),
      },
    }
  )).data;

  if (result.error === null) {
    const refreshToken = Cookies.get("authFlow-refresh_token");
    yield put({
      type: authFlow.actionTypes.GET_REFRESHED_TOKENS_TRY,
      data: { refreshToken },
    });

    yield put({
      type: authFlow.actionTypes.UPDATE_PROFILE_SUCCESS,
      data: result.data,
    });

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Profile saved" },
    });
  } else {
    yield put({
      type: authFlow.actionTypes.UPDATE_PROFILE_FAILED,
      data: result.error,
    });

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Please re-login" },
    });
    setTimeout(() => {
      if (process.env.REACT_APP_AUTH_LOGIN_PAGE_URL) {
        location.href = `${process.env.REACT_APP_AUTH_LOGIN_PAGE_URL}&utm_source=relogin&utm_medium=after-profile-update-try&utm_id=auth`;
      } else {
        const _refreshToken = prompt("Enter refresh token", "");
        if (_refreshToken) {
          location.href = `?authFlow-refresh_token=${_refreshToken}`;
        }
      }
    }, 2000);
  }
}
