import call from "../../helpers/call";

function markAsRedeemed({ authToken, couponId, userMarkedAsRedeamedAt }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/coupon/mark-as-redeemed`,
    payload: {
      couponId, // (Required) String 10-30 in length and starting with "c-"
      userMarkedAsRedeamedAt, // (Required) Must be positive integer or zero
    },
  });
}

export default markAsRedeemed;
