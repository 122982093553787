import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import auth from "../../reducers/auth";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import Cookies from "js-cookie";

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryAuthLogout({}) {
  const payload = {};

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  localStorage.removeItem("authToken");
  // cookie remove & set path and domain MUST match
  Cookies.remove("authToken", {
    path: "/",
    domain:
      // eslint-disable-next-line
      location.host.indexOf("localhost") !== -1
        ? "localhost"
        : `.${process.env.REACT_APP_MAIN_DOMAIN}`,
  });

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.userLogout(payload);
  } catch (e) {
    result = { error: { code: "#2FGA_tryAuthLogout", message: e.message } };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  window.location.reload();

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: auth.actionTypes.LOGOUT_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({ type: auth.actionTypes.LOGOUT_FAILED, data: result.error });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
