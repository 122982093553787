import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

const moment = require("moment");

export default function Countdown({
  date,
  chip = false,
  end = false,
  text = false,
  ...props
}) {
  const theme = useTheme();
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);

  useEffect(() => {
    // updates state every second until timer reaches 0.
    if (moment.unix(date).diff(moment()) >= 0) {
      setTimeout(() => {
        setDays(() => moment.duration(moment.unix(date).diff(moment())).days());
        setHours(() =>
          moment.duration(moment.unix(date).diff(moment())).hours()
        );
        setMinutes(() =>
          moment.duration(moment.unix(date).diff(moment())).minutes()
        );
        setSeconds(() =>
          moment.duration(moment.unix(date).diff(moment())).seconds()
        );
      }, 1000);
      return;
    }
    setDays(0);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
  });

  if (chip)
    return (
      <CountdownChip
        label={{ days, hours, minutes, seconds }}
        end={end}
        {...props}
      />
    );

  if (!seconds) {
    return <CircularProgress size={9} color={"secondary"} />;
  }

  if (text) {
    return days > 0
      ? `${days}d: ${hours}h: ${minutes}m: ${seconds}s`
      : `${hours}h: ${minutes}m: ${seconds}s`;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        marginTop: 2,
        textAlign: "center",
        color: theme.palette.primary.main,
      }}
    >
      <Box>
        <Typography variant={"h2"}>{days < 10 ? `0${days}` : days}</Typography>
        <Typography variant={"body2"}>{days === 1 ? "Day" : "Days"}</Typography>
      </Box>
      <Box>
        <Typography variant={"h2"}>
          {hours < 10 ? `0${hours}` : hours}
        </Typography>
        <Typography variant={"body2"}>
          {hours === 1 ? "Hour" : "Hours"}
        </Typography>
      </Box>
      <Box>
        <Typography variant={"h2"}>
          {minutes < 10 ? `0${minutes}` : minutes}
        </Typography>
        <Typography variant={"body2"}>
          {minutes === 1 ? "Minute" : "Minutes"}
        </Typography>
      </Box>
      <Box>
        <Typography variant={"h2"}>
          {seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
        <Typography variant={"body2"}>
          {seconds === 1 ? "Second" : "Seconds"}
        </Typography>
      </Box>
    </Box>
  );
}

export const CountdownChip = ({ label, ...props }) => {
  return (
    <Chip
      icon={<WatchLaterIcon />}
      label={
        label.seconds ? (
          <Typography variant={"body2"} component={"span"}>
            {props.end ? "Ends " : "Starts "} in{" "}
            <strong>
              {label.days > 0 && `${label.days}d`}
              {label.hours < 10 ? ` 0${label.hours}` : ` ${label.hours}`}h :
              {label.minutes < 10 ? ` 0${label.minutes}` : ` ${label.minutes}`}m
              :{label.seconds < 10 ? ` 0${label.seconds}` : ` ${label.seconds}`}
              s
            </strong>
            {/*{label.days < 10 ? `0${label.days}` : label.days}d :*/}
            {/*<Typography*/}
            {/*  variant={"body2"}*/}
            {/*  sx={{ opacity: 0.8 }}*/}
            {/*  component={"span"}*/}
            {/*>*/}
            {/*  {label.days === 1 ? " day " : " days "}*/}
            {/*</Typography>*/}
          </Typography>
        ) : (
          <CircularProgress size={15} color={"text"} />
        )
      }
      title={props.title && props.title}
      color={props.color ? props.color : "yellow"}
      size={props.size ? props.size : "medium"}
      sx={{
        borderRadius: "0px",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
      }}
    />
  );
};
