import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useFavoriteListLoadingState from "../favorite/useFavoriteListLoadingState";
import useFavoriteAddLoadingState from "../favorite/useFavoriteAddLoadingState";
import useFavoriteRemoveLoadingState from "../favorite/useFavoriteRemoveLoadingState";
// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/favorite/tryFavoriteList";

export default function useFavoriteListResult({}, ___options) {
  const ___favorite = useSelector((___state) => ___state.favorite);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const favoriteListLoadingState = useFavoriteListLoadingState({}, ___options);
  const ___isLoading = favoriteListLoadingState.get("isLoading");
  const isFavoriteAddLoadingState = useFavoriteAddLoadingState({}, ___options);
  const isFavoriteRemoveLoadingState = useFavoriteRemoveLoadingState(
    {},
    ___options
  );

  useEffect(() => {
    if (
      ___isLoading === false &&
      isFavoriteAddLoadingState.get("isLoading") === false &&
      isFavoriteRemoveLoadingState.get("isLoading") === false
    ) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.favorite.list({});
      } else {
        ___actions.favorite.list({});
      }
    }
  }, [isFavoriteAddLoadingState, isFavoriteRemoveLoadingState]);

  if (___options && ___options.identifier) {
    return (
      ___favorite.getIn([
        "list",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___favorite.getIn(["list", "lastResult"]);
}
