import call from "../../helpers/call";

function iframeUrlGet({ authToken, iFrameType }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/tipalti/iframe-url-get`,
    payload: {
      iFrameType, // (Required) Is tipalti iframe type valid
    },
  });
}

export default iframeUrlGet;
