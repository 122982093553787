import call from "../../helpers/call";

function create({ authToken, title }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/tag/create`,
    payload: {
      title, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default create;
