import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const LOGIN_TRY = "auth/login/TRY";
const LOGIN_SUCCESS = "auth/login/SUCCESS";
const LOGIN_FAILED = "auth/login/FAILED";

const LOGIN_TRY_BY_IDENTIFIER = "auth/login/TRY_BY_IDENTIFIER";
const LOGIN_SUCCESS_BY_IDENTIFIER = "auth/login/SUCCESS_BY_IDENTIFIER";
const LOGIN_FAILED_BY_IDENTIFIER = "auth/login/FAILED_BY_IDENTIFIER";

const LOGOUT_TRY = "auth/logout/TRY";
const LOGOUT_SUCCESS = "auth/logout/SUCCESS";
const LOGOUT_FAILED = "auth/logout/FAILED";

const LOGOUT_TRY_BY_IDENTIFIER = "auth/logout/TRY_BY_IDENTIFIER";
const LOGOUT_SUCCESS_BY_IDENTIFIER = "auth/logout/SUCCESS_BY_IDENTIFIER";
const LOGOUT_FAILED_BY_IDENTIFIER = "auth/logout/FAILED_BY_IDENTIFIER";

const initialState = Map({
  login: shape.merge(Map({ dataByIdentifier: Map({}) })),
  logout: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_TRY:
    case LOGOUT_TRY:
      return handleTry({ state, action });
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
      return handleSuccess({ state, action });
    case LOGIN_FAILED:
    case LOGOUT_FAILED:
      return handleFailed({ state, action });
    case LOGIN_TRY_BY_IDENTIFIER:
    case LOGOUT_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case LOGIN_SUCCESS_BY_IDENTIFIER:
    case LOGOUT_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case LOGIN_FAILED_BY_IDENTIFIER:
    case LOGOUT_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  LOGIN_TRY,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_TRY_BY_IDENTIFIER,
  LOGIN_SUCCESS_BY_IDENTIFIER,
  LOGIN_FAILED_BY_IDENTIFIER,

  LOGOUT_TRY,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  LOGOUT_TRY_BY_IDENTIFIER,
  LOGOUT_SUCCESS_BY_IDENTIFIER,
  LOGOUT_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
