import BtnWriteReview from "jsx/BtnWriteReview";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalWriteReview() {
  const portals = [];
  const places = document.querySelectorAll(".write-review");

  for (let i = 0; i < places.length; i++) {
    const place = places[i];
    const productId = place.dataset.productId;
    // if there was any place content, e.g. loading state - clear it out
    place.innerHTML = "";
    portals.push(
      ReactDOM.createPortal(<BtnWriteReview productId={productId} />, place)
    );
  }

  return portals;
}
