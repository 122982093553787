import call from "../../helpers/call";

function setIsPaymentEnabledForInactiveProductsAdmin({
  authToken,
  invoiceId,
  isPaymentEnabledForInactiveProducts,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/set-is-payment-enabled-for-inactive-products-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      isPaymentEnabledForInactiveProducts, // (Required) Value is a boolean. True or false.
    },
  });
}

export default setIsPaymentEnabledForInactiveProductsAdmin;
