import call from "../../helpers/call";

function createAdmin({
  authToken,
  videoChannelSectionId,
  title,
  description,
  duration,
  youtubeVideoId,
  orderIndex,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/video/create-admin`,
    payload: {
      videoChannelSectionId, // (Required) String 10-30 in length and starting with "vcs-"
      title, // (Optional) Any string of 0-120 symbols in length.
      description, // (Optional) Any text
      duration, // (Optional) Must be positive integer or zero
      youtubeVideoId, // (Optional) Any string of 0-120 symbols in length.
      orderIndex, // (Optional) Must be positive integer or zero
    },
  });
}

export default createAdmin;
