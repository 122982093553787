import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import favorite from "../../reducers/favorite";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryFavoriteCountByIdentifier({ data }) {
  const payload = {};

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  const result = yield sdk.favoriteCount(payload);

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: favorite.actionTypes.COUNT_SUCCESS_BY_IDENTIFIER,
      data: {
        ___identifier: data.___identifier,
        payload: { data: result.data, meta: result.meta },
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        message: result.error.humanMessage || "Failed to get favorites count",
      },
    });

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: favorite.actionTypes.COUNT_FAILED_BY_IDENTIFIER,
      data: { ___identifier: data.___identifier, payload: result.error },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
