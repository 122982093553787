import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const EDIT_MY_DISCUSSION_TRY = "discussion/editMyDiscussion/TRY";
const EDIT_MY_DISCUSSION_SUCCESS = "discussion/editMyDiscussion/SUCCESS";
const EDIT_MY_DISCUSSION_FAILED = "discussion/editMyDiscussion/FAILED";

const EDIT_MY_DISCUSSION_TRY_BY_IDENTIFIER =
  "discussion/editMyDiscussion/TRY_BY_IDENTIFIER";
const EDIT_MY_DISCUSSION_SUCCESS_BY_IDENTIFIER =
  "discussion/editMyDiscussion/SUCCESS_BY_IDENTIFIER";
const EDIT_MY_DISCUSSION_FAILED_BY_IDENTIFIER =
  "discussion/editMyDiscussion/FAILED_BY_IDENTIFIER";

const LIST_BY_PRODUCT_TRY = "discussion/listByProduct/TRY";
const LIST_BY_PRODUCT_SUCCESS = "discussion/listByProduct/SUCCESS";
const LIST_BY_PRODUCT_FAILED = "discussion/listByProduct/FAILED";

const LIST_BY_PRODUCT_TRY_BY_IDENTIFIER =
  "discussion/listByProduct/TRY_BY_IDENTIFIER";
const LIST_BY_PRODUCT_SUCCESS_BY_IDENTIFIER =
  "discussion/listByProduct/SUCCESS_BY_IDENTIFIER";
const LIST_BY_PRODUCT_FAILED_BY_IDENTIFIER =
  "discussion/listByProduct/FAILED_BY_IDENTIFIER";

const LIST_REPLIES_FOR_DISCUSSION_TRY =
  "discussion/listRepliesForDiscussion/TRY";
const LIST_REPLIES_FOR_DISCUSSION_SUCCESS =
  "discussion/listRepliesForDiscussion/SUCCESS";
const LIST_REPLIES_FOR_DISCUSSION_FAILED =
  "discussion/listRepliesForDiscussion/FAILED";

const LIST_REPLIES_FOR_DISCUSSION_TRY_BY_IDENTIFIER =
  "discussion/listRepliesForDiscussion/TRY_BY_IDENTIFIER";
const LIST_REPLIES_FOR_DISCUSSION_SUCCESS_BY_IDENTIFIER =
  "discussion/listRepliesForDiscussion/SUCCESS_BY_IDENTIFIER";
const LIST_REPLIES_FOR_DISCUSSION_FAILED_BY_IDENTIFIER =
  "discussion/listRepliesForDiscussion/FAILED_BY_IDENTIFIER";

const MARK_HELPFUL_TRY = "discussion/markHelpful/TRY";
const MARK_HELPFUL_SUCCESS = "discussion/markHelpful/SUCCESS";
const MARK_HELPFUL_FAILED = "discussion/markHelpful/FAILED";

const MARK_HELPFUL_TRY_BY_IDENTIFIER =
  "discussion/markHelpful/TRY_BY_IDENTIFIER";
const MARK_HELPFUL_SUCCESS_BY_IDENTIFIER =
  "discussion/markHelpful/SUCCESS_BY_IDENTIFIER";
const MARK_HELPFUL_FAILED_BY_IDENTIFIER =
  "discussion/markHelpful/FAILED_BY_IDENTIFIER";

const POST_NEW_FOR_PRODUCT_TRY = "discussion/postNewForProduct/TRY";
const POST_NEW_FOR_PRODUCT_SUCCESS = "discussion/postNewForProduct/SUCCESS";
const POST_NEW_FOR_PRODUCT_FAILED = "discussion/postNewForProduct/FAILED";

const POST_NEW_FOR_PRODUCT_TRY_BY_IDENTIFIER =
  "discussion/postNewForProduct/TRY_BY_IDENTIFIER";
const POST_NEW_FOR_PRODUCT_SUCCESS_BY_IDENTIFIER =
  "discussion/postNewForProduct/SUCCESS_BY_IDENTIFIER";
const POST_NEW_FOR_PRODUCT_FAILED_BY_IDENTIFIER =
  "discussion/postNewForProduct/FAILED_BY_IDENTIFIER";

const POST_NEW_REPLY_TRY = "discussion/postNewReply/TRY";
const POST_NEW_REPLY_SUCCESS = "discussion/postNewReply/SUCCESS";
const POST_NEW_REPLY_FAILED = "discussion/postNewReply/FAILED";

const POST_NEW_REPLY_TRY_BY_IDENTIFIER =
  "discussion/postNewReply/TRY_BY_IDENTIFIER";
const POST_NEW_REPLY_SUCCESS_BY_IDENTIFIER =
  "discussion/postNewReply/SUCCESS_BY_IDENTIFIER";
const POST_NEW_REPLY_FAILED_BY_IDENTIFIER =
  "discussion/postNewReply/FAILED_BY_IDENTIFIER";

const REACTION_ADD_TRY = "discussion/reactionAdd/TRY";
const REACTION_ADD_SUCCESS = "discussion/reactionAdd/SUCCESS";
const REACTION_ADD_FAILED = "discussion/reactionAdd/FAILED";

const REACTION_ADD_TRY_BY_IDENTIFIER =
  "discussion/reactionAdd/TRY_BY_IDENTIFIER";
const REACTION_ADD_SUCCESS_BY_IDENTIFIER =
  "discussion/reactionAdd/SUCCESS_BY_IDENTIFIER";
const REACTION_ADD_FAILED_BY_IDENTIFIER =
  "discussion/reactionAdd/FAILED_BY_IDENTIFIER";

const REACTION_REMOVE_TRY = "discussion/reactionRemove/TRY";
const REACTION_REMOVE_SUCCESS = "discussion/reactionRemove/SUCCESS";
const REACTION_REMOVE_FAILED = "discussion/reactionRemove/FAILED";

const REACTION_REMOVE_TRY_BY_IDENTIFIER =
  "discussion/reactionRemove/TRY_BY_IDENTIFIER";
const REACTION_REMOVE_SUCCESS_BY_IDENTIFIER =
  "discussion/reactionRemove/SUCCESS_BY_IDENTIFIER";
const REACTION_REMOVE_FAILED_BY_IDENTIFIER =
  "discussion/reactionRemove/FAILED_BY_IDENTIFIER";

const initialState = Map({
  editMyDiscussion: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listByProduct: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listRepliesForDiscussion: shape.merge(Map({ dataByIdentifier: Map({}) })),
  markHelpful: shape.merge(Map({ dataByIdentifier: Map({}) })),
  postNewForProduct: shape.merge(Map({ dataByIdentifier: Map({}) })),
  postNewReply: shape.merge(Map({ dataByIdentifier: Map({}) })),
  reactionAdd: shape.merge(Map({ dataByIdentifier: Map({}) })),
  reactionRemove: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EDIT_MY_DISCUSSION_TRY:
    case LIST_BY_PRODUCT_TRY:
    case LIST_REPLIES_FOR_DISCUSSION_TRY:
    case MARK_HELPFUL_TRY:
    case POST_NEW_FOR_PRODUCT_TRY:
    case POST_NEW_REPLY_TRY:
    case REACTION_ADD_TRY:
    case REACTION_REMOVE_TRY:
      return handleTry({ state, action });
    case EDIT_MY_DISCUSSION_SUCCESS:
    case LIST_BY_PRODUCT_SUCCESS:
    case LIST_REPLIES_FOR_DISCUSSION_SUCCESS:
    case MARK_HELPFUL_SUCCESS:
    case POST_NEW_FOR_PRODUCT_SUCCESS:
    case POST_NEW_REPLY_SUCCESS:
    case REACTION_ADD_SUCCESS:
    case REACTION_REMOVE_SUCCESS:
      return handleSuccess({ state, action });
    case EDIT_MY_DISCUSSION_FAILED:
    case LIST_BY_PRODUCT_FAILED:
    case LIST_REPLIES_FOR_DISCUSSION_FAILED:
    case MARK_HELPFUL_FAILED:
    case POST_NEW_FOR_PRODUCT_FAILED:
    case POST_NEW_REPLY_FAILED:
    case REACTION_ADD_FAILED:
    case REACTION_REMOVE_FAILED:
      return handleFailed({ state, action });
    case EDIT_MY_DISCUSSION_TRY_BY_IDENTIFIER:
    case LIST_BY_PRODUCT_TRY_BY_IDENTIFIER:
    case LIST_REPLIES_FOR_DISCUSSION_TRY_BY_IDENTIFIER:
    case MARK_HELPFUL_TRY_BY_IDENTIFIER:
    case POST_NEW_FOR_PRODUCT_TRY_BY_IDENTIFIER:
    case POST_NEW_REPLY_TRY_BY_IDENTIFIER:
    case REACTION_ADD_TRY_BY_IDENTIFIER:
    case REACTION_REMOVE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case EDIT_MY_DISCUSSION_SUCCESS_BY_IDENTIFIER:
    case LIST_BY_PRODUCT_SUCCESS_BY_IDENTIFIER:
    case LIST_REPLIES_FOR_DISCUSSION_SUCCESS_BY_IDENTIFIER:
    case MARK_HELPFUL_SUCCESS_BY_IDENTIFIER:
    case POST_NEW_FOR_PRODUCT_SUCCESS_BY_IDENTIFIER:
    case POST_NEW_REPLY_SUCCESS_BY_IDENTIFIER:
    case REACTION_ADD_SUCCESS_BY_IDENTIFIER:
    case REACTION_REMOVE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case EDIT_MY_DISCUSSION_FAILED_BY_IDENTIFIER:
    case LIST_BY_PRODUCT_FAILED_BY_IDENTIFIER:
    case LIST_REPLIES_FOR_DISCUSSION_FAILED_BY_IDENTIFIER:
    case MARK_HELPFUL_FAILED_BY_IDENTIFIER:
    case POST_NEW_FOR_PRODUCT_FAILED_BY_IDENTIFIER:
    case POST_NEW_REPLY_FAILED_BY_IDENTIFIER:
    case REACTION_ADD_FAILED_BY_IDENTIFIER:
    case REACTION_REMOVE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  EDIT_MY_DISCUSSION_TRY,
  EDIT_MY_DISCUSSION_SUCCESS,
  EDIT_MY_DISCUSSION_FAILED,
  EDIT_MY_DISCUSSION_TRY_BY_IDENTIFIER,
  EDIT_MY_DISCUSSION_SUCCESS_BY_IDENTIFIER,
  EDIT_MY_DISCUSSION_FAILED_BY_IDENTIFIER,

  LIST_BY_PRODUCT_TRY,
  LIST_BY_PRODUCT_SUCCESS,
  LIST_BY_PRODUCT_FAILED,
  LIST_BY_PRODUCT_TRY_BY_IDENTIFIER,
  LIST_BY_PRODUCT_SUCCESS_BY_IDENTIFIER,
  LIST_BY_PRODUCT_FAILED_BY_IDENTIFIER,

  LIST_REPLIES_FOR_DISCUSSION_TRY,
  LIST_REPLIES_FOR_DISCUSSION_SUCCESS,
  LIST_REPLIES_FOR_DISCUSSION_FAILED,
  LIST_REPLIES_FOR_DISCUSSION_TRY_BY_IDENTIFIER,
  LIST_REPLIES_FOR_DISCUSSION_SUCCESS_BY_IDENTIFIER,
  LIST_REPLIES_FOR_DISCUSSION_FAILED_BY_IDENTIFIER,

  MARK_HELPFUL_TRY,
  MARK_HELPFUL_SUCCESS,
  MARK_HELPFUL_FAILED,
  MARK_HELPFUL_TRY_BY_IDENTIFIER,
  MARK_HELPFUL_SUCCESS_BY_IDENTIFIER,
  MARK_HELPFUL_FAILED_BY_IDENTIFIER,

  POST_NEW_FOR_PRODUCT_TRY,
  POST_NEW_FOR_PRODUCT_SUCCESS,
  POST_NEW_FOR_PRODUCT_FAILED,
  POST_NEW_FOR_PRODUCT_TRY_BY_IDENTIFIER,
  POST_NEW_FOR_PRODUCT_SUCCESS_BY_IDENTIFIER,
  POST_NEW_FOR_PRODUCT_FAILED_BY_IDENTIFIER,

  POST_NEW_REPLY_TRY,
  POST_NEW_REPLY_SUCCESS,
  POST_NEW_REPLY_FAILED,
  POST_NEW_REPLY_TRY_BY_IDENTIFIER,
  POST_NEW_REPLY_SUCCESS_BY_IDENTIFIER,
  POST_NEW_REPLY_FAILED_BY_IDENTIFIER,

  REACTION_ADD_TRY,
  REACTION_ADD_SUCCESS,
  REACTION_ADD_FAILED,
  REACTION_ADD_TRY_BY_IDENTIFIER,
  REACTION_ADD_SUCCESS_BY_IDENTIFIER,
  REACTION_ADD_FAILED_BY_IDENTIFIER,

  REACTION_REMOVE_TRY,
  REACTION_REMOVE_SUCCESS,
  REACTION_REMOVE_FAILED,
  REACTION_REMOVE_TRY_BY_IDENTIFIER,
  REACTION_REMOVE_SUCCESS_BY_IDENTIFIER,
  REACTION_REMOVE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
