import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingButton from "@mui/lab/LoadingButton";
import useActions from "stateManager/hooks/useActions";
import useIsUserGetLoaded from "stateManager/hooks/user/useIsUserGetLoaded";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

export default function BtnAddVipPlanToCart({
  productPlanId,
  valueCentsPerItem,
  productPlanName,
  productName,
}) {
  const actions = useActions();

  // auth status
  const user = useUserGetResult({});
  const isLoggedIn =
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";

  // loading state
  const isUserLoaded = useIsUserGetLoaded({});
  const isLoading =
    isUserLoaded === false ||
    !productPlanId ||
    !productPlanName ||
    !productName;

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      size="large"
      endIcon={<ArrowForwardIcon fontSize={"inherit"} />}
      fullWidth
      sx={{
        textTransform: "none",
        height: "56px",
        boxShadow: "none",
      }}
      onClick={() => {
        if (isLoggedIn) {
          actions.cart.v2ItemUpdate({
            quantity: 1,
            productPlanId: productPlanId,
            removeQuantity: 0,
            addQuantity: 1,
            valueCentsPerItem: valueCentsPerItem,
            productPlanName: productPlanName,
          });
        } else {
          actions.globalMessages.addToQueue({
            message: "Need to login first",
          });
          openLoginScreenToContinue();
        }
      }}
    >
      Choose {productName}
    </LoadingButton>
  );
}
