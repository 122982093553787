import SidebarCart from "jsx/Cart/Portal/SidebarCart";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalSidebarCart() {
  const portals = [];
  const places = document.querySelectorAll(".sidebar-cart");

  for (let i = 0; i < places.length; i++) {
    const placeholder = places[i];

    // if there was any placeholder content, e.g. loading state - clear it out
    placeholder.innerHTML = "";
    portals.push(ReactDOM.createPortal(<SidebarCart />, placeholder));
  }

  return portals;
}
