import call from "../../helpers/call";

function setAffiliateAsPaidAdmin({
  authToken,
  userId,
  affiliateBonusRecountedAt,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate/set-affiliate-as-paid-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      affiliateBonusRecountedAt, // (Required) Timestamp value. Any positive integer.
    },
  });
}

export default setAffiliateAsPaidAdmin;
