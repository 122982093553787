import call from "../../helpers/call";

function postNewForProduct({ authToken, productId, comment }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discussion/post-new-for-product`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      comment, // (Required) Any text
    },
  });
}

export default postNewForProduct;
