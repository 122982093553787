import fetch from "isomorphic-unfetch";
import getSdkEndpoint from "./getSdkEndpoint";

function call({ endpoint, payload, headers, httpMethod }) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    httpMethod = httpMethod || "POST";

    const url = `${getSdkEndpoint()}${endpoint}`;
    payload = payload || {};
    const headersForFetch = {};

    // set headers
    headers = headers || [];

    if (process && process.env && process.env.sdkUserAgent) {
      headers.push({ name: "user-agent", value: process.env.sdkUserAgent });
    }

    if (headers && headers.length > 0) {
      headers.forEach((header) => {
        if (header.name && header.value) {
          headersForFetch[header.name] = header.value;
        }
      });
    }

    // make request
    let rawResult = null;
    let result = null;

    try {
      headersForFetch["Accept"] = "application/json";
      headersForFetch["Content-Type"] = "application/json";

      rawResult = await fetch(url, {
        method: httpMethod,
        headers: headersForFetch,
        body: JSON.stringify(payload),
      });
    } catch (e) {
      console.error(`#2AYFLL8W7 API request failed:  `, e);
      result = {
        error: { code: "#0NK637XQH", message: "CHECK_YOUR_CONNECTION" },
        data: null,
      };
    }

    let parsedResult = {};
    if (rawResult) {
      const _rawResultText = await rawResult.text();
      try {
        parsedResult = JSON.parse(_rawResultText);
      } catch (e) {
        console.error(`#5hy5gferrrg API_CALL_FAILED: `, e, _rawResultText);
        result = {
          error: { code: "#D31SV524K", message: "API_CALL_FAILED" },
          data: null,
        };
      }
    }

    if (rawResult && rawResult.ok) {
      try {
        result = parsedResult;
      } catch (e) {
        // it was not a JSON
        const detectHtml = result ? result : "";
        if (detectHtml.toString().trim().indexOf("<") === 0) {
          result = {
            error: { code: "#D31SV524K", message: "INTERNAL_ERROR" },
            data: null,
          };
        }
      }

      resolve(result);
    } else {
      // status code not 2xx
      resolve(
        result || {
          error: { code: "#0VHC9KOJ8", message: parsedResult },
          data: null,
        }
      );
    }
  });
}

export default call;
