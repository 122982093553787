import getSdkEndpoint from "../../helpers/getSdkEndpoint";

function user({ authToken }) {
  let ___query = [];
  if (typeof authToken !== "undefined") {
    ___query.push(`authToken=${authToken}`);
  }

  if (___query.length > 0) {
    ___query = `?${___query.join("&")}`;
  } else {
    ___query = "";
  }

  return `${getSdkEndpoint()}/circleso/user${___query}`;
}

export default user;
