import AddToFavoritesBtn from "jsx/Favorites/AddToFavoritesBtn";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalAddToFavoritesBtn() {
  const portals = [];
  const btns = document.querySelectorAll(".btn-add-to-fav");

  for (let i = 0; i < btns.length; i++) {
    const placeholder = btns[i];
    const productId = placeholder.dataset.productId;

    // if there was any placeholder content, e.g. loading state - clear it out
    placeholder.innerHTML = "";
    portals.push(
      ReactDOM.createPortal(
        <AddToFavoritesBtn productId={productId} />,
        placeholder
      )
    );
  }

  return portals;
}
