import call from "../../helpers/call";

function isAffiliateToggleAdmin({ authToken, userId, isAffiliateEnabled }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/is-affiliate-toggle-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      isAffiliateEnabled, // (Required) Value is a boolean. True or false.
    },
  });
}

export default isAffiliateToggleAdmin;
