import call from "../../helpers/call";

function list({ authToken, page, perPage, orderDirection }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/wallet-logs/list`,
    payload: {
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any positive integer. Range: (0,1000]
      orderDirection, // (Required) Valid values: desc, asc
    },
  });
}

export default list;
