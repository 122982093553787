import { takeEvery, takeLatest, takeLeading } from "redux-saga/effects";

import ___analytics from "../reducers/analytics";
import ___auth from "../reducers/auth";
import ___authFlow from "stateManager/reducers/authFlow";
import ___cart from "../reducers/cart";
import ___circleso from "../reducers/circleso";
import ___coupon from "../reducers/coupon";
import ___coupons from "../reducers/coupons";
import ___currency from "../reducers/currency";
import ___discountCoupon from "../reducers/discountCoupon";
import ___discussion from "../reducers/discussion";
import ___favorite from "../reducers/favorite";
import ___image from "../reducers/image";
import ___invoice from "../reducers/invoice";
import ___klavyio from "../reducers/klavyio";
import ___meta from "../reducers/meta";
import ___notifications from "../reducers/notifications";
import ___options from "../reducers/options";
import ___product from "../reducers/product";
import ___productPlan from "../reducers/productPlan";
import ___productPlans from "../reducers/productPlans";
import ___products from "../reducers/products";
import ___review from "../reducers/review";
import ___sales from "../reducers/sales";
import ___stripeSubscription from "../reducers/stripeSubscription";
import ___submitProduct from "../reducers/submitProduct";
import ___subscriptionTracking from "../reducers/subscriptionTracking";
import ___subscriptionTrackingCompany from "../reducers/subscriptionTrackingCompany";
import ___tipalti from "../reducers/tipalti";
import ___user from "../reducers/user";
import ___userDraft from "../reducers/userDraft";
import ___userMeta from "../reducers/userMeta";
import ___vendorPaymentInfo from "../reducers/vendorPaymentInfo";
import ___vendorProduct from "../reducers/vendorProduct";
import ___wallet from "../reducers/wallet";

import tryAnalyticsListProductSales from "./analytics/tryAnalyticsListProductSales";
import tryAnalyticsListProductSalesByIdentifier from "./analytics/tryAnalyticsListProductSalesByIdentifier";
import tryAuthFlowGetAuthorizedUser from "stateManager/sagas/authFlow/tryAuthFlowGetAuthorizedUser";
import tryAuthFlowGetRefreshedTokens from "stateManager/sagas/authFlow/tryAuthFlowGetRefreshedTokens";
import tryAuthFlowGetTokens from "stateManager/sagas/authFlow/tryAuthFlowGetTokens";
import tryAuthFlowUpdateProfile from "stateManager/sagas/authFlow/tryAuthFlowUpdateProfile";
import tryAuthFlowUploadProfileImage from "stateManager/sagas/authFlow/tryAuthFlowUploadProfileImage";
import tryAuthLogin from "./auth/tryAuthLogin";
import tryAuthLoginByIdentifier from "./auth/tryAuthLoginByIdentifier";
import tryAuthLogout from "./auth/tryAuthLogout";
import tryAuthLogoutByIdentifier from "./auth/tryAuthLogoutByIdentifier";
import tryCartGetMyItemsInCartCount from "./cart/tryCartGetMyItemsInCartCount";
import tryCartGetMyItemsInCartCountByIdentifier from "./cart/tryCartGetMyItemsInCartCountByIdentifier";
import tryCartGetViewTrack from "./cart/tryCartGetViewTrack";
import tryCartGetViewTrackByIdentifier from "./cart/tryCartGetViewTrackByIdentifier";
import tryCartHowMuchLeftToGetSplit from "./cart/tryCartHowMuchLeftToGetSplit";
import tryCartHowMuchLeftToGetSplitByIdentifier from "./cart/tryCartHowMuchLeftToGetSplitByIdentifier";
import tryCartListMyItems from "./cart/tryCartListMyItems";
import tryCartListMyItemsByIdentifier from "./cart/tryCartListMyItemsByIdentifier";
import tryCartV2Get from "./cart/tryCartV2Get";
import tryCartV2GetByIdentifier from "./cart/tryCartV2GetByIdentifier";
import tryCartV2ItemUpdate from "./cart/tryCartV2ItemUpdate";
import tryCartV2ItemUpdateByIdentifier from "./cart/tryCartV2ItemUpdateByIdentifier";
import tryCartV2Pay from "./cart/tryCartV2Pay";
import tryCartV2PayByIdentifier from "./cart/tryCartV2PayByIdentifier";
import tryCartVipDiscountList from "./cart/tryCartVipDiscountList";
import tryCartVipDiscountListByIdentifier from "./cart/tryCartVipDiscountListByIdentifier";
import tryCirclesoGetSsoAuthorizationOneTimeUseCode from "./circleso/tryCirclesoGetSsoAuthorizationOneTimeUseCode";
import tryCirclesoGetSsoAuthorizationOneTimeUseCodeByIdentifier from "./circleso/tryCirclesoGetSsoAuthorizationOneTimeUseCodeByIdentifier";
import tryCouponGetDetails from "./coupon/tryCouponGetDetails";
import tryCouponGetDetailsByIdentifier from "./coupon/tryCouponGetDetailsByIdentifier";
import tryCouponGetForRedeem from "./coupon/tryCouponGetForRedeem";
import tryCouponGetForRedeemByIdentifier from "./coupon/tryCouponGetForRedeemByIdentifier";
import tryCouponMarkAsRedeemed from "./coupon/tryCouponMarkAsRedeemed";
import tryCouponMarkAsRedeemedByIdentifier from "./coupon/tryCouponMarkAsRedeemedByIdentifier";
import tryCouponsListMy from "./coupons/tryCouponsListMy";
import tryCouponsListMyByIdentifier from "./coupons/tryCouponsListMyByIdentifier";
import tryCurrencyListAll from "./currency/tryCurrencyListAll";
import tryCurrencyListAllByIdentifier from "./currency/tryCurrencyListAllByIdentifier";
import tryDiscountCouponApplyToInvoice from "./discountCoupon/tryDiscountCouponApplyToInvoice";
import tryDiscountCouponApplyToInvoiceByIdentifier from "./discountCoupon/tryDiscountCouponApplyToInvoiceByIdentifier";
import tryDiscountCouponRemoveFromInvoice from "./discountCoupon/tryDiscountCouponRemoveFromInvoice";
import tryDiscountCouponRemoveFromInvoiceByIdentifier from "./discountCoupon/tryDiscountCouponRemoveFromInvoiceByIdentifier";
import tryDiscussionEditMyDiscussion from "./discussion/tryDiscussionEditMyDiscussion";
import tryDiscussionEditMyDiscussionByIdentifier from "./discussion/tryDiscussionEditMyDiscussionByIdentifier";
import tryDiscussionListByProduct from "./discussion/tryDiscussionListByProduct";
import tryDiscussionListByProductByIdentifier from "./discussion/tryDiscussionListByProductByIdentifier";
import tryDiscussionListRepliesForDiscussion from "./discussion/tryDiscussionListRepliesForDiscussion";
import tryDiscussionListRepliesForDiscussionByIdentifier from "./discussion/tryDiscussionListRepliesForDiscussionByIdentifier";
import tryDiscussionMarkHelpful from "./discussion/tryDiscussionMarkHelpful";
import tryDiscussionMarkHelpfulByIdentifier from "./discussion/tryDiscussionMarkHelpfulByIdentifier";
import tryDiscussionPostNewForProduct from "./discussion/tryDiscussionPostNewForProduct";
import tryDiscussionPostNewForProductByIdentifier from "./discussion/tryDiscussionPostNewForProductByIdentifier";
import tryDiscussionPostNewReply from "./discussion/tryDiscussionPostNewReply";
import tryDiscussionPostNewReplyByIdentifier from "./discussion/tryDiscussionPostNewReplyByIdentifier";
import tryDiscussionReactionAdd from "./discussion/tryDiscussionReactionAdd";
import tryDiscussionReactionAddByIdentifier from "./discussion/tryDiscussionReactionAddByIdentifier";
import tryDiscussionReactionRemove from "./discussion/tryDiscussionReactionRemove";
import tryDiscussionReactionRemoveByIdentifier from "./discussion/tryDiscussionReactionRemoveByIdentifier";
import tryFavoriteAdd from "./favorite/tryFavoriteAdd";
import tryFavoriteAddByIdentifier from "./favorite/tryFavoriteAddByIdentifier";
import tryFavoriteCount from "./favorite/tryFavoriteCount";
import tryFavoriteCountByIdentifier from "./favorite/tryFavoriteCountByIdentifier";
import tryFavoriteGetByProductId from "./favorite/tryFavoriteGetByProductId";
import tryFavoriteGetByProductIdByIdentifier from "./favorite/tryFavoriteGetByProductIdByIdentifier";
import tryFavoriteList from "./favorite/tryFavoriteList";
import tryFavoriteListByIdentifier from "./favorite/tryFavoriteListByIdentifier";
import tryFavoriteRemove from "./favorite/tryFavoriteRemove";
import tryFavoriteRemoveByIdentifier from "./favorite/tryFavoriteRemoveByIdentifier";
import tryImageUpload from "./image/tryImageUpload";
import tryImageUploadByIdentifier from "./image/tryImageUploadByIdentifier";
import tryInvoiceApplyIncentive from "./invoice/tryInvoiceApplyIncentive";
import tryInvoiceApplyIncentiveByIdentifier from "./invoice/tryInvoiceApplyIncentiveByIdentifier";
import tryInvoiceAutomatedRefund from "./invoice/tryInvoiceAutomatedRefund";
import tryInvoiceAutomatedRefundByIdentifier from "./invoice/tryInvoiceAutomatedRefundByIdentifier";
import tryInvoiceCoinbaseGetCharge from "./invoice/tryInvoiceCoinbaseGetCharge";
import tryInvoiceCoinbaseGetChargeByIdentifier from "./invoice/tryInvoiceCoinbaseGetChargeByIdentifier";
import tryInvoiceCoinbaseGetChargeInfo from "./invoice/tryInvoiceCoinbaseGetChargeInfo";
import tryInvoiceCoinbaseGetChargeInfoByIdentifier from "./invoice/tryInvoiceCoinbaseGetChargeInfoByIdentifier";
import tryInvoiceCreate from "./invoice/tryInvoiceCreate";
import tryInvoiceCreateByIdentifier from "./invoice/tryInvoiceCreateByIdentifier";
import tryInvoiceGet from "./invoice/tryInvoiceGet";
import tryInvoiceGetByIdentifier from "./invoice/tryInvoiceGetByIdentifier";
import tryInvoiceGetMyRefundsStats from "./invoice/tryInvoiceGetMyRefundsStats";
import tryInvoiceGetMyRefundsStatsByIdentifier from "./invoice/tryInvoiceGetMyRefundsStatsByIdentifier";
import tryInvoiceGetStripePayIntent from "./invoice/tryInvoiceGetStripePayIntent";
import tryInvoiceGetStripePayIntentByIdentifier from "./invoice/tryInvoiceGetStripePayIntentByIdentifier";
import tryInvoiceListMine from "./invoice/tryInvoiceListMine";
import tryInvoiceListMineByIdentifier from "./invoice/tryInvoiceListMineByIdentifier";
import tryInvoiceMakeStripePayIntent from "./invoice/tryInvoiceMakeStripePayIntent";
import tryInvoiceMakeStripePayIntentByIdentifier from "./invoice/tryInvoiceMakeStripePayIntentByIdentifier";
import tryInvoicePaypalClassicGetPaymentUrl from "./invoice/tryInvoicePaypalClassicGetPaymentUrl";
import tryInvoicePaypalClassicGetPaymentUrlByIdentifier from "./invoice/tryInvoicePaypalClassicGetPaymentUrlByIdentifier";
import tryInvoiceRefundToWallet from "./invoice/tryInvoiceRefundToWallet";
import tryInvoiceRefundToWalletByIdentifier from "./invoice/tryInvoiceRefundToWalletByIdentifier";
import tryInvoiceRemoveWalletFundsFromInvoice from "./invoice/tryInvoiceRemoveWalletFundsFromInvoice";
import tryInvoiceRemoveWalletFundsFromInvoiceByIdentifier from "./invoice/tryInvoiceRemoveWalletFundsFromInvoiceByIdentifier";
import tryInvoiceSplitOptionsList from "./invoice/tryInvoiceSplitOptionsList";
import tryInvoiceSplitOptionsListByIdentifier from "./invoice/tryInvoiceSplitOptionsListByIdentifier";
import tryInvoiceStripeSplitPayIntent from "./invoice/tryInvoiceStripeSplitPayIntent";
import tryInvoiceStripeSplitPayIntentByIdentifier from "./invoice/tryInvoiceStripeSplitPayIntentByIdentifier";
import tryInvoiceUpdateBillingDetails from "./invoice/tryInvoiceUpdateBillingDetails";
import tryInvoiceUpdateBillingDetailsByIdentifier from "./invoice/tryInvoiceUpdateBillingDetailsByIdentifier";
import tryInvoiceVipDiscountsList from "./invoice/tryInvoiceVipDiscountsList";
import tryInvoiceVipDiscountsListByIdentifier from "./invoice/tryInvoiceVipDiscountsListByIdentifier";
import tryKlavyioEmailMeBeforeDealEnds from "./klavyio/tryKlavyioEmailMeBeforeDealEnds";
import tryKlavyioEmailMeBeforeDealEndsByIdentifier from "./klavyio/tryKlavyioEmailMeBeforeDealEndsByIdentifier";
import tryKlavyioSubscribeNewsletter from "./klavyio/tryKlavyioSubscribeNewsletter";
import tryKlavyioSubscribeNewsletterByIdentifier from "./klavyio/tryKlavyioSubscribeNewsletterByIdentifier";
import tryMetaGetIpCountry from "./meta/tryMetaGetIpCountry";
import tryMetaGetIpCountryByIdentifier from "./meta/tryMetaGetIpCountryByIdentifier";
import tryNotificationsCreateDemoNotification from "./notifications/tryNotificationsCreateDemoNotification";
import tryNotificationsCreateDemoNotificationByIdentifier from "./notifications/tryNotificationsCreateDemoNotificationByIdentifier";
import tryNotificationsGetUnreadCount from "./notifications/tryNotificationsGetUnreadCount";
import tryNotificationsGetUnreadCountByIdentifier from "./notifications/tryNotificationsGetUnreadCountByIdentifier";
import tryNotificationsListMine from "./notifications/tryNotificationsListMine";
import tryNotificationsListMineByIdentifier from "./notifications/tryNotificationsListMineByIdentifier";
import tryNotificationsMarkAllAsRead from "./notifications/tryNotificationsMarkAllAsRead";
import tryNotificationsMarkAllAsReadByIdentifier from "./notifications/tryNotificationsMarkAllAsReadByIdentifier";
import tryNotificationsMarkAsRead from "./notifications/tryNotificationsMarkAsRead";
import tryNotificationsMarkAsReadByIdentifier from "./notifications/tryNotificationsMarkAsReadByIdentifier";
import tryOptionsGet from "./options/tryOptionsGet";
import tryOptionsGetByIdentifier from "./options/tryOptionsGetByIdentifier";
import tryProductAnalyticsGetProductSalesStats from "./product/tryProductAnalyticsGetProductSalesStats";
import tryProductAnalyticsGetProductSalesStatsByIdentifier from "./product/tryProductAnalyticsGetProductSalesStatsByIdentifier";
import tryProductGetForRefund from "./product/tryProductGetForRefund";
import tryProductGetForRefundByIdentifier from "./product/tryProductGetForRefundByIdentifier";
import tryProductGetForReview from "./product/tryProductGetForReview";
import tryProductGetForReviewByIdentifier from "./product/tryProductGetForReviewByIdentifier";
import tryProductGetProductDetailsForAnalytics from "./product/tryProductGetProductDetailsForAnalytics";
import tryProductGetProductDetailsForAnalyticsByIdentifier from "./product/tryProductGetProductDetailsForAnalyticsByIdentifier";
import tryProductGetViewTrack from "./product/tryProductGetViewTrack";
import tryProductGetViewTrackByIdentifier from "./product/tryProductGetViewTrackByIdentifier";
import tryProductListMyProducts from "./product/tryProductListMyProducts";
import tryProductListMyProductsByIdentifier from "./product/tryProductListMyProductsByIdentifier";
import tryProductPlanSubscriptionList from "./productPlan/tryProductPlanSubscriptionList";
import tryProductPlanSubscriptionListByIdentifier from "./productPlan/tryProductPlanSubscriptionListByIdentifier";
import tryProductPlansListForPublishedProduct from "./productPlans/tryProductPlansListForPublishedProduct";
import tryProductPlansListForPublishedProductByIdentifier from "./productPlans/tryProductPlansListForPublishedProductByIdentifier";
import tryProductsListActiveProducts from "./products/tryProductsListActiveProducts";
import tryProductsListActiveProductsByIdentifier from "./products/tryProductsListActiveProductsByIdentifier";
import tryReviewCreate from "./review/tryReviewCreate";
import tryReviewCreateByIdentifier from "./review/tryReviewCreateByIdentifier";
import tryReviewGetMyForEdit from "./review/tryReviewGetMyForEdit";
import tryReviewGetMyForEditByIdentifier from "./review/tryReviewGetMyForEditByIdentifier";
import tryReviewList from "./review/tryReviewList";
import tryReviewListByIdentifier from "./review/tryReviewListByIdentifier";
import tryReviewRemoveMine from "./review/tryReviewRemoveMine";
import tryReviewRemoveMineByIdentifier from "./review/tryReviewRemoveMineByIdentifier";
import tryReviewUpdateMine from "./review/tryReviewUpdateMine";
import tryReviewUpdateMineByIdentifier from "./review/tryReviewUpdateMineByIdentifier";
import trySalesGetLatestPurchases from "./sales/trySalesGetLatestPurchases";
import trySalesGetLatestPurchasesByIdentifier from "./sales/trySalesGetLatestPurchasesByIdentifier";
import tryStripeSubscriptionList from "./stripeSubscription/tryStripeSubscriptionList";
import tryStripeSubscriptionListByIdentifier from "./stripeSubscription/tryStripeSubscriptionListByIdentifier";
import trySubmitProductAuditLogsList from "./submitProduct/trySubmitProductAuditLogsList";
import trySubmitProductAuditLogsListByIdentifier from "./submitProduct/trySubmitProductAuditLogsListByIdentifier";
import trySubmitProductCreate from "./submitProduct/trySubmitProductCreate";
import trySubmitProductCreateByIdentifier from "./submitProduct/trySubmitProductCreateByIdentifier";
import trySubmitProductGet from "./submitProduct/trySubmitProductGet";
import trySubmitProductGetByIdentifier from "./submitProduct/trySubmitProductGetByIdentifier";
import trySubmitProductGetCount from "./submitProduct/trySubmitProductGetCount";
import trySubmitProductGetCountByIdentifier from "./submitProduct/trySubmitProductGetCountByIdentifier";
import trySubmitProductImageUpload from "./submitProduct/trySubmitProductImageUpload";
import trySubmitProductImageUploadByIdentifier from "./submitProduct/trySubmitProductImageUploadByIdentifier";
import trySubmitProductList from "./submitProduct/trySubmitProductList";
import trySubmitProductListByIdentifier from "./submitProduct/trySubmitProductListByIdentifier";
import trySubmitProductUpdate from "./submitProduct/trySubmitProductUpdate";
import trySubmitProductUpdateByIdentifier from "./submitProduct/trySubmitProductUpdateByIdentifier";
import trySubscriptionTrackingCompanySearch from "./subscriptionTrackingCompany/trySubscriptionTrackingCompanySearch";
import trySubscriptionTrackingCompanySearchByIdentifier from "./subscriptionTrackingCompany/trySubscriptionTrackingCompanySearchByIdentifier";
import trySubscriptionTrackingCreate from "./subscriptionTracking/trySubscriptionTrackingCreate";
import trySubscriptionTrackingCreateByIdentifier from "./subscriptionTracking/trySubscriptionTrackingCreateByIdentifier";
import trySubscriptionTrackingExportCsv from "./subscriptionTracking/trySubscriptionTrackingExportCsv";
import trySubscriptionTrackingExportCsvByIdentifier from "./subscriptionTracking/trySubscriptionTrackingExportCsvByIdentifier";
import trySubscriptionTrackingGet from "./subscriptionTracking/trySubscriptionTrackingGet";
import trySubscriptionTrackingGetByIdentifier from "./subscriptionTracking/trySubscriptionTrackingGetByIdentifier";
import trySubscriptionTrackingList from "./subscriptionTracking/trySubscriptionTrackingList";
import trySubscriptionTrackingListByIdentifier from "./subscriptionTracking/trySubscriptionTrackingListByIdentifier";
import trySubscriptionTrackingRemove from "./subscriptionTracking/trySubscriptionTrackingRemove";
import trySubscriptionTrackingRemoveByIdentifier from "./subscriptionTracking/trySubscriptionTrackingRemoveByIdentifier";
import trySubscriptionTrackingReports from "./subscriptionTracking/trySubscriptionTrackingReports";
import trySubscriptionTrackingReportsByIdentifier from "./subscriptionTracking/trySubscriptionTrackingReportsByIdentifier";
import trySubscriptionTrackingSpendingsGraphList from "./subscriptionTracking/trySubscriptionTrackingSpendingsGraphList";
import trySubscriptionTrackingSpendingsGraphListByIdentifier from "./subscriptionTracking/trySubscriptionTrackingSpendingsGraphListByIdentifier";
import trySubscriptionTrackingSpendingsList from "./subscriptionTracking/trySubscriptionTrackingSpendingsList";
import trySubscriptionTrackingSpendingsListByIdentifier from "./subscriptionTracking/trySubscriptionTrackingSpendingsListByIdentifier";
import trySubscriptionTrackingTagList from "./subscriptionTracking/trySubscriptionTrackingTagList";
import trySubscriptionTrackingTagListByIdentifier from "./subscriptionTracking/trySubscriptionTrackingTagListByIdentifier";
import trySubscriptionTrackingUpdate from "./subscriptionTracking/trySubscriptionTrackingUpdate";
import trySubscriptionTrackingUpdateByIdentifier from "./subscriptionTracking/trySubscriptionTrackingUpdateByIdentifier";
import tryTipaltiIframeUrlGet from "./tipalti/tryTipaltiIframeUrlGet";
import tryTipaltiIframeUrlGetByIdentifier from "./tipalti/tryTipaltiIframeUrlGetByIdentifier";
import tryUserBillingDetailsGet from "./user/tryUserBillingDetailsGet";
import tryUserBillingDetailsGetByIdentifier from "./user/tryUserBillingDetailsGetByIdentifier";
import tryUserBillingDetailsUpdate from "./user/tryUserBillingDetailsUpdate";
import tryUserBillingDetailsUpdateByIdentifier from "./user/tryUserBillingDetailsUpdateByIdentifier";
import tryUserConfirmEmail from "./user/tryUserConfirmEmail";
import tryUserConfirmEmailByIdentifier from "./user/tryUserConfirmEmailByIdentifier";
import tryUserDraftCreate from "./userDraft/tryUserDraftCreate";
import tryUserDraftCreateByIdentifier from "./userDraft/tryUserDraftCreateByIdentifier";
import tryUserDraftGet from "./userDraft/tryUserDraftGet";
import tryUserDraftGetByIdentifier from "./userDraft/tryUserDraftGetByIdentifier";
import tryUserDraftList from "./userDraft/tryUserDraftList";
import tryUserDraftListByIdentifier from "./userDraft/tryUserDraftListByIdentifier";
import tryUserDraftRemove from "./userDraft/tryUserDraftRemove";
import tryUserDraftRemoveByIdentifier from "./userDraft/tryUserDraftRemoveByIdentifier";
import tryUserDraftUpdate from "./userDraft/tryUserDraftUpdate";
import tryUserDraftUpdateByIdentifier from "./userDraft/tryUserDraftUpdateByIdentifier";
import tryUserGet from "./user/tryUserGet";
import tryUserGetByIdentifier from "./user/tryUserGetByIdentifier";
import tryUserGetIfUserBoughtProduct from "./user/tryUserGetIfUserBoughtProduct";
import tryUserGetIfUserBoughtProductByIdentifier from "./user/tryUserGetIfUserBoughtProductByIdentifier";
import tryUserGetStats from "./user/tryUserGetStats";
import tryUserGetStatsByIdentifier from "./user/tryUserGetStatsByIdentifier";
import tryUserGetUserDealsBoughtCount from "./user/tryUserGetUserDealsBoughtCount";
import tryUserGetUserDealsBoughtCountByIdentifier from "./user/tryUserGetUserDealsBoughtCountByIdentifier";
import tryUserListAvailableIncentives from "./user/tryUserListAvailableIncentives";
import tryUserListAvailableIncentivesByIdentifier from "./user/tryUserListAvailableIncentivesByIdentifier";
import tryUserListInvoiceIncentives from "./user/tryUserListInvoiceIncentives";
import tryUserListInvoiceIncentivesByIdentifier from "./user/tryUserListInvoiceIncentivesByIdentifier";
import tryUserMetaGet from "./userMeta/tryUserMetaGet";
import tryUserMetaGetByIdentifier from "./userMeta/tryUserMetaGetByIdentifier";
import tryUserMetaUpdate from "./userMeta/tryUserMetaUpdate";
import tryUserMetaUpdateByIdentifier from "./userMeta/tryUserMetaUpdateByIdentifier";
import tryUserPasswordChangeWithToken from "./user/tryUserPasswordChangeWithToken";
import tryUserPasswordChangeWithTokenByIdentifier from "./user/tryUserPasswordChangeWithTokenByIdentifier";
import tryUserRequestPasswordRecovery from "./user/tryUserRequestPasswordRecovery";
import tryUserRequestPasswordRecoveryByIdentifier from "./user/tryUserRequestPasswordRecoveryByIdentifier";
import tryUserRequestResendEmailConfirmation from "./user/tryUserRequestResendEmailConfirmation";
import tryUserRequestResendEmailConfirmationByIdentifier from "./user/tryUserRequestResendEmailConfirmationByIdentifier";
import tryUserSignUp from "./user/tryUserSignUp";
import tryUserSignUpByIdentifier from "./user/tryUserSignUpByIdentifier";
import tryUserStripeCardDetach from "./user/tryUserStripeCardDetach";
import tryUserStripeCardDetachByIdentifier from "./user/tryUserStripeCardDetachByIdentifier";
import tryUserStripeCardsList from "./user/tryUserStripeCardsList";
import tryUserStripeCardsListByIdentifier from "./user/tryUserStripeCardsListByIdentifier";
import tryUserUpdate from "./user/tryUserUpdate";
import tryUserUpdateByIdentifier from "./user/tryUserUpdateByIdentifier";
import tryUserUpdateProfileTimezone from "./user/tryUserUpdateProfileTimezone";
import tryUserUpdateProfileTimezoneByIdentifier from "./user/tryUserUpdateProfileTimezoneByIdentifier";
import tryUserUploadProfilePicture from "./user/tryUserUploadProfilePicture";
import tryUserUploadProfilePictureByIdentifier from "./user/tryUserUploadProfilePictureByIdentifier";
import tryVendorPaymentInfoForceList from "./vendorPaymentInfo/tryVendorPaymentInfoForceList";
import tryVendorPaymentInfoForceListByIdentifier from "./vendorPaymentInfo/tryVendorPaymentInfoForceListByIdentifier";
import tryVendorProductGetStats from "./vendorProduct/tryVendorProductGetStats";
import tryVendorProductGetStatsByIdentifier from "./vendorProduct/tryVendorProductGetStatsByIdentifier";
import tryWalletCoverInvoice from "./wallet/tryWalletCoverInvoice";
import tryWalletCoverInvoiceByIdentifier from "./wallet/tryWalletCoverInvoiceByIdentifier";
import tryWalletGetMyOverview from "./wallet/tryWalletGetMyOverview";
import tryWalletGetMyOverviewByIdentifier from "./wallet/tryWalletGetMyOverviewByIdentifier";
import tryWalletListMyWalletLogs from "./wallet/tryWalletListMyWalletLogs";
import tryWalletListMyWalletLogsByIdentifier from "./wallet/tryWalletListMyWalletLogsByIdentifier";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import Cookies from "js-cookie";
import { put } from "redux-saga/effects";

// runs on interval cause login cookie is set outside of this page and page
// has no clue if cookie is set now unless user reloads the page
// so continue check every couple sec. all of these are super quick calls anyways
if (typeof localStorage === "object") {
  setInterval(() => {
    // SDK uses local storage authToken, while cookie is needed to support
    // *.pitchground auth
    const cookieAuthToken = Cookies.get("authToken") || null;
    const lsAuthToken = localStorage.getItem("authToken") || null;

    if (cookieAuthToken && !lsAuthToken) {
      localStorage.setItem("authToken", cookieAuthToken);
      window.location.reload();
    } else if (cookieAuthToken !== lsAuthToken) {
      // something is off. Logout & restart.
      localStorage.removeItem("authToken");
      Cookies.remove("authToken");
      window.location.reload();
    }
  }, 600);
}

// #### CUSTOM__AFTER_IMPORTS ----- END ------

const changeCase = require("change-case");

export default ({ options }) => {
  return function* rootSaga() {
    // #### CUSTOM__BEFORE_ACTIONS ----- START ----

    // #### CUSTOM__BEFORE_ACTIONS ----- END ------

    if (options.extensions && options.extensions.length > 0) {
      for (let _extension of options.extensions) {
        if (_extension.sagas && _extension.sagas.length > 0) {
          for (let _saga of _extension.sagas) {
            if (_saga.sagaFunction && _saga.action && _saga.generatorFunction) {
              yield _saga.sagaFunction(
                `${changeCase.constantCase(_extension.namespace)}_${
                  _saga.action
                }`,
                _saga.generatorFunction
              );
            }
          }
        }
      }
    }

    yield takeEvery(
      ___analytics.actionTypes.LIST_PRODUCT_SALES_TRY_BY_IDENTIFIER,
      tryAnalyticsListProductSalesByIdentifier
    );
    yield takeEvery(
      ___auth.actionTypes.LOGIN_TRY_BY_IDENTIFIER,
      tryAuthLoginByIdentifier
    );
    yield takeEvery(
      ___auth.actionTypes.LOGOUT_TRY_BY_IDENTIFIER,
      tryAuthLogoutByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.GET_MY_ITEMS_IN_CART_COUNT_TRY_BY_IDENTIFIER,
      tryCartGetMyItemsInCartCountByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.GET_VIEW_TRACK_TRY_BY_IDENTIFIER,
      tryCartGetViewTrackByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.HOW_MUCH_LEFT_TO_GET_SPLIT_TRY_BY_IDENTIFIER,
      tryCartHowMuchLeftToGetSplitByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.LIST_MY_ITEMS_TRY_BY_IDENTIFIER,
      tryCartListMyItemsByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.V2_GET_TRY_BY_IDENTIFIER,
      tryCartV2GetByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.V2_ITEM_UPDATE_TRY_BY_IDENTIFIER,
      tryCartV2ItemUpdateByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.V2_PAY_TRY_BY_IDENTIFIER,
      tryCartV2PayByIdentifier
    );
    yield takeEvery(
      ___cart.actionTypes.VIP_DISCOUNT_LIST_TRY_BY_IDENTIFIER,
      tryCartVipDiscountListByIdentifier
    );
    yield takeEvery(
      ___circleso.actionTypes
        .GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY_BY_IDENTIFIER,
      tryCirclesoGetSsoAuthorizationOneTimeUseCodeByIdentifier
    );
    yield takeEvery(
      ___coupon.actionTypes.GET_DETAILS_TRY_BY_IDENTIFIER,
      tryCouponGetDetailsByIdentifier
    );
    yield takeEvery(
      ___coupon.actionTypes.GET_FOR_REDEEM_TRY_BY_IDENTIFIER,
      tryCouponGetForRedeemByIdentifier
    );
    yield takeEvery(
      ___coupon.actionTypes.MARK_AS_REDEEMED_TRY_BY_IDENTIFIER,
      tryCouponMarkAsRedeemedByIdentifier
    );
    yield takeEvery(
      ___coupons.actionTypes.LIST_MY_TRY_BY_IDENTIFIER,
      tryCouponsListMyByIdentifier
    );
    yield takeEvery(
      ___currency.actionTypes.LIST_ALL_TRY_BY_IDENTIFIER,
      tryCurrencyListAllByIdentifier
    );
    yield takeEvery(
      ___discountCoupon.actionTypes.APPLY_TO_INVOICE_TRY_BY_IDENTIFIER,
      tryDiscountCouponApplyToInvoiceByIdentifier
    );
    yield takeEvery(
      ___discountCoupon.actionTypes.REMOVE_FROM_INVOICE_TRY_BY_IDENTIFIER,
      tryDiscountCouponRemoveFromInvoiceByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.EDIT_MY_DISCUSSION_TRY_BY_IDENTIFIER,
      tryDiscussionEditMyDiscussionByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.LIST_BY_PRODUCT_TRY_BY_IDENTIFIER,
      tryDiscussionListByProductByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.LIST_REPLIES_FOR_DISCUSSION_TRY_BY_IDENTIFIER,
      tryDiscussionListRepliesForDiscussionByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.MARK_HELPFUL_TRY_BY_IDENTIFIER,
      tryDiscussionMarkHelpfulByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.POST_NEW_FOR_PRODUCT_TRY_BY_IDENTIFIER,
      tryDiscussionPostNewForProductByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.POST_NEW_REPLY_TRY_BY_IDENTIFIER,
      tryDiscussionPostNewReplyByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.REACTION_ADD_TRY_BY_IDENTIFIER,
      tryDiscussionReactionAddByIdentifier
    );
    yield takeEvery(
      ___discussion.actionTypes.REACTION_REMOVE_TRY_BY_IDENTIFIER,
      tryDiscussionReactionRemoveByIdentifier
    );
    yield takeEvery(
      ___favorite.actionTypes.ADD_TRY_BY_IDENTIFIER,
      tryFavoriteAddByIdentifier
    );
    yield takeEvery(
      ___favorite.actionTypes.COUNT_TRY_BY_IDENTIFIER,
      tryFavoriteCountByIdentifier
    );
    yield takeEvery(
      ___favorite.actionTypes.GET_BY_PRODUCT_ID_TRY_BY_IDENTIFIER,
      tryFavoriteGetByProductIdByIdentifier
    );
    yield takeEvery(
      ___favorite.actionTypes.LIST_TRY_BY_IDENTIFIER,
      tryFavoriteListByIdentifier
    );
    yield takeEvery(
      ___favorite.actionTypes.REMOVE_TRY_BY_IDENTIFIER,
      tryFavoriteRemoveByIdentifier
    );
    yield takeEvery(
      ___image.actionTypes.UPLOAD_TRY_BY_IDENTIFIER,
      tryImageUploadByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.APPLY_INCENTIVE_TRY_BY_IDENTIFIER,
      tryInvoiceApplyIncentiveByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.AUTOMATED_REFUND_TRY_BY_IDENTIFIER,
      tryInvoiceAutomatedRefundByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.COINBASE_GET_CHARGE_INFO_TRY_BY_IDENTIFIER,
      tryInvoiceCoinbaseGetChargeInfoByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.COINBASE_GET_CHARGE_TRY_BY_IDENTIFIER,
      tryInvoiceCoinbaseGetChargeByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.CREATE_TRY_BY_IDENTIFIER,
      tryInvoiceCreateByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.GET_MY_REFUNDS_STATS_TRY_BY_IDENTIFIER,
      tryInvoiceGetMyRefundsStatsByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.GET_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER,
      tryInvoiceGetStripePayIntentByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.GET_TRY_BY_IDENTIFIER,
      tryInvoiceGetByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.LIST_MINE_TRY_BY_IDENTIFIER,
      tryInvoiceListMineByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.MAKE_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER,
      tryInvoiceMakeStripePayIntentByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY_BY_IDENTIFIER,
      tryInvoicePaypalClassicGetPaymentUrlByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.REFUND_TO_WALLET_TRY_BY_IDENTIFIER,
      tryInvoiceRefundToWalletByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY_BY_IDENTIFIER,
      tryInvoiceRemoveWalletFundsFromInvoiceByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.SPLIT_OPTIONS_LIST_TRY_BY_IDENTIFIER,
      tryInvoiceSplitOptionsListByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.STRIPE_SPLIT_PAY_INTENT_TRY_BY_IDENTIFIER,
      tryInvoiceStripeSplitPayIntentByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.UPDATE_BILLING_DETAILS_TRY_BY_IDENTIFIER,
      tryInvoiceUpdateBillingDetailsByIdentifier
    );
    yield takeEvery(
      ___invoice.actionTypes.VIP_DISCOUNTS_LIST_TRY_BY_IDENTIFIER,
      tryInvoiceVipDiscountsListByIdentifier
    );
    yield takeEvery(
      ___klavyio.actionTypes.EMAIL_ME_BEFORE_DEAL_ENDS_TRY_BY_IDENTIFIER,
      tryKlavyioEmailMeBeforeDealEndsByIdentifier
    );
    yield takeEvery(
      ___klavyio.actionTypes.SUBSCRIBE_NEWSLETTER_TRY_BY_IDENTIFIER,
      tryKlavyioSubscribeNewsletterByIdentifier
    );
    yield takeEvery(
      ___meta.actionTypes.GET_IP_COUNTRY_TRY_BY_IDENTIFIER,
      tryMetaGetIpCountryByIdentifier
    );
    yield takeEvery(
      ___notifications.actionTypes.CREATE_DEMO_NOTIFICATION_TRY_BY_IDENTIFIER,
      tryNotificationsCreateDemoNotificationByIdentifier
    );
    yield takeEvery(
      ___notifications.actionTypes.GET_UNREAD_COUNT_TRY_BY_IDENTIFIER,
      tryNotificationsGetUnreadCountByIdentifier
    );
    yield takeEvery(
      ___notifications.actionTypes.LIST_MINE_TRY_BY_IDENTIFIER,
      tryNotificationsListMineByIdentifier
    );
    yield takeEvery(
      ___notifications.actionTypes.MARK_ALL_AS_READ_TRY_BY_IDENTIFIER,
      tryNotificationsMarkAllAsReadByIdentifier
    );
    yield takeEvery(
      ___notifications.actionTypes.MARK_AS_READ_TRY_BY_IDENTIFIER,
      tryNotificationsMarkAsReadByIdentifier
    );
    yield takeEvery(
      ___options.actionTypes.GET_TRY_BY_IDENTIFIER,
      tryOptionsGetByIdentifier
    );
    yield takeEvery(
      ___product.actionTypes
        .ANALYTICS_GET_PRODUCT_SALES_STATS_TRY_BY_IDENTIFIER,
      tryProductAnalyticsGetProductSalesStatsByIdentifier
    );
    yield takeEvery(
      ___product.actionTypes.GET_FOR_REFUND_TRY_BY_IDENTIFIER,
      tryProductGetForRefundByIdentifier
    );
    yield takeEvery(
      ___product.actionTypes.GET_FOR_REVIEW_TRY_BY_IDENTIFIER,
      tryProductGetForReviewByIdentifier
    );
    yield takeEvery(
      ___product.actionTypes
        .GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY_BY_IDENTIFIER,
      tryProductGetProductDetailsForAnalyticsByIdentifier
    );
    yield takeEvery(
      ___product.actionTypes.GET_VIEW_TRACK_TRY_BY_IDENTIFIER,
      tryProductGetViewTrackByIdentifier
    );
    yield takeEvery(
      ___product.actionTypes.LIST_MY_PRODUCTS_TRY_BY_IDENTIFIER,
      tryProductListMyProductsByIdentifier
    );
    yield takeEvery(
      ___productPlan.actionTypes.SUBSCRIPTION_LIST_TRY_BY_IDENTIFIER,
      tryProductPlanSubscriptionListByIdentifier
    );
    yield takeEvery(
      ___productPlans.actionTypes.LIST_FOR_PUBLISHED_PRODUCT_TRY_BY_IDENTIFIER,
      tryProductPlansListForPublishedProductByIdentifier
    );
    yield takeEvery(
      ___products.actionTypes.LIST_ACTIVE_PRODUCTS_TRY_BY_IDENTIFIER,
      tryProductsListActiveProductsByIdentifier
    );
    yield takeEvery(
      ___review.actionTypes.CREATE_TRY_BY_IDENTIFIER,
      tryReviewCreateByIdentifier
    );
    yield takeEvery(
      ___review.actionTypes.GET_MY_FOR_EDIT_TRY_BY_IDENTIFIER,
      tryReviewGetMyForEditByIdentifier
    );
    yield takeEvery(
      ___review.actionTypes.LIST_TRY_BY_IDENTIFIER,
      tryReviewListByIdentifier
    );
    yield takeEvery(
      ___review.actionTypes.REMOVE_MINE_TRY_BY_IDENTIFIER,
      tryReviewRemoveMineByIdentifier
    );
    yield takeEvery(
      ___review.actionTypes.UPDATE_MINE_TRY_BY_IDENTIFIER,
      tryReviewUpdateMineByIdentifier
    );
    yield takeEvery(
      ___sales.actionTypes.GET_LATEST_PURCHASES_TRY_BY_IDENTIFIER,
      trySalesGetLatestPurchasesByIdentifier
    );
    yield takeEvery(
      ___stripeSubscription.actionTypes.LIST_TRY_BY_IDENTIFIER,
      tryStripeSubscriptionListByIdentifier
    );
    yield takeEvery(
      ___submitProduct.actionTypes.AUDIT_LOGS_LIST_TRY_BY_IDENTIFIER,
      trySubmitProductAuditLogsListByIdentifier
    );
    yield takeEvery(
      ___submitProduct.actionTypes.CREATE_TRY_BY_IDENTIFIER,
      trySubmitProductCreateByIdentifier
    );
    yield takeEvery(
      ___submitProduct.actionTypes.GET_COUNT_TRY_BY_IDENTIFIER,
      trySubmitProductGetCountByIdentifier
    );
    yield takeEvery(
      ___submitProduct.actionTypes.GET_TRY_BY_IDENTIFIER,
      trySubmitProductGetByIdentifier
    );
    yield takeEvery(
      ___submitProduct.actionTypes.IMAGE_UPLOAD_TRY_BY_IDENTIFIER,
      trySubmitProductImageUploadByIdentifier
    );
    yield takeEvery(
      ___submitProduct.actionTypes.LIST_TRY_BY_IDENTIFIER,
      trySubmitProductListByIdentifier
    );
    yield takeEvery(
      ___submitProduct.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
      trySubmitProductUpdateByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.CREATE_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingCreateByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.EXPORT_CSV_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingExportCsvByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.GET_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingGetByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.LIST_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingListByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.REMOVE_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingRemoveByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.REPORTS_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingReportsByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes
        .SPENDINGS_GRAPH_LIST_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingSpendingsGraphListByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.SPENDINGS_LIST_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingSpendingsListByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.TAG_LIST_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingTagListByIdentifier
    );
    yield takeEvery(
      ___subscriptionTracking.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingUpdateByIdentifier
    );
    yield takeEvery(
      ___subscriptionTrackingCompany.actionTypes.SEARCH_TRY_BY_IDENTIFIER,
      trySubscriptionTrackingCompanySearchByIdentifier
    );
    yield takeEvery(
      ___tipalti.actionTypes.IFRAME_URL_GET_TRY_BY_IDENTIFIER,
      tryTipaltiIframeUrlGetByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.BILLING_DETAILS_GET_TRY_BY_IDENTIFIER,
      tryUserBillingDetailsGetByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.BILLING_DETAILS_UPDATE_TRY_BY_IDENTIFIER,
      tryUserBillingDetailsUpdateByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.CONFIRM_EMAIL_TRY_BY_IDENTIFIER,
      tryUserConfirmEmailByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.GET_IF_USER_BOUGHT_PRODUCT_TRY_BY_IDENTIFIER,
      tryUserGetIfUserBoughtProductByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.GET_STATS_TRY_BY_IDENTIFIER,
      tryUserGetStatsByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.GET_TRY_BY_IDENTIFIER,
      tryUserGetByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.GET_USER_DEALS_BOUGHT_COUNT_TRY_BY_IDENTIFIER,
      tryUserGetUserDealsBoughtCountByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.LIST_AVAILABLE_INCENTIVES_TRY_BY_IDENTIFIER,
      tryUserListAvailableIncentivesByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.LIST_INVOICE_INCENTIVES_TRY_BY_IDENTIFIER,
      tryUserListInvoiceIncentivesByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.PASSWORD_CHANGE_WITH_TOKEN_TRY_BY_IDENTIFIER,
      tryUserPasswordChangeWithTokenByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.REQUEST_PASSWORD_RECOVERY_TRY_BY_IDENTIFIER,
      tryUserRequestPasswordRecoveryByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.REQUEST_RESEND_EMAIL_CONFIRMATION_TRY_BY_IDENTIFIER,
      tryUserRequestResendEmailConfirmationByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.SIGN_UP_TRY_BY_IDENTIFIER,
      tryUserSignUpByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.STRIPE_CARDS_LIST_TRY_BY_IDENTIFIER,
      tryUserStripeCardsListByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.STRIPE_CARD_DETACH_TRY_BY_IDENTIFIER,
      tryUserStripeCardDetachByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.UPDATE_PROFILE_TIMEZONE_TRY_BY_IDENTIFIER,
      tryUserUpdateProfileTimezoneByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
      tryUserUpdateByIdentifier
    );
    yield takeEvery(
      ___user.actionTypes.UPLOAD_PROFILE_PICTURE_TRY_BY_IDENTIFIER,
      tryUserUploadProfilePictureByIdentifier
    );
    yield takeEvery(
      ___userDraft.actionTypes.CREATE_TRY_BY_IDENTIFIER,
      tryUserDraftCreateByIdentifier
    );
    yield takeEvery(
      ___userDraft.actionTypes.GET_TRY_BY_IDENTIFIER,
      tryUserDraftGetByIdentifier
    );
    yield takeEvery(
      ___userDraft.actionTypes.LIST_TRY_BY_IDENTIFIER,
      tryUserDraftListByIdentifier
    );
    yield takeEvery(
      ___userDraft.actionTypes.REMOVE_TRY_BY_IDENTIFIER,
      tryUserDraftRemoveByIdentifier
    );
    yield takeEvery(
      ___userDraft.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
      tryUserDraftUpdateByIdentifier
    );
    yield takeEvery(
      ___userMeta.actionTypes.GET_TRY_BY_IDENTIFIER,
      tryUserMetaGetByIdentifier
    );
    yield takeEvery(
      ___userMeta.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
      tryUserMetaUpdateByIdentifier
    );
    yield takeEvery(
      ___vendorPaymentInfo.actionTypes.FORCE_LIST_TRY_BY_IDENTIFIER,
      tryVendorPaymentInfoForceListByIdentifier
    );
    yield takeEvery(
      ___vendorProduct.actionTypes.GET_STATS_TRY_BY_IDENTIFIER,
      tryVendorProductGetStatsByIdentifier
    );
    yield takeEvery(
      ___wallet.actionTypes.COVER_INVOICE_TRY_BY_IDENTIFIER,
      tryWalletCoverInvoiceByIdentifier
    );
    yield takeEvery(
      ___wallet.actionTypes.GET_MY_OVERVIEW_TRY_BY_IDENTIFIER,
      tryWalletGetMyOverviewByIdentifier
    );
    yield takeEvery(
      ___wallet.actionTypes.LIST_MY_WALLET_LOGS_TRY_BY_IDENTIFIER,
      tryWalletListMyWalletLogsByIdentifier
    );
    yield takeLatest(___auth.actionTypes.LOGIN_TRY, tryAuthLogin);
    yield takeLatest(___auth.actionTypes.LOGOUT_TRY, tryAuthLogout);
    yield takeLatest(
      ___authFlow.actionTypes.GET_AUTHORIZED_USER_TRY,
      tryAuthFlowGetAuthorizedUser
    );
    yield takeLatest(
      ___authFlow.actionTypes.GET_REFRESHED_TOKENS_TRY,
      tryAuthFlowGetRefreshedTokens
    );
    yield takeLatest(
      ___authFlow.actionTypes.GET_TOKENS_TRY,
      tryAuthFlowGetTokens
    );
    yield takeLatest(
      ___authFlow.actionTypes.UPDATE_PROFILE_TRY,
      tryAuthFlowUpdateProfile
    );
    yield takeLatest(
      ___authFlow.actionTypes.UPLOAD_PROFILE_IMAGE_TRY,
      tryAuthFlowUploadProfileImage
    );
    yield takeLatest(
      ___cart.actionTypes.V2_ITEM_UPDATE_TRY,
      tryCartV2ItemUpdate
    );
    yield takeLatest(___cart.actionTypes.V2_PAY_TRY, tryCartV2Pay);
    yield takeLatest(
      ___coupon.actionTypes.MARK_AS_REDEEMED_TRY,
      tryCouponMarkAsRedeemed
    );
    yield takeLatest(
      ___discountCoupon.actionTypes.APPLY_TO_INVOICE_TRY,
      tryDiscountCouponApplyToInvoice
    );
    yield takeLatest(
      ___discountCoupon.actionTypes.REMOVE_FROM_INVOICE_TRY,
      tryDiscountCouponRemoveFromInvoice
    );
    yield takeLatest(
      ___discussion.actionTypes.EDIT_MY_DISCUSSION_TRY,
      tryDiscussionEditMyDiscussion
    );
    yield takeLatest(
      ___discussion.actionTypes.MARK_HELPFUL_TRY,
      tryDiscussionMarkHelpful
    );
    yield takeLatest(
      ___discussion.actionTypes.POST_NEW_FOR_PRODUCT_TRY,
      tryDiscussionPostNewForProduct
    );
    yield takeLatest(
      ___discussion.actionTypes.POST_NEW_REPLY_TRY,
      tryDiscussionPostNewReply
    );
    yield takeLatest(
      ___discussion.actionTypes.REACTION_ADD_TRY,
      tryDiscussionReactionAdd
    );
    yield takeLatest(
      ___discussion.actionTypes.REACTION_REMOVE_TRY,
      tryDiscussionReactionRemove
    );
    yield takeLatest(___favorite.actionTypes.ADD_TRY, tryFavoriteAdd);
    yield takeLatest(___favorite.actionTypes.COUNT_TRY, tryFavoriteCount);
    yield takeLatest(___favorite.actionTypes.REMOVE_TRY, tryFavoriteRemove);
    yield takeLatest(___image.actionTypes.UPLOAD_TRY, tryImageUpload);
    yield takeLatest(
      ___invoice.actionTypes.APPLY_INCENTIVE_TRY,
      tryInvoiceApplyIncentive
    );
    yield takeLatest(
      ___invoice.actionTypes.AUTOMATED_REFUND_TRY,
      tryInvoiceAutomatedRefund
    );
    yield takeLatest(___invoice.actionTypes.CREATE_TRY, tryInvoiceCreate);
    yield takeLatest(
      ___invoice.actionTypes.MAKE_STRIPE_PAY_INTENT_TRY,
      tryInvoiceMakeStripePayIntent
    );
    yield takeLatest(
      ___invoice.actionTypes.REFUND_TO_WALLET_TRY,
      tryInvoiceRefundToWallet
    );
    yield takeLatest(
      ___invoice.actionTypes.REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY,
      tryInvoiceRemoveWalletFundsFromInvoice
    );
    yield takeLatest(
      ___invoice.actionTypes.STRIPE_SPLIT_PAY_INTENT_TRY,
      tryInvoiceStripeSplitPayIntent
    );
    yield takeLatest(
      ___invoice.actionTypes.UPDATE_BILLING_DETAILS_TRY,
      tryInvoiceUpdateBillingDetails
    );
    yield takeLatest(
      ___klavyio.actionTypes.EMAIL_ME_BEFORE_DEAL_ENDS_TRY,
      tryKlavyioEmailMeBeforeDealEnds
    );
    yield takeLatest(
      ___klavyio.actionTypes.SUBSCRIBE_NEWSLETTER_TRY,
      tryKlavyioSubscribeNewsletter
    );
    yield takeLatest(
      ___notifications.actionTypes.CREATE_DEMO_NOTIFICATION_TRY,
      tryNotificationsCreateDemoNotification
    );
    yield takeLatest(
      ___notifications.actionTypes.MARK_ALL_AS_READ_TRY,
      tryNotificationsMarkAllAsRead
    );
    yield takeLatest(
      ___notifications.actionTypes.MARK_AS_READ_TRY,
      tryNotificationsMarkAsRead
    );
    yield takeLatest(___review.actionTypes.CREATE_TRY, tryReviewCreate);
    yield takeLatest(
      ___review.actionTypes.REMOVE_MINE_TRY,
      tryReviewRemoveMine
    );
    yield takeLatest(
      ___review.actionTypes.UPDATE_MINE_TRY,
      tryReviewUpdateMine
    );
    yield takeLatest(
      ___submitProduct.actionTypes.CREATE_TRY,
      trySubmitProductCreate
    );
    yield takeLatest(
      ___submitProduct.actionTypes.IMAGE_UPLOAD_TRY,
      trySubmitProductImageUpload
    );
    yield takeLatest(
      ___submitProduct.actionTypes.UPDATE_TRY,
      trySubmitProductUpdate
    );
    yield takeLatest(
      ___subscriptionTracking.actionTypes.CREATE_TRY,
      trySubscriptionTrackingCreate
    );
    yield takeLatest(
      ___subscriptionTracking.actionTypes.EXPORT_CSV_TRY,
      trySubscriptionTrackingExportCsv
    );
    yield takeLatest(
      ___subscriptionTracking.actionTypes.REMOVE_TRY,
      trySubscriptionTrackingRemove
    );
    yield takeLatest(
      ___subscriptionTracking.actionTypes.REPORTS_TRY,
      trySubscriptionTrackingReports
    );
    yield takeLatest(
      ___subscriptionTracking.actionTypes.UPDATE_TRY,
      trySubscriptionTrackingUpdate
    );
    yield takeLatest(
      ___subscriptionTrackingCompany.actionTypes.SEARCH_TRY,
      trySubscriptionTrackingCompanySearch
    );
    yield takeLatest(
      ___user.actionTypes.BILLING_DETAILS_UPDATE_TRY,
      tryUserBillingDetailsUpdate
    );
    yield takeLatest(
      ___user.actionTypes.CONFIRM_EMAIL_TRY,
      tryUserConfirmEmail
    );
    yield takeLatest(
      ___user.actionTypes.PASSWORD_CHANGE_WITH_TOKEN_TRY,
      tryUserPasswordChangeWithToken
    );
    yield takeLatest(
      ___user.actionTypes.REQUEST_PASSWORD_RECOVERY_TRY,
      tryUserRequestPasswordRecovery
    );
    yield takeLatest(
      ___user.actionTypes.REQUEST_RESEND_EMAIL_CONFIRMATION_TRY,
      tryUserRequestResendEmailConfirmation
    );
    yield takeLatest(___user.actionTypes.SIGN_UP_TRY, tryUserSignUp);
    yield takeLatest(
      ___user.actionTypes.STRIPE_CARD_DETACH_TRY,
      tryUserStripeCardDetach
    );
    yield takeLatest(
      ___user.actionTypes.UPDATE_PROFILE_TIMEZONE_TRY,
      tryUserUpdateProfileTimezone
    );
    yield takeLatest(___user.actionTypes.UPDATE_TRY, tryUserUpdate);
    yield takeLatest(
      ___user.actionTypes.UPLOAD_PROFILE_PICTURE_TRY,
      tryUserUploadProfilePicture
    );
    yield takeLatest(___userDraft.actionTypes.CREATE_TRY, tryUserDraftCreate);
    yield takeLatest(___userDraft.actionTypes.REMOVE_TRY, tryUserDraftRemove);
    yield takeLatest(___userDraft.actionTypes.UPDATE_TRY, tryUserDraftUpdate);
    yield takeLatest(___userMeta.actionTypes.UPDATE_TRY, tryUserMetaUpdate);
    yield takeLatest(
      ___wallet.actionTypes.COVER_INVOICE_TRY,
      tryWalletCoverInvoice
    );
    yield takeLeading(
      ___analytics.actionTypes.LIST_PRODUCT_SALES_TRY,
      tryAnalyticsListProductSales
    );
    yield takeLeading(
      ___cart.actionTypes.GET_MY_ITEMS_IN_CART_COUNT_TRY,
      tryCartGetMyItemsInCartCount
    );
    yield takeLeading(
      ___cart.actionTypes.GET_VIEW_TRACK_TRY,
      tryCartGetViewTrack
    );
    yield takeLeading(
      ___cart.actionTypes.HOW_MUCH_LEFT_TO_GET_SPLIT_TRY,
      tryCartHowMuchLeftToGetSplit
    );
    yield takeLeading(
      ___cart.actionTypes.LIST_MY_ITEMS_TRY,
      tryCartListMyItems
    );
    yield takeLeading(___cart.actionTypes.V2_GET_TRY, tryCartV2Get);
    yield takeLeading(
      ___cart.actionTypes.VIP_DISCOUNT_LIST_TRY,
      tryCartVipDiscountList
    );
    yield takeLeading(
      ___circleso.actionTypes.GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY,
      tryCirclesoGetSsoAuthorizationOneTimeUseCode
    );
    yield takeLeading(
      ___coupon.actionTypes.GET_DETAILS_TRY,
      tryCouponGetDetails
    );
    yield takeLeading(
      ___coupon.actionTypes.GET_FOR_REDEEM_TRY,
      tryCouponGetForRedeem
    );
    yield takeLeading(___coupons.actionTypes.LIST_MY_TRY, tryCouponsListMy);
    yield takeLeading(___currency.actionTypes.LIST_ALL_TRY, tryCurrencyListAll);
    yield takeLeading(
      ___discussion.actionTypes.LIST_BY_PRODUCT_TRY,
      tryDiscussionListByProduct
    );
    yield takeLeading(
      ___discussion.actionTypes.LIST_REPLIES_FOR_DISCUSSION_TRY,
      tryDiscussionListRepliesForDiscussion
    );
    yield takeLeading(
      ___favorite.actionTypes.GET_BY_PRODUCT_ID_TRY,
      tryFavoriteGetByProductId
    );
    yield takeLeading(___favorite.actionTypes.LIST_TRY, tryFavoriteList);
    yield takeLeading(
      ___invoice.actionTypes.COINBASE_GET_CHARGE_INFO_TRY,
      tryInvoiceCoinbaseGetChargeInfo
    );
    yield takeLeading(
      ___invoice.actionTypes.COINBASE_GET_CHARGE_TRY,
      tryInvoiceCoinbaseGetCharge
    );
    yield takeLeading(
      ___invoice.actionTypes.GET_MY_REFUNDS_STATS_TRY,
      tryInvoiceGetMyRefundsStats
    );
    yield takeLeading(
      ___invoice.actionTypes.GET_STRIPE_PAY_INTENT_TRY,
      tryInvoiceGetStripePayIntent
    );
    yield takeLeading(___invoice.actionTypes.GET_TRY, tryInvoiceGet);
    yield takeLeading(___invoice.actionTypes.LIST_MINE_TRY, tryInvoiceListMine);
    yield takeLeading(
      ___invoice.actionTypes.PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY,
      tryInvoicePaypalClassicGetPaymentUrl
    );
    yield takeLeading(
      ___invoice.actionTypes.SPLIT_OPTIONS_LIST_TRY,
      tryInvoiceSplitOptionsList
    );
    yield takeLeading(
      ___invoice.actionTypes.VIP_DISCOUNTS_LIST_TRY,
      tryInvoiceVipDiscountsList
    );
    yield takeLeading(
      ___meta.actionTypes.GET_IP_COUNTRY_TRY,
      tryMetaGetIpCountry
    );
    yield takeLeading(
      ___notifications.actionTypes.GET_UNREAD_COUNT_TRY,
      tryNotificationsGetUnreadCount
    );
    yield takeLeading(
      ___notifications.actionTypes.LIST_MINE_TRY,
      tryNotificationsListMine
    );
    yield takeLeading(___options.actionTypes.GET_TRY, tryOptionsGet);
    yield takeLeading(
      ___product.actionTypes.ANALYTICS_GET_PRODUCT_SALES_STATS_TRY,
      tryProductAnalyticsGetProductSalesStats
    );
    yield takeLeading(
      ___product.actionTypes.GET_FOR_REFUND_TRY,
      tryProductGetForRefund
    );
    yield takeLeading(
      ___product.actionTypes.GET_FOR_REVIEW_TRY,
      tryProductGetForReview
    );
    yield takeLeading(
      ___product.actionTypes.GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY,
      tryProductGetProductDetailsForAnalytics
    );
    yield takeLeading(
      ___product.actionTypes.GET_VIEW_TRACK_TRY,
      tryProductGetViewTrack
    );
    yield takeLeading(
      ___product.actionTypes.LIST_MY_PRODUCTS_TRY,
      tryProductListMyProducts
    );
    yield takeLeading(
      ___productPlan.actionTypes.SUBSCRIPTION_LIST_TRY,
      tryProductPlanSubscriptionList
    );
    yield takeLeading(
      ___productPlans.actionTypes.LIST_FOR_PUBLISHED_PRODUCT_TRY,
      tryProductPlansListForPublishedProduct
    );
    yield takeLeading(
      ___products.actionTypes.LIST_ACTIVE_PRODUCTS_TRY,
      tryProductsListActiveProducts
    );
    yield takeLeading(
      ___review.actionTypes.GET_MY_FOR_EDIT_TRY,
      tryReviewGetMyForEdit
    );
    yield takeLeading(___review.actionTypes.LIST_TRY, tryReviewList);
    yield takeLeading(
      ___sales.actionTypes.GET_LATEST_PURCHASES_TRY,
      trySalesGetLatestPurchases
    );
    yield takeLeading(
      ___stripeSubscription.actionTypes.LIST_TRY,
      tryStripeSubscriptionList
    );
    yield takeLeading(
      ___submitProduct.actionTypes.AUDIT_LOGS_LIST_TRY,
      trySubmitProductAuditLogsList
    );
    yield takeLeading(
      ___submitProduct.actionTypes.GET_COUNT_TRY,
      trySubmitProductGetCount
    );
    yield takeLeading(
      ___submitProduct.actionTypes.GET_TRY,
      trySubmitProductGet
    );
    yield takeLeading(
      ___submitProduct.actionTypes.LIST_TRY,
      trySubmitProductList
    );
    yield takeLeading(
      ___subscriptionTracking.actionTypes.GET_TRY,
      trySubscriptionTrackingGet
    );
    yield takeLeading(
      ___subscriptionTracking.actionTypes.LIST_TRY,
      trySubscriptionTrackingList
    );
    yield takeLeading(
      ___subscriptionTracking.actionTypes.SPENDINGS_GRAPH_LIST_TRY,
      trySubscriptionTrackingSpendingsGraphList
    );
    yield takeLeading(
      ___subscriptionTracking.actionTypes.SPENDINGS_LIST_TRY,
      trySubscriptionTrackingSpendingsList
    );
    yield takeLeading(
      ___subscriptionTracking.actionTypes.TAG_LIST_TRY,
      trySubscriptionTrackingTagList
    );
    yield takeLeading(
      ___tipalti.actionTypes.IFRAME_URL_GET_TRY,
      tryTipaltiIframeUrlGet
    );
    yield takeLeading(
      ___user.actionTypes.BILLING_DETAILS_GET_TRY,
      tryUserBillingDetailsGet
    );
    yield takeLeading(
      ___user.actionTypes.GET_IF_USER_BOUGHT_PRODUCT_TRY,
      tryUserGetIfUserBoughtProduct
    );
    yield takeLeading(___user.actionTypes.GET_STATS_TRY, tryUserGetStats);
    yield takeLeading(___user.actionTypes.GET_TRY, tryUserGet);
    yield takeLeading(
      ___user.actionTypes.GET_USER_DEALS_BOUGHT_COUNT_TRY,
      tryUserGetUserDealsBoughtCount
    );
    yield takeLeading(
      ___user.actionTypes.LIST_AVAILABLE_INCENTIVES_TRY,
      tryUserListAvailableIncentives
    );
    yield takeLeading(
      ___user.actionTypes.LIST_INVOICE_INCENTIVES_TRY,
      tryUserListInvoiceIncentives
    );
    yield takeLeading(
      ___user.actionTypes.STRIPE_CARDS_LIST_TRY,
      tryUserStripeCardsList
    );
    yield takeLeading(___userDraft.actionTypes.GET_TRY, tryUserDraftGet);
    yield takeLeading(___userDraft.actionTypes.LIST_TRY, tryUserDraftList);
    yield takeLeading(___userMeta.actionTypes.GET_TRY, tryUserMetaGet);
    yield takeLeading(
      ___vendorPaymentInfo.actionTypes.FORCE_LIST_TRY,
      tryVendorPaymentInfoForceList
    );
    yield takeLeading(
      ___vendorProduct.actionTypes.GET_STATS_TRY,
      tryVendorProductGetStats
    );
    yield takeLeading(
      ___wallet.actionTypes.GET_MY_OVERVIEW_TRY,
      tryWalletGetMyOverview
    );
    yield takeLeading(
      ___wallet.actionTypes.LIST_MY_WALLET_LOGS_TRY,
      tryWalletListMyWalletLogs
    );

    // #### CUSTOM__AFTER_ACTIONS ----- START ----

    yield takeEvery("*", function* _autoLogout({ data }) {
      if (typeof localStorage === "object") {
        if (data && data.message && data.message === "UNAUTHORIZED") {
          if (localStorage.getItem("authToken")) {
            yield put({
              type: ___auth.actionTypes.LOGOUT_TRY,
              data: {},
            });
          }
        }
      }
    });

    // #### CUSTOM__AFTER_ACTIONS ----- END ------
  };
};
