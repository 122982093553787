import call from "../../helpers/call";

function login({
  authToken,
  email,
  password,
  recaptchaToken,
  lastUrl,
  keepSessionForDays,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/login`,
    payload: {
      email, // (Required) Valid email address
      password, // (Required) Any string of 0-120 symbols in length.
      recaptchaToken, // (Required) Any string of 0-120 symbols in length.
      lastUrl, // (Optional) Any string of 0-255 symbols in length.
      keepSessionForDays, // (Optional) Must be positive integer or zero
    },
  });
}

export default login;
