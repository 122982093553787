import useNotificationsGetUnreadCountLoadingState from "stateManager/hooks/notifications/useNotificationsGetUnreadCountLoadingState";
import useNotificationsGetUnreadCountResult from "stateManager/hooks/notifications/useNotificationsGetUnreadCountResult";

export default function useIsNotificationsGetUnreadCountLoaded({}, ___options) {
  const loadingState = useNotificationsGetUnreadCountLoadingState(
    {},
    ___options
  );
  const resultState = useNotificationsGetUnreadCountResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
