import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import SecurityIcon from "@mui/icons-material/Security";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { CircularProgress, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useUrl from "hook-use-url";
import Cookies from "js-cookie";
import BillingDetailsForm from "jsx/BillingDetailsForm";
import CartItems from "jsx/Cart/CartItems";
import CartSplit from "jsx/Cart/CartSplit";
import SidebarEmptyCart from "jsx/Cart/Portal/SidebarEmptyCart";
import UnderCart from "jsx/Cart/UnderCart";
import { useEffect, useState } from "react";
import useCartGetViewTrackResult from "stateManager/hooks/cart/useCartGetViewTrackResult";
import useCartListMyItemsResult from "stateManager/hooks/cart/useCartListMyItemsResult";
import useCartV2GetResult from "stateManager/hooks/cart/useCartV2GetResult";
import useCartV2ItemUpdateLoadingState from "stateManager/hooks/cart/useCartV2ItemUpdateLoadingState";
import useIsCartV2GetLoaded from "stateManager/hooks/cart/useIsCartV2GetLoaded";
import useInvoiceCreateLoadingState from "stateManager/hooks/invoice/useInvoiceCreateLoadingState";
import useInvoiceCreateResult from "stateManager/hooks/invoice/useInvoiceCreateResult";
import useIsInvoiceCreateAfterSuccess from "stateManager/hooks/invoice/useIsInvoiceCreateAfterSuccess";
import useActions from "stateManager/hooks/useActions";
import useIsUserBillingDetailsUpdateAfterSuccess from "stateManager/hooks/user/useIsUserBillingDetailsUpdateAfterSuccess";
import useUserBillingDetailsUpdateLoadingState from "stateManager/hooks/user/useUserBillingDetailsUpdateLoadingState";
import ActiveDeals from "jsx/Cart/Portal/ActiveDeals";

export default function SidebarCart({ user }) {
  const theme = useTheme();
  const actions = useActions();
  const cartItems = useCartListMyItemsResult({});
  const isCartEverLoaded = cartItems.getIn(["result"]) !== null;
  const itemUpdateLoadingState = useCartV2ItemUpdateLoadingState({});
  const cart = useCartV2GetResult({});
  // const activeUser = useUserGetResult({});
  const [billingDetails, setBillingDetails] = useState(null);
  const [currentView, setCurrentView] = useState("cart");

  // track cart view for marketing things
  useCartGetViewTrackResult({});

  const isUserBillingDetailsUpdateAfterSuccess =
    useIsUserBillingDetailsUpdateAfterSuccess({});
  const isInvoiceCreateAfterSuccess = useIsInvoiceCreateAfterSuccess({});
  const createdInvoice = useInvoiceCreateResult({});

  const invoiceCreateLoadingState = useInvoiceCreateLoadingState({});
  const billingDetailsUpdateLoadingState =
    useUserBillingDetailsUpdateLoadingState({});
  const isInvoiceCreateLoading =
    billingDetailsUpdateLoadingState.get("isLoading") ||
    invoiceCreateLoadingState.get("isLoading");

  useEffect(() => {
    if (isInvoiceCreateAfterSuccess === true) {
      const newInvoiceId = createdInvoice.getIn([
        "result",
        "data",
        "invoiceId",
      ]);
      if (newInvoiceId) {
        if (location.host.endsWith(".com"))
          location.href = `https://app.pitchground.com/invoices?invoice-id=${newInvoiceId}`;
        else
          location.href = `https://app.pitchground.net/invoices?invoice-id=${newInvoiceId}`;
      }
    }
  }, [isInvoiceCreateAfterSuccess, createdInvoice]);

  useEffect(() => {
    if (
      isUserBillingDetailsUpdateAfterSuccess === true &&
      billingDetails !== null
    ) {
      const impact_irclickid = Cookies.get("impact_irclickid") || null;
      const affiliateId = impact_irclickid
        ? `impact_irclickid=${impact_irclickid}`
        : "";
      actions.invoice.create({
        buyerName: billingDetails.name,
        buyerEmail: billingDetails.email,
        buyerAddress: billingDetails.address,
        buyerCity: billingDetails.city,
        buyerState: billingDetails.state,
        buyerPostcode: billingDetails.postcode,
        buyerCountry: billingDetails.country,
        buyerVat: billingDetails.vat,
        affiliateId,
        userAgent: navigator.userAgent,
      });
    }
  }, [isUserBillingDetailsUpdateAfterSuccess, billingDetails]);

  const isCartLoading =
    useIsCartV2GetLoaded({}) === false ||
    itemUpdateLoadingState.get("isLoading") === true;

  const isCartEmpty =
    isCartLoading === false &&
    typeof cartItems.getIn(["result", "data", "0"]) === "undefined";

  let cartItemsContent = null;

  if (isInvoiceCreateLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: theme.spacing(6, 0),
          width: "100%",
        }}
      >
        <CircularProgress color={"secondary"} />
      </div>
    );
  }

  if (isCartLoading === true) {
    cartItemsContent = (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: {
            xs: theme.spacing(2, "auto"),
            sm: theme.spacing(8, "auto"),
          },
        }}
      >
        <CircularProgress color={"secondary"} />
      </Box>
    );
  } else {
    cartItemsContent = <CartItems cart={cart} cartItems={cartItems} />;
  }

  if ((isCartLoading || isCartEmpty) && currentView !== "active-deals") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: {
            xs: theme.spacing(2, "auto"),
            sm: theme.spacing(8, "auto"),
          },
          textAlign: "center",
        }}
      >
        {isCartEverLoaded === true && isCartEmpty ? (
          <SidebarEmptyCart setCurrentView={setCurrentView} />
        ) : (
          <CircularProgress color={"secondary"} />
        )}
      </Box>
    );
  }

  return (
    <Grid container spacing={1} flexDirection="column-reverse">
      {currentView === "billing-form" && (
        <Grid item xs={12}>
          <Box
            sx={{
              margin: {
                xs: theme.spacing(0, 1),
                sm: theme.spacing(2, 5, 3, 5),
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h2">Billing Details</Typography>
              <Button size="small" onClick={() => setCurrentView("cart")}>
                Go back to Cart
              </Button>
            </Box>
            <Divider sx={{ m: theme.spacing(3, 0, 5, 0) }} />
            <div style={{ minHeight: 360 }}>
              <BillingDetailsForm
                buttonTitle={"Continue with Payment"}
                buttonProps={{
                  fullWidth: true,
                  endIcon: <ArrowForwardIcon />,
                }}
                onBeforeSubmit={(details) => setBillingDetails(details)}
              />
            </div>
            <Typography
              variant={"caption"}
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: theme.spacing(1.5),
                mb: theme.spacing(3.5),
              }}
            >
              <SecurityIcon
                style={{ marginRight: theme.spacing(1), fontSize: 17 }}
              />
              100% Safe & Secure
            </Typography>
            {user?.isEmailConfirmed === false ? (
              <Box
                sx={{
                  textAlign: "center",
                  mb: theme.spacing(2),
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    position: "relative",
                    height: "24px",
                    width: "24px",
                    mr: theme.spacing(1),
                  }}
                >
                  <WarningAmberRoundedIcon
                    sx={{
                      mr: theme.spacing(1),
                      position: "absolute",
                      left: 0,
                      bottom: "-7px",
                    }}
                    color="primary"
                  />
                </Box>
                <Typography variant="body2" component="span">
                  Please activate your account to continue to payment.
                </Typography>
                <Button
                  href={"https://app.pitchground.com/auth/activate"}
                  target={"_blank"}
                  size="small"
                  startIcon={<MailOutlineRoundedIcon />}
                >
                  Activate Account
                </Button>
              </Box>
            ) : null}
          </Box>
        </Grid>
      )}

      {currentView === "active-deals" && (
        <Grid item xs={12}>
          <ActiveDeals setCurrentView={setCurrentView} />
        </Grid>
      )}

      {currentView === "cart" && (
        <Grid
          item
          xs={12}
          sx={{
            background: theme.palette.background.paper,
          }}
        >
          <Box
            sx={{
              margin: {
                xs: theme.spacing(0, 1),
                sm: theme.spacing(0, 3, 3, 5),
              },
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                margin: theme.spacing(2, 1, 1),
              }}
            >
              <Typography variant={"h2"}>Cart</Typography>
              {isCartEverLoaded === true && isCartEmpty === false ? (
                <Button
                  // href={`https://${process.env.REACT_APP_MAIN_DOMAIN}/deals`}
                  onClick={() => setCurrentView("active-deals")}
                  startIcon={<AddIcon />}
                  variant={"outlined"}
                >
                  Add More
                </Button>
              ) : null}
            </div>
            <Divider sx={{ margin: theme.spacing(2, 0) }} />
            <CartSplit cart={cart} />
            <Box sx={{ minHeight: 150 }}>{cartItemsContent}</Box>
            <Button
              variant="contained"
              size="large"
              fullWidth
              sx={{ mt: theme.spacing(2) }}
              onClick={() => setCurrentView("billing-form")}
              endIcon={<ArrowForwardIcon />}
            >
              Continue to Billing
            </Button>
            <UnderCart />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
