import call from "../../helpers/call";

function listByAffiliateAdmin({
  authToken,
  affiliateUserId,
  affiliateLinkId,
  page,
  perPage,
  orderDirection,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/list-by-affiliate-admin`,
    payload: {
      affiliateUserId, // (Optional) String 10-30 in length and starting with "u-"
      affiliateLinkId, // (Optional) String 10-30 in length and starting with "al-"
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any positive integer. Range: (0,1000]
      orderDirection, // (Required) Valid values: desc, asc
    },
  });
}

export default listByAffiliateAdmin;
