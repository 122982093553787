import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import propTypes from "prop-types";

export default function PgDialog({ children, onClose, isLoading }) {
  const theme = useTheme();
  return (
    <>
      <Backdrop
        style={{
          // backdropFilter: "blur(24px)",
          zIndex: theme.zIndex.modal,
          // background: "transparent",
          // background: "rgba(255, 255, 255, 0.8)",
          background: theme.palette.background.paper,
        }}
        open
      >
        <div
          style={{
            background: theme.palette.background.default,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: theme.spacing(10),
            display: "flex",
            justifyContent: "center",
            // padding: theme.spacing(2, 6),
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: theme.spacing(0, 2),
              flex: 1,
              width: "100%",
              maxWidth: 1400,
            }}
          >
            <div style={{ width: 200, height: 50 }}>
              <img
                style={{ width: 187, height: 41 }}
                src="https://pitchground.com/pitchground_with_slogan.svg"
                alt="Pitchground"
              />
            </div>
            <div>
              {isLoading && (
                <>
                  <CircularProgress size={18} />
                  <div>Saving...</div>
                </>
              )}
              <IconButton
                aria-label="close-dialog"
                onClick={onClose}
                style={{
                  margin: "5px",
                  border: `1px solid ${theme.palette.divider},`,
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <Box
          sx={{
            padding: { xs: 0, sm: theme.spacing(2, 1) },
            marginTop: "80px",
            maxHeight: "calc(100vh - 80px)",
            overflowY: "auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {children}
        </Box>
      </Backdrop>
    </>
  );
}

PgDialog.propTypes = {
  children: propTypes.node,
};
