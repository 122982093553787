import call from "../../helpers/call";

function splitPaymentUpdateAdmin({
  authToken,
  invoiceId,
  splitPaymentMonths,
  splitPaymentPermille,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/split-payment-update-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      splitPaymentMonths, // (Required) Any positive integer. 0 is not valid.
      splitPaymentPermille, // (Required) Integer: 1-1000
    },
  });
}

export default splitPaymentUpdateAdmin;
