import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Link } from "@mui/material";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import _get from "lodash.get";
import propTypes from "prop-types";
import { useState } from "react";
import useCartVipDiscountListLoadingState from "stateManager/hooks/cart/useCartVipDiscountListLoadingState";
import useCartVipDiscountListResult from "stateManager/hooks/cart/useCartVipDiscountListResult";
import useIsCartVipDiscountListLoaded from "stateManager/hooks/cart/useIsCartVipDiscountListLoaded";
import useIsProductPlansListForPublishedProductLoaded from "stateManager/hooks/productPlans/useIsProductPlansListForPublishedProductLoaded";
import useProductPlansListForPublishedProductResult from "stateManager/hooks/productPlans/useProductPlansListForPublishedProductResult";
import useStripeSubscriptionListResult from "stateManager/hooks/stripeSubscription/useStripeSubscriptionListResult";
import useActions from "stateManager/hooks/useActions";

export default function CartItems({ cart, cartItems }) {
  const actions = useActions();
  const theme = useTheme();

  const subscriptionResult = useStripeSubscriptionListResult({});
  const stripeResult = subscriptionResult.getIn([
    "result",
    "data",
    "stripeResult",
  ])
    ? subscriptionResult.getIn(["result", "data", "stripeResult"]).toJS()
    : null;
  let doShowVipAd = !stripeResult || !stripeResult[0];

  const cartVipDiscountResult = useCartVipDiscountListResult({});
  const vipDiscountLoadingState = useCartVipDiscountListLoadingState({});
  const isVipDiscountLoaded = useIsCartVipDiscountListLoaded({});
  const isVipDiscountLoading =
    isVipDiscountLoaded === false || vipDiscountLoadingState.get("isLoading");
  const vipPlans = cartVipDiscountResult.getIn([
    "result",
    "data",
    "cartVipDiscountList",
  ])
    ? cartVipDiscountResult
        .getIn(["result", "data", "cartVipDiscountList"])
        .toJS()
    : [];

  const vipPlusPlan = vipPlans.find(
    (plan) => plan.planName.indexOf("VIP+") > 0
  );

  const vipPlan = vipPlans.find((plan) => plan.planName.indexOf("VIP ") > 0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSmUp = useMediaQuery((_theme) => _theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery((_theme) => _theme.breakpoints.up("md"));

  const open = Boolean(anchorEl);

  const [openedProductId, setOpenedProductId] = useState(null);

  const productPlans = useProductPlansListForPublishedProductResult({
    productId: openedProductId,
  });
  const arePlansLoading =
    useIsProductPlansListForPublishedProductLoaded({}) === false ||
    (typeof productPlans.getIn(["result", "data", "0", "productId"]) !==
      "undefined" &&
      productPlans.getIn(["result", "data", "0", "productId"]) !==
        openedProductId);

  const otherProductPlansRows = [];

  const items = cartItems.getIn(["result", "data"]);

  const rows = [];
  if (items) {
    items.forEach((item) => {
      if (
        arePlansLoading === false &&
        openedProductId === item.get("productId") &&
        productPlans.getIn(["result", "data"])
      ) {
        productPlans.getIn(["result", "data"]).map((productPlan) => {
          if (productPlan.get("productPlanId") !== item.get("productPlanId")) {
            otherProductPlansRows.push(
              <MenuItem
                key={productPlan.get("productPlanId")}
                value={productPlan.get("productPlanId")}
              >
                {productPlan.get("name")}
              </MenuItem>
            );
          }
        });
      }

      rows.push(
        <div key={item.get("productPlanId")}>
          <Typography style={{ margin: theme.spacing(4, 0, 2) }} variant={"h6"}>
            {item.get("productName")}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  size={"small"}
                  fullWidth
                  select
                  value={item.get("productPlanId")}
                  onChange={(e) => {
                    actions.cart.v2ItemUpdate({
                      quantity: item.get("quantity"),
                      productPlanId: e.target.value,
                      removeQuantity: 0,
                      addQuantity: item.get("quantity"),
                      valueCentsPerItem: item.get("priceForOne"),
                      productPlanName: item.get("productPlanName"),
                    });
                    actions.cart.v2ItemUpdate({
                      quantity: 0,
                      productPlanId: item.get("productPlanId"),
                      removeQuantity: item.get("quantity"),
                      addQuantity: 0,
                      valueCentsPerItem: item.get("priceForOne"),
                      productPlanName: item.get("productPlanName"),
                    });
                  }}
                  SelectProps={{
                    onOpen: () => {
                      setOpenedProductId(item.get("productId"));
                    },
                  }}
                >
                  <MenuItem value={item.get("productPlanId")}>
                    {item.get("productPlanName")}
                  </MenuItem>
                  {arePlansLoading ? (
                    <MenuItem>
                      <div
                        style={{
                          margin: theme.spacing(1, 0),
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <CircularProgress color={"secondary"} />
                      </div>
                    </MenuItem>
                  ) : (
                    otherProductPlansRows
                  )}
                </TextField>
                <TextField
                  size={"small"}
                  sx={{ width: 115, marginLeft: theme.spacing(2) }}
                  select
                  disabled={item.get("productPlanName").indexOf("VIP") > 0}
                  value={item.get("quantity")}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10);
                    let addQuantity = 0;
                    let removeQuantity = 0;

                    if (item.get("quantity") > newQuantity) {
                      removeQuantity = item.get("quantity") - newQuantity;
                    } else if (item.get("quantity") < newQuantity) {
                      addQuantity = newQuantity - item.get("quantity");
                    }

                    actions.cart.v2ItemUpdate({
                      quantity: newQuantity,
                      productPlanId: item.get("productPlanId"),
                      addQuantity,
                      removeQuantity,
                      valueCentsPerItem: item.get("priceForOne"),
                      productPlanName: item.get("productPlanName"),
                    });
                  }}
                  SelectProps={{
                    native: true,
                  }}
                  label="Qty"
                  InputLabelProps={{ shrink: true }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_n) => {
                    return (
                      <option value={_n} key={_n}>
                        {_n}
                      </option>
                    );
                  })}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "space-between", sm: "space-evenly" },
                }}
              >
                <div>
                  <Typography variant={"h5"}>
                    ${item.get("priceTotal") / 100}
                  </Typography>
                </div>
                <IconButton
                  sx={{ marginLeft: theme.spacing(1) }}
                  onClick={() => {
                    actions.cart.v2ItemUpdate({
                      quantity: 0,
                      productPlanId: item.get("productPlanId"),
                      addQuantity: 0,
                      removeQuantity: item.get("quantity"),
                      valueCentsPerItem: item.get("priceForOne"),
                      productPlanName: item.get("productPlanName"),
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/*<TextField*/}
            {/*  size={"small"}*/}
            {/*  fullWidth*/}
            {/*  select*/}
            {/*  value={item.get("productPlanId")}*/}
            {/*  onChange={(e) => {*/}
            {/*    actions.cart.v2ItemUpdate({*/}
            {/*      quantity: item.get("quantity"),*/}
            {/*      productPlanId: e.target.value,*/}
            {/*    });*/}
            {/*    actions.cart.v2ItemUpdate({*/}
            {/*      quantity: 0,*/}
            {/*      productPlanId: item.get("productPlanId"),*/}
            {/*    });*/}
            {/*  }}*/}
            {/*  SelectProps={{*/}
            {/*    onOpen: () => {*/}
            {/*      setOpenedProductId(item.get("productId"));*/}
            {/*    },*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <MenuItem value={item.get("productPlanId")}>*/}
            {/*    {item.get("productPlanName")}*/}
            {/*  </MenuItem>*/}
            {/*  {arePlansLoading ? (*/}
            {/*    <MenuItem>*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          margin: theme.spacing(1, 0),*/}
            {/*          textAlign: "center",*/}
            {/*          width: "100%",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <CircularProgress color={"secondary"} />*/}
            {/*      </div>*/}
            {/*    </MenuItem>*/}
            {/*  ) : (*/}
            {/*    otherProductPlansRows*/}
            {/*  )}*/}
            {/*</TextField>*/}
            {/*<TextField*/}
            {/*  size={"small"}*/}
            {/*  style={{ width: 115, margin: theme.spacing(0, 3) }}*/}
            {/*  select*/}
            {/*  value={item.get("quantity")}*/}
            {/*  onChange={(e) => {*/}
            {/*    actions.cart.v2ItemUpdate({*/}
            {/*      quantity: parseInt(e.target.value, 10),*/}
            {/*      productPlanId: item.get("productPlanId"),*/}
            {/*    });*/}
            {/*  }}*/}
            {/*  SelectProps={{*/}
            {/*    native: true,*/}
            {/*  }}*/}
            {/*  label="Qty"*/}
            {/*  InputLabelProps={{ shrink: true }}*/}
            {/*>*/}
            {/*  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_n) => {*/}
            {/*    return (*/}
            {/*      <option value={_n} key={_n}>*/}
            {/*        {_n}*/}
            {/*      </option>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</TextField>*/}
            {/*<div>*/}
            {/*  <Typography variant={"h5"}>*/}
            {/*    ${item.get("priceTotal") / 100}*/}
            {/*  </Typography>*/}
            {/*</div>*/}
            {/*<IconButton*/}
            {/*  style={{ margin: theme.spacing(0, 0, 0, 1) }}*/}
            {/*  onClick={() => {*/}
            {/*    actions.cart.v2ItemUpdate({*/}
            {/*      quantity: 0,*/}
            {/*      productPlanId: item.get("productPlanId"),*/}
            {/*    });*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <CloseIcon />*/}
            {/*</IconButton>*/}
          </div>
        </div>
      );
    });
  }

  if (cartItems)
    return (
      <>
        <div>
          {rows}
          <Divider style={{ margin: theme.spacing(2, 0, 2) }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: theme.spacing(2, 0),
            }}
          >
            <Typography variant={"h3"}>Total</Typography>
            <Typography variant={"h3"} color={"primary"}>
              ${cart.getIn(["result", "data", "total"]) / 100}
            </Typography>
          </div>
        </div>
        <Divider style={{ margin: theme.spacing(2, 0, 2) }} />

        {doShowVipAd ? (
          <Box
            sx={{
              backgroundColor: theme.palette.secondary[200],
              borderRadius: theme.spacing(0.5),
              padding: isSmUp ? theme.spacing(2) : theme.spacing(1, 0),
              display: "flex",
              alignItems: "center",
              margin: "auto",
            }}
          >
            {isVipDiscountLoading && <CircularProgress color={"secondary"} />}
            {!isVipDiscountLoading && (
              <>
                {isSmUp ? (
                  <img width={48} height={48} src={"/img/pg-vip.svg"} />
                ) : null}

                <Box
                  sx={{
                    marginLeft: theme.spacing(2),
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="subtitle2" component="span">
                    Save upto{" "}
                    <Typography
                      variant="subtitle2"
                      component="span"
                      sx={{ color: theme.palette.success.light }}
                    >
                      $
                      {(_get(vipPlusPlan, "saveUpto") ||
                        _get(vipPlan, "saveUpto") ||
                        0) / 100}
                    </Typography>{" "}
                    on this order and get more discounts with PG VIP membership.{" "}
                  </Typography>

                  <Link
                    variant="subtitle2"
                    color="primary"
                    underline="none"
                    sx={{ cursor: "pointer" }}
                    href={`https://${process.env.REACT_APP_MAIN_DOMAIN}/vip`}
                    target="_blank"
                    rel="noopener"
                  >
                    Learn more
                  </Link>
                </Box>

                <Box sx={{ marginLeft: theme.spacing(1) }}>
                  <Typography variant="body2">From</Typography>{" "}
                  <Typography variant="h5">${vipPlan.price / 100}</Typography>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      height: "40px",
                      minWidth: "40px",
                    }}
                    onClick={handleClick}
                  >
                    <AddShoppingCartRoundedIcon />
                  </Button>
                </Box>

                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  {vipPlans.map((_plan) => {
                    let imgName;
                    if (_plan.planName.indexOf("PG VIP "))
                      imgName = "pg-vip.svg";
                    else if (_plan.planName.indexOf("PG VIP+ "))
                      imgName = "pg-vip-plus.svg";

                    return (
                      <Box
                        key={_plan.planId}
                        sx={{
                          backgroundColor: theme.palette.secondary[200],
                          borderRadius: theme.spacing(0.5),
                          padding: theme.spacing(1.5),
                          display: "flex",
                          alignItems: "center",
                          minWidth: "260px",
                          margin: theme.spacing(1),
                        }}
                      >
                        <img width={48} height={48} src={`/img/${imgName}`} />

                        <Box sx={{ marginLeft: theme.spacing(1), flexGrow: 1 }}>
                          <Typography variant="body2">
                            {_plan.planName}
                          </Typography>
                          <Typography variant="subtitle1">
                            ${_plan.price / 100} /yr
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            marginLeft: theme.spacing(0.5),
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="caption">Save</Typography>
                          <Typography
                            variant="h5"
                            sx={{ color: theme.palette.success.light }}
                          >
                            ${_plan.saveUpto / 100}
                          </Typography>
                        </Box>

                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            height: "40px",
                            minWidth: "40px",
                            maxWidth: "40px",
                            marginLeft: theme.spacing(2),
                          }}
                          onClick={() =>
                            actions.cart.v2ItemUpdate({
                              quantity: 1,
                              productPlanId: _plan.planId,
                              removeQuantity: 0,
                              addQuantity: 1,
                              valueCentsPerItem: _plan.price,
                              productPlanName: _plan.planName,
                            })
                          }
                        >
                          <AddShoppingCartRoundedIcon />
                        </Button>
                      </Box>
                    );
                  })}
                </Popover>
              </>
            )}
          </Box>
        ) : null}
      </>
    );
}

CartItems.propTypes = {
  cart: propTypes.object.isRequired,
  cartItems: propTypes.object.isRequired,
};
