import call from "../helpers/call";

function stripeCardDetach({ authToken, stripePaymentMethodId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/stripe-card-detach`,
    payload: {
      stripePaymentMethodId, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default stripeCardDetach;
