import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const UPLOAD_TRY = "image/upload/TRY";
const UPLOAD_SUCCESS = "image/upload/SUCCESS";
const UPLOAD_FAILED = "image/upload/FAILED";

const UPLOAD_TRY_BY_IDENTIFIER = "image/upload/TRY_BY_IDENTIFIER";
const UPLOAD_SUCCESS_BY_IDENTIFIER = "image/upload/SUCCESS_BY_IDENTIFIER";
const UPLOAD_FAILED_BY_IDENTIFIER = "image/upload/FAILED_BY_IDENTIFIER";

const initialState = Map({
  upload: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPLOAD_TRY:
      return handleTry({ state, action });
    case UPLOAD_SUCCESS:
      return handleSuccess({ state, action });
    case UPLOAD_FAILED:
      return handleFailed({ state, action });
    case UPLOAD_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case UPLOAD_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case UPLOAD_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  UPLOAD_TRY,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  UPLOAD_TRY_BY_IDENTIFIER,
  UPLOAD_SUCCESS_BY_IDENTIFIER,
  UPLOAD_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
