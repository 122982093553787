import call from "../../helpers/call";

function removeAdmin({ authToken, userId, productPlanId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart-item/remove-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      productPlanId, // (Required) String 10-30 in length and starting with "pp-"
    },
  });
}

export default removeAdmin;
