import call from "../../helpers/call";

function markAllAsRead({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/notifications/mark-all-as-read`,
    payload: {},
  });
}

export default markAllAsRead;
