import call from "../../helpers/call";

function modifierArchiveAdmin({ authToken, invoiceId, invoiceModifierId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/modifier-archive-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      invoiceModifierId, // (Required) String 10-30 in length and starting with "im-"
    },
  });
}

export default modifierArchiveAdmin;
