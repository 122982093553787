import call from "../../helpers/call";

function updateAdmin({
  authToken,
  userId,
  name,
  username,
  email,
  dateOfBirth,
  emailPublic,
  twitter,
  location,
  biography,
  countryCode,
  agreedWithTosAt,
  defaultPaymentMethod,
  isEmailConfirmed,
  type,
  timezone,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/update-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      name, // (Optional) String. 1 to 50 symbols in length.
      username, // (Optional) Must be a camel case string. E.g.: someValue
      email, // (Optional) Valid email address
      dateOfBirth, // (Optional) Timestamp value. Any positive integer.
      emailPublic, // (Optional) Valid email address
      twitter, // (Optional) Must be a camel case string. E.g.: someValue
      location, // (Optional) Any string of 0-255 symbols in length.
      biography, // (Optional) Any string of 0-255 symbols in length.
      countryCode, // (Optional) 2 letter country code. E.g. IN, LT, US, CZ, PE
      agreedWithTosAt, // (Optional) Timestamp value. Any positive integer.
      defaultPaymentMethod, // (Optional) Valid values: stripe, paypal
      isEmailConfirmed, // (Optional) Value is a boolean. True or false.
      type, // (Optional) Valid values: admin, user
      timezone, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default updateAdmin;
