import call from "../../helpers/call";

function get({ authToken, productPlanId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product-plan/get`,
    payload: {
      productPlanId, // (Required) String 10-30 in length and starting with "pp-"
    },
  });
}

export default get;
