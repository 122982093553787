import call from "../../helpers/call";

function listMy({ authToken, page, perPage, state, searchQuery }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/coupons/list-my`,
    payload: {
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any of: 5,10,15,20,25,30,35,40,45,50,100
      state, // (Optional) String. One of: available, disabled, refunded, sold
      searchQuery, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default listMy;
