import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const APPLY_TO_INVOICE_TRY = "discountCoupon/applyToInvoice/TRY";
const APPLY_TO_INVOICE_SUCCESS = "discountCoupon/applyToInvoice/SUCCESS";
const APPLY_TO_INVOICE_FAILED = "discountCoupon/applyToInvoice/FAILED";

const APPLY_TO_INVOICE_TRY_BY_IDENTIFIER =
  "discountCoupon/applyToInvoice/TRY_BY_IDENTIFIER";
const APPLY_TO_INVOICE_SUCCESS_BY_IDENTIFIER =
  "discountCoupon/applyToInvoice/SUCCESS_BY_IDENTIFIER";
const APPLY_TO_INVOICE_FAILED_BY_IDENTIFIER =
  "discountCoupon/applyToInvoice/FAILED_BY_IDENTIFIER";

const REMOVE_FROM_INVOICE_TRY = "discountCoupon/removeFromInvoice/TRY";
const REMOVE_FROM_INVOICE_SUCCESS = "discountCoupon/removeFromInvoice/SUCCESS";
const REMOVE_FROM_INVOICE_FAILED = "discountCoupon/removeFromInvoice/FAILED";

const REMOVE_FROM_INVOICE_TRY_BY_IDENTIFIER =
  "discountCoupon/removeFromInvoice/TRY_BY_IDENTIFIER";
const REMOVE_FROM_INVOICE_SUCCESS_BY_IDENTIFIER =
  "discountCoupon/removeFromInvoice/SUCCESS_BY_IDENTIFIER";
const REMOVE_FROM_INVOICE_FAILED_BY_IDENTIFIER =
  "discountCoupon/removeFromInvoice/FAILED_BY_IDENTIFIER";

const initialState = Map({
  applyToInvoice: shape.merge(Map({ dataByIdentifier: Map({}) })),
  removeFromInvoice: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case APPLY_TO_INVOICE_TRY:
    case REMOVE_FROM_INVOICE_TRY:
      return handleTry({ state, action });
    case APPLY_TO_INVOICE_SUCCESS:
    case REMOVE_FROM_INVOICE_SUCCESS:
      return handleSuccess({ state, action });
    case APPLY_TO_INVOICE_FAILED:
    case REMOVE_FROM_INVOICE_FAILED:
      return handleFailed({ state, action });
    case APPLY_TO_INVOICE_TRY_BY_IDENTIFIER:
    case REMOVE_FROM_INVOICE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case APPLY_TO_INVOICE_SUCCESS_BY_IDENTIFIER:
    case REMOVE_FROM_INVOICE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case APPLY_TO_INVOICE_FAILED_BY_IDENTIFIER:
    case REMOVE_FROM_INVOICE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  APPLY_TO_INVOICE_TRY,
  APPLY_TO_INVOICE_SUCCESS,
  APPLY_TO_INVOICE_FAILED,
  APPLY_TO_INVOICE_TRY_BY_IDENTIFIER,
  APPLY_TO_INVOICE_SUCCESS_BY_IDENTIFIER,
  APPLY_TO_INVOICE_FAILED_BY_IDENTIFIER,

  REMOVE_FROM_INVOICE_TRY,
  REMOVE_FROM_INVOICE_SUCCESS,
  REMOVE_FROM_INVOICE_FAILED,
  REMOVE_FROM_INVOICE_TRY_BY_IDENTIFIER,
  REMOVE_FROM_INVOICE_SUCCESS_BY_IDENTIFIER,
  REMOVE_FROM_INVOICE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
