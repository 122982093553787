import call from "../../helpers/call";

function removeAdmin({ authToken, banId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/ban/remove-admin`,
    payload: {
      banId, // (Required) String 10-30 in length and starting with "b-"
    },
  });
}

export default removeAdmin;
