/* eslint-disable no-restricted-globals */
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import useActions from "stateManager/hooks/useActions";
import useIsUserGetIfUserBoughtProductLoaded from "stateManager/hooks/user/useIsUserGetIfUserBoughtProductLoaded";
import useUserGetIfUserBoughtProductResult from "stateManager/hooks/user/useUserGetIfUserBoughtProductResult";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

export default function BtnWriteReview({ productId }) {
  const actions = useActions();
  const user = useUserGetResult({});

  const isLoggedIn =
    user &&
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";
  const ifUserBoughtProduct = useUserGetIfUserBoughtProductResult({
    productId,
  });
  const isLoaded = useIsUserGetIfUserBoughtProductLoaded({});

  let isLoading = !isLoaded;

  const handleClick = () => {
    if (isLoggedIn) {
      if (
        isLoaded === true &&
        ifUserBoughtProduct.get("wasSuccess") &&
        ifUserBoughtProduct.getIn(["result", "data"])
      ) {
        const isUserBoughtProduct = ifUserBoughtProduct
          .getIn(["result", "data"])
          .toJS();
        if (
          isUserBoughtProduct.length > 0 &&
          isUserBoughtProduct[0].isValidatedBuyer
        ) {
          window.location.href = `https://app.${process.env.REACT_APP_MAIN_DOMAIN}/purchases?dialog=write-review&review-product-id=${productId}`;
        } else {
          actions.globalMessages.addToQueue({
            message: "Please purchase the product first.",
          });
        }
      }
    } else {
      actions.globalMessages.addToQueue({
        message: "Need to login first",
      });
      openLoginScreenToContinue();
    }
  };

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      startIcon={<StarOutlineIcon fontSize={"inherit"} />}
      size={"large"}
      sx={{
        textTransform: "none",
        height: 56,
        width: { xs: "100%", sm: "auto" },
      }}
      onClick={handleClick}
    >
      Write Review
    </LoadingButton>
  );
}
