import call from "../../helpers/call";

function listByProduct({
  authToken,
  productId,
  page,
  perPage,
  sortBy,
  sortDirection,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discussion/list-by-product`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any of: 5,10,15,20,25,30,35,40,45,50,100
      sortBy, // (Optional) One of: createdAt, helpfulCount
      sortDirection, // (Optional) Valid values: desc, asc
    },
  });
}

export default listByProduct;
