import useCartListMyItemsLoadingState from "stateManager/hooks/cart/useCartListMyItemsLoadingState";
import useCartListMyItemsResult from "stateManager/hooks/cart/useCartListMyItemsResult";

export default function useIsCartListMyItemsLoaded({}, ___options) {
  const loadingState = useCartListMyItemsLoadingState({}, ___options);
  const resultState = useCartListMyItemsResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
