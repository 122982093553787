import call from "../../helpers/call";

function listIncentiveAdmin({ authToken, ownerUserId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/list-incentive-admin`,
    payload: {
      ownerUserId, // (Required) String 10-30 in length and starting with "u-"
    },
  });
}

export default listIncentiveAdmin;
