import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";

export default function BeforeLogin() {
  const theme = useTheme();
  return (
    <div style={{ display: "flex", gap: theme.spacing(0.5) }}>
      <Button
        color={"inherit"}
        variant={"text"}
        href={`https://app.${process.env.REACT_APP_MAIN_DOMAIN}/auth/login?return-url=${window.location.href}`}
      >
        Login
      </Button>
      <Button
        variant={"contained"}
        color={"primary"}
        href={`https://app.${process.env.REACT_APP_MAIN_DOMAIN}/auth/sign-up`}
      >
        Sign Up
      </Button>
    </div>
  );
}
