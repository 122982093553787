import axios from "axios";
import { put } from "redux-saga/effects";
import getIdToken from "stateManager/hooks/authFlow/helpers/getIdToken";
import authFlow from "../../reducers/authFlow";
import globalMessages from "../../reducers/globalMessages";

export default function* tryAuthFlowUploadProfileImage({ data }) {
  let result = (yield axios.post(
    `${process.env.REACT_APP_AUTH_API_URL}/upload-new-profile-picture`,
    data.imageBlob,
    {
      headers: {
        "Content-Type": "image/png",
        "x-auth": getIdToken({}),
      },
    }
  )).data;

  if (result.error === null) {
    const idToken = getIdToken({});
    yield put({
      type: authFlow.actionTypes.GET_AUTHORIZED_USER_TRY,
      data: { idToken },
    });

    yield put({
      type: authFlow.actionTypes.UPLOAD_PROFILE_IMAGE_SUCCESS,
      data: result.data,
    });

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Profile image uploaded" },
    });
  } else {
    yield put({
      type: authFlow.actionTypes.UPLOAD_PROFILE_IMAGE_FAILED,
      data: result.error,
    });

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Please re-login" },
    });
    setTimeout(() => {
      if (process.env.REACT_APP_AUTH_LOGIN_PAGE_URL) {
        location.href = `${process.env.REACT_APP_AUTH_LOGIN_PAGE_URL}&utm_source=relogin&utm_medium=after-upload-profile-picture-try&utm_id=auth`;
      } else {
        const _refreshToken = prompt("Enter refresh token", "");
        if (_refreshToken) {
          location.href = `?authFlow-refresh_token=${_refreshToken}`;
        }
      }
    }, 2000);
  }
}
