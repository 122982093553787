import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const LIST_ACTIVE_PRODUCTS_TRY = "products/listActiveProducts/TRY";
const LIST_ACTIVE_PRODUCTS_SUCCESS = "products/listActiveProducts/SUCCESS";
const LIST_ACTIVE_PRODUCTS_FAILED = "products/listActiveProducts/FAILED";

const LIST_ACTIVE_PRODUCTS_TRY_BY_IDENTIFIER =
  "products/listActiveProducts/TRY_BY_IDENTIFIER";
const LIST_ACTIVE_PRODUCTS_SUCCESS_BY_IDENTIFIER =
  "products/listActiveProducts/SUCCESS_BY_IDENTIFIER";
const LIST_ACTIVE_PRODUCTS_FAILED_BY_IDENTIFIER =
  "products/listActiveProducts/FAILED_BY_IDENTIFIER";

const initialState = Map({
  listActiveProducts: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_ACTIVE_PRODUCTS_TRY:
      return handleTry({ state, action });
    case LIST_ACTIVE_PRODUCTS_SUCCESS:
      return handleSuccess({ state, action });
    case LIST_ACTIVE_PRODUCTS_FAILED:
      return handleFailed({ state, action });
    case LIST_ACTIVE_PRODUCTS_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case LIST_ACTIVE_PRODUCTS_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case LIST_ACTIVE_PRODUCTS_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  LIST_ACTIVE_PRODUCTS_TRY,
  LIST_ACTIVE_PRODUCTS_SUCCESS,
  LIST_ACTIVE_PRODUCTS_FAILED,
  LIST_ACTIVE_PRODUCTS_TRY_BY_IDENTIFIER,
  LIST_ACTIVE_PRODUCTS_SUCCESS_BY_IDENTIFIER,
  LIST_ACTIVE_PRODUCTS_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
