import call from "../../helpers/call";

function getStatsAdmin({ authToken, userId, lastPayoutAt }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate/get-stats-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      lastPayoutAt, // (Optional) Timestamp value. Any positive integer.
    },
  });
}

export default getStatsAdmin;
