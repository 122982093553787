import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import auth from "../../reducers/auth";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";
import Cookies from "js-cookie";

const changeCase = require("change-case");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryAuthLoginByIdentifier({ data }) {
  const payload = {
    email: data.payload.email,
    password: data.payload.password,
    recaptchaToken: data.payload.recaptchaToken,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.payload.lastUrl !== "undefined") {
    payload.lastUrl = data.payload.lastUrl;
  }

  if (typeof data.payload.keepSessionForDays !== "undefined") {
    payload.keepSessionForDays = data.payload.keepSessionForDays;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  const result = yield sdk.userLogin(payload);

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: auth.actionTypes.LOGIN_SUCCESS_BY_IDENTIFIER,
      data: {
        ___identifier: data.___identifier,
        payload: { data: result.data, meta: result.meta },
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    localStorage.setItem("authToken", result.data.authToken);
    // when removing cookie, domain & path must match with this
    Cookies.set("authToken", result.data.authToken, {
      expires: 365,
      path: "/",
      domain:
        // eslint-disable-next-line
        location.host.indexOf("localhost") !== -1
          ? "localhost"
          : `.${process.env.REACT_APP_MAIN_DOMAIN}`,
    });

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Welcome back 🙂" },
    });
    setTimeout(() => {
      if (window.name === "pg-login-window") {
        window.self.close();
      } else {
        window.location.replace("/");
      }
    }, 300);

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: auth.actionTypes.LOGIN_FAILED_BY_IDENTIFIER,
      data: { ___identifier: data.___identifier, payload: result.error },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        queue: "bottomLeft",
        message: changeCase.capitalCase(result.error.message),
      },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
