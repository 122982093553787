import call from "../../helpers/call";

function userAdmin({ authToken, userId, note, type }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/ban/user-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      note, // (Optional) Any text
      type, // (Required) possible ban types: addToCart, login
    },
  });
}

export default userAdmin;
