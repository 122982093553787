import getSdkEndpoint from "../../helpers/getSdkEndpoint";

function csvExport_24ge2g42xa({ authToken }) {
  let ___query = [];
  if (typeof authToken !== "undefined") {
    ___query.push(`authToken=${authToken}`);
  }

  if (___query.length > 0) {
    ___query = `?${___query.join("&")}`;
  } else {
    ___query = "";
  }

  return `${getSdkEndpoint()}/products/csv-export-24ge2g42xa${___query}`;
}

export default csvExport_24ge2g42xa;
