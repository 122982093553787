import usePrevious from "hook-use-previous";
import { useSelector } from "react-redux";
import useDiscussionReactionRemoveLoadingState from "stateManager/hooks/discussion/useDiscussionReactionRemoveLoadingState";

export default function useIsDiscussionReactionRemoveAfterSuccess(
  {},
  ___options
) {
  const loadingState = useDiscussionReactionRemoveLoadingState({}, ___options);
  const discussion = useSelector((___state) => ___state.discussion);
  const wasLoading = usePrevious(loadingState.get("isLoading"));

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      discussion.getIn([
        "reactionRemove",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
        "wasSuccess",
      ]) === true
    );
  } else {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      discussion.getIn(["reactionRemove", "lastResult", "wasSuccess"]) === true
    );
  }
}
