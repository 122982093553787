import call from "../../helpers/call";

function spendingsList({ authToken, page, perPage, startAt, endAt }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription-tracking/spendings-list`,
    payload: {
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any positive integer. Range: (0,1000]
      startAt, // (Required) Timestamp value. Any positive integer.
      endAt, // (Required) Timestamp value. Any positive integer.
    },
  });
}

export default spendingsList;
