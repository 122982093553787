import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const COVER_INVOICE_TRY = "wallet/coverInvoice/TRY";
const COVER_INVOICE_SUCCESS = "wallet/coverInvoice/SUCCESS";
const COVER_INVOICE_FAILED = "wallet/coverInvoice/FAILED";

const COVER_INVOICE_TRY_BY_IDENTIFIER = "wallet/coverInvoice/TRY_BY_IDENTIFIER";
const COVER_INVOICE_SUCCESS_BY_IDENTIFIER =
  "wallet/coverInvoice/SUCCESS_BY_IDENTIFIER";
const COVER_INVOICE_FAILED_BY_IDENTIFIER =
  "wallet/coverInvoice/FAILED_BY_IDENTIFIER";

const GET_MY_OVERVIEW_TRY = "wallet/getMyOverview/TRY";
const GET_MY_OVERVIEW_SUCCESS = "wallet/getMyOverview/SUCCESS";
const GET_MY_OVERVIEW_FAILED = "wallet/getMyOverview/FAILED";

const GET_MY_OVERVIEW_TRY_BY_IDENTIFIER =
  "wallet/getMyOverview/TRY_BY_IDENTIFIER";
const GET_MY_OVERVIEW_SUCCESS_BY_IDENTIFIER =
  "wallet/getMyOverview/SUCCESS_BY_IDENTIFIER";
const GET_MY_OVERVIEW_FAILED_BY_IDENTIFIER =
  "wallet/getMyOverview/FAILED_BY_IDENTIFIER";

const LIST_MY_WALLET_LOGS_TRY = "wallet/listMyWalletLogs/TRY";
const LIST_MY_WALLET_LOGS_SUCCESS = "wallet/listMyWalletLogs/SUCCESS";
const LIST_MY_WALLET_LOGS_FAILED = "wallet/listMyWalletLogs/FAILED";

const LIST_MY_WALLET_LOGS_TRY_BY_IDENTIFIER =
  "wallet/listMyWalletLogs/TRY_BY_IDENTIFIER";
const LIST_MY_WALLET_LOGS_SUCCESS_BY_IDENTIFIER =
  "wallet/listMyWalletLogs/SUCCESS_BY_IDENTIFIER";
const LIST_MY_WALLET_LOGS_FAILED_BY_IDENTIFIER =
  "wallet/listMyWalletLogs/FAILED_BY_IDENTIFIER";

const initialState = Map({
  coverInvoice: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getMyOverview: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listMyWalletLogs: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case COVER_INVOICE_TRY:
    case GET_MY_OVERVIEW_TRY:
    case LIST_MY_WALLET_LOGS_TRY:
      return handleTry({ state, action });
    case COVER_INVOICE_SUCCESS:
    case GET_MY_OVERVIEW_SUCCESS:
    case LIST_MY_WALLET_LOGS_SUCCESS:
      return handleSuccess({ state, action });
    case COVER_INVOICE_FAILED:
    case GET_MY_OVERVIEW_FAILED:
    case LIST_MY_WALLET_LOGS_FAILED:
      return handleFailed({ state, action });
    case COVER_INVOICE_TRY_BY_IDENTIFIER:
    case GET_MY_OVERVIEW_TRY_BY_IDENTIFIER:
    case LIST_MY_WALLET_LOGS_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case COVER_INVOICE_SUCCESS_BY_IDENTIFIER:
    case GET_MY_OVERVIEW_SUCCESS_BY_IDENTIFIER:
    case LIST_MY_WALLET_LOGS_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case COVER_INVOICE_FAILED_BY_IDENTIFIER:
    case GET_MY_OVERVIEW_FAILED_BY_IDENTIFIER:
    case LIST_MY_WALLET_LOGS_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  COVER_INVOICE_TRY,
  COVER_INVOICE_SUCCESS,
  COVER_INVOICE_FAILED,
  COVER_INVOICE_TRY_BY_IDENTIFIER,
  COVER_INVOICE_SUCCESS_BY_IDENTIFIER,
  COVER_INVOICE_FAILED_BY_IDENTIFIER,

  GET_MY_OVERVIEW_TRY,
  GET_MY_OVERVIEW_SUCCESS,
  GET_MY_OVERVIEW_FAILED,
  GET_MY_OVERVIEW_TRY_BY_IDENTIFIER,
  GET_MY_OVERVIEW_SUCCESS_BY_IDENTIFIER,
  GET_MY_OVERVIEW_FAILED_BY_IDENTIFIER,

  LIST_MY_WALLET_LOGS_TRY,
  LIST_MY_WALLET_LOGS_SUCCESS,
  LIST_MY_WALLET_LOGS_FAILED,
  LIST_MY_WALLET_LOGS_TRY_BY_IDENTIFIER,
  LIST_MY_WALLET_LOGS_SUCCESS_BY_IDENTIFIER,
  LIST_MY_WALLET_LOGS_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
