import call from "../../helpers/call";

function remove({ authToken, discountCouponCategoryId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discount-coupon-category/remove`,
    payload: {
      discountCouponCategoryId, // (Required) String 10-30 in length and starting with "dcc-"
    },
  });
}

export default remove;
