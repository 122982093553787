import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const BILLING_DETAILS_GET_TRY = "user/billingDetailsGet/TRY";
const BILLING_DETAILS_GET_SUCCESS = "user/billingDetailsGet/SUCCESS";
const BILLING_DETAILS_GET_FAILED = "user/billingDetailsGet/FAILED";

const BILLING_DETAILS_GET_TRY_BY_IDENTIFIER =
  "user/billingDetailsGet/TRY_BY_IDENTIFIER";
const BILLING_DETAILS_GET_SUCCESS_BY_IDENTIFIER =
  "user/billingDetailsGet/SUCCESS_BY_IDENTIFIER";
const BILLING_DETAILS_GET_FAILED_BY_IDENTIFIER =
  "user/billingDetailsGet/FAILED_BY_IDENTIFIER";

const BILLING_DETAILS_UPDATE_TRY = "user/billingDetailsUpdate/TRY";
const BILLING_DETAILS_UPDATE_SUCCESS = "user/billingDetailsUpdate/SUCCESS";
const BILLING_DETAILS_UPDATE_FAILED = "user/billingDetailsUpdate/FAILED";

const BILLING_DETAILS_UPDATE_TRY_BY_IDENTIFIER =
  "user/billingDetailsUpdate/TRY_BY_IDENTIFIER";
const BILLING_DETAILS_UPDATE_SUCCESS_BY_IDENTIFIER =
  "user/billingDetailsUpdate/SUCCESS_BY_IDENTIFIER";
const BILLING_DETAILS_UPDATE_FAILED_BY_IDENTIFIER =
  "user/billingDetailsUpdate/FAILED_BY_IDENTIFIER";

const CONFIRM_EMAIL_TRY = "user/confirmEmail/TRY";
const CONFIRM_EMAIL_SUCCESS = "user/confirmEmail/SUCCESS";
const CONFIRM_EMAIL_FAILED = "user/confirmEmail/FAILED";

const CONFIRM_EMAIL_TRY_BY_IDENTIFIER = "user/confirmEmail/TRY_BY_IDENTIFIER";
const CONFIRM_EMAIL_SUCCESS_BY_IDENTIFIER =
  "user/confirmEmail/SUCCESS_BY_IDENTIFIER";
const CONFIRM_EMAIL_FAILED_BY_IDENTIFIER =
  "user/confirmEmail/FAILED_BY_IDENTIFIER";

const GET_TRY = "user/get/TRY";
const GET_SUCCESS = "user/get/SUCCESS";
const GET_FAILED = "user/get/FAILED";

const GET_TRY_BY_IDENTIFIER = "user/get/TRY_BY_IDENTIFIER";
const GET_SUCCESS_BY_IDENTIFIER = "user/get/SUCCESS_BY_IDENTIFIER";
const GET_FAILED_BY_IDENTIFIER = "user/get/FAILED_BY_IDENTIFIER";

const GET_IF_USER_BOUGHT_PRODUCT_TRY = "user/getIfUserBoughtProduct/TRY";
const GET_IF_USER_BOUGHT_PRODUCT_SUCCESS =
  "user/getIfUserBoughtProduct/SUCCESS";
const GET_IF_USER_BOUGHT_PRODUCT_FAILED = "user/getIfUserBoughtProduct/FAILED";

const GET_IF_USER_BOUGHT_PRODUCT_TRY_BY_IDENTIFIER =
  "user/getIfUserBoughtProduct/TRY_BY_IDENTIFIER";
const GET_IF_USER_BOUGHT_PRODUCT_SUCCESS_BY_IDENTIFIER =
  "user/getIfUserBoughtProduct/SUCCESS_BY_IDENTIFIER";
const GET_IF_USER_BOUGHT_PRODUCT_FAILED_BY_IDENTIFIER =
  "user/getIfUserBoughtProduct/FAILED_BY_IDENTIFIER";

const GET_STATS_TRY = "user/getStats/TRY";
const GET_STATS_SUCCESS = "user/getStats/SUCCESS";
const GET_STATS_FAILED = "user/getStats/FAILED";

const GET_STATS_TRY_BY_IDENTIFIER = "user/getStats/TRY_BY_IDENTIFIER";
const GET_STATS_SUCCESS_BY_IDENTIFIER = "user/getStats/SUCCESS_BY_IDENTIFIER";
const GET_STATS_FAILED_BY_IDENTIFIER = "user/getStats/FAILED_BY_IDENTIFIER";

const GET_USER_DEALS_BOUGHT_COUNT_TRY = "user/getUserDealsBoughtCount/TRY";
const GET_USER_DEALS_BOUGHT_COUNT_SUCCESS =
  "user/getUserDealsBoughtCount/SUCCESS";
const GET_USER_DEALS_BOUGHT_COUNT_FAILED =
  "user/getUserDealsBoughtCount/FAILED";

const GET_USER_DEALS_BOUGHT_COUNT_TRY_BY_IDENTIFIER =
  "user/getUserDealsBoughtCount/TRY_BY_IDENTIFIER";
const GET_USER_DEALS_BOUGHT_COUNT_SUCCESS_BY_IDENTIFIER =
  "user/getUserDealsBoughtCount/SUCCESS_BY_IDENTIFIER";
const GET_USER_DEALS_BOUGHT_COUNT_FAILED_BY_IDENTIFIER =
  "user/getUserDealsBoughtCount/FAILED_BY_IDENTIFIER";

const LIST_AVAILABLE_INCENTIVES_TRY = "user/listAvailableIncentives/TRY";
const LIST_AVAILABLE_INCENTIVES_SUCCESS =
  "user/listAvailableIncentives/SUCCESS";
const LIST_AVAILABLE_INCENTIVES_FAILED = "user/listAvailableIncentives/FAILED";

const LIST_AVAILABLE_INCENTIVES_TRY_BY_IDENTIFIER =
  "user/listAvailableIncentives/TRY_BY_IDENTIFIER";
const LIST_AVAILABLE_INCENTIVES_SUCCESS_BY_IDENTIFIER =
  "user/listAvailableIncentives/SUCCESS_BY_IDENTIFIER";
const LIST_AVAILABLE_INCENTIVES_FAILED_BY_IDENTIFIER =
  "user/listAvailableIncentives/FAILED_BY_IDENTIFIER";

const LIST_INVOICE_INCENTIVES_TRY = "user/listInvoiceIncentives/TRY";
const LIST_INVOICE_INCENTIVES_SUCCESS = "user/listInvoiceIncentives/SUCCESS";
const LIST_INVOICE_INCENTIVES_FAILED = "user/listInvoiceIncentives/FAILED";

const LIST_INVOICE_INCENTIVES_TRY_BY_IDENTIFIER =
  "user/listInvoiceIncentives/TRY_BY_IDENTIFIER";
const LIST_INVOICE_INCENTIVES_SUCCESS_BY_IDENTIFIER =
  "user/listInvoiceIncentives/SUCCESS_BY_IDENTIFIER";
const LIST_INVOICE_INCENTIVES_FAILED_BY_IDENTIFIER =
  "user/listInvoiceIncentives/FAILED_BY_IDENTIFIER";

const PASSWORD_CHANGE_WITH_TOKEN_TRY = "user/passwordChangeWithToken/TRY";
const PASSWORD_CHANGE_WITH_TOKEN_SUCCESS =
  "user/passwordChangeWithToken/SUCCESS";
const PASSWORD_CHANGE_WITH_TOKEN_FAILED = "user/passwordChangeWithToken/FAILED";

const PASSWORD_CHANGE_WITH_TOKEN_TRY_BY_IDENTIFIER =
  "user/passwordChangeWithToken/TRY_BY_IDENTIFIER";
const PASSWORD_CHANGE_WITH_TOKEN_SUCCESS_BY_IDENTIFIER =
  "user/passwordChangeWithToken/SUCCESS_BY_IDENTIFIER";
const PASSWORD_CHANGE_WITH_TOKEN_FAILED_BY_IDENTIFIER =
  "user/passwordChangeWithToken/FAILED_BY_IDENTIFIER";

const REQUEST_PASSWORD_RECOVERY_TRY = "user/requestPasswordRecovery/TRY";
const REQUEST_PASSWORD_RECOVERY_SUCCESS =
  "user/requestPasswordRecovery/SUCCESS";
const REQUEST_PASSWORD_RECOVERY_FAILED = "user/requestPasswordRecovery/FAILED";

const REQUEST_PASSWORD_RECOVERY_TRY_BY_IDENTIFIER =
  "user/requestPasswordRecovery/TRY_BY_IDENTIFIER";
const REQUEST_PASSWORD_RECOVERY_SUCCESS_BY_IDENTIFIER =
  "user/requestPasswordRecovery/SUCCESS_BY_IDENTIFIER";
const REQUEST_PASSWORD_RECOVERY_FAILED_BY_IDENTIFIER =
  "user/requestPasswordRecovery/FAILED_BY_IDENTIFIER";

const REQUEST_RESEND_EMAIL_CONFIRMATION_TRY =
  "user/requestResendEmailConfirmation/TRY";
const REQUEST_RESEND_EMAIL_CONFIRMATION_SUCCESS =
  "user/requestResendEmailConfirmation/SUCCESS";
const REQUEST_RESEND_EMAIL_CONFIRMATION_FAILED =
  "user/requestResendEmailConfirmation/FAILED";

const REQUEST_RESEND_EMAIL_CONFIRMATION_TRY_BY_IDENTIFIER =
  "user/requestResendEmailConfirmation/TRY_BY_IDENTIFIER";
const REQUEST_RESEND_EMAIL_CONFIRMATION_SUCCESS_BY_IDENTIFIER =
  "user/requestResendEmailConfirmation/SUCCESS_BY_IDENTIFIER";
const REQUEST_RESEND_EMAIL_CONFIRMATION_FAILED_BY_IDENTIFIER =
  "user/requestResendEmailConfirmation/FAILED_BY_IDENTIFIER";

const SIGN_UP_TRY = "user/signUp/TRY";
const SIGN_UP_SUCCESS = "user/signUp/SUCCESS";
const SIGN_UP_FAILED = "user/signUp/FAILED";

const SIGN_UP_TRY_BY_IDENTIFIER = "user/signUp/TRY_BY_IDENTIFIER";
const SIGN_UP_SUCCESS_BY_IDENTIFIER = "user/signUp/SUCCESS_BY_IDENTIFIER";
const SIGN_UP_FAILED_BY_IDENTIFIER = "user/signUp/FAILED_BY_IDENTIFIER";

const STRIPE_CARD_DETACH_TRY = "user/stripeCardDetach/TRY";
const STRIPE_CARD_DETACH_SUCCESS = "user/stripeCardDetach/SUCCESS";
const STRIPE_CARD_DETACH_FAILED = "user/stripeCardDetach/FAILED";

const STRIPE_CARD_DETACH_TRY_BY_IDENTIFIER =
  "user/stripeCardDetach/TRY_BY_IDENTIFIER";
const STRIPE_CARD_DETACH_SUCCESS_BY_IDENTIFIER =
  "user/stripeCardDetach/SUCCESS_BY_IDENTIFIER";
const STRIPE_CARD_DETACH_FAILED_BY_IDENTIFIER =
  "user/stripeCardDetach/FAILED_BY_IDENTIFIER";

const STRIPE_CARDS_LIST_TRY = "user/stripeCardsList/TRY";
const STRIPE_CARDS_LIST_SUCCESS = "user/stripeCardsList/SUCCESS";
const STRIPE_CARDS_LIST_FAILED = "user/stripeCardsList/FAILED";

const STRIPE_CARDS_LIST_TRY_BY_IDENTIFIER =
  "user/stripeCardsList/TRY_BY_IDENTIFIER";
const STRIPE_CARDS_LIST_SUCCESS_BY_IDENTIFIER =
  "user/stripeCardsList/SUCCESS_BY_IDENTIFIER";
const STRIPE_CARDS_LIST_FAILED_BY_IDENTIFIER =
  "user/stripeCardsList/FAILED_BY_IDENTIFIER";

const UPDATE_TRY = "user/update/TRY";
const UPDATE_SUCCESS = "user/update/SUCCESS";
const UPDATE_FAILED = "user/update/FAILED";

const UPDATE_TRY_BY_IDENTIFIER = "user/update/TRY_BY_IDENTIFIER";
const UPDATE_SUCCESS_BY_IDENTIFIER = "user/update/SUCCESS_BY_IDENTIFIER";
const UPDATE_FAILED_BY_IDENTIFIER = "user/update/FAILED_BY_IDENTIFIER";

const UPDATE_PROFILE_TIMEZONE_TRY = "user/updateProfileTimezone/TRY";
const UPDATE_PROFILE_TIMEZONE_SUCCESS = "user/updateProfileTimezone/SUCCESS";
const UPDATE_PROFILE_TIMEZONE_FAILED = "user/updateProfileTimezone/FAILED";

const UPDATE_PROFILE_TIMEZONE_TRY_BY_IDENTIFIER =
  "user/updateProfileTimezone/TRY_BY_IDENTIFIER";
const UPDATE_PROFILE_TIMEZONE_SUCCESS_BY_IDENTIFIER =
  "user/updateProfileTimezone/SUCCESS_BY_IDENTIFIER";
const UPDATE_PROFILE_TIMEZONE_FAILED_BY_IDENTIFIER =
  "user/updateProfileTimezone/FAILED_BY_IDENTIFIER";

const UPLOAD_PROFILE_PICTURE_TRY = "user/uploadProfilePicture/TRY";
const UPLOAD_PROFILE_PICTURE_SUCCESS = "user/uploadProfilePicture/SUCCESS";
const UPLOAD_PROFILE_PICTURE_FAILED = "user/uploadProfilePicture/FAILED";

const UPLOAD_PROFILE_PICTURE_TRY_BY_IDENTIFIER =
  "user/uploadProfilePicture/TRY_BY_IDENTIFIER";
const UPLOAD_PROFILE_PICTURE_SUCCESS_BY_IDENTIFIER =
  "user/uploadProfilePicture/SUCCESS_BY_IDENTIFIER";
const UPLOAD_PROFILE_PICTURE_FAILED_BY_IDENTIFIER =
  "user/uploadProfilePicture/FAILED_BY_IDENTIFIER";

const initialState = Map({
  billingDetailsGet: shape.merge(Map({ dataByIdentifier: Map({}) })),
  billingDetailsUpdate: shape.merge(Map({ dataByIdentifier: Map({}) })),
  confirmEmail: shape.merge(Map({ dataByIdentifier: Map({}) })),
  get: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getIfUserBoughtProduct: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getStats: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getUserDealsBoughtCount: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listAvailableIncentives: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listInvoiceIncentives: shape.merge(Map({ dataByIdentifier: Map({}) })),
  passwordChangeWithToken: shape.merge(Map({ dataByIdentifier: Map({}) })),
  requestPasswordRecovery: shape.merge(Map({ dataByIdentifier: Map({}) })),
  requestResendEmailConfirmation: shape.merge(
    Map({ dataByIdentifier: Map({}) })
  ),
  signUp: shape.merge(Map({ dataByIdentifier: Map({}) })),
  stripeCardDetach: shape.merge(Map({ dataByIdentifier: Map({}) })),
  stripeCardsList: shape.merge(Map({ dataByIdentifier: Map({}) })),
  update: shape.merge(Map({ dataByIdentifier: Map({}) })),
  updateProfileTimezone: shape.merge(Map({ dataByIdentifier: Map({}) })),
  uploadProfilePicture: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case BILLING_DETAILS_GET_TRY:
    case BILLING_DETAILS_UPDATE_TRY:
    case CONFIRM_EMAIL_TRY:
    case GET_TRY:
    case GET_IF_USER_BOUGHT_PRODUCT_TRY:
    case GET_STATS_TRY:
    case GET_USER_DEALS_BOUGHT_COUNT_TRY:
    case LIST_AVAILABLE_INCENTIVES_TRY:
    case LIST_INVOICE_INCENTIVES_TRY:
    case PASSWORD_CHANGE_WITH_TOKEN_TRY:
    case REQUEST_PASSWORD_RECOVERY_TRY:
    case REQUEST_RESEND_EMAIL_CONFIRMATION_TRY:
    case SIGN_UP_TRY:
    case STRIPE_CARD_DETACH_TRY:
    case STRIPE_CARDS_LIST_TRY:
    case UPDATE_TRY:
    case UPDATE_PROFILE_TIMEZONE_TRY:
    case UPLOAD_PROFILE_PICTURE_TRY:
      return handleTry({ state, action });
    case BILLING_DETAILS_GET_SUCCESS:
    case BILLING_DETAILS_UPDATE_SUCCESS:
    case CONFIRM_EMAIL_SUCCESS:
    case GET_SUCCESS:
    case GET_IF_USER_BOUGHT_PRODUCT_SUCCESS:
    case GET_STATS_SUCCESS:
    case GET_USER_DEALS_BOUGHT_COUNT_SUCCESS:
    case LIST_AVAILABLE_INCENTIVES_SUCCESS:
    case LIST_INVOICE_INCENTIVES_SUCCESS:
    case PASSWORD_CHANGE_WITH_TOKEN_SUCCESS:
    case REQUEST_PASSWORD_RECOVERY_SUCCESS:
    case REQUEST_RESEND_EMAIL_CONFIRMATION_SUCCESS:
    case SIGN_UP_SUCCESS:
    case STRIPE_CARD_DETACH_SUCCESS:
    case STRIPE_CARDS_LIST_SUCCESS:
    case UPDATE_SUCCESS:
    case UPDATE_PROFILE_TIMEZONE_SUCCESS:
    case UPLOAD_PROFILE_PICTURE_SUCCESS:
      return handleSuccess({ state, action });
    case BILLING_DETAILS_GET_FAILED:
    case BILLING_DETAILS_UPDATE_FAILED:
    case CONFIRM_EMAIL_FAILED:
    case GET_FAILED:
    case GET_IF_USER_BOUGHT_PRODUCT_FAILED:
    case GET_STATS_FAILED:
    case GET_USER_DEALS_BOUGHT_COUNT_FAILED:
    case LIST_AVAILABLE_INCENTIVES_FAILED:
    case LIST_INVOICE_INCENTIVES_FAILED:
    case PASSWORD_CHANGE_WITH_TOKEN_FAILED:
    case REQUEST_PASSWORD_RECOVERY_FAILED:
    case REQUEST_RESEND_EMAIL_CONFIRMATION_FAILED:
    case SIGN_UP_FAILED:
    case STRIPE_CARD_DETACH_FAILED:
    case STRIPE_CARDS_LIST_FAILED:
    case UPDATE_FAILED:
    case UPDATE_PROFILE_TIMEZONE_FAILED:
    case UPLOAD_PROFILE_PICTURE_FAILED:
      return handleFailed({ state, action });
    case BILLING_DETAILS_GET_TRY_BY_IDENTIFIER:
    case BILLING_DETAILS_UPDATE_TRY_BY_IDENTIFIER:
    case CONFIRM_EMAIL_TRY_BY_IDENTIFIER:
    case GET_TRY_BY_IDENTIFIER:
    case GET_IF_USER_BOUGHT_PRODUCT_TRY_BY_IDENTIFIER:
    case GET_STATS_TRY_BY_IDENTIFIER:
    case GET_USER_DEALS_BOUGHT_COUNT_TRY_BY_IDENTIFIER:
    case LIST_AVAILABLE_INCENTIVES_TRY_BY_IDENTIFIER:
    case LIST_INVOICE_INCENTIVES_TRY_BY_IDENTIFIER:
    case PASSWORD_CHANGE_WITH_TOKEN_TRY_BY_IDENTIFIER:
    case REQUEST_PASSWORD_RECOVERY_TRY_BY_IDENTIFIER:
    case REQUEST_RESEND_EMAIL_CONFIRMATION_TRY_BY_IDENTIFIER:
    case SIGN_UP_TRY_BY_IDENTIFIER:
    case STRIPE_CARD_DETACH_TRY_BY_IDENTIFIER:
    case STRIPE_CARDS_LIST_TRY_BY_IDENTIFIER:
    case UPDATE_TRY_BY_IDENTIFIER:
    case UPDATE_PROFILE_TIMEZONE_TRY_BY_IDENTIFIER:
    case UPLOAD_PROFILE_PICTURE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case BILLING_DETAILS_GET_SUCCESS_BY_IDENTIFIER:
    case BILLING_DETAILS_UPDATE_SUCCESS_BY_IDENTIFIER:
    case CONFIRM_EMAIL_SUCCESS_BY_IDENTIFIER:
    case GET_SUCCESS_BY_IDENTIFIER:
    case GET_IF_USER_BOUGHT_PRODUCT_SUCCESS_BY_IDENTIFIER:
    case GET_STATS_SUCCESS_BY_IDENTIFIER:
    case GET_USER_DEALS_BOUGHT_COUNT_SUCCESS_BY_IDENTIFIER:
    case LIST_AVAILABLE_INCENTIVES_SUCCESS_BY_IDENTIFIER:
    case LIST_INVOICE_INCENTIVES_SUCCESS_BY_IDENTIFIER:
    case PASSWORD_CHANGE_WITH_TOKEN_SUCCESS_BY_IDENTIFIER:
    case REQUEST_PASSWORD_RECOVERY_SUCCESS_BY_IDENTIFIER:
    case REQUEST_RESEND_EMAIL_CONFIRMATION_SUCCESS_BY_IDENTIFIER:
    case SIGN_UP_SUCCESS_BY_IDENTIFIER:
    case STRIPE_CARD_DETACH_SUCCESS_BY_IDENTIFIER:
    case STRIPE_CARDS_LIST_SUCCESS_BY_IDENTIFIER:
    case UPDATE_SUCCESS_BY_IDENTIFIER:
    case UPDATE_PROFILE_TIMEZONE_SUCCESS_BY_IDENTIFIER:
    case UPLOAD_PROFILE_PICTURE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case BILLING_DETAILS_GET_FAILED_BY_IDENTIFIER:
    case BILLING_DETAILS_UPDATE_FAILED_BY_IDENTIFIER:
    case CONFIRM_EMAIL_FAILED_BY_IDENTIFIER:
    case GET_FAILED_BY_IDENTIFIER:
    case GET_IF_USER_BOUGHT_PRODUCT_FAILED_BY_IDENTIFIER:
    case GET_STATS_FAILED_BY_IDENTIFIER:
    case GET_USER_DEALS_BOUGHT_COUNT_FAILED_BY_IDENTIFIER:
    case LIST_AVAILABLE_INCENTIVES_FAILED_BY_IDENTIFIER:
    case LIST_INVOICE_INCENTIVES_FAILED_BY_IDENTIFIER:
    case PASSWORD_CHANGE_WITH_TOKEN_FAILED_BY_IDENTIFIER:
    case REQUEST_PASSWORD_RECOVERY_FAILED_BY_IDENTIFIER:
    case REQUEST_RESEND_EMAIL_CONFIRMATION_FAILED_BY_IDENTIFIER:
    case SIGN_UP_FAILED_BY_IDENTIFIER:
    case STRIPE_CARD_DETACH_FAILED_BY_IDENTIFIER:
    case STRIPE_CARDS_LIST_FAILED_BY_IDENTIFIER:
    case UPDATE_FAILED_BY_IDENTIFIER:
    case UPDATE_PROFILE_TIMEZONE_FAILED_BY_IDENTIFIER:
    case UPLOAD_PROFILE_PICTURE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  BILLING_DETAILS_GET_TRY,
  BILLING_DETAILS_GET_SUCCESS,
  BILLING_DETAILS_GET_FAILED,
  BILLING_DETAILS_GET_TRY_BY_IDENTIFIER,
  BILLING_DETAILS_GET_SUCCESS_BY_IDENTIFIER,
  BILLING_DETAILS_GET_FAILED_BY_IDENTIFIER,

  BILLING_DETAILS_UPDATE_TRY,
  BILLING_DETAILS_UPDATE_SUCCESS,
  BILLING_DETAILS_UPDATE_FAILED,
  BILLING_DETAILS_UPDATE_TRY_BY_IDENTIFIER,
  BILLING_DETAILS_UPDATE_SUCCESS_BY_IDENTIFIER,
  BILLING_DETAILS_UPDATE_FAILED_BY_IDENTIFIER,

  CONFIRM_EMAIL_TRY,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILED,
  CONFIRM_EMAIL_TRY_BY_IDENTIFIER,
  CONFIRM_EMAIL_SUCCESS_BY_IDENTIFIER,
  CONFIRM_EMAIL_FAILED_BY_IDENTIFIER,

  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  GET_TRY_BY_IDENTIFIER,
  GET_SUCCESS_BY_IDENTIFIER,
  GET_FAILED_BY_IDENTIFIER,

  GET_IF_USER_BOUGHT_PRODUCT_TRY,
  GET_IF_USER_BOUGHT_PRODUCT_SUCCESS,
  GET_IF_USER_BOUGHT_PRODUCT_FAILED,
  GET_IF_USER_BOUGHT_PRODUCT_TRY_BY_IDENTIFIER,
  GET_IF_USER_BOUGHT_PRODUCT_SUCCESS_BY_IDENTIFIER,
  GET_IF_USER_BOUGHT_PRODUCT_FAILED_BY_IDENTIFIER,

  GET_STATS_TRY,
  GET_STATS_SUCCESS,
  GET_STATS_FAILED,
  GET_STATS_TRY_BY_IDENTIFIER,
  GET_STATS_SUCCESS_BY_IDENTIFIER,
  GET_STATS_FAILED_BY_IDENTIFIER,

  GET_USER_DEALS_BOUGHT_COUNT_TRY,
  GET_USER_DEALS_BOUGHT_COUNT_SUCCESS,
  GET_USER_DEALS_BOUGHT_COUNT_FAILED,
  GET_USER_DEALS_BOUGHT_COUNT_TRY_BY_IDENTIFIER,
  GET_USER_DEALS_BOUGHT_COUNT_SUCCESS_BY_IDENTIFIER,
  GET_USER_DEALS_BOUGHT_COUNT_FAILED_BY_IDENTIFIER,

  LIST_AVAILABLE_INCENTIVES_TRY,
  LIST_AVAILABLE_INCENTIVES_SUCCESS,
  LIST_AVAILABLE_INCENTIVES_FAILED,
  LIST_AVAILABLE_INCENTIVES_TRY_BY_IDENTIFIER,
  LIST_AVAILABLE_INCENTIVES_SUCCESS_BY_IDENTIFIER,
  LIST_AVAILABLE_INCENTIVES_FAILED_BY_IDENTIFIER,

  LIST_INVOICE_INCENTIVES_TRY,
  LIST_INVOICE_INCENTIVES_SUCCESS,
  LIST_INVOICE_INCENTIVES_FAILED,
  LIST_INVOICE_INCENTIVES_TRY_BY_IDENTIFIER,
  LIST_INVOICE_INCENTIVES_SUCCESS_BY_IDENTIFIER,
  LIST_INVOICE_INCENTIVES_FAILED_BY_IDENTIFIER,

  PASSWORD_CHANGE_WITH_TOKEN_TRY,
  PASSWORD_CHANGE_WITH_TOKEN_SUCCESS,
  PASSWORD_CHANGE_WITH_TOKEN_FAILED,
  PASSWORD_CHANGE_WITH_TOKEN_TRY_BY_IDENTIFIER,
  PASSWORD_CHANGE_WITH_TOKEN_SUCCESS_BY_IDENTIFIER,
  PASSWORD_CHANGE_WITH_TOKEN_FAILED_BY_IDENTIFIER,

  REQUEST_PASSWORD_RECOVERY_TRY,
  REQUEST_PASSWORD_RECOVERY_SUCCESS,
  REQUEST_PASSWORD_RECOVERY_FAILED,
  REQUEST_PASSWORD_RECOVERY_TRY_BY_IDENTIFIER,
  REQUEST_PASSWORD_RECOVERY_SUCCESS_BY_IDENTIFIER,
  REQUEST_PASSWORD_RECOVERY_FAILED_BY_IDENTIFIER,

  REQUEST_RESEND_EMAIL_CONFIRMATION_TRY,
  REQUEST_RESEND_EMAIL_CONFIRMATION_SUCCESS,
  REQUEST_RESEND_EMAIL_CONFIRMATION_FAILED,
  REQUEST_RESEND_EMAIL_CONFIRMATION_TRY_BY_IDENTIFIER,
  REQUEST_RESEND_EMAIL_CONFIRMATION_SUCCESS_BY_IDENTIFIER,
  REQUEST_RESEND_EMAIL_CONFIRMATION_FAILED_BY_IDENTIFIER,

  SIGN_UP_TRY,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILED,
  SIGN_UP_TRY_BY_IDENTIFIER,
  SIGN_UP_SUCCESS_BY_IDENTIFIER,
  SIGN_UP_FAILED_BY_IDENTIFIER,

  STRIPE_CARD_DETACH_TRY,
  STRIPE_CARD_DETACH_SUCCESS,
  STRIPE_CARD_DETACH_FAILED,
  STRIPE_CARD_DETACH_TRY_BY_IDENTIFIER,
  STRIPE_CARD_DETACH_SUCCESS_BY_IDENTIFIER,
  STRIPE_CARD_DETACH_FAILED_BY_IDENTIFIER,

  STRIPE_CARDS_LIST_TRY,
  STRIPE_CARDS_LIST_SUCCESS,
  STRIPE_CARDS_LIST_FAILED,
  STRIPE_CARDS_LIST_TRY_BY_IDENTIFIER,
  STRIPE_CARDS_LIST_SUCCESS_BY_IDENTIFIER,
  STRIPE_CARDS_LIST_FAILED_BY_IDENTIFIER,

  UPDATE_TRY,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  UPDATE_TRY_BY_IDENTIFIER,
  UPDATE_SUCCESS_BY_IDENTIFIER,
  UPDATE_FAILED_BY_IDENTIFIER,

  UPDATE_PROFILE_TIMEZONE_TRY,
  UPDATE_PROFILE_TIMEZONE_SUCCESS,
  UPDATE_PROFILE_TIMEZONE_FAILED,
  UPDATE_PROFILE_TIMEZONE_TRY_BY_IDENTIFIER,
  UPDATE_PROFILE_TIMEZONE_SUCCESS_BY_IDENTIFIER,
  UPDATE_PROFILE_TIMEZONE_FAILED_BY_IDENTIFIER,

  UPLOAD_PROFILE_PICTURE_TRY,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  UPLOAD_PROFILE_PICTURE_FAILED,
  UPLOAD_PROFILE_PICTURE_TRY_BY_IDENTIFIER,
  UPLOAD_PROFILE_PICTURE_SUCCESS_BY_IDENTIFIER,
  UPLOAD_PROFILE_PICTURE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
