import call from "../../helpers/call";

function howMuchLeftToGetSplit({ authToken, carTotalAmountCents }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart/how-much-left-to-get-split`,
    payload: {
      carTotalAmountCents, // (Required) Must be positive integer or zero
    },
  });
}

export default howMuchLeftToGetSplit;
