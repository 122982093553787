import call from "../../helpers/call";

function getPaymentGatewayTransactionUrlAdmin({ authToken, invoiceId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/get-payment-gateway-transaction-url-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
    },
  });
}

export default getPaymentGatewayTransactionUrlAdmin;
