import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const GET_DETAILS_TRY = "coupon/getDetails/TRY";
const GET_DETAILS_SUCCESS = "coupon/getDetails/SUCCESS";
const GET_DETAILS_FAILED = "coupon/getDetails/FAILED";

const GET_DETAILS_TRY_BY_IDENTIFIER = "coupon/getDetails/TRY_BY_IDENTIFIER";
const GET_DETAILS_SUCCESS_BY_IDENTIFIER =
  "coupon/getDetails/SUCCESS_BY_IDENTIFIER";
const GET_DETAILS_FAILED_BY_IDENTIFIER =
  "coupon/getDetails/FAILED_BY_IDENTIFIER";

const GET_FOR_REDEEM_TRY = "coupon/getForRedeem/TRY";
const GET_FOR_REDEEM_SUCCESS = "coupon/getForRedeem/SUCCESS";
const GET_FOR_REDEEM_FAILED = "coupon/getForRedeem/FAILED";

const GET_FOR_REDEEM_TRY_BY_IDENTIFIER =
  "coupon/getForRedeem/TRY_BY_IDENTIFIER";
const GET_FOR_REDEEM_SUCCESS_BY_IDENTIFIER =
  "coupon/getForRedeem/SUCCESS_BY_IDENTIFIER";
const GET_FOR_REDEEM_FAILED_BY_IDENTIFIER =
  "coupon/getForRedeem/FAILED_BY_IDENTIFIER";

const MARK_AS_REDEEMED_TRY = "coupon/markAsRedeemed/TRY";
const MARK_AS_REDEEMED_SUCCESS = "coupon/markAsRedeemed/SUCCESS";
const MARK_AS_REDEEMED_FAILED = "coupon/markAsRedeemed/FAILED";

const MARK_AS_REDEEMED_TRY_BY_IDENTIFIER =
  "coupon/markAsRedeemed/TRY_BY_IDENTIFIER";
const MARK_AS_REDEEMED_SUCCESS_BY_IDENTIFIER =
  "coupon/markAsRedeemed/SUCCESS_BY_IDENTIFIER";
const MARK_AS_REDEEMED_FAILED_BY_IDENTIFIER =
  "coupon/markAsRedeemed/FAILED_BY_IDENTIFIER";

const initialState = Map({
  getDetails: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getForRedeem: shape.merge(Map({ dataByIdentifier: Map({}) })),
  markAsRedeemed: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DETAILS_TRY:
    case GET_FOR_REDEEM_TRY:
    case MARK_AS_REDEEMED_TRY:
      return handleTry({ state, action });
    case GET_DETAILS_SUCCESS:
    case GET_FOR_REDEEM_SUCCESS:
    case MARK_AS_REDEEMED_SUCCESS:
      return handleSuccess({ state, action });
    case GET_DETAILS_FAILED:
    case GET_FOR_REDEEM_FAILED:
    case MARK_AS_REDEEMED_FAILED:
      return handleFailed({ state, action });
    case GET_DETAILS_TRY_BY_IDENTIFIER:
    case GET_FOR_REDEEM_TRY_BY_IDENTIFIER:
    case MARK_AS_REDEEMED_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case GET_DETAILS_SUCCESS_BY_IDENTIFIER:
    case GET_FOR_REDEEM_SUCCESS_BY_IDENTIFIER:
    case MARK_AS_REDEEMED_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case GET_DETAILS_FAILED_BY_IDENTIFIER:
    case GET_FOR_REDEEM_FAILED_BY_IDENTIFIER:
    case MARK_AS_REDEEMED_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  GET_DETAILS_TRY,
  GET_DETAILS_SUCCESS,
  GET_DETAILS_FAILED,
  GET_DETAILS_TRY_BY_IDENTIFIER,
  GET_DETAILS_SUCCESS_BY_IDENTIFIER,
  GET_DETAILS_FAILED_BY_IDENTIFIER,

  GET_FOR_REDEEM_TRY,
  GET_FOR_REDEEM_SUCCESS,
  GET_FOR_REDEEM_FAILED,
  GET_FOR_REDEEM_TRY_BY_IDENTIFIER,
  GET_FOR_REDEEM_SUCCESS_BY_IDENTIFIER,
  GET_FOR_REDEEM_FAILED_BY_IDENTIFIER,

  MARK_AS_REDEEMED_TRY,
  MARK_AS_REDEEMED_SUCCESS,
  MARK_AS_REDEEMED_FAILED,
  MARK_AS_REDEEMED_TRY_BY_IDENTIFIER,
  MARK_AS_REDEEMED_SUCCESS_BY_IDENTIFIER,
  MARK_AS_REDEEMED_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
