import SidebarCartBtn from "jsx/Cart/Portal/SidebarCartBtn";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalSidebarCartBtn() {
  const portals = [];
  const btns = document.querySelectorAll(".sidebar-cart-btn");

  for (let i = 0; i < btns.length; i++) {
    const placeholder = btns[i];

    // if there was any placeholder content, e.g. loading state - clear it out
    placeholder.innerHTML = "";
    portals.push(ReactDOM.createPortal(<SidebarCartBtn />, placeholder));
  }

  return portals;
}
