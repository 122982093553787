import call from "../../helpers/call";

function getListForPayoutAdmin({ authToken, searchQuery }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate/get-list-for-payout-admin`,
    payload: {
      searchQuery, // (Optional) Any text
    },
  });
}

export default getListForPayoutAdmin;
