import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "stateManager/hooks/authFlow/jsx/AuthorizedRoute";
import HandleAuthFlows from "stateManager/hooks/authFlow/jsx/HandleAuthFlows";
import getCustomThemeStyles from "stateManager/utils/themeStyles";
import getStore from "../getStore";
import useGetThemeMode from "../utils/useGetThemeMode";
import GlobalMessages from "./GlobalMessages";

function getDefaultComponent({ componentName }) {
  return () => {
    return (
      <div
        style={{
          margin: 24,
          padding: 24,
          borderRadius: 12,
          background: "#FAFAFA",
          color: "#212121",
        }}
      >
        <h1 style={{ textAlign: "center", margin: "24px 0" }}>
          Make sure {componentName} is within components you passed into
          `initApp()`
        </h1>

        <div>
          <div>Sample:</div>
          <div
            style={{
              background: "#E0E0E0",
              borderRadius: 6,
              margin: "12px 0",
              padding: 12,
              fontFamily: "monospace",
              color: "#212121",
            }}
          >
            {`initApp({components: {${componentName}: () => <div>Hello from ${componentName}</div>}})`}
          </div>
        </div>
      </div>
    );
  };
}

export default function Root({ components, extraGlobalComponents, options }) {
  extraGlobalComponents = extraGlobalComponents || [];
  const { store, history } = getStore({
    options,
    doLogStateActionsToConsole: false,
    doSupportReduxDevtools: true,
    homepage: "/",
    maxActionLogsInReduxDevTool: 200,
  });

  const themeMode = useGetThemeMode();
  const theme = createTheme(getCustomThemeStyles({ themeMode }));

  if (document) {
    document.body.style.background = theme.palette.background.default;
    document.body.style.color = theme.palette.text.primary;
  }

  const requiredComponents = [
    "AuthActivateWithToken",
    "Cart",
    "AuthActivate",
    "SubscriptiontrackingAdd",
    "Wallet",
    "VendorBilling",
    "VendorDiscussions",
    "Home",
    "AuthLogout",
    "Purchases",
    "AuthPasswordChangeWithToken",
    "AuthLogin",
    "VendorAnalytics",
    "AuthSignUp",
    "SubscriptiontrackingDashboard",
    "Vendor",
    "VendorMyProductsSingle",
    "VendorNewProduct",
    "Invoices",
    "SubscriptiontrackingSubslist",
    "Profile",
    "Subscriptiontracking",
    "AuthRecover",
    "VendorMyProducts",
  ];
  const componentsMissing = [];
  requiredComponents.forEach((componentName) => {
    if (typeof components[componentName] === "undefined") {
      componentsMissing.push(componentName);
      components[componentName] = getDefaultComponent({ componentName });
    }
  });
  if (componentsMissing.length > 0 && options.doRenderRoutes === true) {
    console.error("Components missing in initApp(): ", componentsMissing);
  }

  let routes = null;
  if (options.doRenderRoutes === true) {
    const routesFromExtensions = [];
    if (options.extensions && options.extensions.length > 0) {
      options.extensions.forEach((extension) => {
        if (extension.routes && extension.routes.length > 0) {
          extension.routes.forEach((_route) => {
            routesFromExtensions.push(
              <Route
                key={_route.path}
                path={_route.path}
                exact
                component={_route.component ? () => _route.component : null}
              />
            );
          });
        }
      });
    }
    routes = (
      <Switch>
        <Route path={"/"} exact component={() => components.Home} />
        <Route
          path={"/auth/activate"}
          exact
          component={() => components.AuthActivate}
        />
        <Route
          path={"/auth/activate-with-token"}
          exact
          component={() => components.AuthActivateWithToken}
        />
        <Route
          path={"/auth/login"}
          exact
          component={() => components.AuthLogin}
        />
        <Route
          path={"/auth/logout"}
          exact
          component={() => components.AuthLogout}
        />
        <Route
          path={"/auth/password-change-with-token"}
          exact
          component={() => components.AuthPasswordChangeWithToken}
        />
        <Route
          path={"/auth/recover"}
          exact
          component={() => components.AuthRecover}
        />
        <Route
          path={"/auth/sign-up"}
          exact
          component={() => components.AuthSignUp}
        />
        <Route path={"/cart"} exact component={() => components.Cart} />
        <Route path={"/invoices"} exact component={() => components.Invoices} />
        <Route path={"/profile"} exact component={() => components.Profile} />
        <Route
          path={"/purchases"}
          exact
          component={() => components.Purchases}
        />
        <Route
          path={"/substracker"}
          exact
          component={() => components.Subscriptiontracking}
        />
        <Route
          path={"/substracker/add"}
          exact
          component={() => components.SubscriptiontrackingAdd}
        />
        <Route
          path={"/substracker/dashboard"}
          exact
          component={() => components.SubscriptiontrackingDashboard}
        />
        <Route
          path={"/substracker/subslist"}
          exact
          component={() => components.SubscriptiontrackingSubslist}
        />
        <Route path={"/vendor"} exact component={() => components.Vendor} />
        <Route
          path={"/vendor/analytics"}
          exact
          component={() => components.VendorAnalytics}
        />
        <Route
          path={"/vendor/billing"}
          exact
          component={() => components.VendorBilling}
        />
        <Route
          path={"/vendor/discussions"}
          exact
          component={() => components.VendorDiscussions}
        />
        <Route
          path={"/vendor/my-products"}
          exact
          component={() => components.VendorMyProducts}
        />
        <Route
          path={"/vendor/my-products/single"}
          exact
          component={() => components.VendorMyProductsSingle}
        />
        <Route
          path={"/vendor/new-product"}
          exact
          component={() => components.VendorNewProduct}
        />
        <Route path={"/wallet"} exact component={() => components.Wallet} />
        {routesFromExtensions}
      </Switch>
    );
  }

  return (
    <div>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <GlobalMessages />
            {extraGlobalComponents}
            {routes}
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </div>
  );
}
