import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DealCard from "./DealCard";

export default function FavoritesSidebar({ favoritesList, setDrawerOpen }) {
  const theme = useTheme();
  const isSmUp = useMediaQuery((_theme) => _theme.breakpoints.up("sm"));

  return (
    <>
      <Container sx={{ pb: theme.spacing(3) }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: theme.spacing(3),
          }}
        >
          <Typography variant="h2">Wishlist</Typography>

          <div style={{ flexGrow: 1 }}></div>

          {favoritesList?.size > 0 && isSmUp ? (
            <Button
              variant="outlined"
              sx={{ mr: theme.spacing(2) }}
              startIcon={<AddRoundedIcon />}
              size="small"
              href={`https://${process.env.REACT_APP_MAIN_DOMAIN}/deals`}
            >
              Add more products
            </Button>
          ) : null}

          <IconButton onClick={() => setDrawerOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <Divider sx={{ m: theme.spacing(3, 0) }} />

        {favoritesList?.size > 0 && (
          <Grid container spacing={3}>
            {favoritesList?.toJS()?.map((_product) => (
              <Grid
                item
                xs={12}
                sm={6}
                //   md={4}
                key={`${_product.productId}`}
              >
                <DealCard _product={_product} />
              </Grid>
            ))}
          </Grid>
        )}

        {favoritesList?.size === 0 && (
          <div style={{ marginTop: theme.spacing(15), textAlign: "center" }}>
            <Typography variant="h3">Your wishlist looks empty</Typography>

            <Typography sx={{ mt: theme.spacing(2) }}>
              We have a great collection of softwares that can help fuel your
              business growth. You might want to check some.
            </Typography>

            <Button
              variant="contained"
              //   size="small"
              endIcon={<ArrowForwardRoundedIcon />}
              sx={{ mt: theme.spacing(2) }}
              href={`https://${process.env.REACT_APP_MAIN_DOMAIN}/deals`}
            >
              Explore deals
            </Button>
          </div>
        )}
      </Container>
    </>
  );
}
