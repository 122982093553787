import call from "../../helpers/call";

function createAdmin({ authToken, productId, name, description }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/video-channel/create-admin`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      name, // (Required) Any string of 0-120 symbols in length.
      description, // (Required) Any text
    },
  });
}

export default createAdmin;
