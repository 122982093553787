import BeforeLoginBtns from "jsx/BeforeLoginBtns";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalBeforeLogin() {
  const portals = [];
  const places = document.querySelectorAll(".before-login");

  for (let i = 0; i < places.length; i++) {
    const place = places[i];
    // if there was any place content, e.g. loading state - clear it out
    place.innerHTML = "";
    portals.push(ReactDOM.createPortal(<BeforeLoginBtns />, place));
  }
  return portals;
}
