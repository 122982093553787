import call from "../../helpers/call";

function getAdmin({ authToken, walletId, ownerUserId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/wallet/get-admin`,
    payload: {
      walletId, // (Optional) String 10-30 in length and starting with "w-"
      ownerUserId, // (Optional) String 10-30 in length and starting with "u-"
    },
  });
}

export default getAdmin;
