import call from "../../helpers/call";

function isProductBuyer({ authToken, userId, productId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/is-product-buyer`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
      productId, // (Required) String 10-30 in length and starting with "p-"
    },
  });
}

export default isProductBuyer;
