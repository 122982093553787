import call from "../../helpers/call";

function listIncentive({ authToken, validUntilAtFrom, usedOnInvoiceId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/list-incentive`,
    payload: {
      validUntilAtFrom, // (Required) Timestamp value. Any positive integer.
      usedOnInvoiceId, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default listIncentive;
