import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import user from "../../reducers/user";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

const _get = require("lodash.get");
const moment = require("moment-timezone");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryUserGet({}) {
  const payload = {};

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.userGet(payload);
  } catch (e) {
    result = { error: { code: "#2FGA_tryUserGet", message: e.message } };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: user.actionTypes.GET_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    if (!_get(result, "data.timezone")) {
      yield put({
        type: user.actionTypes.UPDATE_PROFILE_TIMEZONE_TRY,
        data: { timezone: moment.tz.guess() },
      });
    }

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({ type: user.actionTypes.GET_FAILED, data: result.error });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
