import call from "../../helpers/call";

function tagRemove({ authToken, tagId, productId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/tag-remove`,
    payload: {
      tagId, // (Required) String 10-30 in length and starting with "tag-"
      productId, // (Required) String 10-30 in length and starting with "p-"
    },
  });
}

export default tagRemove;
