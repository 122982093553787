import getSdkEndpoint from "../../helpers/getSdkEndpoint";

function paypalClassicSuccess({ authToken, token, PayerID }) {
  let ___query = [];
  if (typeof authToken !== "undefined") {
    ___query.push(`authToken=${authToken}`);
  }

  if (typeof token !== "undefined") {
    ___query.push(`token=${token}`);
  }

  if (typeof payerId !== "undefined") {
    ___query.push(`PayerID=${PayerID}`);
  }

  if (___query.length > 0) {
    ___query = `?${___query.join("&")}`;
  } else {
    ___query = "";
  }

  return `${getSdkEndpoint()}/invoice/paypal-classic-success${___query}`;
}

export default paypalClassicSuccess;
