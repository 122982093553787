import useUserMetaGetLoadingState from "stateManager/hooks/userMeta/useUserMetaGetLoadingState";
import useUserMetaGetResult from "stateManager/hooks/userMeta/useUserMetaGetResult";

export default function useIsUserMetaGetLoaded({}, ___options) {
  const loadingState = useUserMetaGetLoadingState({}, ___options);
  const resultState = useUserMetaGetResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
