import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import analytics from "../../reducers/analytics";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryAnalyticsListProductSalesByIdentifier({ data }) {
  const payload = {
    productId: data.payload.productId,
    page: data.payload.page,
    perPage: data.payload.perPage,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.payload.fromAt !== "undefined") {
    payload.fromAt = data.payload.fromAt;
  }

  if (typeof data.payload.toAt !== "undefined") {
    payload.toAt = data.payload.toAt;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  const result = yield sdk.analyticsListProductSales(payload);

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: analytics.actionTypes.LIST_PRODUCT_SALES_SUCCESS_BY_IDENTIFIER,
      data: {
        ___identifier: data.___identifier,
        payload: { data: result.data, meta: result.meta },
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: analytics.actionTypes.LIST_PRODUCT_SALES_FAILED_BY_IDENTIFIER,
      data: { ___identifier: data.___identifier, payload: result.error },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
