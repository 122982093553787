import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useTheme } from "@mui/material";
import useUrl from "hook-use-url";
import useIsDialogOpen from "hooks/useIsDialogOpen";
import DialogDiscussionQuestion from "jsx/VendorDiscussions/DialogDiscussionQuestion";
import SingleDiscussion from "jsx/VendorDiscussions/SingleDiscussion";
import React from "react";
import useDiscussionListByProductLoadingState from "stateManager/hooks/discussion/useDiscussionListByProductLoadingState";
import useDiscussionListByProductResult from "stateManager/hooks/discussion/useDiscussionListByProductResult";
import useIsDiscussionListByProductLoaded from "stateManager/hooks/discussion/useIsDiscussionListByProductLoaded";
import useIsProductGetProductDetailsForAnalyticsLoaded from "stateManager/hooks/product/useIsProductGetProductDetailsForAnalyticsLoaded";
import useProductGetProductDetailsForAnalyticsLoadingState from "stateManager/hooks/product/useProductGetProductDetailsForAnalyticsLoadingState";
import useProductGetProductDetailsForAnalyticsResult from "stateManager/hooks/product/useProductGetProductDetailsForAnalyticsResult";
import useActions from "stateManager/hooks/useActions";
import useIsUserGetLoaded from "stateManager/hooks/user/useIsUserGetLoaded";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

export default function VendorDiscussionsPortal({ productId }) {
  const theme = useTheme();
  const url = useUrl();
  const actions = useActions();
  const [open, setOpen] = React.useState(false);
  // const productId = url.get({ variable: "product-id" });
  const perPage = parseInt(url.get({ variable: "perPage" })) || 5;
  const page = parseInt(url.get({ variable: "page" })) || 1;
  const [sortByValue, setSortByValue] = React.useState("newest-first");
  const [sortBy, setSortBy] = React.useState("createdAt");
  const [sortDirection, setSortDirection] = React.useState("desc");
  const user = useUserGetResult({});
  const isUserLoaded = useIsUserGetLoaded({});
  const isLoggedIn =
    isUserLoaded &&
    user &&
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";

  const isDialogOpen = useIsDialogOpen({
    dialog: "select-discussions-question",
  });
  const productDetails = useProductGetProductDetailsForAnalyticsResult({
    productId,
  });

  const productLoadingState =
    useProductGetProductDetailsForAnalyticsLoadingState({});
  const isProductLoaded = useIsProductGetProductDetailsForAnalyticsLoaded({});
  const isProductDetailsLoading =
    isProductLoaded === false || productLoadingState.get("isLoading");

  let loading = false;
  if (isProductDetailsLoading) {
    loading = true;
  }

  const discussions = useDiscussionListByProductResult({
    productId,
    page,
    perPage,
    sortBy,
    sortDirection,
  });
  const discussionsLoadingState = useDiscussionListByProductLoadingState({});
  const areDiscussionsLoaded = useIsDiscussionListByProductLoaded({});
  const isProductDiscussionsLoading =
    areDiscussionsLoaded === false || discussionsLoadingState.get("isLoading");

  if (isProductDiscussionsLoading) {
    loading = true;
  }

  let rows = [];
  let count = 0;
  let totalDiscussions = 0;
  if (areDiscussionsLoaded === true && discussions.get("wasSuccess") === true) {
    discussions
      .getIn(["result", "data"])
      .toJS()
      .forEach((discussion) => {
        if (discussion && discussion.discussionId) {
          totalDiscussions = discussions.getIn([
            "result",
            "meta",
            "pagination",
            "totalItems",
          ]);
          count = totalDiscussions ? Math.ceil(totalDiscussions / perPage) : 0;
          rows.push(
            <SingleDiscussion data={discussion} key={discussion.discussionId} />
          );
        }
      });
  }

  const handlePageChange = (event, value) => {
    url.multipleActions({
      removeArray: ["discussion-id", "reply-per-page", "reply-page"],
      setPairs: [
        {
          variable: "page",
          value: value,
        },
      ],
    });
  };

  const handleClickOpen = () => {
    if (isLoggedIn) setOpen(true);
    else {
      actions.globalMessages.addToQueue({
        message: "Need to login first",
      });
      openLoginScreenToContinue();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setSortByValue(e.target.value);
    if (e.target.value === "newest-first") {
      setSortBy("createdAt");
      setSortDirection("desc");
    }
    if (e.target.value === "oldest-first") {
      setSortBy("createdAt");
      setSortDirection("asc");
    }
    if (e.target.value === "most-helpful") {
      setSortBy("helpfulCount");
      setSortDirection("");
    }
    url.multipleActions({
      removeArray: ["discussion-id", "reply-per-page", "reply-page", "page"],
    });
  };

  return (
    <Box>
      {open && (
        <DialogDiscussionQuestion
          productId={productId}
          open={open}
          onClose={handleClose}
        />
      )}
      {loading ? (
        <div style={{ textAlign: "center", margin: theme.spacing(4, 0) }}>
          <CircularProgress color={"secondary"} />
        </div>
      ) : (
        <Box
          sx={{
            margin: { xs: theme.spacing(0, 0, 4), sm: theme.spacing(5, 0) },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box flexGrow={1}>
              <Typography variant={"h2"}>Discussions</Typography>
              <Typography variant={"body2"}>
                {totalDiscussions} Discussions
              </Typography>
            </Box>
            <Box
              sx={{
                mt: { xs: theme.spacing(2), sm: theme.spacing("auto", 0) },
              }}
            >
              {rows.length > 0 && (
                <TextField
                  id="outlined-select-sort"
                  select
                  label="Sort by"
                  value={sortByValue}
                  size={"small"}
                  sx={{ width: 200, mr: theme.spacing(1) }}
                  onChange={handleChange}
                >
                  <MenuItem value="newest-first">
                    <Typography variant={"body2"}>Newest First</Typography>
                  </MenuItem>
                  <MenuItem value="most-helpful">
                    <Typography variant={"body2"}>Most Helpful</Typography>
                  </MenuItem>
                  <MenuItem value="oldest-first">
                    <Typography variant={"body2"}>Oldest First</Typography>
                  </MenuItem>
                </TextField>
              )}
              <Button
                variant="contained"
                color="primary"
                size={"medium"}
                startIcon={<QuestionAnswerIcon />}
                onClick={handleClickOpen}
                sx={{ mt: { xs: theme.spacing(2), sm: 0 } }}
              >
                Ask a Question
              </Button>
            </Box>
          </Box>
          <Box sx={{ m: theme.spacing(5, 0) }}>
            {rows.length > 0 ? (
              <>
                {rows}
                {totalDiscussions > perPage && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      count={count}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <img
                    src={`https://app.${process.env.REACT_APP_MAIN_DOMAIN}/img/empty-discussion.svg`}
                  />
                </Box>
                <Typography sx={{ mt: theme.spacing(5) }}>
                  No Discussion Found. Be the first person to ask a question.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
