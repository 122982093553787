import call from "../../helpers/call";

function billingDetailsGet({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/billing-details-get`,
    payload: {},
  });
}

export default billingDetailsGet;
