import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import discountCoupon from "../../reducers/discountCoupon";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";
const _get = require("lodash.get");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryDiscountCouponApplyToInvoice({ data }) {
  const payload = {
    discountCouponCode: data.discountCouponCode,
    invoiceId: data.invoiceId,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.discountCouponApplyToInvoice(payload);
  } catch (e) {
    result = {
      error: {
        code: "#2FGA_tryDiscountCouponApplyToInvoice",
        message: e.message,
      },
    };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        message: "Coupon Applied",
      },
    });

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: discountCoupon.actionTypes.APPLY_TO_INVOICE_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    if (_get(result, "error.message") === "CAN_NOT_APPLY_COUPON_ON_1_PLUS_1") {
      yield put({
        type: globalMessages.actionTypes.ADD_TO_QUEUE,
        data: {
          message: "Coupons cannot be applied with 1+1 offer",
        },
      });
    } else {
      yield put({
        type: globalMessages.actionTypes.ADD_TO_QUEUE,
        data: {
          message:
            _get(result, "error.message").replaceAll("_", " ").toLowerCase() ||
            "Check your Coupon",
          queue: "bottomRight",
        },
      });
    }

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: discountCoupon.actionTypes.APPLY_TO_INVOICE_FAILED,
      data: result.error,
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
