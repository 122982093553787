import call from "../../helpers/call";

function listAdmin({
  authToken,
  isActive,
  isArchived,
  page,
  perPage,
  orderDirection,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription/list-admin`,
    payload: {
      isActive, // (Optional) Value is a boolean. True or false.
      isArchived, // (Optional) Value is a boolean. True or false.
      page, // (Optional) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any positive integer. Range: (0,1000]
      orderDirection, // (Optional) Valid values: desc, asc
    },
  });
}

export default listAdmin;
