import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const GET_TRY = "options/get/TRY";
const GET_SUCCESS = "options/get/SUCCESS";
const GET_FAILED = "options/get/FAILED";

const GET_TRY_BY_IDENTIFIER = "options/get/TRY_BY_IDENTIFIER";
const GET_SUCCESS_BY_IDENTIFIER = "options/get/SUCCESS_BY_IDENTIFIER";
const GET_FAILED_BY_IDENTIFIER = "options/get/FAILED_BY_IDENTIFIER";

const initialState = Map({
  get: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TRY:
      return handleTry({ state, action });
    case GET_SUCCESS:
      return handleSuccess({ state, action });
    case GET_FAILED:
      return handleFailed({ state, action });
    case GET_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case GET_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case GET_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  GET_TRY_BY_IDENTIFIER,
  GET_SUCCESS_BY_IDENTIFIER,
  GET_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
