import call from "../../helpers/call";

function makePaypalPayoutAdmin({ authToken, email, amountCents }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate/make-paypal-payout-admin`,
    payload: {
      email, // (Required) Valid email address
      amountCents, // (Required) Any positive integer. 0 is not valid.
    },
  });
}

export default makePaypalPayoutAdmin;
