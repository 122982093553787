import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import VerifiedIcon from "@mui/icons-material/Verified";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DialogDiscussionQuestion from "jsx/VendorDiscussions/DialogDiscussionQuestion";
import React from "react";
import Emoji from "react-emoji-render";
import useIsDiscussionReactionAddAfterError from "stateManager/hooks/discussion/useIsDiscussionReactionAddAfterError";
import useIsDiscussionReactionAddAfterSuccess from "stateManager/hooks/discussion/useIsDiscussionReactionAddAfterSuccess";
import useIsDiscussionReactionRemoveAfterError from "stateManager/hooks/discussion/useIsDiscussionReactionRemoveAfterError";
import useIsDiscussionReactionRemoveAfterSuccess from "stateManager/hooks/discussion/useIsDiscussionReactionRemoveAfterSuccess";
import useActions from "stateManager/hooks/useActions";
import useIsUserGetLoaded from "stateManager/hooks/user/useIsUserGetLoaded";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

const moment = require("moment");
const emojiList = [
  ":+1:",
  ":heart:",
  ":open_mouth:",
  ":hugging_face:",
  ":rolling_on_the_floor_laughing:",
  ":disappointed:",
  // ":rage:",
];

export const ReviewAuthor = ({
  author,
  isValidatedBuyer = false,
  isProductOwner = false,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: { xs: "start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Typography variant={"h6"}>{author}</Typography>
      {(isValidatedBuyer || isProductOwner) && (
        <Box
          sx={{
            ml: { xs: 0, sm: theme.spacing(1) },
            mb: { xs: theme.spacing(0.5), sm: 0 },
            color: isValidatedBuyer ? "#0B79D0" : "#4CAF50",
            fontWeight: 400,
            display: "flex",
            fontSize: 12,
            alignItems: "center",
            "& svg": {
              fontSize: 12,
              mr: theme.spacing(0.5),
            },
          }}
        >
          {isValidatedBuyer ? <VerifiedIcon /> : <VerifiedUserIcon />}
          <Typography variant="caption">
            {isValidatedBuyer ? "Verified Buyer" : "Product Owner"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default function SingleItem({ data, itemType, ...props }) {
  const actions = useActions();
  const theme = useTheme();
  const timeAgo = moment.unix(data.createdAt).fromNow();
  const user = useUserGetResult({});
  const isUserLoaded = useIsUserGetLoaded({});
  const isLoggedIn =
    isUserLoaded &&
    user &&
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";
  const loggedInUser =
    isUserLoaded &&
    user &&
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined" &&
    user.getIn(["result", "data", "userId"]);

  const isAuthor =
    isUserLoaded &&
    user &&
    user.getIn(["result", "data", "userId"]) === data.authorId
      ? true
      : false;

  const reactionAddAfterSuccess = useIsDiscussionReactionAddAfterSuccess({});
  const reactionAddAfterError = useIsDiscussionReactionAddAfterError({});
  const reactionRemoveAfterSuccess = useIsDiscussionReactionRemoveAfterSuccess(
    {}
  );
  const reactionRemoveAfterError = useIsDiscussionReactionRemoveAfterError({});

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentEmoji, setCurrentEmoji] = React.useState("");
  const [reactions, setReactions] = React.useState([]);

  React.useEffect(() => {
    if (data.meta.reactions) {
      const allReactions = [];
      for (const [key, value] of Object.entries(data.meta.reactions)) {
        const reaction = {
          emoji: key,
          authors: value.length > 0 ? value.map((each) => each.authorId) : [],
        };
        allReactions.push(reaction);
      }
      if (allReactions.length > 0) setReactions(allReactions);
    }
  }, []);

  const toggleEmojiOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEmojiClick = (emoji) => {
    if (isLoggedIn) {
      let forAdding = true;
      let emojiAuthors = [];
      if (reactions && reactions.length > 0) {
        reactions.forEach((reaction) => {
          if (reaction.emoji === emoji) {
            emojiAuthors = reaction.authors;
            if (reaction.authors && reaction.authors.length > 0) {
              const isEmojiAuthor = reaction.authors.includes(loggedInUser);
              emojiAuthors = reaction.authors.filter(
                (author) => author !== loggedInUser
              );
              if (isEmojiAuthor) forAdding = false;
            }
          }
        });
      }
      setCurrentEmoji(emoji);
      if (forAdding) {
        const reaction = {
          emoji,
          authors: [...emojiAuthors, loggedInUser],
        };
        setReactions([...reactions, reaction]);

        // add reaction action
        if (emojiList.includes(emoji)) {
          actions.discussion.reactionAdd({
            discussionId: data.discussionId,
            reaction: emoji,
          });
        }
      } else {
        const reaction = {
          emoji,
          authors: emojiAuthors,
        };
        if (emojiAuthors.length > 0) setReactions([...reactions, reaction]);
        else {
          const filteredArr = reactions.filter((each) => each.emoji !== emoji);
          setReactions(filteredArr);
        }

        // remove reaction action
        if (emojiList.includes(emoji)) {
          actions.discussion.reactionRemove({
            discussionId: data.discussionId,
            reaction: emoji,
          });
        }
      }
      handleEmojiClose();
    } else {
      actions.globalMessages.addToQueue({
        message: "Need to login first",
      });
      openLoginScreenToContinue();
    }
  };

  React.useEffect(() => {
    if (reactionAddAfterSuccess || reactionRemoveAfterSuccess)
      setCurrentEmoji("");
  }, [reactionAddAfterSuccess, reactionRemoveAfterSuccess]);

  React.useEffect(() => {
    if (reactionAddAfterError) {
      let emojiAuthors = [];
      if (currentEmoji && reactions && reactions.length > 0) {
        const reaction = reactions.filter(
          (each) => each.emoji === currentEmoji
        );
        if (reaction.length > 0 && reaction[0].authors.length > 0) {
          emojiAuthors = reaction[0].authors.filter(
            (author) => author !== loggedInUser
          );
        }
        if (emojiAuthors.length > 0) {
          const reactionObj = {
            emoji: currentEmoji,
            authors: emojiAuthors,
          };
          setReactions([...reactions, reactionObj]);
        } else {
          const filteredArr = reactions.filter(
            (each) => each.emoji !== currentEmoji
          );
          setReactions(filteredArr);
        }
        setCurrentEmoji("");
      }
    }
  }, [reactionAddAfterError]);

  React.useEffect(() => {
    if (reactionRemoveAfterError) {
      let emojiAuthors = [];
      if (currentEmoji) {
        if (reactions && reactions.length > 0) {
          const reaction = reactions.filter(
            (each) => each.emoji === currentEmoji
          );
          if (reaction.length > 0) {
            emojiAuthors = reaction[0].authors;
          }
        }

        const reactionObj = {
          emoji: currentEmoji,
          authors: [...emojiAuthors, loggedInUser],
        };
        setReactions([...reactions, reactionObj]);
        setCurrentEmoji("");
      }
    }
  }, [reactionRemoveAfterError]);

  const handleEmojiClose = () => {
    setAnchorEl(null);
  };
  const openEmoji = Boolean(anchorEl);
  const emojiId = openEmoji ? `${data.discussionId}-popover` : undefined;

  const handleHelpfulClick = () => {
    if (isLoggedIn) {
      actions.discussion.markHelpful({
        discussionId: data.discussionId,
      });
    } else {
      actions.globalMessages.addToQueue({
        message: "Need to login first",
      });
      openLoginScreenToContinue();
    }
  };

  const handleEditClick = () => {
    if (itemType === "question") setOpen(true);
    else {
      props.onEditClick({
        comment: data.comment,
        discussionId: data.discussionId,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && (
        <DialogDiscussionQuestion
          open={open}
          onClose={handleClose}
          productId={data.productId}
          discussionId={data.discussionId}
          comment={data.comment}
          createdAt={data.createdAt}
        />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          mb: theme.spacing(1),
        }}
      >
        {itemType !== "question" && (
          <Avatar
            alt={data.authorName}
            src={data.author && data.author.avatar_url}
            sx={{
              width: 52,
              height: 52,
              bgcolor: data.isValidatedBuyer ? "#0B79D0" : "#4CAF50",
            }}
          >
            {data.authorName.match(/\b\w/g).join("").slice(0, 2).toUpperCase()}
          </Avatar>
        )}

        <Box>
          <Box
            sx={{
              paddingLeft: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            <ReviewAuthor
              author={data.authorName}
              isValidatedBuyer={data.isValidatedBuyer}
              isProductOwner={data.isProductOwner}
            />
            <Typography
              variant={"caption"}
              color={"text.secondary"}
              component={"div"}
            >
              {timeAgo}
            </Typography>
          </Box>

          <Box sx={{ ml: theme.spacing(1), mb: theme.spacing(1) }}>
            <Box sx={{ mb: theme.spacing(1) }}>
              <Typography variant="body0">{data.comment}</Typography>
            </Box>
            {!isAuthor && (
              <Button
                sx={{ textTransform: "none", mr: theme.spacing(2) }}
                variant={"outlined"}
                startIcon={<ThumbUpIcon />}
                onClick={handleHelpfulClick}
                size={"small"}
              >
                Helpful {data.helpfulCount > 0 && `(${data.helpfulCount})`}
              </Button>
            )}

            <IconButton onClick={toggleEmojiOpen} size={"small"}>
              <InsertEmoticonIcon />
            </IconButton>
            {reactions &&
              reactions.length > 0 &&
              reactions.map((each) => {
                if (each.authors && each.authors.length > 0) {
                  const isEmojiAuthor = each.authors.includes(loggedInUser);
                  return (
                    <Chip
                      icon={<Emoji text={each.emoji} />}
                      label={each.authors.length}
                      key={`list-${each.emoji}`}
                      variant={isEmojiAuthor ? "" : "outlined"}
                      onClick={() => handleEmojiClick(each.emoji)}
                      sx={{
                        p: 0,
                        borderRadius: theme.spacing(2),
                        ml: theme.spacing(1),
                      }}
                    />
                  );
                }
              })}

            {openEmoji && (
              <Box sx={{ position: "absolute", zIndex: 10 }}>
                <Popover
                  id={emojiId}
                  open={openEmoji}
                  anchorEl={anchorEl}
                  onClose={handleEmojiClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box sx={{ padding: theme.spacing(1) }}>
                    {emojiList.map((each) => (
                      <Emoji
                        key={`open-${each}`}
                        text={each}
                        style={{
                          cursor: "pointer",
                          padding: theme.spacing(0.5),
                        }}
                        onClick={() => handleEmojiClick(each)}
                      />
                    ))}
                  </Box>
                </Popover>
              </Box>
            )}
            {isAuthor && data.createdAt > moment().subtract(1, "hour").unix() && (
              <Button
                sx={{ textTransform: "none", ml: theme.spacing(1) }}
                startIcon={<EditIcon />}
                onClick={handleEditClick}
                size={"small"}
              >
                Edit {itemType === "question" ? "Question" : "Reply"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
