import call from "../../helpers/call";

function getStripePayIntent({ authToken, invoiceId, stripePaymentMethodId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/get-stripe-pay-intent`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      stripePaymentMethodId, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default getStripePayIntent;
