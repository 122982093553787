import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

export default function UnderCart() {
  const theme = useTheme();

  return (
    <div>
      <Typography
        variant={"h5"}
        style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(3) }}
      >
        Why PitchGround?
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ minWidth: 70 }}>
          <img width={54} height={54} src={"/img/refund-icon.svg"} />
        </div>
        <div style={{ flex: 1 }}>
          <Typography variant={"subtitle2"}>
            60 Day Money Back Guarantee
          </Typography>
          <Typography variant={"body2"}>
            If you are not happy for any reason, PitchGround offers 60 days
            instant money back guarantee.
          </Typography>
        </div>
      </div>
      <div
        style={{
          margin: theme.spacing(2, 0),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ minWidth: 70 }}>
          <img width={54} height={54} src={"/img/customers.svg"} />
        </div>
        <div style={{ flex: 1 }}>
          <Typography variant={"subtitle2"}>
            Over 30,000+ Happy Customers{" "}
          </Typography>
          <Typography variant={"body2"}>
            Customer success is everything for us. Hence, most of our customers
            trust PitchGround and re-purchase with us every month.{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
}
