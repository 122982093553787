import call from "../../helpers/call";

function listRepliesForDiscussion({
  authToken,
  parentDiscussionId,
  page,
  perPage,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discussion/list-replies-for-discussion`,
    payload: {
      parentDiscussionId, // (Required) String 10-30 in length and starting with "d-"
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any of: 5,10,15,20,25,30,35,40,45,50,100
    },
  });
}

export default listRepliesForDiscussion;
