import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import useKlavyioEmailMeBeforeDealEndsLoadingState from "stateManager/hooks/klavyio/useKlavyioEmailMeBeforeDealEndsLoadingState";
import useActions from "stateManager/hooks/useActions";
import useIsUserGetLoaded from "stateManager/hooks/user/useIsUserGetLoaded";

export default function EmailMeBeforeDealEnds({ productId }) {
  const actions = useActions();
  const isUserLoaded = useIsUserGetLoaded({});
  const klavyioLoadingState = useKlavyioEmailMeBeforeDealEndsLoadingState({});

  // track product views for marketing
  // useProductGetViewTrackResult({
  //
  // });

  const isLoading =
    isUserLoaded === false || klavyioLoadingState.get("isLoading");

  if (isLoading) {
    return <CircularProgress size={12} color={"secondary"} />;
  }

  return (
    <Button
      size="small"
      color="inherit"
      startIcon={<AddAlertIcon size="small" />}
      sx={{ textTransform: "none" }}
      disabled={isLoading}
      onClick={() => {
        actions.klavyio.emailMeBeforeDealEnds({ productId });
      }}
    >
      Email Me Before Deal Ends
    </Button>
  );
}
