import Typography from "@mui/material/Typography";

export default function CartSplit({ cart }) {
  const howMuchLeftToGet = cart.getIn([
    "result",
    "data",
    "amountHowMuchLeftToGetSplitCents",
  ]);

  if (howMuchLeftToGet === null || typeof howMuchLeftToGet === "undefined") {
    return null;
  }

  if (howMuchLeftToGet <= 0) {
    return (
      <Typography variant={"h5"} color={"primary"}>
        Split pay option for this cart will be available during the payment.
      </Typography>
    );
  }

  return (
    <Typography variant={"h5"} color={"primary"}>
      {`Add $${
        howMuchLeftToGet / 100
      } more worth deals to get split payment option during the payment.`}
    </Typography>
  );
}
