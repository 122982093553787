import { useEffect } from "react";
import { useState } from "react";

export default function useGetThemeMode() {
  const initialMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches === true
      ? "dark"
      : "light";

  const [themeMode, setThemeMode] = useState(initialMode);

  useEffect(() => {
    const interval = setInterval(() => {
      const newMode =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches === true
          ? "dark"
          : "light";
      if (themeMode !== newMode) {
        setThemeMode(newMode);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [themeMode]);

  return themeMode;
}
