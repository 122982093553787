import call from "../../helpers/call";

function setUpdatedAtAdmin({ authToken, invoiceId, timestamp }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/coupons/set-updated-at-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      timestamp, // (Required) Timestamp value. Any positive integer.
    },
  });
}

export default setUpdatedAtAdmin;
