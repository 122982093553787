import useCartV2GetLoadingState from "stateManager/hooks/cart/useCartV2GetLoadingState";
import useCartV2GetResult from "stateManager/hooks/cart/useCartV2GetResult";

export default function useIsCartV2GetLoaded({}, ___options) {
  const loadingState = useCartV2GetLoadingState({}, ___options);
  const resultState = useCartV2GetResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
