import call from "../../helpers/call";

function getAdmin({ authToken, userId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart/get-admin`,
    payload: {
      userId, // (Required) String 10-30 in length and starting with "u-"
    },
  });
}

export default getAdmin;
