import call from "../../helpers/call";

function listMine({ authToken, state, searchQuery, page, perPage }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/list-mine`,
    payload: {
      state, // (Optional) One of: paid, pending, refunded, partlyPaid, partlyRefunded, overpaid
      searchQuery, // (Optional) Any string of 0-120 symbols in length.
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any of: 5,10,15,20,25,30,35,40,45,50,100
    },
  });
}

export default listMine;
