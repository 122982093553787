import call from "../../helpers/call";

function setPaypalEmail({ authToken, paypalEmail }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/set-paypal-email`,
    payload: {
      paypalEmail, // (Required) Valid email address
    },
  });
}

export default setPaypalEmail;
