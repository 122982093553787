import call from "../../helpers/call";

function listAdmin({ authToken, searchQuery, page, perPage, orderDirection }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/list-admin`,
    payload: {
      searchQuery, // (Optional) Any text
      page, // (Optional) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any positive integer. Range: (0,1000]
      orderDirection, // (Optional) Valid values: desc, asc
    },
  });
}

export default listAdmin;
