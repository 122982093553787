import call from "../../helpers/call";

function reject({ authToken, productSubmittedId, rejectReason }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/submit-product/reject`,
    payload: {
      productSubmittedId, // (Required) String 10-30 in length and starting with "ps-"
      rejectReason, // (Required) Any text
    },
  });
}

export default reject;
