import call from "../../helpers/call";

function exportCsv({ authToken, startAt, endAt }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription-tracking/export-csv`,
    payload: {
      startAt, // (Required) Timestamp value. Any positive integer.
      endAt, // (Required) Timestamp value. Any positive integer.
    },
  });
}

export default exportCsv;
