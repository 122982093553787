import call from "../../helpers/call";

function stripeSplitPayIntent({
  authToken,
  invoiceId,
  stripePaymentMethodId,
  numberOfSplitParts,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/stripe-split-pay-intent`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      stripePaymentMethodId, // (Optional) Any string of 0-120 symbols in length.
      numberOfSplitParts, // (Required) Is integer equal to 1 or more
    },
  });
}

export default stripeSplitPayIntent;
