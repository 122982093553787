import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useProductGetViewTrackLoadingState from "../product/useProductGetViewTrackLoadingState";

// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/product/tryProductGetViewTrack";

export default function useProductGetViewTrackResult(
  { productSlug, at, lastViewedUrl },
  ___options
) {
  const ___product = useSelector((___state) => ___state.product);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const productGetViewTrackLoadingState = useProductGetViewTrackLoadingState(
    {},
    ___options
  );
  const ___isLoading = productGetViewTrackLoadingState.get("isLoading");

  useEffect(() => {
    if (___isLoading === false && productSlug && at) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.product.getViewTrack({
          productSlug,
          at,
          lastViewedUrl,
        });
      } else {
        ___actions.product.getViewTrack({ productSlug, at, lastViewedUrl });
      }
    }
  }, [productSlug, lastViewedUrl]);

  if (___options && ___options.identifier) {
    return (
      ___product.getIn([
        "getViewTrack",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___product.getIn(["getViewTrack", "lastResult"]);
}
