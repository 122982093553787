import React from "react";
import GlobalMessage from "./GlobalMessage";

export default function GlobalMessages() {
  return (
    <>
      <GlobalMessage queue={"topCenter"} />
      <GlobalMessage queue={"topRight"} />
      <GlobalMessage queue={"bottomRight"} />
      <GlobalMessage queue={"bottomCenter"} />
      <GlobalMessage queue={"bottomLeft"} />
      <GlobalMessage queue={"topLeft"} />
    </>
  );
}
