import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const EMAIL_ME_BEFORE_DEAL_ENDS_TRY = "klavyio/emailMeBeforeDealEnds/TRY";
const EMAIL_ME_BEFORE_DEAL_ENDS_SUCCESS =
  "klavyio/emailMeBeforeDealEnds/SUCCESS";
const EMAIL_ME_BEFORE_DEAL_ENDS_FAILED = "klavyio/emailMeBeforeDealEnds/FAILED";

const EMAIL_ME_BEFORE_DEAL_ENDS_TRY_BY_IDENTIFIER =
  "klavyio/emailMeBeforeDealEnds/TRY_BY_IDENTIFIER";
const EMAIL_ME_BEFORE_DEAL_ENDS_SUCCESS_BY_IDENTIFIER =
  "klavyio/emailMeBeforeDealEnds/SUCCESS_BY_IDENTIFIER";
const EMAIL_ME_BEFORE_DEAL_ENDS_FAILED_BY_IDENTIFIER =
  "klavyio/emailMeBeforeDealEnds/FAILED_BY_IDENTIFIER";

const SUBSCRIBE_NEWSLETTER_TRY = "klavyio/subscribeNewsletter/TRY";
const SUBSCRIBE_NEWSLETTER_SUCCESS = "klavyio/subscribeNewsletter/SUCCESS";
const SUBSCRIBE_NEWSLETTER_FAILED = "klavyio/subscribeNewsletter/FAILED";

const SUBSCRIBE_NEWSLETTER_TRY_BY_IDENTIFIER =
  "klavyio/subscribeNewsletter/TRY_BY_IDENTIFIER";
const SUBSCRIBE_NEWSLETTER_SUCCESS_BY_IDENTIFIER =
  "klavyio/subscribeNewsletter/SUCCESS_BY_IDENTIFIER";
const SUBSCRIBE_NEWSLETTER_FAILED_BY_IDENTIFIER =
  "klavyio/subscribeNewsletter/FAILED_BY_IDENTIFIER";

const initialState = Map({
  emailMeBeforeDealEnds: shape.merge(Map({ dataByIdentifier: Map({}) })),
  subscribeNewsletter: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EMAIL_ME_BEFORE_DEAL_ENDS_TRY:
    case SUBSCRIBE_NEWSLETTER_TRY:
      return handleTry({ state, action });
    case EMAIL_ME_BEFORE_DEAL_ENDS_SUCCESS:
    case SUBSCRIBE_NEWSLETTER_SUCCESS:
      return handleSuccess({ state, action });
    case EMAIL_ME_BEFORE_DEAL_ENDS_FAILED:
    case SUBSCRIBE_NEWSLETTER_FAILED:
      return handleFailed({ state, action });
    case EMAIL_ME_BEFORE_DEAL_ENDS_TRY_BY_IDENTIFIER:
    case SUBSCRIBE_NEWSLETTER_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case EMAIL_ME_BEFORE_DEAL_ENDS_SUCCESS_BY_IDENTIFIER:
    case SUBSCRIBE_NEWSLETTER_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case EMAIL_ME_BEFORE_DEAL_ENDS_FAILED_BY_IDENTIFIER:
    case SUBSCRIBE_NEWSLETTER_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  EMAIL_ME_BEFORE_DEAL_ENDS_TRY,
  EMAIL_ME_BEFORE_DEAL_ENDS_SUCCESS,
  EMAIL_ME_BEFORE_DEAL_ENDS_FAILED,
  EMAIL_ME_BEFORE_DEAL_ENDS_TRY_BY_IDENTIFIER,
  EMAIL_ME_BEFORE_DEAL_ENDS_SUCCESS_BY_IDENTIFIER,
  EMAIL_ME_BEFORE_DEAL_ENDS_FAILED_BY_IDENTIFIER,

  SUBSCRIBE_NEWSLETTER_TRY,
  SUBSCRIBE_NEWSLETTER_SUCCESS,
  SUBSCRIBE_NEWSLETTER_FAILED,
  SUBSCRIBE_NEWSLETTER_TRY_BY_IDENTIFIER,
  SUBSCRIBE_NEWSLETTER_SUCCESS_BY_IDENTIFIER,
  SUBSCRIBE_NEWSLETTER_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
