import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import BillingDetailsFormFields from "jsx/BillingDetailsFormFields";
import propTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import useCartListMyItemsResult from "stateManager/hooks/cart/useCartListMyItemsResult";
import useIsCartListMyItemsLoaded from "stateManager/hooks/cart/useIsCartListMyItemsLoaded";
import useStripeSubscriptionListResult from "stateManager/hooks/stripeSubscription/useStripeSubscriptionListResult";
import useActions from "stateManager/hooks/useActions";
import useIsUserBillingDetailsGetLoaded from "stateManager/hooks/user/useIsUserBillingDetailsGetLoaded";
import useUserBillingDetailsGetResult from "stateManager/hooks/user/useUserBillingDetailsGetResult";
import useUserBillingDetailsUpdateLoadingState from "stateManager/hooks/user/useUserBillingDetailsUpdateLoadingState";

export default function BillingDetailsForm({
  buttonTitle,
  buttonProps,
  onBeforeSubmit,
}) {
  buttonProps = buttonProps || {};
  const theme = useTheme();
  const actions = useActions();
  const [userBillingDetailsId, setUserBillingDetailsId] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [postCode, setPostCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [vat, setVat] = useState(null);

  const subscriptionResult = useStripeSubscriptionListResult({});
  const stripeResult = subscriptionResult.getIn([
    "result",
    "data",
    "stripeResult",
  ])
    ? subscriptionResult.getIn(["result", "data", "stripeResult"]).toJS()
    : null;
  let doesNotHaveVipYet = !stripeResult || !stripeResult[0];

  const cartItems = useCartListMyItemsResult({});
  const isCartLoaded = useIsCartListMyItemsLoaded({});

  const billingDetailsResult = useUserBillingDetailsGetResult({});
  const isBillingDetailsGetLoaded = useIsUserBillingDetailsGetLoaded({});
  const updateLoadState = useUserBillingDetailsUpdateLoadingState({});

  useEffect(() => {
    if (billingDetailsResult.getIn(["result", "data", "userId"])) {
      setUserBillingDetailsId(
        billingDetailsResult.getIn(["result", "data", "userBillingDetailsId"])
      );
      setName(billingDetailsResult.getIn(["result", "data", "name"]));
      setEmail(billingDetailsResult.getIn(["result", "data", "email"]));
      setAddress(billingDetailsResult.getIn(["result", "data", "address"]));
      setCity(billingDetailsResult.getIn(["result", "data", "city"]));
      setState(billingDetailsResult.getIn(["result", "data", "state"]));
      setPostCode(billingDetailsResult.getIn(["result", "data", "postcode"]));
      setCountry(billingDetailsResult.getIn(["result", "data", "country"]));
      setVat(billingDetailsResult.getIn(["result", "data", "vat"]));
    }
  }, [billingDetailsResult]);

  const isLoading =
    isCartLoaded === false ||
    (isBillingDetailsGetLoaded === false &&
      updateLoadState.get("isLoading") === true) ||
    userBillingDetailsId === null;

  let isVipInCart = false;
  let doDisableBecauseCanNotBuyVipWithOtherStuff = false;
  let isVipQuantityMoreThen1 = false;

  if (cartItems && cartItems.getIn(["result", "data", 0])) {
    cartItems.getIn(["result", "data"]).forEach((_cartItem) => {
      if (_cartItem.get("productName").indexOf("PG VIP") !== -1) {
        isVipInCart = true;
        if (_cartItem.get("quantity") > 1) {
          isVipQuantityMoreThen1 = true;
        }
      }
      if (_cartItem.get("productPlanName").indexOf("PG VIP") !== -1) {
        isVipInCart = true;
        if (_cartItem.get("quantity") > 1) {
          isVipQuantityMoreThen1 = true;
        }
      }
    });
  }

  if (isVipInCart && doesNotHaveVipYet === false) {
    doDisableBecauseCanNotBuyVipWithOtherStuff = true;
  }

  if (isVipInCart && cartItems.getIn(["result", "data", 1])) {
    doDisableBecauseCanNotBuyVipWithOtherStuff = true;
  }

  const submit = () => {
    if (doDisableBecauseCanNotBuyVipWithOtherStuff) {
      return;
    }
    if (typeof onBeforeSubmit === "function")
      onBeforeSubmit({
        name,
        email,
        address,
        city,
        state,
        postcode: postCode,
        country,
        vat,
      });
    actions.user.billingDetailsUpdate({
      userBillingDetailsId,
      name,
      email,
      address,
      city,
      state,
      postcode: postCode,
      country,
      vat,
    });
  };

  return (
    <div>
      {doDisableBecauseCanNotBuyVipWithOtherStuff ? (
        <div
          style={{
            padding: "24px 12px",
            textAlign: "center",
            background: "#FFE0B2",
            color: "#212121",
            fontSize: "18px",
          }}
        >
          {doesNotHaveVipYet === false ? (
            "You already have VIP subscription"
          ) : (
            <>
              You need to buy VIP subscription separately. Remove all items from
              cart, just leave VIP subscription.
              <div style={{ marginTop: "12px" }}>
                After buying VIP, all your next invoices will have VIP benefits.
              </div>
              <div>
                {isVipQuantityMoreThen1
                  ? "Can not buy more then 1 VIP subscription, check quantity on cart"
                  : null}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          {isLoading === false ? (
            <>
              <BillingDetailsFormFields
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                address={address}
                setAddress={setAddress}
                city={city}
                setCity={setCity}
                state={state}
                setState={setState}
                postCode={postCode}
                setPostCode={setPostCode}
                country={country}
                setCountry={setCountry}
                vat={vat}
                setVat={setVat}
                onSubmit={submit}
              />
              <div style={{ margin: theme.spacing(2, 0) }}>
                <Button
                  disabled={doDisableBecauseCanNotBuyVipWithOtherStuff}
                  onClick={submit}
                  variant={"contained"}
                  {...buttonProps}
                >
                  {buttonTitle}
                </Button>
              </div>
            </>
          ) : (
            <div style={{ margin: theme.spacing(4, 0), textAlign: "center" }}>
              <CircularProgress color={"secondary"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
BillingDetailsForm.propTypes = {
  buttonTitle: propTypes.string.isRequired,
  buttonProps: propTypes.object,
  onBeforeSubmit: propTypes.func,
};
