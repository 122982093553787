import call from "../../helpers/call";

function create({ authToken, videoChannelId, name, orderIndex, description }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/video-channel-section/create`,
    payload: {
      videoChannelId, // (Required) String 10-30 in length and starting with "vc-"
      name, // (Required) Any string of 0-120 symbols in length.
      orderIndex, // (Required) Must be positive integer or zero
      description, // (Optional) Any text
    },
  });
}

export default create;
