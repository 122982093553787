import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const APPLY_INCENTIVE_TRY = "invoice/applyIncentive/TRY";
const APPLY_INCENTIVE_SUCCESS = "invoice/applyIncentive/SUCCESS";
const APPLY_INCENTIVE_FAILED = "invoice/applyIncentive/FAILED";

const APPLY_INCENTIVE_TRY_BY_IDENTIFIER =
  "invoice/applyIncentive/TRY_BY_IDENTIFIER";
const APPLY_INCENTIVE_SUCCESS_BY_IDENTIFIER =
  "invoice/applyIncentive/SUCCESS_BY_IDENTIFIER";
const APPLY_INCENTIVE_FAILED_BY_IDENTIFIER =
  "invoice/applyIncentive/FAILED_BY_IDENTIFIER";

const AUTOMATED_REFUND_TRY = "invoice/automatedRefund/TRY";
const AUTOMATED_REFUND_SUCCESS = "invoice/automatedRefund/SUCCESS";
const AUTOMATED_REFUND_FAILED = "invoice/automatedRefund/FAILED";

const AUTOMATED_REFUND_TRY_BY_IDENTIFIER =
  "invoice/automatedRefund/TRY_BY_IDENTIFIER";
const AUTOMATED_REFUND_SUCCESS_BY_IDENTIFIER =
  "invoice/automatedRefund/SUCCESS_BY_IDENTIFIER";
const AUTOMATED_REFUND_FAILED_BY_IDENTIFIER =
  "invoice/automatedRefund/FAILED_BY_IDENTIFIER";

const COINBASE_GET_CHARGE_TRY = "invoice/coinbaseGetCharge/TRY";
const COINBASE_GET_CHARGE_SUCCESS = "invoice/coinbaseGetCharge/SUCCESS";
const COINBASE_GET_CHARGE_FAILED = "invoice/coinbaseGetCharge/FAILED";

const COINBASE_GET_CHARGE_TRY_BY_IDENTIFIER =
  "invoice/coinbaseGetCharge/TRY_BY_IDENTIFIER";
const COINBASE_GET_CHARGE_SUCCESS_BY_IDENTIFIER =
  "invoice/coinbaseGetCharge/SUCCESS_BY_IDENTIFIER";
const COINBASE_GET_CHARGE_FAILED_BY_IDENTIFIER =
  "invoice/coinbaseGetCharge/FAILED_BY_IDENTIFIER";

const COINBASE_GET_CHARGE_INFO_TRY = "invoice/coinbaseGetChargeInfo/TRY";
const COINBASE_GET_CHARGE_INFO_SUCCESS =
  "invoice/coinbaseGetChargeInfo/SUCCESS";
const COINBASE_GET_CHARGE_INFO_FAILED = "invoice/coinbaseGetChargeInfo/FAILED";

const COINBASE_GET_CHARGE_INFO_TRY_BY_IDENTIFIER =
  "invoice/coinbaseGetChargeInfo/TRY_BY_IDENTIFIER";
const COINBASE_GET_CHARGE_INFO_SUCCESS_BY_IDENTIFIER =
  "invoice/coinbaseGetChargeInfo/SUCCESS_BY_IDENTIFIER";
const COINBASE_GET_CHARGE_INFO_FAILED_BY_IDENTIFIER =
  "invoice/coinbaseGetChargeInfo/FAILED_BY_IDENTIFIER";

const CREATE_TRY = "invoice/create/TRY";
const CREATE_SUCCESS = "invoice/create/SUCCESS";
const CREATE_FAILED = "invoice/create/FAILED";

const CREATE_TRY_BY_IDENTIFIER = "invoice/create/TRY_BY_IDENTIFIER";
const CREATE_SUCCESS_BY_IDENTIFIER = "invoice/create/SUCCESS_BY_IDENTIFIER";
const CREATE_FAILED_BY_IDENTIFIER = "invoice/create/FAILED_BY_IDENTIFIER";

const GET_TRY = "invoice/get/TRY";
const GET_SUCCESS = "invoice/get/SUCCESS";
const GET_FAILED = "invoice/get/FAILED";

const GET_TRY_BY_IDENTIFIER = "invoice/get/TRY_BY_IDENTIFIER";
const GET_SUCCESS_BY_IDENTIFIER = "invoice/get/SUCCESS_BY_IDENTIFIER";
const GET_FAILED_BY_IDENTIFIER = "invoice/get/FAILED_BY_IDENTIFIER";

const GET_MY_REFUNDS_STATS_TRY = "invoice/getMyRefundsStats/TRY";
const GET_MY_REFUNDS_STATS_SUCCESS = "invoice/getMyRefundsStats/SUCCESS";
const GET_MY_REFUNDS_STATS_FAILED = "invoice/getMyRefundsStats/FAILED";

const GET_MY_REFUNDS_STATS_TRY_BY_IDENTIFIER =
  "invoice/getMyRefundsStats/TRY_BY_IDENTIFIER";
const GET_MY_REFUNDS_STATS_SUCCESS_BY_IDENTIFIER =
  "invoice/getMyRefundsStats/SUCCESS_BY_IDENTIFIER";
const GET_MY_REFUNDS_STATS_FAILED_BY_IDENTIFIER =
  "invoice/getMyRefundsStats/FAILED_BY_IDENTIFIER";

const GET_STRIPE_PAY_INTENT_TRY = "invoice/getStripePayIntent/TRY";
const GET_STRIPE_PAY_INTENT_SUCCESS = "invoice/getStripePayIntent/SUCCESS";
const GET_STRIPE_PAY_INTENT_FAILED = "invoice/getStripePayIntent/FAILED";

const GET_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER =
  "invoice/getStripePayIntent/TRY_BY_IDENTIFIER";
const GET_STRIPE_PAY_INTENT_SUCCESS_BY_IDENTIFIER =
  "invoice/getStripePayIntent/SUCCESS_BY_IDENTIFIER";
const GET_STRIPE_PAY_INTENT_FAILED_BY_IDENTIFIER =
  "invoice/getStripePayIntent/FAILED_BY_IDENTIFIER";

const LIST_MINE_TRY = "invoice/listMine/TRY";
const LIST_MINE_SUCCESS = "invoice/listMine/SUCCESS";
const LIST_MINE_FAILED = "invoice/listMine/FAILED";

const LIST_MINE_TRY_BY_IDENTIFIER = "invoice/listMine/TRY_BY_IDENTIFIER";
const LIST_MINE_SUCCESS_BY_IDENTIFIER =
  "invoice/listMine/SUCCESS_BY_IDENTIFIER";
const LIST_MINE_FAILED_BY_IDENTIFIER = "invoice/listMine/FAILED_BY_IDENTIFIER";

const MAKE_STRIPE_PAY_INTENT_TRY = "invoice/makeStripePayIntent/TRY";
const MAKE_STRIPE_PAY_INTENT_SUCCESS = "invoice/makeStripePayIntent/SUCCESS";
const MAKE_STRIPE_PAY_INTENT_FAILED = "invoice/makeStripePayIntent/FAILED";

const MAKE_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER =
  "invoice/makeStripePayIntent/TRY_BY_IDENTIFIER";
const MAKE_STRIPE_PAY_INTENT_SUCCESS_BY_IDENTIFIER =
  "invoice/makeStripePayIntent/SUCCESS_BY_IDENTIFIER";
const MAKE_STRIPE_PAY_INTENT_FAILED_BY_IDENTIFIER =
  "invoice/makeStripePayIntent/FAILED_BY_IDENTIFIER";

const PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY =
  "invoice/paypalClassicGetPaymentUrl/TRY";
const PAYPAL_CLASSIC_GET_PAYMENT_URL_SUCCESS =
  "invoice/paypalClassicGetPaymentUrl/SUCCESS";
const PAYPAL_CLASSIC_GET_PAYMENT_URL_FAILED =
  "invoice/paypalClassicGetPaymentUrl/FAILED";

const PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY_BY_IDENTIFIER =
  "invoice/paypalClassicGetPaymentUrl/TRY_BY_IDENTIFIER";
const PAYPAL_CLASSIC_GET_PAYMENT_URL_SUCCESS_BY_IDENTIFIER =
  "invoice/paypalClassicGetPaymentUrl/SUCCESS_BY_IDENTIFIER";
const PAYPAL_CLASSIC_GET_PAYMENT_URL_FAILED_BY_IDENTIFIER =
  "invoice/paypalClassicGetPaymentUrl/FAILED_BY_IDENTIFIER";

const REFUND_TO_WALLET_TRY = "invoice/refundToWallet/TRY";
const REFUND_TO_WALLET_SUCCESS = "invoice/refundToWallet/SUCCESS";
const REFUND_TO_WALLET_FAILED = "invoice/refundToWallet/FAILED";

const REFUND_TO_WALLET_TRY_BY_IDENTIFIER =
  "invoice/refundToWallet/TRY_BY_IDENTIFIER";
const REFUND_TO_WALLET_SUCCESS_BY_IDENTIFIER =
  "invoice/refundToWallet/SUCCESS_BY_IDENTIFIER";
const REFUND_TO_WALLET_FAILED_BY_IDENTIFIER =
  "invoice/refundToWallet/FAILED_BY_IDENTIFIER";

const REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY =
  "invoice/removeWalletFundsFromInvoice/TRY";
const REMOVE_WALLET_FUNDS_FROM_INVOICE_SUCCESS =
  "invoice/removeWalletFundsFromInvoice/SUCCESS";
const REMOVE_WALLET_FUNDS_FROM_INVOICE_FAILED =
  "invoice/removeWalletFundsFromInvoice/FAILED";

const REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY_BY_IDENTIFIER =
  "invoice/removeWalletFundsFromInvoice/TRY_BY_IDENTIFIER";
const REMOVE_WALLET_FUNDS_FROM_INVOICE_SUCCESS_BY_IDENTIFIER =
  "invoice/removeWalletFundsFromInvoice/SUCCESS_BY_IDENTIFIER";
const REMOVE_WALLET_FUNDS_FROM_INVOICE_FAILED_BY_IDENTIFIER =
  "invoice/removeWalletFundsFromInvoice/FAILED_BY_IDENTIFIER";

const SPLIT_OPTIONS_LIST_TRY = "invoice/splitOptionsList/TRY";
const SPLIT_OPTIONS_LIST_SUCCESS = "invoice/splitOptionsList/SUCCESS";
const SPLIT_OPTIONS_LIST_FAILED = "invoice/splitOptionsList/FAILED";

const SPLIT_OPTIONS_LIST_TRY_BY_IDENTIFIER =
  "invoice/splitOptionsList/TRY_BY_IDENTIFIER";
const SPLIT_OPTIONS_LIST_SUCCESS_BY_IDENTIFIER =
  "invoice/splitOptionsList/SUCCESS_BY_IDENTIFIER";
const SPLIT_OPTIONS_LIST_FAILED_BY_IDENTIFIER =
  "invoice/splitOptionsList/FAILED_BY_IDENTIFIER";

const STRIPE_SPLIT_PAY_INTENT_TRY = "invoice/stripeSplitPayIntent/TRY";
const STRIPE_SPLIT_PAY_INTENT_SUCCESS = "invoice/stripeSplitPayIntent/SUCCESS";
const STRIPE_SPLIT_PAY_INTENT_FAILED = "invoice/stripeSplitPayIntent/FAILED";

const STRIPE_SPLIT_PAY_INTENT_TRY_BY_IDENTIFIER =
  "invoice/stripeSplitPayIntent/TRY_BY_IDENTIFIER";
const STRIPE_SPLIT_PAY_INTENT_SUCCESS_BY_IDENTIFIER =
  "invoice/stripeSplitPayIntent/SUCCESS_BY_IDENTIFIER";
const STRIPE_SPLIT_PAY_INTENT_FAILED_BY_IDENTIFIER =
  "invoice/stripeSplitPayIntent/FAILED_BY_IDENTIFIER";

const UPDATE_BILLING_DETAILS_TRY = "invoice/updateBillingDetails/TRY";
const UPDATE_BILLING_DETAILS_SUCCESS = "invoice/updateBillingDetails/SUCCESS";
const UPDATE_BILLING_DETAILS_FAILED = "invoice/updateBillingDetails/FAILED";

const UPDATE_BILLING_DETAILS_TRY_BY_IDENTIFIER =
  "invoice/updateBillingDetails/TRY_BY_IDENTIFIER";
const UPDATE_BILLING_DETAILS_SUCCESS_BY_IDENTIFIER =
  "invoice/updateBillingDetails/SUCCESS_BY_IDENTIFIER";
const UPDATE_BILLING_DETAILS_FAILED_BY_IDENTIFIER =
  "invoice/updateBillingDetails/FAILED_BY_IDENTIFIER";

const VIP_DISCOUNTS_LIST_TRY = "invoice/vipDiscountsList/TRY";
const VIP_DISCOUNTS_LIST_SUCCESS = "invoice/vipDiscountsList/SUCCESS";
const VIP_DISCOUNTS_LIST_FAILED = "invoice/vipDiscountsList/FAILED";

const VIP_DISCOUNTS_LIST_TRY_BY_IDENTIFIER =
  "invoice/vipDiscountsList/TRY_BY_IDENTIFIER";
const VIP_DISCOUNTS_LIST_SUCCESS_BY_IDENTIFIER =
  "invoice/vipDiscountsList/SUCCESS_BY_IDENTIFIER";
const VIP_DISCOUNTS_LIST_FAILED_BY_IDENTIFIER =
  "invoice/vipDiscountsList/FAILED_BY_IDENTIFIER";

const initialState = Map({
  applyIncentive: shape.merge(Map({ dataByIdentifier: Map({}) })),
  automatedRefund: shape.merge(Map({ dataByIdentifier: Map({}) })),
  coinbaseGetCharge: shape.merge(Map({ dataByIdentifier: Map({}) })),
  coinbaseGetChargeInfo: shape.merge(Map({ dataByIdentifier: Map({}) })),
  create: shape.merge(Map({ dataByIdentifier: Map({}) })),
  get: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getMyRefundsStats: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getStripePayIntent: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listMine: shape.merge(Map({ dataByIdentifier: Map({}) })),
  makeStripePayIntent: shape.merge(Map({ dataByIdentifier: Map({}) })),
  paypalClassicGetPaymentUrl: shape.merge(Map({ dataByIdentifier: Map({}) })),
  refundToWallet: shape.merge(Map({ dataByIdentifier: Map({}) })),
  removeWalletFundsFromInvoice: shape.merge(Map({ dataByIdentifier: Map({}) })),
  splitOptionsList: shape.merge(Map({ dataByIdentifier: Map({}) })),
  stripeSplitPayIntent: shape.merge(Map({ dataByIdentifier: Map({}) })),
  updateBillingDetails: shape.merge(Map({ dataByIdentifier: Map({}) })),
  vipDiscountsList: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case APPLY_INCENTIVE_TRY:
    case AUTOMATED_REFUND_TRY:
    case COINBASE_GET_CHARGE_TRY:
    case COINBASE_GET_CHARGE_INFO_TRY:
    case CREATE_TRY:
    case GET_TRY:
    case GET_MY_REFUNDS_STATS_TRY:
    case GET_STRIPE_PAY_INTENT_TRY:
    case LIST_MINE_TRY:
    case MAKE_STRIPE_PAY_INTENT_TRY:
    case PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY:
    case REFUND_TO_WALLET_TRY:
    case REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY:
    case SPLIT_OPTIONS_LIST_TRY:
    case STRIPE_SPLIT_PAY_INTENT_TRY:
    case UPDATE_BILLING_DETAILS_TRY:
    case VIP_DISCOUNTS_LIST_TRY:
      return handleTry({ state, action });
    case APPLY_INCENTIVE_SUCCESS:
    case AUTOMATED_REFUND_SUCCESS:
    case COINBASE_GET_CHARGE_SUCCESS:
    case COINBASE_GET_CHARGE_INFO_SUCCESS:
    case CREATE_SUCCESS:
    case GET_SUCCESS:
    case GET_MY_REFUNDS_STATS_SUCCESS:
    case GET_STRIPE_PAY_INTENT_SUCCESS:
    case LIST_MINE_SUCCESS:
    case MAKE_STRIPE_PAY_INTENT_SUCCESS:
    case PAYPAL_CLASSIC_GET_PAYMENT_URL_SUCCESS:
    case REFUND_TO_WALLET_SUCCESS:
    case REMOVE_WALLET_FUNDS_FROM_INVOICE_SUCCESS:
    case SPLIT_OPTIONS_LIST_SUCCESS:
    case STRIPE_SPLIT_PAY_INTENT_SUCCESS:
    case UPDATE_BILLING_DETAILS_SUCCESS:
    case VIP_DISCOUNTS_LIST_SUCCESS:
      return handleSuccess({ state, action });
    case APPLY_INCENTIVE_FAILED:
    case AUTOMATED_REFUND_FAILED:
    case COINBASE_GET_CHARGE_FAILED:
    case COINBASE_GET_CHARGE_INFO_FAILED:
    case CREATE_FAILED:
    case GET_FAILED:
    case GET_MY_REFUNDS_STATS_FAILED:
    case GET_STRIPE_PAY_INTENT_FAILED:
    case LIST_MINE_FAILED:
    case MAKE_STRIPE_PAY_INTENT_FAILED:
    case PAYPAL_CLASSIC_GET_PAYMENT_URL_FAILED:
    case REFUND_TO_WALLET_FAILED:
    case REMOVE_WALLET_FUNDS_FROM_INVOICE_FAILED:
    case SPLIT_OPTIONS_LIST_FAILED:
    case STRIPE_SPLIT_PAY_INTENT_FAILED:
    case UPDATE_BILLING_DETAILS_FAILED:
    case VIP_DISCOUNTS_LIST_FAILED:
      return handleFailed({ state, action });
    case APPLY_INCENTIVE_TRY_BY_IDENTIFIER:
    case AUTOMATED_REFUND_TRY_BY_IDENTIFIER:
    case COINBASE_GET_CHARGE_TRY_BY_IDENTIFIER:
    case COINBASE_GET_CHARGE_INFO_TRY_BY_IDENTIFIER:
    case CREATE_TRY_BY_IDENTIFIER:
    case GET_TRY_BY_IDENTIFIER:
    case GET_MY_REFUNDS_STATS_TRY_BY_IDENTIFIER:
    case GET_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER:
    case LIST_MINE_TRY_BY_IDENTIFIER:
    case MAKE_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER:
    case PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY_BY_IDENTIFIER:
    case REFUND_TO_WALLET_TRY_BY_IDENTIFIER:
    case REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY_BY_IDENTIFIER:
    case SPLIT_OPTIONS_LIST_TRY_BY_IDENTIFIER:
    case STRIPE_SPLIT_PAY_INTENT_TRY_BY_IDENTIFIER:
    case UPDATE_BILLING_DETAILS_TRY_BY_IDENTIFIER:
    case VIP_DISCOUNTS_LIST_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case APPLY_INCENTIVE_SUCCESS_BY_IDENTIFIER:
    case AUTOMATED_REFUND_SUCCESS_BY_IDENTIFIER:
    case COINBASE_GET_CHARGE_SUCCESS_BY_IDENTIFIER:
    case COINBASE_GET_CHARGE_INFO_SUCCESS_BY_IDENTIFIER:
    case CREATE_SUCCESS_BY_IDENTIFIER:
    case GET_SUCCESS_BY_IDENTIFIER:
    case GET_MY_REFUNDS_STATS_SUCCESS_BY_IDENTIFIER:
    case GET_STRIPE_PAY_INTENT_SUCCESS_BY_IDENTIFIER:
    case LIST_MINE_SUCCESS_BY_IDENTIFIER:
    case MAKE_STRIPE_PAY_INTENT_SUCCESS_BY_IDENTIFIER:
    case PAYPAL_CLASSIC_GET_PAYMENT_URL_SUCCESS_BY_IDENTIFIER:
    case REFUND_TO_WALLET_SUCCESS_BY_IDENTIFIER:
    case REMOVE_WALLET_FUNDS_FROM_INVOICE_SUCCESS_BY_IDENTIFIER:
    case SPLIT_OPTIONS_LIST_SUCCESS_BY_IDENTIFIER:
    case STRIPE_SPLIT_PAY_INTENT_SUCCESS_BY_IDENTIFIER:
    case UPDATE_BILLING_DETAILS_SUCCESS_BY_IDENTIFIER:
    case VIP_DISCOUNTS_LIST_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case APPLY_INCENTIVE_FAILED_BY_IDENTIFIER:
    case AUTOMATED_REFUND_FAILED_BY_IDENTIFIER:
    case COINBASE_GET_CHARGE_FAILED_BY_IDENTIFIER:
    case COINBASE_GET_CHARGE_INFO_FAILED_BY_IDENTIFIER:
    case CREATE_FAILED_BY_IDENTIFIER:
    case GET_FAILED_BY_IDENTIFIER:
    case GET_MY_REFUNDS_STATS_FAILED_BY_IDENTIFIER:
    case GET_STRIPE_PAY_INTENT_FAILED_BY_IDENTIFIER:
    case LIST_MINE_FAILED_BY_IDENTIFIER:
    case MAKE_STRIPE_PAY_INTENT_FAILED_BY_IDENTIFIER:
    case PAYPAL_CLASSIC_GET_PAYMENT_URL_FAILED_BY_IDENTIFIER:
    case REFUND_TO_WALLET_FAILED_BY_IDENTIFIER:
    case REMOVE_WALLET_FUNDS_FROM_INVOICE_FAILED_BY_IDENTIFIER:
    case SPLIT_OPTIONS_LIST_FAILED_BY_IDENTIFIER:
    case STRIPE_SPLIT_PAY_INTENT_FAILED_BY_IDENTIFIER:
    case UPDATE_BILLING_DETAILS_FAILED_BY_IDENTIFIER:
    case VIP_DISCOUNTS_LIST_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  APPLY_INCENTIVE_TRY,
  APPLY_INCENTIVE_SUCCESS,
  APPLY_INCENTIVE_FAILED,
  APPLY_INCENTIVE_TRY_BY_IDENTIFIER,
  APPLY_INCENTIVE_SUCCESS_BY_IDENTIFIER,
  APPLY_INCENTIVE_FAILED_BY_IDENTIFIER,

  AUTOMATED_REFUND_TRY,
  AUTOMATED_REFUND_SUCCESS,
  AUTOMATED_REFUND_FAILED,
  AUTOMATED_REFUND_TRY_BY_IDENTIFIER,
  AUTOMATED_REFUND_SUCCESS_BY_IDENTIFIER,
  AUTOMATED_REFUND_FAILED_BY_IDENTIFIER,

  COINBASE_GET_CHARGE_TRY,
  COINBASE_GET_CHARGE_SUCCESS,
  COINBASE_GET_CHARGE_FAILED,
  COINBASE_GET_CHARGE_TRY_BY_IDENTIFIER,
  COINBASE_GET_CHARGE_SUCCESS_BY_IDENTIFIER,
  COINBASE_GET_CHARGE_FAILED_BY_IDENTIFIER,

  COINBASE_GET_CHARGE_INFO_TRY,
  COINBASE_GET_CHARGE_INFO_SUCCESS,
  COINBASE_GET_CHARGE_INFO_FAILED,
  COINBASE_GET_CHARGE_INFO_TRY_BY_IDENTIFIER,
  COINBASE_GET_CHARGE_INFO_SUCCESS_BY_IDENTIFIER,
  COINBASE_GET_CHARGE_INFO_FAILED_BY_IDENTIFIER,

  CREATE_TRY,
  CREATE_SUCCESS,
  CREATE_FAILED,
  CREATE_TRY_BY_IDENTIFIER,
  CREATE_SUCCESS_BY_IDENTIFIER,
  CREATE_FAILED_BY_IDENTIFIER,

  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  GET_TRY_BY_IDENTIFIER,
  GET_SUCCESS_BY_IDENTIFIER,
  GET_FAILED_BY_IDENTIFIER,

  GET_MY_REFUNDS_STATS_TRY,
  GET_MY_REFUNDS_STATS_SUCCESS,
  GET_MY_REFUNDS_STATS_FAILED,
  GET_MY_REFUNDS_STATS_TRY_BY_IDENTIFIER,
  GET_MY_REFUNDS_STATS_SUCCESS_BY_IDENTIFIER,
  GET_MY_REFUNDS_STATS_FAILED_BY_IDENTIFIER,

  GET_STRIPE_PAY_INTENT_TRY,
  GET_STRIPE_PAY_INTENT_SUCCESS,
  GET_STRIPE_PAY_INTENT_FAILED,
  GET_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER,
  GET_STRIPE_PAY_INTENT_SUCCESS_BY_IDENTIFIER,
  GET_STRIPE_PAY_INTENT_FAILED_BY_IDENTIFIER,

  LIST_MINE_TRY,
  LIST_MINE_SUCCESS,
  LIST_MINE_FAILED,
  LIST_MINE_TRY_BY_IDENTIFIER,
  LIST_MINE_SUCCESS_BY_IDENTIFIER,
  LIST_MINE_FAILED_BY_IDENTIFIER,

  MAKE_STRIPE_PAY_INTENT_TRY,
  MAKE_STRIPE_PAY_INTENT_SUCCESS,
  MAKE_STRIPE_PAY_INTENT_FAILED,
  MAKE_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER,
  MAKE_STRIPE_PAY_INTENT_SUCCESS_BY_IDENTIFIER,
  MAKE_STRIPE_PAY_INTENT_FAILED_BY_IDENTIFIER,

  PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY,
  PAYPAL_CLASSIC_GET_PAYMENT_URL_SUCCESS,
  PAYPAL_CLASSIC_GET_PAYMENT_URL_FAILED,
  PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY_BY_IDENTIFIER,
  PAYPAL_CLASSIC_GET_PAYMENT_URL_SUCCESS_BY_IDENTIFIER,
  PAYPAL_CLASSIC_GET_PAYMENT_URL_FAILED_BY_IDENTIFIER,

  REFUND_TO_WALLET_TRY,
  REFUND_TO_WALLET_SUCCESS,
  REFUND_TO_WALLET_FAILED,
  REFUND_TO_WALLET_TRY_BY_IDENTIFIER,
  REFUND_TO_WALLET_SUCCESS_BY_IDENTIFIER,
  REFUND_TO_WALLET_FAILED_BY_IDENTIFIER,

  REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY,
  REMOVE_WALLET_FUNDS_FROM_INVOICE_SUCCESS,
  REMOVE_WALLET_FUNDS_FROM_INVOICE_FAILED,
  REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY_BY_IDENTIFIER,
  REMOVE_WALLET_FUNDS_FROM_INVOICE_SUCCESS_BY_IDENTIFIER,
  REMOVE_WALLET_FUNDS_FROM_INVOICE_FAILED_BY_IDENTIFIER,

  SPLIT_OPTIONS_LIST_TRY,
  SPLIT_OPTIONS_LIST_SUCCESS,
  SPLIT_OPTIONS_LIST_FAILED,
  SPLIT_OPTIONS_LIST_TRY_BY_IDENTIFIER,
  SPLIT_OPTIONS_LIST_SUCCESS_BY_IDENTIFIER,
  SPLIT_OPTIONS_LIST_FAILED_BY_IDENTIFIER,

  STRIPE_SPLIT_PAY_INTENT_TRY,
  STRIPE_SPLIT_PAY_INTENT_SUCCESS,
  STRIPE_SPLIT_PAY_INTENT_FAILED,
  STRIPE_SPLIT_PAY_INTENT_TRY_BY_IDENTIFIER,
  STRIPE_SPLIT_PAY_INTENT_SUCCESS_BY_IDENTIFIER,
  STRIPE_SPLIT_PAY_INTENT_FAILED_BY_IDENTIFIER,

  UPDATE_BILLING_DETAILS_TRY,
  UPDATE_BILLING_DETAILS_SUCCESS,
  UPDATE_BILLING_DETAILS_FAILED,
  UPDATE_BILLING_DETAILS_TRY_BY_IDENTIFIER,
  UPDATE_BILLING_DETAILS_SUCCESS_BY_IDENTIFIER,
  UPDATE_BILLING_DETAILS_FAILED_BY_IDENTIFIER,

  VIP_DISCOUNTS_LIST_TRY,
  VIP_DISCOUNTS_LIST_SUCCESS,
  VIP_DISCOUNTS_LIST_FAILED,
  VIP_DISCOUNTS_LIST_TRY_BY_IDENTIFIER,
  VIP_DISCOUNTS_LIST_SUCCESS_BY_IDENTIFIER,
  VIP_DISCOUNTS_LIST_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
