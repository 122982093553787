import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useCirclesoGetSsoAuthorizationOneTimeUseCodeLoadingState from "../circleso/useCirclesoGetSsoAuthorizationOneTimeUseCodeLoadingState";

// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/circleso/tryCirclesoGetSsoAuthorizationOneTimeUseCode";

export default function useCirclesoGetSsoAuthorizationOneTimeUseCodeResult(
  {},
  ___options
) {
  const ___circleso = useSelector((___state) => ___state.circleso);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const circlesoGetSsoAuthorizationOneTimeUseCodeLoadingState =
    useCirclesoGetSsoAuthorizationOneTimeUseCodeLoadingState({}, ___options);
  const ___isLoading =
    circlesoGetSsoAuthorizationOneTimeUseCodeLoadingState.get("isLoading");

  useEffect(() => {
    if (___isLoading === false) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.circleso.getSsoAuthorizationOneTimeUseCode({});
      } else {
        ___actions.circleso.getSsoAuthorizationOneTimeUseCode({});
      }
    }
  }, []);

  if (___options && ___options.identifier) {
    return (
      ___circleso.getIn([
        "getSsoAuthorizationOneTimeUseCode",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___circleso.getIn(["getSsoAuthorizationOneTimeUseCode", "lastResult"]);
}
