import call from "../../helpers/call";

function createAdmin({
  authToken,
  productId,
  slackHookUrl,
  slackChannel,
  integrationWebhookUrl,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/vendor-settings/create-admin`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      slackHookUrl, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      slackChannel, // (Optional) Any string of 0-120 symbols in length.
      integrationWebhookUrl, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
    },
  });
}

export default createAdmin;
