import Cookies from "js-cookie";

const ls = require("localstorage-ttl");

export default function saveImpactUrlToCookie() {
  if (window && window.location) {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      let impact_irclickid = urlParams.get("irclickid");
      let impact_irgwc = urlParams.get("irgwc");

      // legacy system saved this in local storage. Safe to remove after 2022-01-01 -- START
      if (
        ls.get("impact_irclickid") &&
        !impact_irclickid &&
        ls.get("impact_irgwc") &&
        !impact_irgwc
      ) {
        if (!Cookies.get("impact_irclickid") && !Cookies.get("impact_irgwc")) {
          impact_irclickid = ls.get("impact_irclickid");
          impact_irgwc = ls.get("impact_irgwc");
        }
      }
      // legacy system saved this in local storage. Safe to remove after 2022-01-01 -- END

      if (impact_irclickid && impact_irgwc) {
        Cookies.set("impact_irclickid", impact_irclickid, {
          expires: 90, // days
          path: "/",
          domain:
            // eslint-disable-next-line
            location.host.indexOf("localhost") !== -1
              ? "localhost"
              : `.${process.env.REACT_APP_MAIN_DOMAIN}`,
        });

        Cookies.set("impact_irgwc", impact_irgwc, {
          expires: 90, // days
          path: "/",
          domain:
            // eslint-disable-next-line
            location.host.indexOf("localhost") !== -1
              ? "localhost"
              : `.${process.env.REACT_APP_MAIN_DOMAIN}`,
        });
      }
    } catch (e) {
      console.error(`#2021266154147554 error: `, e);
    }
  }
}
