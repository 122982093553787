import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

/*
Second param is optional object with options:

@identifier - to get loading state by single identifier
@doUseFirstLoading - get loading state of first loading item either regular or any identifier
*/

export default function useNotificationsMarkAllAsReadLoadingState(
  {},
  ___options
) {
  const notifications = useSelector((___state) => ___state.notifications);
  let result = null;

  if (___options && ___options.identifier) {
    result =
      notifications.getIn([
        "markAllAsRead",
        "dataByIdentifier",
        ___options.identifier,
        "loading",
      ]) || ___shape.get("loading");
  }

  if (result === null && ___options && ___options.doUseFirstLoading) {
    if (notifications.getIn(["remove", "loading", "isLoading"]) === true) {
      result = notifications.getIn(["remove", "loading"]);
    }

    if (result === null) {
      let stateByIdentifiers = notifications.getIn([
        "markAllAsRead",
        "dataByIdentifier",
      ]);
      if (stateByIdentifiers && stateByIdentifiers.size > 0) {
        stateByIdentifiers.forEach((byIdentifier, identifierKey) => {
          if (
            notifications.getIn([
              "markAllAsRead",
              "dataByIdentifier",
              identifierKey,
              "loading",
              "isLoading",
            ]) === true
          ) {
            result = notifications.getIn([
              "markAllAsRead",
              "dataByIdentifier",
              identifierKey,
              "loading",
            ]);
          }
        });
      }
    }
  }

  if (result === null) {
    result = notifications.getIn(["markAllAsRead", "loading"]);
  }

  return result;
}
