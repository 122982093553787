import usePrevious from "hook-use-previous";
import { useSelector } from "react-redux";
import useInvoiceCreateLoadingState from "stateManager/hooks/invoice/useInvoiceCreateLoadingState";

export default function useIsInvoiceCreateAfterSuccess({}, ___options) {
  const loadingState = useInvoiceCreateLoadingState({}, ___options);
  const invoice = useSelector((___state) => ___state.invoice);
  const wasLoading = usePrevious(loadingState.get("isLoading"));

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      invoice.getIn([
        "create",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
        "wasSuccess",
      ]) === true
    );
  } else {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      invoice.getIn(["create", "lastResult", "wasSuccess"]) === true
    );
  }
}
