import call from "../../helpers/call";

function create({ authToken, title, productPlanId, type, amount }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription/create`,
    payload: {
      title, // (Required) Any text
      productPlanId, // (Required) String 10-30 in length and starting with "pp-"
      type, // (Required) String. One of: monthly, yearly, or 2years
      amount, // (Required) Any positive integer. 0 is not valid.
    },
  });
}

export default create;
