import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const LIST_MY_TRY = "coupons/listMy/TRY";
const LIST_MY_SUCCESS = "coupons/listMy/SUCCESS";
const LIST_MY_FAILED = "coupons/listMy/FAILED";

const LIST_MY_TRY_BY_IDENTIFIER = "coupons/listMy/TRY_BY_IDENTIFIER";
const LIST_MY_SUCCESS_BY_IDENTIFIER = "coupons/listMy/SUCCESS_BY_IDENTIFIER";
const LIST_MY_FAILED_BY_IDENTIFIER = "coupons/listMy/FAILED_BY_IDENTIFIER";

const initialState = Map({
  listMy: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_MY_TRY:
      return handleTry({ state, action });
    case LIST_MY_SUCCESS:
      return handleSuccess({ state, action });
    case LIST_MY_FAILED:
      return handleFailed({ state, action });
    case LIST_MY_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case LIST_MY_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case LIST_MY_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  LIST_MY_TRY,
  LIST_MY_SUCCESS,
  LIST_MY_FAILED,
  LIST_MY_TRY_BY_IDENTIFIER,
  LIST_MY_SUCCESS_BY_IDENTIFIER,
  LIST_MY_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
