import useUserBillingDetailsGetLoadingState from "stateManager/hooks/user/useUserBillingDetailsGetLoadingState";
import useUserBillingDetailsGetResult from "stateManager/hooks/user/useUserBillingDetailsGetResult";

export default function useIsUserBillingDetailsGetLoaded({}, ___options) {
  const loadingState = useUserBillingDetailsGetLoadingState({}, ___options);
  const resultState = useUserBillingDetailsGetResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
