import AfterLoginBtns from "jsx/AfterLoginBtns";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalAfterLogin() {
  const portals = [];
  const places = document.querySelectorAll(".after-login");

  for (let i = 0; i < places.length; i++) {
    const place = places[i];
    // if there was any place content, e.g. loading state - clear it out
    place.innerHTML = "";
    // if (localStorage.getItem("authToken")) {
    portals.push(ReactDOM.createPortal(<AfterLoginBtns />, place));
    // }
  }
  return portals;
}
