import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

export default function useInvoiceCreateResult({}, ___options) {
  const ___invoice = useSelector((___state) => ___state.invoice);

  if (___options && ___options.identifier) {
    return (
      ___invoice.getIn([
        "create",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___invoice.getIn(["create", "lastResult"]);
}
