import { Chip, Typography } from "@mui/material";

export default function Tag(props) {
  return (
    <Chip
      icon={props.icon && props.icon}
      label={
        <Typography variant={"subtitle2"} component={"span"}>
          {props.label}
        </Typography>
      }
      title={props.title && props.title}
      //   color={props.color ? props.color : "orange"}
      size={props.size ? props.size : "medium"}
      sx={{
        height: props.size && props.size === "small" ? "30px" : "34px",
        padding: props.size && props.size === "small" ? "4px 0" : "6px 0",
        width: props.fullWidth ? "100%" : "auto",
        backgroundColor: props.color ? props.color : "#FA9237",
        color: "#fff",
      }}
      variant={props.variant ? props.variant : null}
    />
  );
}
