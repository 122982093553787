import { Map } from "immutable";
import {
  handleFailed,
  handleSuccess,
  handleTry,
  shape,
} from "../utils/reducerHelpers";

const GET_TOKENS_TRY = "authFlow/getTokens/TRY";
const GET_TOKENS_SUCCESS = "authFlow/getTokens/SUCCESS";
const GET_TOKENS_FAILED = "authFlow/getTokens/FAILED";

const GET_REFRESHED_TOKENS_TRY = "authFlow/getRefreshedTokens/TRY";
const GET_REFRESHED_TOKENS_SUCCESS = "authFlow/getRefreshedTokens/SUCCESS";
const GET_REFRESHED_TOKENS_FAILED = "authFlow/getRefreshedTokens/FAILED";

const GET_AUTHORIZED_USER_TRY = "authFlow/getAuthorizedUser/TRY";
const GET_AUTHORIZED_USER_SUCCESS = "authFlow/getAuthorizedUser/SUCCESS";
const GET_AUTHORIZED_USER_FAILED = "authFlow/getAuthorizedUser/FAILED";

const UPDATE_PROFILE_TRY = "authFlow/updateProfile/TRY";
const UPDATE_PROFILE_SUCCESS = "authFlow/updateProfile/SUCCESS";
const UPDATE_PROFILE_FAILED = "authFlow/updateProfile/FAILED";

const UPLOAD_PROFILE_IMAGE_TRY = "authFlow/uploadProfileImage/TRY";
const UPLOAD_PROFILE_IMAGE_SUCCESS = "authFlow/uploadProfileImage/SUCCESS";
const UPLOAD_PROFILE_IMAGE_FAILED = "authFlow/uploadProfileImage/FAILED";

const initialState = Map({
  getTokens: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getRefreshedTokens: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getAuthorizedUser: shape.merge(Map({ dataByIdentifier: Map({}) })),
  updateProfile: shape.merge(Map({ dataByIdentifier: Map({}) })),
  uploadProfileImage: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PROFILE_TRY:
    case GET_TOKENS_TRY:
    case GET_REFRESHED_TOKENS_TRY:
    case GET_AUTHORIZED_USER_TRY:
    case UPLOAD_PROFILE_IMAGE_TRY:
      return handleTry({ state, action });
    case UPDATE_PROFILE_SUCCESS:
    case GET_TOKENS_SUCCESS:
    case GET_REFRESHED_TOKENS_SUCCESS:
    case GET_AUTHORIZED_USER_SUCCESS:
    case UPLOAD_PROFILE_IMAGE_SUCCESS:
      return handleSuccess({ state, action });
    case UPDATE_PROFILE_FAILED:
    case GET_TOKENS_FAILED:
    case GET_REFRESHED_TOKENS_FAILED:
    case GET_AUTHORIZED_USER_FAILED:
    case UPLOAD_PROFILE_IMAGE_FAILED:
      return handleFailed({ state, action });
    default:
      return state;
  }
}

const actionTypes = {
  GET_TOKENS_TRY,
  GET_TOKENS_SUCCESS,
  GET_TOKENS_FAILED,
  GET_REFRESHED_TOKENS_TRY,
  GET_REFRESHED_TOKENS_SUCCESS,
  GET_REFRESHED_TOKENS_FAILED,
  GET_AUTHORIZED_USER_TRY,
  GET_AUTHORIZED_USER_SUCCESS,
  GET_AUTHORIZED_USER_FAILED,
  UPDATE_PROFILE_TRY,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  UPLOAD_PROFILE_IMAGE_TRY,
  UPLOAD_PROFILE_IMAGE_FAILED,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
};

export default {
  actionTypes,
  reducer,
};
