import call from "../../helpers/call";

function listAdmin({
  authToken,
  orderByDirection,
  balanceMoreThen,
  balanceLessThen,
  page,
  perPage,
  ownerUserId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/wallet/list-admin`,
    payload: {
      orderByDirection, // (Optional) Valid values: desc, asc
      balanceMoreThen, // (Optional) Must be positive integer or zero
      balanceLessThen, // (Optional) Must be positive integer or zero
      page, // (Optional) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any positive integer. Range: (0,1000]
      ownerUserId, // (Optional) String 10-30 in length and starting with "u-"
    },
  });
}

export default listAdmin;
