import call from "../../helpers/call";

function create({ authToken, productId, title, comment, stars, pros, cons }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/review/create`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
      title, // (Required) Any string of 0-120 symbols in length.
      comment, // (Required) Any text
      stars, // (Required) Must be positive integer or zero
      pros, // (Required) Any text
      cons, // (Required) Any text
    },
  });
}

export default create;
