import useCartGetMyItemsInCartCountLoadingState from "stateManager/hooks/cart/useCartGetMyItemsInCartCountLoadingState";
import useCartGetMyItemsInCartCountResult from "stateManager/hooks/cart/useCartGetMyItemsInCartCountResult";

export default function useIsCartGetMyItemsInCartCountLoaded({}, ___options) {
  const loadingState = useCartGetMyItemsInCartCountLoadingState({}, ___options);
  const resultState = useCartGetMyItemsInCartCountResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
