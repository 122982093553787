import call from "../../helpers/call";

function getFullProductData({ authToken, productSlug }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/video/get-full-product-data`,
    payload: {
      productSlug, // (Required) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
    },
  });
}

export default getFullProductData;
