import { Map, List } from "immutable";

const ADD_TO_QUEUE = "globalMessages/ADD_TO_QUEUE";
const REMOVE_FROM_QUEUE = "globalMessages/REMOVE_FROM_QUEUE";

const initialState = Map({
  queues: Map({
    topCenter: List([]),
    topRight: List([]),
    bottomRight: List([]),
    bottomCenter: List([]),
    bottomLeft: List([]),
    topLeft: List([]),
  }),
});

function reducer(state = initialState, action = {}) {
  let queue = null;
  let currentQueue = null;

  switch (action.type) {
    case ADD_TO_QUEUE: {
      queue = action.data.queue || "bottomLeft";
      currentQueue = state.getIn(["queues", queue]) || List([]);
      const message = action.data.message || "Something went wrong 😬";
      const timeoutSeconds = action.data.timeoutSeconds || 4;
      return state.setIn(
        ["queues", queue],
        currentQueue.push(
          Map({
            messageId: `${Date.now()}${Math.random()}`,
            message,
            timeoutSeconds,
            action: action.data.action || null,
            doHideOnActionClick: action.data.doHideOnActionClick !== false,
          })
        )
      );
    }
    case REMOVE_FROM_QUEUE: {
      queue = action.data.queue || "bottomLeft";
      const queueItem = action.data.queueItem;
      currentQueue = state.getIn(["queues", queue]) || List([]);
      const _itemToRemove = currentQueue.findEntry(
        (single) => single.get("messageId") === queueItem.get("messageId")
      );
      if (_itemToRemove && (_itemToRemove[0] || _itemToRemove[0] === 0)) {
        return state.setIn(
          ["queues", queue],
          currentQueue.delete(_itemToRemove[0])
        );
      }
      return state;
    }
    default:
      return state;
  }
}

const actionTypes = {
  ADD_TO_QUEUE,
  REMOVE_FROM_QUEUE,
};

export default {
  actionTypes,
  reducer,
};
