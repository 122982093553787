import call from "../../helpers/call";

function listStatsOverviewAdmin({
  authToken,
  searchQuery,
  state,
  paidFrom,
  paidTo,
  createdFrom,
  createdTo,
  isSplitEnabled,
  ownerUserId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/list-stats-overview-admin`,
    payload: {
      searchQuery, // (Optional) Any text
      state, // (Optional) One of: paid, pending, refunded, partlyPaid, partlyRefunded, overpaid
      paidFrom, // (Optional) Timestamp value. Any positive integer.
      paidTo, // (Optional) Timestamp value. Any positive integer.
      createdFrom, // (Optional) Timestamp value. Any positive integer.
      createdTo, // (Optional) Timestamp value. Any positive integer.
      isSplitEnabled, // (Optional) Value is a boolean. True or false.
      ownerUserId, // (Optional) String 10-30 in length and starting with "u-"
    },
  });
}

export default listStatsOverviewAdmin;
