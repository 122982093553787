import call from "../../helpers/call";

function affiliateFormSubmit({ authToken, data }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/affiliate-form-submit`,
    payload: {
      data, // (Required) JS object with at least one key
    },
  });
}

export default affiliateFormSubmit;
