import useNotificationsListMineLoadingState from "stateManager/hooks/notifications/useNotificationsListMineLoadingState";
import useNotificationsListMineResult from "stateManager/hooks/notifications/useNotificationsListMineResult";

export default function useIsNotificationsListMineLoaded({}, ___options) {
  const loadingState = useNotificationsListMineLoadingState({}, ___options);
  const resultState = useNotificationsListMineResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
