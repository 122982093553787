import call from "../../helpers/call";

function updatePaidAtAdmin({ authToken, invoiceId, paidAt }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/update-paid-at-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      paidAt, // (Required) Timestamp value. Any positive integer.
    },
  });
}

export default updatePaidAtAdmin;
