import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import subscriptionTracking from "../../reducers/subscriptionTracking";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

// Write any custom code here. It won't be overwritten.

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* trySubscriptionTrackingUpdate({ data }) {
  const payload = {
    subscriptionTrackingId: data.subscriptionTrackingId,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.companyId !== "undefined") {
    payload.companyId = data.companyId;
  }

  if (typeof data.subscriptionType !== "undefined") {
    payload.subscriptionType = data.subscriptionType;
  }

  if (typeof data.tags !== "undefined") {
    payload.tags = data.tags;
  }

  if (typeof data.purchasedAt !== "undefined") {
    payload.purchasedAt = data.purchasedAt;
  }

  if (typeof data.isAutoRenewing !== "undefined") {
    payload.isAutoRenewing = data.isAutoRenewing;
  }

  if (typeof data.cost !== "undefined") {
    payload.cost = data.cost;
  }

  if (typeof data.costCents !== "undefined") {
    payload.costCents = data.costCents;
  }

  if (typeof data.currencyId !== "undefined") {
    payload.currencyId = data.currencyId;
  }

  if (typeof data.isPaused !== "undefined") {
    payload.isPaused = data.isPaused;
  }

  if (typeof data.isCancelled !== "undefined") {
    payload.isCancelled = data.isCancelled;
  }

  if (typeof data.repeatEveryNumber !== "undefined") {
    payload.repeatEveryNumber = data.repeatEveryNumber;
  }

  if (typeof data.repeatEveryTerm !== "undefined") {
    payload.repeatEveryTerm = data.repeatEveryTerm;
  }

  if (typeof data.planName !== "undefined") {
    payload.planName = data.planName;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.subscriptionTrackingUpdate(payload);
  } catch (e) {
    result = {
      error: {
        code: "#2FGA_trySubscriptionTrackingUpdate",
        message: e.message,
      },
    };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: subscriptionTracking.actionTypes.UPDATE_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: subscriptionTracking.actionTypes.UPDATE_FAILED,
      data: result.error,
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
