import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const CREATE_TRY = "review/create/TRY";
const CREATE_SUCCESS = "review/create/SUCCESS";
const CREATE_FAILED = "review/create/FAILED";

const CREATE_TRY_BY_IDENTIFIER = "review/create/TRY_BY_IDENTIFIER";
const CREATE_SUCCESS_BY_IDENTIFIER = "review/create/SUCCESS_BY_IDENTIFIER";
const CREATE_FAILED_BY_IDENTIFIER = "review/create/FAILED_BY_IDENTIFIER";

const GET_MY_FOR_EDIT_TRY = "review/getMyForEdit/TRY";
const GET_MY_FOR_EDIT_SUCCESS = "review/getMyForEdit/SUCCESS";
const GET_MY_FOR_EDIT_FAILED = "review/getMyForEdit/FAILED";

const GET_MY_FOR_EDIT_TRY_BY_IDENTIFIER =
  "review/getMyForEdit/TRY_BY_IDENTIFIER";
const GET_MY_FOR_EDIT_SUCCESS_BY_IDENTIFIER =
  "review/getMyForEdit/SUCCESS_BY_IDENTIFIER";
const GET_MY_FOR_EDIT_FAILED_BY_IDENTIFIER =
  "review/getMyForEdit/FAILED_BY_IDENTIFIER";

const LIST_TRY = "review/list/TRY";
const LIST_SUCCESS = "review/list/SUCCESS";
const LIST_FAILED = "review/list/FAILED";

const LIST_TRY_BY_IDENTIFIER = "review/list/TRY_BY_IDENTIFIER";
const LIST_SUCCESS_BY_IDENTIFIER = "review/list/SUCCESS_BY_IDENTIFIER";
const LIST_FAILED_BY_IDENTIFIER = "review/list/FAILED_BY_IDENTIFIER";

const REMOVE_MINE_TRY = "review/removeMine/TRY";
const REMOVE_MINE_SUCCESS = "review/removeMine/SUCCESS";
const REMOVE_MINE_FAILED = "review/removeMine/FAILED";

const REMOVE_MINE_TRY_BY_IDENTIFIER = "review/removeMine/TRY_BY_IDENTIFIER";
const REMOVE_MINE_SUCCESS_BY_IDENTIFIER =
  "review/removeMine/SUCCESS_BY_IDENTIFIER";
const REMOVE_MINE_FAILED_BY_IDENTIFIER =
  "review/removeMine/FAILED_BY_IDENTIFIER";

const UPDATE_MINE_TRY = "review/updateMine/TRY";
const UPDATE_MINE_SUCCESS = "review/updateMine/SUCCESS";
const UPDATE_MINE_FAILED = "review/updateMine/FAILED";

const UPDATE_MINE_TRY_BY_IDENTIFIER = "review/updateMine/TRY_BY_IDENTIFIER";
const UPDATE_MINE_SUCCESS_BY_IDENTIFIER =
  "review/updateMine/SUCCESS_BY_IDENTIFIER";
const UPDATE_MINE_FAILED_BY_IDENTIFIER =
  "review/updateMine/FAILED_BY_IDENTIFIER";

const initialState = Map({
  create: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getMyForEdit: shape.merge(Map({ dataByIdentifier: Map({}) })),
  list: shape.merge(Map({ dataByIdentifier: Map({}) })),
  removeMine: shape.merge(Map({ dataByIdentifier: Map({}) })),
  updateMine: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_TRY:
    case GET_MY_FOR_EDIT_TRY:
    case LIST_TRY:
    case REMOVE_MINE_TRY:
    case UPDATE_MINE_TRY:
      return handleTry({ state, action });
    case CREATE_SUCCESS:
    case GET_MY_FOR_EDIT_SUCCESS:
    case LIST_SUCCESS:
    case REMOVE_MINE_SUCCESS:
    case UPDATE_MINE_SUCCESS:
      return handleSuccess({ state, action });
    case CREATE_FAILED:
    case GET_MY_FOR_EDIT_FAILED:
    case LIST_FAILED:
    case REMOVE_MINE_FAILED:
    case UPDATE_MINE_FAILED:
      return handleFailed({ state, action });
    case CREATE_TRY_BY_IDENTIFIER:
    case GET_MY_FOR_EDIT_TRY_BY_IDENTIFIER:
    case LIST_TRY_BY_IDENTIFIER:
    case REMOVE_MINE_TRY_BY_IDENTIFIER:
    case UPDATE_MINE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case CREATE_SUCCESS_BY_IDENTIFIER:
    case GET_MY_FOR_EDIT_SUCCESS_BY_IDENTIFIER:
    case LIST_SUCCESS_BY_IDENTIFIER:
    case REMOVE_MINE_SUCCESS_BY_IDENTIFIER:
    case UPDATE_MINE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case CREATE_FAILED_BY_IDENTIFIER:
    case GET_MY_FOR_EDIT_FAILED_BY_IDENTIFIER:
    case LIST_FAILED_BY_IDENTIFIER:
    case REMOVE_MINE_FAILED_BY_IDENTIFIER:
    case UPDATE_MINE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  CREATE_TRY,
  CREATE_SUCCESS,
  CREATE_FAILED,
  CREATE_TRY_BY_IDENTIFIER,
  CREATE_SUCCESS_BY_IDENTIFIER,
  CREATE_FAILED_BY_IDENTIFIER,

  GET_MY_FOR_EDIT_TRY,
  GET_MY_FOR_EDIT_SUCCESS,
  GET_MY_FOR_EDIT_FAILED,
  GET_MY_FOR_EDIT_TRY_BY_IDENTIFIER,
  GET_MY_FOR_EDIT_SUCCESS_BY_IDENTIFIER,
  GET_MY_FOR_EDIT_FAILED_BY_IDENTIFIER,

  LIST_TRY,
  LIST_SUCCESS,
  LIST_FAILED,
  LIST_TRY_BY_IDENTIFIER,
  LIST_SUCCESS_BY_IDENTIFIER,
  LIST_FAILED_BY_IDENTIFIER,

  REMOVE_MINE_TRY,
  REMOVE_MINE_SUCCESS,
  REMOVE_MINE_FAILED,
  REMOVE_MINE_TRY_BY_IDENTIFIER,
  REMOVE_MINE_SUCCESS_BY_IDENTIFIER,
  REMOVE_MINE_FAILED_BY_IDENTIFIER,

  UPDATE_MINE_TRY,
  UPDATE_MINE_SUCCESS,
  UPDATE_MINE_FAILED,
  UPDATE_MINE_TRY_BY_IDENTIFIER,
  UPDATE_MINE_SUCCESS_BY_IDENTIFIER,
  UPDATE_MINE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
