import call from "../../helpers/call";

function listAdmin({
  authToken,
  searchQuery,
  state,
  paidFrom,
  paidTo,
  createdFrom,
  createdTo,
  page,
  perPage,
  orderDirection,
  isSplitEnabled,
  ownerUserId,
  affiliateUserId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/list-admin`,
    payload: {
      searchQuery, // (Optional) Any text
      state, // (Optional) One of: paid, pending, refunded, partlyPaid, partlyRefunded, overpaid
      paidFrom, // (Optional) Timestamp value. Any positive integer.
      paidTo, // (Optional) Timestamp value. Any positive integer.
      createdFrom, // (Optional) Timestamp value. Any positive integer.
      createdTo, // (Optional) Timestamp value. Any positive integer.
      page, // (Optional) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any positive integer. Range: (0,1000]
      orderDirection, // (Optional) Valid values: desc, asc
      isSplitEnabled, // (Optional) Value is a boolean. True or false.
      ownerUserId, // (Optional) String 10-30 in length and starting with "u-"
      affiliateUserId, // (Optional) String 10-30 in length and starting with "u-"
    },
  });
}

export default listAdmin;
