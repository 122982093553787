import call from "../../helpers/call";

function subscribeNewsletter({ authToken, email, relatedProductId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/klavyio/subscribe-newsletter`,
    payload: {
      email, // (Required) Valid email address
      relatedProductId, // (Optional) String 10-30 in length and starting with "p-"
    },
  });
}

export default subscribeNewsletter;
