import call from "../../helpers/call";

function webhook({ authToken, token, payerId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/paypal/webhook`,
    payload: {
      token, // (Required) Any text
      payerId, // (Required) Any text
    },
  });
}

export default webhook;
