import usePrevious from "hook-use-previous";
import { useSelector } from "react-redux";
import useNotificationsMarkAsReadLoadingState from "stateManager/hooks/notifications/useNotificationsMarkAsReadLoadingState";

export default function useIsNotificationsMarkAsReadAfterSuccess(
  {},
  ___options
) {
  const loadingState = useNotificationsMarkAsReadLoadingState({}, ___options);
  const notifications = useSelector((___state) => ___state.notifications);
  const wasLoading = usePrevious(loadingState.get("isLoading"));

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      notifications.getIn([
        "markAsRead",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
        "wasSuccess",
      ]) === true
    );
  } else {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      notifications.getIn(["markAsRead", "lastResult", "wasSuccess"]) === true
    );
  }
}
