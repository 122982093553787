import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const ADD_TRY = "favorite/add/TRY";
const ADD_SUCCESS = "favorite/add/SUCCESS";
const ADD_FAILED = "favorite/add/FAILED";

const ADD_TRY_BY_IDENTIFIER = "favorite/add/TRY_BY_IDENTIFIER";
const ADD_SUCCESS_BY_IDENTIFIER = "favorite/add/SUCCESS_BY_IDENTIFIER";
const ADD_FAILED_BY_IDENTIFIER = "favorite/add/FAILED_BY_IDENTIFIER";

const COUNT_TRY = "favorite/count/TRY";
const COUNT_SUCCESS = "favorite/count/SUCCESS";
const COUNT_FAILED = "favorite/count/FAILED";

const COUNT_TRY_BY_IDENTIFIER = "favorite/count/TRY_BY_IDENTIFIER";
const COUNT_SUCCESS_BY_IDENTIFIER = "favorite/count/SUCCESS_BY_IDENTIFIER";
const COUNT_FAILED_BY_IDENTIFIER = "favorite/count/FAILED_BY_IDENTIFIER";

const GET_BY_PRODUCT_ID_TRY = "favorite/getByProductId/TRY";
const GET_BY_PRODUCT_ID_SUCCESS = "favorite/getByProductId/SUCCESS";
const GET_BY_PRODUCT_ID_FAILED = "favorite/getByProductId/FAILED";

const GET_BY_PRODUCT_ID_TRY_BY_IDENTIFIER =
  "favorite/getByProductId/TRY_BY_IDENTIFIER";
const GET_BY_PRODUCT_ID_SUCCESS_BY_IDENTIFIER =
  "favorite/getByProductId/SUCCESS_BY_IDENTIFIER";
const GET_BY_PRODUCT_ID_FAILED_BY_IDENTIFIER =
  "favorite/getByProductId/FAILED_BY_IDENTIFIER";

const LIST_TRY = "favorite/list/TRY";
const LIST_SUCCESS = "favorite/list/SUCCESS";
const LIST_FAILED = "favorite/list/FAILED";

const LIST_TRY_BY_IDENTIFIER = "favorite/list/TRY_BY_IDENTIFIER";
const LIST_SUCCESS_BY_IDENTIFIER = "favorite/list/SUCCESS_BY_IDENTIFIER";
const LIST_FAILED_BY_IDENTIFIER = "favorite/list/FAILED_BY_IDENTIFIER";

const REMOVE_TRY = "favorite/remove/TRY";
const REMOVE_SUCCESS = "favorite/remove/SUCCESS";
const REMOVE_FAILED = "favorite/remove/FAILED";

const REMOVE_TRY_BY_IDENTIFIER = "favorite/remove/TRY_BY_IDENTIFIER";
const REMOVE_SUCCESS_BY_IDENTIFIER = "favorite/remove/SUCCESS_BY_IDENTIFIER";
const REMOVE_FAILED_BY_IDENTIFIER = "favorite/remove/FAILED_BY_IDENTIFIER";

const initialState = Map({
  add: shape.merge(Map({ dataByIdentifier: Map({}) })),
  count: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getByProductId: shape.merge(Map({ dataByIdentifier: Map({}) })),
  list: shape.merge(Map({ dataByIdentifier: Map({}) })),
  remove: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_TRY:
    case COUNT_TRY:
    case GET_BY_PRODUCT_ID_TRY:
    case LIST_TRY:
    case REMOVE_TRY:
      return handleTry({ state, action });
    case ADD_SUCCESS:
    case COUNT_SUCCESS:
    case GET_BY_PRODUCT_ID_SUCCESS:
    case LIST_SUCCESS:
    case REMOVE_SUCCESS:
      return handleSuccess({ state, action });
    case ADD_FAILED:
    case COUNT_FAILED:
    case GET_BY_PRODUCT_ID_FAILED:
    case LIST_FAILED:
    case REMOVE_FAILED:
      return handleFailed({ state, action });
    case ADD_TRY_BY_IDENTIFIER:
    case COUNT_TRY_BY_IDENTIFIER:
    case GET_BY_PRODUCT_ID_TRY_BY_IDENTIFIER:
    case LIST_TRY_BY_IDENTIFIER:
    case REMOVE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case ADD_SUCCESS_BY_IDENTIFIER:
    case COUNT_SUCCESS_BY_IDENTIFIER:
    case GET_BY_PRODUCT_ID_SUCCESS_BY_IDENTIFIER:
    case LIST_SUCCESS_BY_IDENTIFIER:
    case REMOVE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case ADD_FAILED_BY_IDENTIFIER:
    case COUNT_FAILED_BY_IDENTIFIER:
    case GET_BY_PRODUCT_ID_FAILED_BY_IDENTIFIER:
    case LIST_FAILED_BY_IDENTIFIER:
    case REMOVE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  ADD_TRY,
  ADD_SUCCESS,
  ADD_FAILED,
  ADD_TRY_BY_IDENTIFIER,
  ADD_SUCCESS_BY_IDENTIFIER,
  ADD_FAILED_BY_IDENTIFIER,

  COUNT_TRY,
  COUNT_SUCCESS,
  COUNT_FAILED,
  COUNT_TRY_BY_IDENTIFIER,
  COUNT_SUCCESS_BY_IDENTIFIER,
  COUNT_FAILED_BY_IDENTIFIER,

  GET_BY_PRODUCT_ID_TRY,
  GET_BY_PRODUCT_ID_SUCCESS,
  GET_BY_PRODUCT_ID_FAILED,
  GET_BY_PRODUCT_ID_TRY_BY_IDENTIFIER,
  GET_BY_PRODUCT_ID_SUCCESS_BY_IDENTIFIER,
  GET_BY_PRODUCT_ID_FAILED_BY_IDENTIFIER,

  LIST_TRY,
  LIST_SUCCESS,
  LIST_FAILED,
  LIST_TRY_BY_IDENTIFIER,
  LIST_SUCCESS_BY_IDENTIFIER,
  LIST_FAILED_BY_IDENTIFIER,

  REMOVE_TRY,
  REMOVE_SUCCESS,
  REMOVE_FAILED,
  REMOVE_TRY_BY_IDENTIFIER,
  REMOVE_SUCCESS_BY_IDENTIFIER,
  REMOVE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
