import call from "../../helpers/call";

function count({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/favorite/count`,
    payload: {},
  });
}

export default count;
