import call from "../../helpers/call";

function uploadProfilePicture({ authToken, base64 }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/upload-profile-picture`,
    payload: {
      base64, // (Required) Is base64 image valid
    },
  });
}

export default uploadProfilePicture;
