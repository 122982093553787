import call from "../../helpers/call";

function create({
  authToken,
  stripeCardId,
  stripeApiCustomerId,
  stripeApiCardId,
  stripeApiPaymentMethodId,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/stripe-card/create`,
    payload: {
      stripeCardId, // (Required) String 10-30 in length and starting with "sc-"
      stripeApiCustomerId, // (Required) String 10-30 in length and starting with "cus-"
      stripeApiCardId, // (Required) String 10-30 in length and starting with "card-"
      stripeApiPaymentMethodId, // (Required) String 10-30 in length and starting with "pm-"
    },
  });
}

export default create;
