import useProductGetProductDetailsForAnalyticsLoadingState from "stateManager/hooks/product/useProductGetProductDetailsForAnalyticsLoadingState";
import useProductGetProductDetailsForAnalyticsResult from "stateManager/hooks/product/useProductGetProductDetailsForAnalyticsResult";

export default function useIsProductGetProductDetailsForAnalyticsLoaded(
  {},
  ___options
) {
  const loadingState = useProductGetProductDetailsForAnalyticsLoadingState(
    {},
    ___options
  );
  const resultState = useProductGetProductDetailsForAnalyticsResult(
    {},
    ___options
  );

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
