import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import subscriptionTracking from "../../reducers/subscriptionTracking";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

// Write any custom code here. It won't be overwritten.

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* trySubscriptionTrackingCreateByIdentifier({ data }) {
  const payload = {
    companyId: data.payload.companyId,
    subscriptionType: data.payload.subscriptionType,
    tags: data.payload.tags,
    purchasedAt: data.payload.purchasedAt,
    cost: data.payload.cost,
    currencyId: data.payload.currencyId,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.payload.repeatEveryTerm !== "undefined") {
    payload.repeatEveryTerm = data.payload.repeatEveryTerm;
  }

  if (typeof data.payload.repeatEveryNumber !== "undefined") {
    payload.repeatEveryNumber = data.payload.repeatEveryNumber;
  }

  if (typeof data.payload.isAutoRenewing !== "undefined") {
    payload.isAutoRenewing = data.payload.isAutoRenewing;
  }

  if (typeof data.payload.costCents !== "undefined") {
    payload.costCents = data.payload.costCents;
  }

  if (typeof data.payload.planName !== "undefined") {
    payload.planName = data.payload.planName;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  const result = yield sdk.subscriptionTrackingCreate(payload);

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: subscriptionTracking.actionTypes.CREATE_SUCCESS_BY_IDENTIFIER,
      data: {
        ___identifier: data.___identifier,
        payload: { data: result.data, meta: result.meta },
      },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: subscriptionTracking.actionTypes.CREATE_FAILED_BY_IDENTIFIER,
      data: { ___identifier: data.___identifier, payload: result.error },
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
