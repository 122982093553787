import call from "../../helpers/call";

function updateAdmin({
  authToken,
  userBillingDetailsId,
  name,
  email,
  address,
  city,
  state,
  postcode,
  country,
  vat,
  isDefault,
  defaultPaymentMethod,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user-billing-details/update-admin`,
    payload: {
      userBillingDetailsId, // (Required) String 10-30 in length and starting with "ub-"
      name, // (Optional) String. 1 to 50 symbols in length.
      email, // (Optional) Valid email address
      address, // (Optional) Any text
      city, // (Optional) Any text
      state, // (Optional) Any text
      postcode, // (Optional) Any text
      country, // (Optional) 2 letter country code. E.g. IN, LT, US, CZ, PE
      vat, // (Optional) Any text
      isDefault, // (Optional) Value is a boolean. True or false.
      defaultPaymentMethod, // (Optional) Valid values: stripe, paypal
    },
  });
}

export default updateAdmin;
