import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { Badge, CircularProgress, Drawer } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import useFavoriteAddLoadingState from "stateManager/hooks/favorite/useFavoriteAddLoadingState";
import useFavoriteListLoadingState from "stateManager/hooks/favorite/useFavoriteListLoadingState";
import useFavoriteListResult from "stateManager/hooks/favorite/useFavoriteListResult";
import useFavoriteRemoveLoadingState from "stateManager/hooks/favorite/useFavoriteRemoveLoadingState";
import FavoritesSidebar from "./FavoritesSidebar";

const drawerWidth = 776;

export default function FavoritesNavBtn() {
  const favoriteListLoadingState = useFavoriteListLoadingState({});
  const favAddLoadingState = useFavoriteAddLoadingState({});
  const favRemoveLoadingState = useFavoriteRemoveLoadingState({});

  const favoritesListResult = useFavoriteListResult({});
  const favoritesList = favoritesListResult.getIn(["result", "data"]);

  const favoritesCount = favoritesList?.size;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  const drawer = (
    <>
      <FavoritesSidebar
        favoritesList={favoritesList}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );

  const isLoading =
    typeof favoritesCount !== "number" ||
    favAddLoadingState.get("isLoading") === true ||
    favRemoveLoadingState.get("isLoading") === true ||
    favoriteListLoadingState.get("isLoading") === true;

  //   const container =
  //     window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <IconButton onClick={() => setDrawerOpen(true)}>
        <Badge
          color={isLoading ? "default" : "primary"}
          badgeContent={
            isLoading ? (
              <CircularProgress size={12} color="secondary" />
            ) : (
              favoritesCount
            )
          }
        >
          <FavoriteBorderOutlinedIcon />
        </Badge>
      </IconButton>

      <Drawer
        keepMounted={true}
        // container={container}
        variant="temporary"
        open={drawerOpen}
        anchor={"right"}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            maxWidth: "90%",
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}
