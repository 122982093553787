import call from "../../helpers/call";

function addFundsAdmin({ authToken, ownerUserId, amountCents, description }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/wallet/add-funds-admin`,
    payload: {
      ownerUserId, // (Required) String 10-30 in length and starting with "u-"
      amountCents, // (Required) Any positive integer. 0 is not valid.
      description, // (Required) Any string of 0-255 symbols in length.
    },
  });
}

export default addFundsAdmin;
