import call from "../../helpers/call";

function create({
  authToken,
  name,
  discountCouponCategoryId,
  expiresAt,
  maximumRedemption,
  discountType,
  discountAmountCents,
  discountAmountPercentageMultipliedByTen,
  listOfProductPlansToApply,
  maximumRedemptionsForSingleAccount,
  overrideExpireTimeMinutes,
  overrideNoPurchaseTimeInDays,
  discountCouponCount,
  discountCouponPrefix,
  isForNewUsersOnly,
  newUserRegistrationStartsAt,
  isAllowedAfterSamePlanRefund,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discount-coupon/create`,
    payload: {
      name, // (Required) Any string of 0-120 symbols in length.
      discountCouponCategoryId, // (Required) String 10-30 in length and starting with "dcc-"
      expiresAt, // (Optional) Must be positive integer or zero
      maximumRedemption, // (Required) Is integer equal to 1 or more
      discountType, // (Required) one of: fixed, percentage
      discountAmountCents, // (Optional) Must be positive integer or zero
      discountAmountPercentageMultipliedByTen, // (Optional) Must be positive integer or zero
      listOfProductPlansToApply, // (Optional) Is array of product plan ids valid
      maximumRedemptionsForSingleAccount, // (Optional) Is integer equal to 1 or more
      overrideExpireTimeMinutes, // (Optional) Must be positive integer or zero
      overrideNoPurchaseTimeInDays, // (Optional) Must be positive integer or zero
      discountCouponCount, // (Optional) Is integer equal to 1 or more
      discountCouponPrefix, // (Required) Any string of 0-120 symbols in length.
      isForNewUsersOnly, // (Optional) Value is a boolean. True or false.
      newUserRegistrationStartsAt, // (Optional) Must be positive integer or zero
      isAllowedAfterSamePlanRefund, // (Optional) Value is a boolean. True or false.
    },
  });
}

export default create;
