import BtnAddVipPlanToCart from "jsx/BtnAddVipPlanToCart";
import React from "react";
import ReactDOM from "react-dom";

export default function AddVipPlanToCart() {
  const portals = [];
  const btns = document.querySelectorAll(".btn-add-vip-plan-to-cart");

  for (let i = 0; i < btns.length; i++) {
    const placeholder = btns[i];
    const productPlanId = placeholder.dataset.productPlanId;
    const valueCentsPerItem = placeholder.dataset.valueCentsPerItem;
    const productPlanName = placeholder.dataset.productPlanName;
    const productName = placeholder.dataset.productName;
    // if there was any placeholder content, e.g. loading state - clear it out
    placeholder.innerHTML = "";
    portals.push(
      ReactDOM.createPortal(
        <BtnAddVipPlanToCart
          productPlanId={productPlanId}
          valueCentsPerItem={valueCentsPerItem}
          productPlanName={productPlanName}
          productName={productName}
        />,
        placeholder
      )
    );
  }

  return portals;
}
