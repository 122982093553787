import call from "../../helpers/call";

function list({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/stripe-subscription/list`,
    payload: {},
  });
}

export default list;
