import call from "../../helpers/call";

function listAdmin({ authToken, page, perPage, orderDirection, userId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/cart/list-admin`,
    payload: {
      page, // (Optional) Must be any integer from 1 and up. First page is "1"
      perPage, // (Optional) Any positive integer. Range: (0,1000]
      orderDirection, // (Optional) Valid values: desc, asc
      userId, // (Optional) String 10-30 in length and starting with "u-"
    },
  });
}

export default listAdmin;
