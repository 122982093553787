import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const CREATE_TRY = "subscriptionTracking/create/TRY";
const CREATE_SUCCESS = "subscriptionTracking/create/SUCCESS";
const CREATE_FAILED = "subscriptionTracking/create/FAILED";

const CREATE_TRY_BY_IDENTIFIER =
  "subscriptionTracking/create/TRY_BY_IDENTIFIER";
const CREATE_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/create/SUCCESS_BY_IDENTIFIER";
const CREATE_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/create/FAILED_BY_IDENTIFIER";

const EXPORT_CSV_TRY = "subscriptionTracking/exportCsv/TRY";
const EXPORT_CSV_SUCCESS = "subscriptionTracking/exportCsv/SUCCESS";
const EXPORT_CSV_FAILED = "subscriptionTracking/exportCsv/FAILED";

const EXPORT_CSV_TRY_BY_IDENTIFIER =
  "subscriptionTracking/exportCsv/TRY_BY_IDENTIFIER";
const EXPORT_CSV_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/exportCsv/SUCCESS_BY_IDENTIFIER";
const EXPORT_CSV_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/exportCsv/FAILED_BY_IDENTIFIER";

const GET_TRY = "subscriptionTracking/get/TRY";
const GET_SUCCESS = "subscriptionTracking/get/SUCCESS";
const GET_FAILED = "subscriptionTracking/get/FAILED";

const GET_TRY_BY_IDENTIFIER = "subscriptionTracking/get/TRY_BY_IDENTIFIER";
const GET_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/get/SUCCESS_BY_IDENTIFIER";
const GET_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/get/FAILED_BY_IDENTIFIER";

const LIST_TRY = "subscriptionTracking/list/TRY";
const LIST_SUCCESS = "subscriptionTracking/list/SUCCESS";
const LIST_FAILED = "subscriptionTracking/list/FAILED";

const LIST_TRY_BY_IDENTIFIER = "subscriptionTracking/list/TRY_BY_IDENTIFIER";
const LIST_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/list/SUCCESS_BY_IDENTIFIER";
const LIST_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/list/FAILED_BY_IDENTIFIER";

const REMOVE_TRY = "subscriptionTracking/remove/TRY";
const REMOVE_SUCCESS = "subscriptionTracking/remove/SUCCESS";
const REMOVE_FAILED = "subscriptionTracking/remove/FAILED";

const REMOVE_TRY_BY_IDENTIFIER =
  "subscriptionTracking/remove/TRY_BY_IDENTIFIER";
const REMOVE_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/remove/SUCCESS_BY_IDENTIFIER";
const REMOVE_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/remove/FAILED_BY_IDENTIFIER";

const REPORTS_TRY = "subscriptionTracking/reports/TRY";
const REPORTS_SUCCESS = "subscriptionTracking/reports/SUCCESS";
const REPORTS_FAILED = "subscriptionTracking/reports/FAILED";

const REPORTS_TRY_BY_IDENTIFIER =
  "subscriptionTracking/reports/TRY_BY_IDENTIFIER";
const REPORTS_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/reports/SUCCESS_BY_IDENTIFIER";
const REPORTS_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/reports/FAILED_BY_IDENTIFIER";

const SPENDINGS_GRAPH_LIST_TRY = "subscriptionTracking/spendingsGraphList/TRY";
const SPENDINGS_GRAPH_LIST_SUCCESS =
  "subscriptionTracking/spendingsGraphList/SUCCESS";
const SPENDINGS_GRAPH_LIST_FAILED =
  "subscriptionTracking/spendingsGraphList/FAILED";

const SPENDINGS_GRAPH_LIST_TRY_BY_IDENTIFIER =
  "subscriptionTracking/spendingsGraphList/TRY_BY_IDENTIFIER";
const SPENDINGS_GRAPH_LIST_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/spendingsGraphList/SUCCESS_BY_IDENTIFIER";
const SPENDINGS_GRAPH_LIST_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/spendingsGraphList/FAILED_BY_IDENTIFIER";

const SPENDINGS_LIST_TRY = "subscriptionTracking/spendingsList/TRY";
const SPENDINGS_LIST_SUCCESS = "subscriptionTracking/spendingsList/SUCCESS";
const SPENDINGS_LIST_FAILED = "subscriptionTracking/spendingsList/FAILED";

const SPENDINGS_LIST_TRY_BY_IDENTIFIER =
  "subscriptionTracking/spendingsList/TRY_BY_IDENTIFIER";
const SPENDINGS_LIST_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/spendingsList/SUCCESS_BY_IDENTIFIER";
const SPENDINGS_LIST_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/spendingsList/FAILED_BY_IDENTIFIER";

const TAG_LIST_TRY = "subscriptionTracking/tagList/TRY";
const TAG_LIST_SUCCESS = "subscriptionTracking/tagList/SUCCESS";
const TAG_LIST_FAILED = "subscriptionTracking/tagList/FAILED";

const TAG_LIST_TRY_BY_IDENTIFIER =
  "subscriptionTracking/tagList/TRY_BY_IDENTIFIER";
const TAG_LIST_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/tagList/SUCCESS_BY_IDENTIFIER";
const TAG_LIST_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/tagList/FAILED_BY_IDENTIFIER";

const UPDATE_TRY = "subscriptionTracking/update/TRY";
const UPDATE_SUCCESS = "subscriptionTracking/update/SUCCESS";
const UPDATE_FAILED = "subscriptionTracking/update/FAILED";

const UPDATE_TRY_BY_IDENTIFIER =
  "subscriptionTracking/update/TRY_BY_IDENTIFIER";
const UPDATE_SUCCESS_BY_IDENTIFIER =
  "subscriptionTracking/update/SUCCESS_BY_IDENTIFIER";
const UPDATE_FAILED_BY_IDENTIFIER =
  "subscriptionTracking/update/FAILED_BY_IDENTIFIER";

const initialState = Map({
  create: shape.merge(Map({ dataByIdentifier: Map({}) })),
  exportCsv: shape.merge(Map({ dataByIdentifier: Map({}) })),
  get: shape.merge(Map({ dataByIdentifier: Map({}) })),
  list: shape.merge(Map({ dataByIdentifier: Map({}) })),
  remove: shape.merge(Map({ dataByIdentifier: Map({}) })),
  reports: shape.merge(Map({ dataByIdentifier: Map({}) })),
  spendingsGraphList: shape.merge(Map({ dataByIdentifier: Map({}) })),
  spendingsList: shape.merge(Map({ dataByIdentifier: Map({}) })),
  tagList: shape.merge(Map({ dataByIdentifier: Map({}) })),
  update: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_TRY:
    case EXPORT_CSV_TRY:
    case GET_TRY:
    case LIST_TRY:
    case REMOVE_TRY:
    case REPORTS_TRY:
    case SPENDINGS_GRAPH_LIST_TRY:
    case SPENDINGS_LIST_TRY:
    case TAG_LIST_TRY:
    case UPDATE_TRY:
      return handleTry({ state, action });
    case CREATE_SUCCESS:
    case EXPORT_CSV_SUCCESS:
    case GET_SUCCESS:
    case LIST_SUCCESS:
    case REMOVE_SUCCESS:
    case REPORTS_SUCCESS:
    case SPENDINGS_GRAPH_LIST_SUCCESS:
    case SPENDINGS_LIST_SUCCESS:
    case TAG_LIST_SUCCESS:
    case UPDATE_SUCCESS:
      return handleSuccess({ state, action });
    case CREATE_FAILED:
    case EXPORT_CSV_FAILED:
    case GET_FAILED:
    case LIST_FAILED:
    case REMOVE_FAILED:
    case REPORTS_FAILED:
    case SPENDINGS_GRAPH_LIST_FAILED:
    case SPENDINGS_LIST_FAILED:
    case TAG_LIST_FAILED:
    case UPDATE_FAILED:
      return handleFailed({ state, action });
    case CREATE_TRY_BY_IDENTIFIER:
    case EXPORT_CSV_TRY_BY_IDENTIFIER:
    case GET_TRY_BY_IDENTIFIER:
    case LIST_TRY_BY_IDENTIFIER:
    case REMOVE_TRY_BY_IDENTIFIER:
    case REPORTS_TRY_BY_IDENTIFIER:
    case SPENDINGS_GRAPH_LIST_TRY_BY_IDENTIFIER:
    case SPENDINGS_LIST_TRY_BY_IDENTIFIER:
    case TAG_LIST_TRY_BY_IDENTIFIER:
    case UPDATE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case CREATE_SUCCESS_BY_IDENTIFIER:
    case EXPORT_CSV_SUCCESS_BY_IDENTIFIER:
    case GET_SUCCESS_BY_IDENTIFIER:
    case LIST_SUCCESS_BY_IDENTIFIER:
    case REMOVE_SUCCESS_BY_IDENTIFIER:
    case REPORTS_SUCCESS_BY_IDENTIFIER:
    case SPENDINGS_GRAPH_LIST_SUCCESS_BY_IDENTIFIER:
    case SPENDINGS_LIST_SUCCESS_BY_IDENTIFIER:
    case TAG_LIST_SUCCESS_BY_IDENTIFIER:
    case UPDATE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case CREATE_FAILED_BY_IDENTIFIER:
    case EXPORT_CSV_FAILED_BY_IDENTIFIER:
    case GET_FAILED_BY_IDENTIFIER:
    case LIST_FAILED_BY_IDENTIFIER:
    case REMOVE_FAILED_BY_IDENTIFIER:
    case REPORTS_FAILED_BY_IDENTIFIER:
    case SPENDINGS_GRAPH_LIST_FAILED_BY_IDENTIFIER:
    case SPENDINGS_LIST_FAILED_BY_IDENTIFIER:
    case TAG_LIST_FAILED_BY_IDENTIFIER:
    case UPDATE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  CREATE_TRY,
  CREATE_SUCCESS,
  CREATE_FAILED,
  CREATE_TRY_BY_IDENTIFIER,
  CREATE_SUCCESS_BY_IDENTIFIER,
  CREATE_FAILED_BY_IDENTIFIER,

  EXPORT_CSV_TRY,
  EXPORT_CSV_SUCCESS,
  EXPORT_CSV_FAILED,
  EXPORT_CSV_TRY_BY_IDENTIFIER,
  EXPORT_CSV_SUCCESS_BY_IDENTIFIER,
  EXPORT_CSV_FAILED_BY_IDENTIFIER,

  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  GET_TRY_BY_IDENTIFIER,
  GET_SUCCESS_BY_IDENTIFIER,
  GET_FAILED_BY_IDENTIFIER,

  LIST_TRY,
  LIST_SUCCESS,
  LIST_FAILED,
  LIST_TRY_BY_IDENTIFIER,
  LIST_SUCCESS_BY_IDENTIFIER,
  LIST_FAILED_BY_IDENTIFIER,

  REMOVE_TRY,
  REMOVE_SUCCESS,
  REMOVE_FAILED,
  REMOVE_TRY_BY_IDENTIFIER,
  REMOVE_SUCCESS_BY_IDENTIFIER,
  REMOVE_FAILED_BY_IDENTIFIER,

  REPORTS_TRY,
  REPORTS_SUCCESS,
  REPORTS_FAILED,
  REPORTS_TRY_BY_IDENTIFIER,
  REPORTS_SUCCESS_BY_IDENTIFIER,
  REPORTS_FAILED_BY_IDENTIFIER,

  SPENDINGS_GRAPH_LIST_TRY,
  SPENDINGS_GRAPH_LIST_SUCCESS,
  SPENDINGS_GRAPH_LIST_FAILED,
  SPENDINGS_GRAPH_LIST_TRY_BY_IDENTIFIER,
  SPENDINGS_GRAPH_LIST_SUCCESS_BY_IDENTIFIER,
  SPENDINGS_GRAPH_LIST_FAILED_BY_IDENTIFIER,

  SPENDINGS_LIST_TRY,
  SPENDINGS_LIST_SUCCESS,
  SPENDINGS_LIST_FAILED,
  SPENDINGS_LIST_TRY_BY_IDENTIFIER,
  SPENDINGS_LIST_SUCCESS_BY_IDENTIFIER,
  SPENDINGS_LIST_FAILED_BY_IDENTIFIER,

  TAG_LIST_TRY,
  TAG_LIST_SUCCESS,
  TAG_LIST_FAILED,
  TAG_LIST_TRY_BY_IDENTIFIER,
  TAG_LIST_SUCCESS_BY_IDENTIFIER,
  TAG_LIST_FAILED_BY_IDENTIFIER,

  UPDATE_TRY,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  UPDATE_TRY_BY_IDENTIFIER,
  UPDATE_SUCCESS_BY_IDENTIFIER,
  UPDATE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
