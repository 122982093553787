export default function openLoginScreenToContinue() {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const loginWindow = window.open(
    `https://app.${process.env.REACT_APP_MAIN_DOMAIN}/auth/login`,
    "pg-login-window",
    `height=${Math.min(vh, 700)},width=${Math.min(500, vw)}`
  );
  loginWindow.onunload = () => {
    // eslint-disable-next-line
    location.reload();
  };
}
