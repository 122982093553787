import call from "../../helpers/call";

function get({ authToken, imageId, dimensions }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/image/get`,
    payload: {
      imageId, // (Required) String 10-30 in length and starting with "i-"
      dimensions, // (Required) Any string of 0-120 symbols in length.
    },
  });
}

export default get;
