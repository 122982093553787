import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import useUrl from "hook-use-url";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import React from "react";
import useIsProductListMyProductsLoaded from "stateManager/hooks/product/useIsProductListMyProductsLoaded";
import useActions from "stateManager/hooks/useActions";

export default function DialogDiscussionQuestion({
  open,
  onClose,
  productId,
  ...props
}) {
  const url = useUrl();
  const theme = useTheme();
  const actions = useActions();
  const isLoading = useIsProductListMyProductsLoaded({}) === false;
  // const productId = url.get({ variable: "product-id" });

  const [comment, setComment] = React.useState(
    props.comment ? props.comment : ""
  );
  const handleClickChange = (e) => {
    setComment(e.target.value);
  };
  const handleSubmit = () => {
    if (comment !== "") {
      if (
        props.discussionId &&
        moment().diff(moment.unix(props.createdAt), "hour") <= 1
      ) {
        actions.discussion.editMyDiscussion({
          comment,
          discussionId: props.discussionId,
        });
      } else {
        actions.discussion.postNewForProduct({
          productId,
          comment,
        });
      }

      handleCloseClick();
      url.multipleActions({
        removeArray: ["discussion-id", "reply-per-page", "reply-page", "page"],
        setPairs: [
          {
            variable: "product-id",
            value: productId,
          },
        ],
      });
    }
  };

  const handleCloseClick = () => {
    setComment("");
    onClose();
  };

  return (
    <Dialog maxWidth={"sm"} fullWidth open={open} onClose={handleCloseClick}>
      <div style={{ margin: theme.spacing(4) }}>
        {isLoading === false ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant={"h3"}>
              {props.discussionId ? "Edit the " : "Ask a "} Question
            </Typography>
            <IconButton
              style={{ border: "1px solid" }}
              onClick={handleCloseClick}
              size={"small"}
            >
              <CloseIcon fontSize={"inherit"} />
            </IconButton>
          </div>
        ) : null}
        {isLoading ? (
          <div style={{ margin: theme.spacing(4, 8), textAlign: "center" }}>
            <CircularProgress color={"secondary"} />
          </div>
        ) : (
          <div style={{ margin: theme.spacing(2, 0, 0) }}>
            <TextField
              placeholder="Write your question..."
              multiline
              fullWidth
              rows={10}
              value={comment}
              onChange={handleClickChange}
            />
            {/*<Box>*/}
            {/*  <FormGroup>*/}
            {/*    <FormControlLabel*/}
            {/*      control={<Checkbox />}*/}
            {/*      label={*/}
            {/*        <Typography variant={"body1"}>*/}
            {/*          Notify me via email when someone replies to this answer*/}
            {/*        </Typography>*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </FormGroup>*/}
            {/*</Box>*/}
            <Box
              sx={{
                mt: theme.spacing(3),
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                variant={"contained"}
                onClick={handleSubmit}
                sx={{ mb: theme.spacing(1) }}
              >
                {props.discussionId ? "Update" : "Submit"} Question
              </Button>
              <Button onClick={handleCloseClick}>Cancel</Button>
            </Box>
          </div>
        )}
      </div>
    </Dialog>
  );
}
