import call from "../../helpers/call";

function creatAdmin({ authToken, variable }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/options/creat-admin`,
    payload: {
      variable, // (Required) Any string of 0-255 symbols in length.
    },
  });
}

export default creatAdmin;
