import call from "../../helpers/call";

function getListOfActiveAffiliates({ authToken, searchQuery }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate/get-list-of-active-affiliates`,
    payload: {
      searchQuery, // (Optional) Any text
    },
  });
}

export default getListOfActiveAffiliates;
