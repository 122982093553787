/* eslint-disable no-restricted-globals */
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LoadingButton from "@mui/lab/LoadingButton";
import Cookies from "js-cookie";
import { useEffect } from "react";
import useCartV2ItemUpdateLoadingState from "stateManager/hooks/cart/useCartV2ItemUpdateLoadingState";
import useActions from "stateManager/hooks/useActions";
import useIsUserGetLoaded from "stateManager/hooks/user/useIsUserGetLoaded";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

export default function BtnAddToCart({
  productPlanId,
  valueCentsPerItem,
  productPlanName,
}) {
  const actions = useActions();
  const loadingState = useCartV2ItemUpdateLoadingState({});
  const user = useUserGetResult({});
  const isUserLoaded = useIsUserGetLoaded({});
  const isLoading =
    (loadingState.get("isLoading") &&
      loadingState.getIn(["payload", "productPlanId"]) === productPlanId) ||
    isUserLoaded === false;

  const isLoggedIn =
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";

  useEffect(() => {
    if (Cookies.get("addToCartAfterLogin") && isLoggedIn) {
      try {
        // don't want json.parse() to throw errors and crash app if some detail missing
        actions.cart.v2ItemUpdate(
          JSON.parse(Cookies.get("addToCartAfterLogin"))
        );
        Cookies.remove("addToCartAfterLogin", {
          path: "/",
          domain:
            location.host.indexOf("localhost") !== -1
              ? "localhost"
              : `.${process.env.REACT_APP_MAIN_DOMAIN}`,
        });
      } catch (error) {
        console.log("failed to read or remove cookie", error);
      }
    }
  }, [isLoggedIn]);

  const addProductToCookies = () => {
    try {
      // don't want json.stringify() to throw errors and crash app if some detail missing
      Cookies.set(
        "addToCartAfterLogin",
        JSON.stringify({
          productPlanId,
          quantity: 1,
          addQuantity: 1,
          removeQuantity: 0,
          valueCentsPerItem,
          productPlanName,
        }),
        {
          path: "/",
          domain:
            location.host.indexOf("localhost") !== -1
              ? "localhost"
              : `.${process.env.REACT_APP_MAIN_DOMAIN}`,
        }
      );
    } catch (error) {
      console.log("failed to set cookie", error);
    }
  };

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      size={"large"}
      startIcon={<AddShoppingCartIcon />}
      fullWidth
      onClick={() => {
        if (isLoading === false) {
          if (isLoggedIn) {
            actions.cart.v2ItemUpdate({
              productPlanId,
              quantity: 1,
              addQuantity: 1,
              removeQuantity: 0,
              valueCentsPerItem,
              productPlanName,
            });
          } else {
            addProductToCookies();

            actions.globalMessages.addToQueue({
              message: "Need to login first",
            });
            openLoginScreenToContinue();
          }
        }
      }}
    >
      Add to Cart
    </LoadingButton>
  );
}
