import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useNotificationsListMineLoadingState from "../notifications/useNotificationsListMineLoadingState";
import useNotificationsCreateDemoNotificationLoadingState from "../notifications/useNotificationsCreateDemoNotificationLoadingState";
// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/notifications/tryNotificationsListMine";

export default function useNotificationsListMineResult(
  { page, perPage },
  ___options
) {
  const ___notifications = useSelector((___state) => ___state.notifications);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const notificationsListMineLoadingState =
    useNotificationsListMineLoadingState({}, ___options);
  const ___isLoading = notificationsListMineLoadingState.get("isLoading");
  const isNotificationsCreateDemoNotificationLoadingState =
    useNotificationsCreateDemoNotificationLoadingState({}, ___options);

  useEffect(() => {
    if (
      ___isLoading === false &&
      isNotificationsCreateDemoNotificationLoadingState.get("isLoading") ===
        false &&
      page &&
      perPage
    ) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.notifications.listMine({ page, perPage });
      } else {
        ___actions.notifications.listMine({ page, perPage });
      }
    }
  }, [isNotificationsCreateDemoNotificationLoadingState, page, perPage]);

  if (___options && ___options.identifier) {
    return (
      ___notifications.getIn([
        "listMine",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___notifications.getIn(["listMine", "lastResult"]);
}
