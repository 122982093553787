import call from "../../helpers/call";

function getAdmin({ authToken, affiliateBuyLogId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate-buy-log/get-admin`,
    payload: {
      affiliateBuyLogId, // (Required) String 10-30 in length and starting with "abl-"
    },
  });
}

export default getAdmin;
