import call from "../../helpers/call";

function create({ authToken, name, description }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/discount-coupon-category/create`,
    payload: {
      name, // (Required) Is string120 and not empty
      description, // (Optional) Any text
    },
  });
}

export default create;
