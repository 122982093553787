import call from "../../helpers/call";

function update({
  authToken,
  splitPaymentSettingId,
  customerRegisteredEarlierThanAt,
  userTotalSpentCents,
  splitPaymentMessageTresholdPercentage,
  splitOptions,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/split-payment-setting/update`,
    payload: {
      splitPaymentSettingId, // (Required) String 10-30 in length and starting with "sps-"
      customerRegisteredEarlierThanAt, // (Optional) Must be positive integer or zero
      userTotalSpentCents, // (Optional) Must be positive integer or zero
      splitPaymentMessageTresholdPercentage, // (Optional) Must be positive integer or zero
      splitOptions, // (Optional) Check: value && Array.isArray(value)
    },
  });
}

export default update;
