import call from "../../helpers/call";

function modifiersAddAdmin({
  authToken,
  invoiceId,
  action,
  amount,
  description,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/modifiers-add-admin`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      action, // (Required) One of: coverFromPaypal, coverFromStripe, coverFromWallet, decreaseTotal, increaseTotal, refundToPaypal, refundToStripe, refundToWallet, coverFromCoinbase, refundToCoinbase
      amount, // (Required) Any positive integer. 0 is not valid.
      description, // (Required) Any string of 0-255 symbols in length.
    },
  });
}

export default modifiersAddAdmin;
