import call from "../../helpers/call";

function removeLogAdmin({ authToken, walletLogId, walletId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/wallet-logs/remove-log-admin`,
    payload: {
      walletLogId, // (Required) String 10-30 in length and starting with "wl-"
      walletId, // (Required) String 10-30 in length and starting with "w-"
    },
  });
}

export default removeLogAdmin;
