import call from "../../helpers/call";

function get({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/wallet/get`,
    payload: {},
  });
}

export default get;
