import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useUserMetaGetLoadingState from "../userMeta/useUserMetaGetLoadingState";
import useUserGetLoadingState from "../user/useUserGetLoadingState";
import useUserMetaUpdateLoadingState from "../userMeta/useUserMetaUpdateLoadingState";
// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/userMeta/tryUserMetaGet";

export default function useUserMetaGetResult({}, ___options) {
  const ___userMeta = useSelector((___state) => ___state.userMeta);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const userMetaGetLoadingState = useUserMetaGetLoadingState({}, ___options);
  const ___isLoading = userMetaGetLoadingState.get("isLoading");
  const isUserGetLoadingState = useUserGetLoadingState({}, ___options);
  const isUserMetaUpdateLoadingState = useUserMetaUpdateLoadingState(
    {},
    ___options
  );

  useEffect(() => {
    if (
      ___isLoading === false &&
      isUserGetLoadingState.get("isLoading") === false &&
      isUserMetaUpdateLoadingState.get("isLoading") === false
    ) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.userMeta.get({});
      } else {
        ___actions.userMeta.get({});
      }
    }
  }, [isUserGetLoadingState, isUserMetaUpdateLoadingState]);

  if (___options && ___options.identifier) {
    return (
      ___userMeta.getIn([
        "get",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___userMeta.getIn(["get", "lastResult"]);
}
