import useUrl from "hook-use-url";
import useHandlePixelsIntegrations from "hooks/useHandlePixelsIntegrations";
import Cookies from "js-cookie";
import HandleSsoRedirect from "jsx/HandleSsoRedirect";
// import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

export default function HandlePixels() {
  // Communicate SSO login -- START
  const url = useUrl();
  const redirectUrl = url.get({ variable: "return-url" });
  // const activeUser = useUserGetResult({});
  // const isLoggedIn = Boolean(activeUser.getIn(["result", "data", "userId"]));
  const authToken = localStorage.getItem("authToken");

  let content = null;
  if (
    redirectUrl &&
    redirectUrl === "https://community.pitchground.com/oauth2/callback"
  ) {
    if (!authToken) {
      openLoginScreenToContinue();
    } else {
      content = <HandleSsoRedirect />;
    }
  }
  // Communicate SSO login -- END

  useHandlePixelsIntegrations();
  return content;
}
