import call from "../../helpers/call";

function coverInvoice({ authToken, invoiceId, amountCents }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/wallet/cover-invoice`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      amountCents, // (Required) Any positive integer. 0 is not valid.
    },
  });
}

export default coverInvoice;
