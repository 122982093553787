import call from "../../helpers/call";

function get({ authToken, slug, productId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/get`,
    payload: {
      slug, // (Required) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
      productId, // (Optional) String 10-30 in length and starting with "p-"
    },
  });
}

export default get;
