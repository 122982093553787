import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const CREATE_DEMO_NOTIFICATION_TRY = "notifications/createDemoNotification/TRY";
const CREATE_DEMO_NOTIFICATION_SUCCESS =
  "notifications/createDemoNotification/SUCCESS";
const CREATE_DEMO_NOTIFICATION_FAILED =
  "notifications/createDemoNotification/FAILED";

const CREATE_DEMO_NOTIFICATION_TRY_BY_IDENTIFIER =
  "notifications/createDemoNotification/TRY_BY_IDENTIFIER";
const CREATE_DEMO_NOTIFICATION_SUCCESS_BY_IDENTIFIER =
  "notifications/createDemoNotification/SUCCESS_BY_IDENTIFIER";
const CREATE_DEMO_NOTIFICATION_FAILED_BY_IDENTIFIER =
  "notifications/createDemoNotification/FAILED_BY_IDENTIFIER";

const GET_UNREAD_COUNT_TRY = "notifications/getUnreadCount/TRY";
const GET_UNREAD_COUNT_SUCCESS = "notifications/getUnreadCount/SUCCESS";
const GET_UNREAD_COUNT_FAILED = "notifications/getUnreadCount/FAILED";

const GET_UNREAD_COUNT_TRY_BY_IDENTIFIER =
  "notifications/getUnreadCount/TRY_BY_IDENTIFIER";
const GET_UNREAD_COUNT_SUCCESS_BY_IDENTIFIER =
  "notifications/getUnreadCount/SUCCESS_BY_IDENTIFIER";
const GET_UNREAD_COUNT_FAILED_BY_IDENTIFIER =
  "notifications/getUnreadCount/FAILED_BY_IDENTIFIER";

const LIST_MINE_TRY = "notifications/listMine/TRY";
const LIST_MINE_SUCCESS = "notifications/listMine/SUCCESS";
const LIST_MINE_FAILED = "notifications/listMine/FAILED";

const LIST_MINE_TRY_BY_IDENTIFIER = "notifications/listMine/TRY_BY_IDENTIFIER";
const LIST_MINE_SUCCESS_BY_IDENTIFIER =
  "notifications/listMine/SUCCESS_BY_IDENTIFIER";
const LIST_MINE_FAILED_BY_IDENTIFIER =
  "notifications/listMine/FAILED_BY_IDENTIFIER";

const MARK_ALL_AS_READ_TRY = "notifications/markAllAsRead/TRY";
const MARK_ALL_AS_READ_SUCCESS = "notifications/markAllAsRead/SUCCESS";
const MARK_ALL_AS_READ_FAILED = "notifications/markAllAsRead/FAILED";

const MARK_ALL_AS_READ_TRY_BY_IDENTIFIER =
  "notifications/markAllAsRead/TRY_BY_IDENTIFIER";
const MARK_ALL_AS_READ_SUCCESS_BY_IDENTIFIER =
  "notifications/markAllAsRead/SUCCESS_BY_IDENTIFIER";
const MARK_ALL_AS_READ_FAILED_BY_IDENTIFIER =
  "notifications/markAllAsRead/FAILED_BY_IDENTIFIER";

const MARK_AS_READ_TRY = "notifications/markAsRead/TRY";
const MARK_AS_READ_SUCCESS = "notifications/markAsRead/SUCCESS";
const MARK_AS_READ_FAILED = "notifications/markAsRead/FAILED";

const MARK_AS_READ_TRY_BY_IDENTIFIER =
  "notifications/markAsRead/TRY_BY_IDENTIFIER";
const MARK_AS_READ_SUCCESS_BY_IDENTIFIER =
  "notifications/markAsRead/SUCCESS_BY_IDENTIFIER";
const MARK_AS_READ_FAILED_BY_IDENTIFIER =
  "notifications/markAsRead/FAILED_BY_IDENTIFIER";

const initialState = Map({
  createDemoNotification: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getUnreadCount: shape.merge(Map({ dataByIdentifier: Map({}) })),
  listMine: shape.merge(Map({ dataByIdentifier: Map({}) })),
  markAllAsRead: shape.merge(Map({ dataByIdentifier: Map({}) })),
  markAsRead: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_DEMO_NOTIFICATION_TRY:
    case GET_UNREAD_COUNT_TRY:
    case LIST_MINE_TRY:
    case MARK_ALL_AS_READ_TRY:
    case MARK_AS_READ_TRY:
      return handleTry({ state, action });
    case CREATE_DEMO_NOTIFICATION_SUCCESS:
    case GET_UNREAD_COUNT_SUCCESS:
    case LIST_MINE_SUCCESS:
    case MARK_ALL_AS_READ_SUCCESS:
    case MARK_AS_READ_SUCCESS:
      return handleSuccess({ state, action });
    case CREATE_DEMO_NOTIFICATION_FAILED:
    case GET_UNREAD_COUNT_FAILED:
    case LIST_MINE_FAILED:
    case MARK_ALL_AS_READ_FAILED:
    case MARK_AS_READ_FAILED:
      return handleFailed({ state, action });
    case CREATE_DEMO_NOTIFICATION_TRY_BY_IDENTIFIER:
    case GET_UNREAD_COUNT_TRY_BY_IDENTIFIER:
    case LIST_MINE_TRY_BY_IDENTIFIER:
    case MARK_ALL_AS_READ_TRY_BY_IDENTIFIER:
    case MARK_AS_READ_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case CREATE_DEMO_NOTIFICATION_SUCCESS_BY_IDENTIFIER:
    case GET_UNREAD_COUNT_SUCCESS_BY_IDENTIFIER:
    case LIST_MINE_SUCCESS_BY_IDENTIFIER:
    case MARK_ALL_AS_READ_SUCCESS_BY_IDENTIFIER:
    case MARK_AS_READ_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case CREATE_DEMO_NOTIFICATION_FAILED_BY_IDENTIFIER:
    case GET_UNREAD_COUNT_FAILED_BY_IDENTIFIER:
    case LIST_MINE_FAILED_BY_IDENTIFIER:
    case MARK_ALL_AS_READ_FAILED_BY_IDENTIFIER:
    case MARK_AS_READ_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  CREATE_DEMO_NOTIFICATION_TRY,
  CREATE_DEMO_NOTIFICATION_SUCCESS,
  CREATE_DEMO_NOTIFICATION_FAILED,
  CREATE_DEMO_NOTIFICATION_TRY_BY_IDENTIFIER,
  CREATE_DEMO_NOTIFICATION_SUCCESS_BY_IDENTIFIER,
  CREATE_DEMO_NOTIFICATION_FAILED_BY_IDENTIFIER,

  GET_UNREAD_COUNT_TRY,
  GET_UNREAD_COUNT_SUCCESS,
  GET_UNREAD_COUNT_FAILED,
  GET_UNREAD_COUNT_TRY_BY_IDENTIFIER,
  GET_UNREAD_COUNT_SUCCESS_BY_IDENTIFIER,
  GET_UNREAD_COUNT_FAILED_BY_IDENTIFIER,

  LIST_MINE_TRY,
  LIST_MINE_SUCCESS,
  LIST_MINE_FAILED,
  LIST_MINE_TRY_BY_IDENTIFIER,
  LIST_MINE_SUCCESS_BY_IDENTIFIER,
  LIST_MINE_FAILED_BY_IDENTIFIER,

  MARK_ALL_AS_READ_TRY,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILED,
  MARK_ALL_AS_READ_TRY_BY_IDENTIFIER,
  MARK_ALL_AS_READ_SUCCESS_BY_IDENTIFIER,
  MARK_ALL_AS_READ_FAILED_BY_IDENTIFIER,

  MARK_AS_READ_TRY,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILED,
  MARK_AS_READ_TRY_BY_IDENTIFIER,
  MARK_AS_READ_SUCCESS_BY_IDENTIFIER,
  MARK_AS_READ_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
