import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const AUDIT_LOGS_LIST_TRY = "submitProduct/auditLogsList/TRY";
const AUDIT_LOGS_LIST_SUCCESS = "submitProduct/auditLogsList/SUCCESS";
const AUDIT_LOGS_LIST_FAILED = "submitProduct/auditLogsList/FAILED";

const AUDIT_LOGS_LIST_TRY_BY_IDENTIFIER =
  "submitProduct/auditLogsList/TRY_BY_IDENTIFIER";
const AUDIT_LOGS_LIST_SUCCESS_BY_IDENTIFIER =
  "submitProduct/auditLogsList/SUCCESS_BY_IDENTIFIER";
const AUDIT_LOGS_LIST_FAILED_BY_IDENTIFIER =
  "submitProduct/auditLogsList/FAILED_BY_IDENTIFIER";

const CREATE_TRY = "submitProduct/create/TRY";
const CREATE_SUCCESS = "submitProduct/create/SUCCESS";
const CREATE_FAILED = "submitProduct/create/FAILED";

const CREATE_TRY_BY_IDENTIFIER = "submitProduct/create/TRY_BY_IDENTIFIER";
const CREATE_SUCCESS_BY_IDENTIFIER =
  "submitProduct/create/SUCCESS_BY_IDENTIFIER";
const CREATE_FAILED_BY_IDENTIFIER = "submitProduct/create/FAILED_BY_IDENTIFIER";

const GET_TRY = "submitProduct/get/TRY";
const GET_SUCCESS = "submitProduct/get/SUCCESS";
const GET_FAILED = "submitProduct/get/FAILED";

const GET_TRY_BY_IDENTIFIER = "submitProduct/get/TRY_BY_IDENTIFIER";
const GET_SUCCESS_BY_IDENTIFIER = "submitProduct/get/SUCCESS_BY_IDENTIFIER";
const GET_FAILED_BY_IDENTIFIER = "submitProduct/get/FAILED_BY_IDENTIFIER";

const GET_COUNT_TRY = "submitProduct/getCount/TRY";
const GET_COUNT_SUCCESS = "submitProduct/getCount/SUCCESS";
const GET_COUNT_FAILED = "submitProduct/getCount/FAILED";

const GET_COUNT_TRY_BY_IDENTIFIER = "submitProduct/getCount/TRY_BY_IDENTIFIER";
const GET_COUNT_SUCCESS_BY_IDENTIFIER =
  "submitProduct/getCount/SUCCESS_BY_IDENTIFIER";
const GET_COUNT_FAILED_BY_IDENTIFIER =
  "submitProduct/getCount/FAILED_BY_IDENTIFIER";

const IMAGE_UPLOAD_TRY = "submitProduct/imageUpload/TRY";
const IMAGE_UPLOAD_SUCCESS = "submitProduct/imageUpload/SUCCESS";
const IMAGE_UPLOAD_FAILED = "submitProduct/imageUpload/FAILED";

const IMAGE_UPLOAD_TRY_BY_IDENTIFIER =
  "submitProduct/imageUpload/TRY_BY_IDENTIFIER";
const IMAGE_UPLOAD_SUCCESS_BY_IDENTIFIER =
  "submitProduct/imageUpload/SUCCESS_BY_IDENTIFIER";
const IMAGE_UPLOAD_FAILED_BY_IDENTIFIER =
  "submitProduct/imageUpload/FAILED_BY_IDENTIFIER";

const LIST_TRY = "submitProduct/list/TRY";
const LIST_SUCCESS = "submitProduct/list/SUCCESS";
const LIST_FAILED = "submitProduct/list/FAILED";

const LIST_TRY_BY_IDENTIFIER = "submitProduct/list/TRY_BY_IDENTIFIER";
const LIST_SUCCESS_BY_IDENTIFIER = "submitProduct/list/SUCCESS_BY_IDENTIFIER";
const LIST_FAILED_BY_IDENTIFIER = "submitProduct/list/FAILED_BY_IDENTIFIER";

const UPDATE_TRY = "submitProduct/update/TRY";
const UPDATE_SUCCESS = "submitProduct/update/SUCCESS";
const UPDATE_FAILED = "submitProduct/update/FAILED";

const UPDATE_TRY_BY_IDENTIFIER = "submitProduct/update/TRY_BY_IDENTIFIER";
const UPDATE_SUCCESS_BY_IDENTIFIER =
  "submitProduct/update/SUCCESS_BY_IDENTIFIER";
const UPDATE_FAILED_BY_IDENTIFIER = "submitProduct/update/FAILED_BY_IDENTIFIER";

const initialState = Map({
  auditLogsList: shape.merge(Map({ dataByIdentifier: Map({}) })),
  create: shape.merge(Map({ dataByIdentifier: Map({}) })),
  get: shape.merge(Map({ dataByIdentifier: Map({}) })),
  getCount: shape.merge(Map({ dataByIdentifier: Map({}) })),
  imageUpload: shape.merge(Map({ dataByIdentifier: Map({}) })),
  list: shape.merge(Map({ dataByIdentifier: Map({}) })),
  update: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUDIT_LOGS_LIST_TRY:
    case CREATE_TRY:
    case GET_TRY:
    case GET_COUNT_TRY:
    case IMAGE_UPLOAD_TRY:
    case LIST_TRY:
    case UPDATE_TRY:
      return handleTry({ state, action });
    case AUDIT_LOGS_LIST_SUCCESS:
    case CREATE_SUCCESS:
    case GET_SUCCESS:
    case GET_COUNT_SUCCESS:
    case IMAGE_UPLOAD_SUCCESS:
    case LIST_SUCCESS:
    case UPDATE_SUCCESS:
      return handleSuccess({ state, action });
    case AUDIT_LOGS_LIST_FAILED:
    case CREATE_FAILED:
    case GET_FAILED:
    case GET_COUNT_FAILED:
    case IMAGE_UPLOAD_FAILED:
    case LIST_FAILED:
    case UPDATE_FAILED:
      return handleFailed({ state, action });
    case AUDIT_LOGS_LIST_TRY_BY_IDENTIFIER:
    case CREATE_TRY_BY_IDENTIFIER:
    case GET_TRY_BY_IDENTIFIER:
    case GET_COUNT_TRY_BY_IDENTIFIER:
    case IMAGE_UPLOAD_TRY_BY_IDENTIFIER:
    case LIST_TRY_BY_IDENTIFIER:
    case UPDATE_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case AUDIT_LOGS_LIST_SUCCESS_BY_IDENTIFIER:
    case CREATE_SUCCESS_BY_IDENTIFIER:
    case GET_SUCCESS_BY_IDENTIFIER:
    case GET_COUNT_SUCCESS_BY_IDENTIFIER:
    case IMAGE_UPLOAD_SUCCESS_BY_IDENTIFIER:
    case LIST_SUCCESS_BY_IDENTIFIER:
    case UPDATE_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case AUDIT_LOGS_LIST_FAILED_BY_IDENTIFIER:
    case CREATE_FAILED_BY_IDENTIFIER:
    case GET_FAILED_BY_IDENTIFIER:
    case GET_COUNT_FAILED_BY_IDENTIFIER:
    case IMAGE_UPLOAD_FAILED_BY_IDENTIFIER:
    case LIST_FAILED_BY_IDENTIFIER:
    case UPDATE_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  AUDIT_LOGS_LIST_TRY,
  AUDIT_LOGS_LIST_SUCCESS,
  AUDIT_LOGS_LIST_FAILED,
  AUDIT_LOGS_LIST_TRY_BY_IDENTIFIER,
  AUDIT_LOGS_LIST_SUCCESS_BY_IDENTIFIER,
  AUDIT_LOGS_LIST_FAILED_BY_IDENTIFIER,

  CREATE_TRY,
  CREATE_SUCCESS,
  CREATE_FAILED,
  CREATE_TRY_BY_IDENTIFIER,
  CREATE_SUCCESS_BY_IDENTIFIER,
  CREATE_FAILED_BY_IDENTIFIER,

  GET_TRY,
  GET_SUCCESS,
  GET_FAILED,
  GET_TRY_BY_IDENTIFIER,
  GET_SUCCESS_BY_IDENTIFIER,
  GET_FAILED_BY_IDENTIFIER,

  GET_COUNT_TRY,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILED,
  GET_COUNT_TRY_BY_IDENTIFIER,
  GET_COUNT_SUCCESS_BY_IDENTIFIER,
  GET_COUNT_FAILED_BY_IDENTIFIER,

  IMAGE_UPLOAD_TRY,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_FAILED,
  IMAGE_UPLOAD_TRY_BY_IDENTIFIER,
  IMAGE_UPLOAD_SUCCESS_BY_IDENTIFIER,
  IMAGE_UPLOAD_FAILED_BY_IDENTIFIER,

  LIST_TRY,
  LIST_SUCCESS,
  LIST_FAILED,
  LIST_TRY_BY_IDENTIFIER,
  LIST_SUCCESS_BY_IDENTIFIER,
  LIST_FAILED_BY_IDENTIFIER,

  UPDATE_TRY,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  UPDATE_TRY_BY_IDENTIFIER,
  UPDATE_SUCCESS_BY_IDENTIFIER,
  UPDATE_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
