import call from "../../helpers/call";

function emailMeBeforeDealEnds({ authToken, productId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/klavyio/email-me-before-deal-ends`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
    },
  });
}

export default emailMeBeforeDealEnds;
