import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const LIST_TRY = "stripeSubscription/list/TRY";
const LIST_SUCCESS = "stripeSubscription/list/SUCCESS";
const LIST_FAILED = "stripeSubscription/list/FAILED";

const LIST_TRY_BY_IDENTIFIER = "stripeSubscription/list/TRY_BY_IDENTIFIER";
const LIST_SUCCESS_BY_IDENTIFIER =
  "stripeSubscription/list/SUCCESS_BY_IDENTIFIER";
const LIST_FAILED_BY_IDENTIFIER =
  "stripeSubscription/list/FAILED_BY_IDENTIFIER";

const initialState = Map({
  list: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_TRY:
      return handleTry({ state, action });
    case LIST_SUCCESS:
      return handleSuccess({ state, action });
    case LIST_FAILED:
      return handleFailed({ state, action });
    case LIST_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case LIST_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case LIST_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  LIST_TRY,
  LIST_SUCCESS,
  LIST_FAILED,
  LIST_TRY_BY_IDENTIFIER,
  LIST_SUCCESS_BY_IDENTIFIER,
  LIST_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
