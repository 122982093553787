import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const FORCE_LIST_TRY = "vendorPaymentInfo/forceList/TRY";
const FORCE_LIST_SUCCESS = "vendorPaymentInfo/forceList/SUCCESS";
const FORCE_LIST_FAILED = "vendorPaymentInfo/forceList/FAILED";

const FORCE_LIST_TRY_BY_IDENTIFIER =
  "vendorPaymentInfo/forceList/TRY_BY_IDENTIFIER";
const FORCE_LIST_SUCCESS_BY_IDENTIFIER =
  "vendorPaymentInfo/forceList/SUCCESS_BY_IDENTIFIER";
const FORCE_LIST_FAILED_BY_IDENTIFIER =
  "vendorPaymentInfo/forceList/FAILED_BY_IDENTIFIER";

const initialState = Map({
  forceList: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FORCE_LIST_TRY:
      return handleTry({ state, action });
    case FORCE_LIST_SUCCESS:
      return handleSuccess({ state, action });
    case FORCE_LIST_FAILED:
      return handleFailed({ state, action });
    case FORCE_LIST_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case FORCE_LIST_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case FORCE_LIST_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  FORCE_LIST_TRY,
  FORCE_LIST_SUCCESS,
  FORCE_LIST_FAILED,
  FORCE_LIST_TRY_BY_IDENTIFIER,
  FORCE_LIST_SUCCESS_BY_IDENTIFIER,
  FORCE_LIST_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
