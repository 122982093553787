import sdk from "../../sdks/@pitchground/sdk";

import {put} from "redux-saga/effects";
import invoice from "../../reducers/invoice";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryInvoiceAutomatedRefund({data}) {
  const payload = {
    couponId: data.couponId,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.invoiceAutomatedRefund(payload);
  } catch (e) {
    result = {
      error: {code: "#2FGA_tryInvoiceAutomatedRefund", message: e.message},
    };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        message:
          "Refund initialized. You should get your $ back in couple minutes.",
      },
    });

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: invoice.actionTypes.AUTOMATED_REFUND_SUCCESS,
      data: {data: result.data, meta: result.meta},
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: {
        message:
          result.error.humanMessage || result.error.message || "Failed to automated refund invoice",
      },
    });

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({
      type: invoice.actionTypes.AUTOMATED_REFUND_FAILED,
      data: result.error,
    });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
