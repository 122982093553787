import call from "../../helpers/call";

function getProductDetailsForAnalytics({ authToken, productId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/get-product-details-for-analytics`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
    },
  });
}

export default getProductDetailsForAnalytics;
