import { useSelector } from "react-redux";
import { shape as ___shape } from "stateManager/utils/reducerHelpers";

import { useEffect } from "react";
import useActions from "../useActions";
import useActionsByIdentifier from "../useActionsByIdentifier";
import useCartVipDiscountListLoadingState from "../cart/useCartVipDiscountListLoadingState";

// ___relatedSaga is here just for developer tools to quickly jump to related Redux Saga
// where action of this hook happens when need to add e.g. success/error message
// after SDK call or need to check which SDK call relates to this hook or any details
// about the saga
// eslint-disable-line
import ___relatedSaga from "stateManager/sagas/cart/tryCartVipDiscountList";

export default function useCartVipDiscountListResult({}, ___options) {
  const ___cart = useSelector((___state) => ___state.cart);

  const ___actions = useActions();
  const ___actionsByIdentifier = useActionsByIdentifier({
    identifier: ___options && ___options.identifier,
  });
  const cartVipDiscountListLoadingState = useCartVipDiscountListLoadingState(
    {},
    ___options
  );
  const ___isLoading = cartVipDiscountListLoadingState.get("isLoading");

  useEffect(() => {
    if (___isLoading === false) {
      if (___options && ___options.identifier) {
        ___actionsByIdentifier.cart.vipDiscountList({});
      } else {
        ___actions.cart.vipDiscountList({});
      }
    }
  }, []);

  if (___options && ___options.identifier) {
    return (
      ___cart.getIn([
        "vipDiscountList",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
      ]) || ___shape.get("lastResult")
    );
  }

  return ___cart.getIn(["vipDiscountList", "lastResult"]);
}
