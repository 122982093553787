import { useSelector } from "react-redux";

export default function useGetGlobalMessages({ queue }) {
  if (
    !queue ||
    [
      "topCenter",
      "topRight",
      "bottomRight",
      "bottomCenter",
      "bottomLeft",
      "topLeft",
    ].includes(queue) === false
  ) {
    queue = "bottomLeft";
  }
  const globalMessages = useSelector((___state) => ___state.globalMessages);
  return globalMessages.getIn(["queues", queue]);
}
