import useUserGetLoadingState from "stateManager/hooks/user/useUserGetLoadingState";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";

export default function useIsUserGetLoaded({}, ___options) {
  const loadingState = useUserGetLoadingState({}, ___options);
  const resultState = useUserGetResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
