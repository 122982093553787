import call from "../../helpers/call";

function markAsRead({ authToken, notificationId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/notifications/mark-as-read`,
    payload: {
      notificationId, // (Required) String 10-30 in length and starting with "no-"
    },
  });
}

export default markAsRead;
