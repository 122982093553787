import sdk from "../../sdks/@pitchground/sdk";

import { put } from "redux-saga/effects";
import user from "../../reducers/user";

// #### CUSTOM__AFTER_IMPORTS ----- START ----

import globalMessages from "../../reducers/globalMessages";

const _get = require("lodash.get");

// #### CUSTOM__AFTER_IMPORTS ----- END ------

export default function* tryUserSignUp({ data }) {
  const payload = {
    recaptchaToken: data.recaptchaToken,
  };

  // todo remove this from hardcoded code
  if (localStorage.getItem("authToken")) {
    payload.authToken = localStorage.getItem("authToken");
  }

  if (typeof data.email !== "undefined") {
    payload.email = data.email;
  }

  if (typeof data.password !== "undefined") {
    payload.password = data.password;
  }

  if (typeof data.name !== "undefined") {
    payload.name = data.name;
  }

  if (typeof data.username !== "undefined") {
    payload.username = data.username;
  }

  if (typeof data.emailPublic !== "undefined") {
    payload.emailPublic = data.emailPublic;
  }

  if (typeof data.twitter !== "undefined") {
    payload.twitter = data.twitter;
  }

  if (typeof data.countryCode !== "undefined") {
    payload.countryCode = data.countryCode;
  }

  if (typeof data.dateOfBirth !== "undefined") {
    payload.dateOfBirth = data.dateOfBirth;
  }

  if (typeof data.location !== "undefined") {
    payload.location = data.location;
  }

  if (typeof data.profileImageBase64 !== "undefined") {
    payload.profileImageBase64 = data.profileImageBase64;
  }

  if (typeof data.biography !== "undefined") {
    payload.biography = data.biography;
  }

  if (typeof data.affiliateUserId !== "undefined") {
    payload.affiliateUserId = data.affiliateUserId;
  }

  if (typeof data.productId !== "undefined") {
    payload.productId = data.productId;
  }

  if (typeof data.productPlanId !== "undefined") {
    payload.productPlanId = data.productPlanId;
  }

  if (typeof data.referralMagicCode !== "undefined") {
    payload.referralMagicCode = data.referralMagicCode;
  }

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_PAYLOAD_BUILT ----- END ------

  let result = null;
  try {
    result = yield sdk.userSignUp(payload);
  } catch (e) {
    result = { error: { code: "#2FGA_tryUserSignUp", message: e.message } };
  }

  // #### CUSTOM__AFTER_ASYNC_CALL ----- START ----

  // Write any custom code here. It won't be overwritten.

  // #### CUSTOM__AFTER_ASYNC_CALL ----- END ------

  if (result.error === null) {
    // #### CUSTOM__AFTER_SUCCESS ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_SUCCESS ----- END ------

    yield put({
      type: user.actionTypes.SIGN_UP_SUCCESS,
      data: { data: result.data, meta: result.meta },
    });

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- START ----

    yield put({
      type: globalMessages.actionTypes.ADD_TO_QUEUE,
      data: { queue: "bottomLeft", message: "Registered. Pleas Login." },
    });
    setTimeout(() => window.location.replace("/auth/login"), 400);

    // #### CUSTOM__AFTER_SUCCESS_ACTIONS ----- END ------
  } else {
    // #### CUSTOM__AFTER_ERROR ----- START ----

    // Write any custom code here. It won't be overwritten.

    // #### CUSTOM__AFTER_ERROR ----- END ------

    yield put({ type: user.actionTypes.SIGN_UP_FAILED, data: result.error });

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- START ----

    if (_get(result, "error.message") === "EMAIL_REGISTERED") {
      yield put({
        type: globalMessages.actionTypes.ADD_TO_QUEUE,
        data: { queue: "bottomLeft", message: "Email already registered." },
      });
    } else {
      yield put({
        type: globalMessages.actionTypes.ADD_TO_QUEUE,
        data: { queue: "bottomLeft", message: _get(result, "error.message") },
      });
    }

    // #### CUSTOM__AFTER_ERROR_ACTIONS ----- END ------
  }
}
