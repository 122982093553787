import call from "../../helpers/call";

function listAvailableIncentives({ authToken }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/list-available-incentives`,
    payload: {},
  });
}

export default listAvailableIncentives;
