import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ReplyIcon from "@mui/icons-material/Reply";
import useUrl from "hook-use-url";
import useDiscussionListReplies from "jsx/VendorDiscussions/hooks/useDiscussionsListReplies";
import SingleItem from "jsx/VendorDiscussions/SingleDiscussion/SingleItem";
import React from "react";
import useActions from "stateManager/hooks/useActions";
import useIsUserGetLoaded from "stateManager/hooks/user/useIsUserGetLoaded";
import useUserGetResult from "stateManager/hooks/user/useUserGetResult";
import openLoginScreenToContinue from "utils/openLoginScreenToContinue";

const moment = require("moment");

const SingleDiscussion = ({ data }) => {
  const theme = useTheme();
  const actions = useActions();
  const url = useUrl();
  const user = useUserGetResult({});
  const isUserLoaded = useIsUserGetLoaded({});
  const isLoggedIn =
    isUserLoaded &&
    user &&
    user.get("wasSuccess") === true &&
    typeof user.getIn(["result", "data", "userId"]) !== "undefined";

  const discussionId = url.get({ variable: "discussion-id" });
  const replyPerPage = parseInt(url.get({ variable: "reply-per-page" })) || 5;
  const replyPage = parseInt(url.get({ variable: "reply-page" })) || 1;

  const [reply, setReply] = React.useState("");
  const [isReplyOpen, setIsReplyOpen] = React.useState(false);
  const [isReplySubmitting, setIsReplySubmitting] = React.useState(false);
  const [discussionIdForEdit, setDiscussionIdForEdit] = React.useState("");

  React.useEffect(() => {
    if (data.discussionId !== discussionId) setIsReplyOpen(false);
    if (data.discussionId === discussionId) {
      // window.location.hash = discussionId;
      // console.log("ref", discussionId, ref.current);
      // if (ref.current) {
      //   console.log("current", ref.current);
      //   ref.current.scrollIntoView({ behavior: "smooth" });
      // }

      const discussionElement = document.getElementById(discussionId);
      if (discussionElement) {
        discussionElement.scrollIntoView();
      }
    }
  }, [discussionId]);

  const handleSubmitReplyClick = () => {
    if (isLoggedIn) {
      if (reply.trim() !== "") {
        if (isReplySubmitting === false) {
          if (discussionIdForEdit) {
            actions.discussion.editMyDiscussion({
              comment: reply,
              discussionId: discussionIdForEdit,
            });
          } else {
            actions.discussion.postNewReply({
              parentDiscussionId: data.discussionId,
              comment: reply,
            });
          }
        }
        setReply("");
        setIsReplySubmitting(true);
      } else {
        actions.globalMessages.addToQueue({
          message: "Please type something.",
        });
        setReply("");
      }
    } else {
      actions.globalMessages.addToQueue({
        message: "Need to login first",
      });
      openLoginScreenToContinue();
    }
  };

  const handleReplyClick = (id) => {
    if (isLoggedIn) {
      setIsReplyOpen(true);
      setReply("");
      setDiscussionIdForEdit("");
      url.multipleActions({
        setPairs: [
          {
            variable: "discussion-id",
            value: data.discussionId,
          },
        ],
      });
    } else {
      actions.globalMessages.addToQueue({
        message: "Need to login first",
      });
      openLoginScreenToContinue();
    }
  };

  const handleReplyEditClick = (replyData) => {
    setReply(replyData.comment);
    setIsReplyOpen(true);
    setDiscussionIdForEdit(replyData.discussionId);
    url.multipleActions({
      setPairs: [
        {
          variable: "discussion-id",
          value: data.discussionId,
        },
      ],
    });
    const discussionElement = document.getElementById(
      "reply-" + data.discussionId
    );
    if (discussionElement) {
      discussionElement.scrollIntoView({
        // behavior: "smooth",
        block: "end",
      });
    }
  };

  const handleReplyCancelClick = () => {
    setIsReplyOpen(false);
  };

  const listDiscussionReplies = useDiscussionListReplies({
    parentDiscussionId: data.discussionId,
    page: replyPage,
    perPage: replyPerPage,
  });

  let rows = [];
  let totalReplies = 0;
  if (!listDiscussionReplies.isLoading && listDiscussionReplies.wasSuccess) {
    const pagination = listDiscussionReplies.pagination.toJS();
    if (listDiscussionReplies.data.toJS().length > 0) {
      totalReplies = pagination.totalItems;
      listDiscussionReplies.data
        .toJS()
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .forEach((singleReply) => {
          rows.push(
            <SingleItem
              data={singleReply}
              itemType={"reply"}
              onEditClick={handleReplyEditClick}
              key={singleReply.discussionId}
            />
          );
        });
    }
  }

  return (
    <>
      <Card
        id={data.discussionId}
        // ref={ref}
        sx={{
          marginBottom: 4,
          border: "1px solid #E5E1E1",
          padding: { xs: theme.spacing(2, 1), sm: theme.spacing(2, 3) },
          background: theme.palette.background.default,
        }}
        variant="outlined"
      >
        <Box sx={{ display: "flex", mb: theme.spacing(2) }}>
          <Avatar
            alt={data.authorName}
            src={data.author && data.author.avatar_url}
            sx={{
              width: 52,
              height: 52,
              bgcolor: data.isValidatedBuyer ? "#0B79D0" : "#4CAF50",
            }}
          >
            {data.authorName.match(/\b\w/g).join("").slice(0, 2).toUpperCase()}
          </Avatar>
          <Box
            sx={{
              borderLeft: totalReplies > 0 ? "2px solid #E5E5E5" : "none",
              ml: "-27px",
              mt: theme.spacing(8),
              pl: theme.spacing(3),
              flexGrow: 1,
            }}
          >
            <Box sx={{ mt: theme.spacing(-8) }}>
              <SingleItem data={data} itemType={"question"} />
            </Box>
            {listDiscussionReplies.isLoading ? (
              <Box
                sx={{
                  m: theme.spacing(4, 0),
                  textAlign: "center",
                }}
              >
                <CircularProgress color={"secondary"} size={14} />
              </Box>
            ) : (
              rows
            )}
            <Box>
              <Box
                sx={{
                  mb: theme.spacing(1),
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                {isReplyOpen ? null : (
                  <Button
                    sx={{ textTransform: "none", mr: theme.spacing(2) }}
                    onClick={() => handleReplyClick(data.discussionId)}
                    variant={"contained"}
                    startIcon={<ReplyIcon />}
                    size={"small"}
                  >
                    Reply{" "}
                    {data.childDiscussionsCount > 0 &&
                      `(${data.childDiscussionsCount})`}
                  </Button>
                )}
                {totalReplies > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                      mt: { xs: theme.spacing(1), sm: 0 },
                    }}
                  >
                    {totalReplies > replyPerPage && (
                      <Button
                        sx={{ textTransform: "none" }}
                        size={"small"}
                        onClick={() =>
                          url.multipleActions({
                            setPairs: [
                              {
                                variable: "discussion-id",
                                value: data.discussionId,
                              },
                              {
                                variable: "reply-per-page",
                                value: replyPerPage + 5,
                              },
                            ],
                          })
                        }
                      >
                        Show More Replies
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
              {isReplyOpen && (
                <Box id={`reply-${discussionId}`}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: theme.spacing(1), sm: theme.spacing(3) },
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <TextField
                      placeholder={"Write your reply..."}
                      size={"small"}
                      sx={{ flexGrow: 1 }}
                      disabled={isReplySubmitting}
                      value={reply}
                      autoFocus
                      onChange={(e) => setReply(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleSubmitReplyClick();
                        }
                      }}
                    />
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={handleSubmitReplyClick}
                      variant={"contained"}
                      disabled={isReplySubmitting}
                    >
                      {isReplySubmitting ? (
                        <CircularProgress size={12} color={"secondary"} />
                      ) : discussionIdForEdit ? (
                        "Update Reply"
                      ) : (
                        "Submit Reply"
                      )}
                    </Button>
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={handleReplyCancelClick}
                    >
                      Cancel
                    </Button>
                  </Box>
                  {/*<FormGroup>*/}
                  {/*  <FormControlLabel*/}
                  {/*    control={<Checkbox />}*/}
                  {/*    label={*/}
                  {/*      <Typography variant={"body1"}>*/}
                  {/*        Notify me via email when someone replies to this answer*/}
                  {/*      </Typography>*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</FormGroup>*/}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};
export default SingleDiscussion;
