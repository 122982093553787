import Subscribe from "jsx/Subscribe";
import React from "react";
import ReactDOM from "react-dom";

export default function PortalSubscribe() {
  const portals = [];
  const places = document.querySelectorAll(".subscribe-input");

  for (let i = 0; i < places.length; i++) {
    const place = places[i];
    // if there was any place content, e.g. loading state - clear it out
    place.innerHTML = "";
    portals.push(ReactDOM.createPortal(<Subscribe />, place));
  }

  return portals;
}
