import call from "../../helpers/call";

function removeAdmin({ authToken, productId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/remove-admin`,
    payload: {
      productId, // (Required) String 10-30 in length and starting with "p-"
    },
  });
}

export default removeAdmin;
