import call from "../../helpers/call";

function view({ authToken, productSlug, at, lastViewedUrl }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/view`,
    payload: {
      productSlug, // (Required) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
      at, // (Required) Timestamp value. Any positive integer.
      lastViewedUrl, // (Optional) Any text
    },
  });
}

export default view;
