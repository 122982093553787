import call from "../../helpers/call";

function get({ authToken, subscriptionId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/subscription/get`,
    payload: {
      subscriptionId, // (Required) String 10-30 in length and starting with "su-"
    },
  });
}

export default get;
