import { useEffect } from "react";
import { useSelector } from "react-redux";
import useActionsByIdentifier from "stateManager/hooks/useActionsByIdentifier";

export default function useDiscussionListReplies({
  parentDiscussionId,
  page,
  perPage,
}) {
  const ___discussion = useSelector((___state) => ___state.discussion);
  const ___actions = useActionsByIdentifier({ identifier: parentDiscussionId });

  useEffect(() => {
    if (parentDiscussionId && page && perPage) {
      ___actions.discussion.listRepliesForDiscussion({
        parentDiscussionId,
        page,
        perPage,
      });
    }
  }, [parentDiscussionId, page, perPage]);

  return {
    data: ___discussion.getIn([
      "listRepliesForDiscussion",
      "dataByIdentifier",
      parentDiscussionId,
      "lastResult",
      "result",
      "data",
    ]),
    pagination: ___discussion.getIn([
      "listRepliesForDiscussion",
      "dataByIdentifier",
      parentDiscussionId,
      "lastResult",
      "result",
      "meta",
      "pagination",
    ]),
    wasSuccess: ___discussion.getIn([
      "listRepliesForDiscussion",
      "dataByIdentifier",
      parentDiscussionId,
      "lastResult",
      "wasSuccess",
    ]),
    isLoading: ___discussion.getIn([
      "listRepliesForDiscussion",
      "dataByIdentifier",
      parentDiscussionId,
      "loading",
      "isLoading",
    ]),
  };
}
