import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const SUBSCRIPTION_LIST_TRY = "productPlan/subscriptionList/TRY";
const SUBSCRIPTION_LIST_SUCCESS = "productPlan/subscriptionList/SUCCESS";
const SUBSCRIPTION_LIST_FAILED = "productPlan/subscriptionList/FAILED";

const SUBSCRIPTION_LIST_TRY_BY_IDENTIFIER =
  "productPlan/subscriptionList/TRY_BY_IDENTIFIER";
const SUBSCRIPTION_LIST_SUCCESS_BY_IDENTIFIER =
  "productPlan/subscriptionList/SUCCESS_BY_IDENTIFIER";
const SUBSCRIPTION_LIST_FAILED_BY_IDENTIFIER =
  "productPlan/subscriptionList/FAILED_BY_IDENTIFIER";

const initialState = Map({
  subscriptionList: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUBSCRIPTION_LIST_TRY:
      return handleTry({ state, action });
    case SUBSCRIPTION_LIST_SUCCESS:
      return handleSuccess({ state, action });
    case SUBSCRIPTION_LIST_FAILED:
      return handleFailed({ state, action });
    case SUBSCRIPTION_LIST_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case SUBSCRIPTION_LIST_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case SUBSCRIPTION_LIST_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  SUBSCRIPTION_LIST_TRY,
  SUBSCRIPTION_LIST_SUCCESS,
  SUBSCRIPTION_LIST_FAILED,
  SUBSCRIPTION_LIST_TRY_BY_IDENTIFIER,
  SUBSCRIPTION_LIST_SUCCESS_BY_IDENTIFIER,
  SUBSCRIPTION_LIST_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
