import call from "../../helpers/call";

function update({
  authToken,
  password,
  name,
  username,
  email,
  twitter,
  dateOfBirth,
  location,
  profileImageBase64,
  biography,
  defaultPaymentMethod,
  countryCode,
  timezone,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/update`,
    payload: {
      password, // (Optional) Any text
      name, // (Optional) Any text
      username, // (Optional) Any text
      email, // (Optional) Valid email address
      twitter, // (Optional) Any text
      dateOfBirth, // (Optional) Any text
      location, // (Optional) Any text
      profileImageBase64, // (Optional) Any text
      biography, // (Optional) Any text
      defaultPaymentMethod, // (Optional) Any text
      countryCode, // (Optional) Any text
      timezone, // (Optional) Any string of 0-120 symbols in length.
    },
  });
}

export default update;
