import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import useCartV2ItemUpdateLoadingState from "stateManager/hooks/cart/useCartV2ItemUpdateLoadingState";
import useActions from "stateManager/hooks/useActions";

export default function SingleDeal({ product, plans }) {
  const theme = useTheme();
  const actions = useActions();

  const isSmUp = useMediaQuery((_theme) => _theme.breakpoints.up("sm"));

  const firstPlan =
    plans.find((_plan) =>
      _plan.get("planName").toLowerCase()?.startsWith("plan a")
    ) || plans.first();

  const [selectedPlan, setSelectedPlan] = useState(firstPlan);

  useEffect(() => {
    setSelectedPlan(firstPlan);
  }, [plans]);

  const loadingState = useCartV2ItemUpdateLoadingState({});
  const isLoading =
    loadingState.get("isLoading") &&
    loadingState.getIn(["payload", "productPlanId"]) ===
      selectedPlan.get("productPlanId");

  return (
    <>
      <Box sx={{ mb: { xs: theme.spacing(1), sm: theme.spacing(1.5) } }}>
        <Typography variant="h6" sx={{ mb: { sm: theme.spacing(0.5) } }}>
          {product.name}
        </Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ flexGrow: 1, ...(!isSmUp ? { maxWidth: "200px" } : {}) }}
          >
            <FormControl fullWidth size="small">
              <Select
                id="simple-select"
                value={selectedPlan}
                onChange={({ target }) => {
                  setSelectedPlan(target.value);
                }}
              >
                {plans.map((_plan) => (
                  <MenuItem value={_plan} key={_plan.get("productPlanId")}>
                    {_plan.get("planName")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Typography
            variant="h5"
            sx={{
              m: { xs: theme.spacing(0, 1), sm: theme.spacing(0, 2) },
              textAlign: "center",
            }}
          >
            ${selectedPlan.get("planPriceCents") / 100}
            <Typography
              variant="button"
              sx={{ color: theme.palette.text.disabled }}
              component="div"
            >
              <strike>{selectedPlan.get("planOldPrice").split("/")[0]}</strike>
            </Typography>
          </Typography>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="small"
            sx={{
              height: "40px",
              minWidth: "40px",
            }}
            onClick={() =>
              actions.cart.v2ItemUpdate({
                quantity: 1,
                productPlanId: selectedPlan.get("productPlanId"),
                removeQuantity: 0,
                addQuantity: 1,
                valueCentsPerItem: selectedPlan.get("planPriceCents"),
                productPlanName: selectedPlan.get("planName"),
              })
            }
          >
            <AddShoppingCartRoundedIcon />
          </LoadingButton>
        </div>
      </Box>
    </>
  );
}
