import call from "../../helpers/call";

function create({
  authToken,
  ownerUserId,
  draftId,
  name,
  slug,
  description,
  content,
  websiteTitle,
  websiteUrl,
  metaTitle,
  metaDescription,
  metaOgTitle,
  metaOgUrl,
  metaOgDescription,
  followCount,
  flagCount,
  commentCount,
  isDeal,
  isSellActive,
  showCouponsCount,
  refundDays,
  saleLimit,
  fomoMessageCouponsLimit,
  fomoMessage,
  dealStartedAt,
  dealEndedAt,
  isPaypalEnabled,
  isCreditCardEnabled,
  testimonialName,
  testimonialTitle,
  testimonialDescription,
  tag_1,
  tag_2,
  tag_3,
  tag_4,
  tag_5,
  publishedAt,
  createdAt,
  updatedAt,
  isArchived,
  discussionEmbedUrl,
  isCircleDiscussionEnabled,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/product/create`,
    payload: {
      ownerUserId, // (Optional) String 10-30 in length and starting with "u-"
      draftId, // (Optional) String 10-30 in length and starting with "p-"
      name, // (Optional) String. 1 to 50 symbols in length.
      slug, // (Optional) String which must pass this check: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
      description, // (Optional) Any text
      content, // (Optional) Any text
      websiteTitle, // (Optional) String of length from 1 to 250
      websiteUrl, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      metaTitle, // (Optional) String of length from 1 to 250
      metaDescription, // (Optional) Any text
      metaOgTitle, // (Optional) String of length from 1 to 250
      metaOgUrl, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      metaOgDescription, // (Optional) Any text
      followCount, // (Optional) Must be positive integer or zero
      flagCount, // (Optional) Must be positive integer or zero
      commentCount, // (Optional) Must be positive integer or zero
      isDeal, // (Optional) Value is a boolean. True or false.
      isSellActive, // (Optional) Value is a boolean. True or false.
      showCouponsCount, // (Optional) Value is a boolean. True or false.
      refundDays, // (Optional) Must be positive integer or zero
      saleLimit, // (Optional) Must be positive integer or zero
      fomoMessageCouponsLimit, // (Optional) Must be positive integer or zero
      fomoMessage, // (Optional) String of length from 1 to 250
      dealStartedAt, // (Optional) Timestamp value. Any positive integer.
      dealEndedAt, // (Optional) Timestamp value. Any positive integer.
      isPaypalEnabled, // (Optional) Value is a boolean. True or false.
      isCreditCardEnabled, // (Optional) Value is a boolean. True or false.
      testimonialName, // (Optional) String. 1 to 50 symbols in length.
      testimonialTitle, // (Optional) String of length from 1 to 250
      testimonialDescription, // (Optional) Any text
      tag_1, // (Optional) String of length from 1 to 250
      tag_2, // (Optional) String of length from 1 to 250
      tag_3, // (Optional) String of length from 1 to 250
      tag_4, // (Optional) String of length from 1 to 250
      tag_5, // (Optional) String of length from 1 to 250
      publishedAt, // (Optional) Timestamp value. Any positive integer.
      createdAt, // (Optional) Timestamp value. Any positive integer.
      updatedAt, // (Optional) Timestamp value. Any positive integer.
      isArchived, // (Optional) Value is a boolean. True or false.
      discussionEmbedUrl, // (Optional) String of length from 8 to 3000 symbols. Must start with "http://" or "https://" and have a dot inside.
      isCircleDiscussionEnabled, // (Optional) Value is a boolean. True or false.
    },
  });
}

export default create;
