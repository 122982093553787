/* eslint-disable no-shadow */
import { useDispatch } from "react-redux";

import reducerAnalytics from "../reducers/analytics";
import reducerAuth from "../reducers/auth";
import reducerCart from "../reducers/cart";
import reducerCircleso from "../reducers/circleso";
import reducerCoupon from "../reducers/coupon";
import reducerCoupons from "../reducers/coupons";
import reducerCurrency from "../reducers/currency";
import reducerCustomGlobalState from "../reducers/customGlobalState";
import reducerDiscountCoupon from "../reducers/discountCoupon";
import reducerDiscussion from "../reducers/discussion";
import reducerFavorite from "../reducers/favorite";
import reducerGlobalMessages from "../reducers/globalMessages";
import reducerImage from "../reducers/image";
import reducerInvoice from "../reducers/invoice";
import reducerKlavyio from "../reducers/klavyio";
import reducerMeta from "../reducers/meta";
import reducerNotifications from "../reducers/notifications";
import reducerOptions from "../reducers/options";
import reducerProduct from "../reducers/product";
import reducerProductPlan from "../reducers/productPlan";
import reducerProductPlans from "../reducers/productPlans";
import reducerProducts from "../reducers/products";
import reducerReview from "../reducers/review";
import reducerSales from "../reducers/sales";
import reducerStripeSubscription from "../reducers/stripeSubscription";
import reducerSubmitProduct from "../reducers/submitProduct";
import reducerSubscriptionTracking from "../reducers/subscriptionTracking";
import reducerSubscriptionTrackingCompany from "../reducers/subscriptionTrackingCompany";
import reducerTipalti from "../reducers/tipalti";
import reducerUser from "../reducers/user";
import reducerUserDraft from "../reducers/userDraft";
import reducerUserMeta from "../reducers/userMeta";
import reducerVendorPaymentInfo from "../reducers/vendorPaymentInfo";
import reducerVendorProduct from "../reducers/vendorProduct";
import reducerWallet from "../reducers/wallet";

export default function useActionsByIdentifier({ identifier }) {
  const ___dispatch = useDispatch();
  const ___identifier = identifier;

  return {
    analytics: {
      listProductSales: ({ productId, fromAt, toAt, page, perPage }) =>
        ___dispatch({
          type: reducerAnalytics.actionTypes
            .LIST_PRODUCT_SALES_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { productId, fromAt, toAt, page, perPage },
          },
        }),
    },
    auth: {
      login: ({
        email,
        password,
        recaptchaToken,
        lastUrl,
        keepSessionForDays,
      }) =>
        ___dispatch({
          type: reducerAuth.actionTypes.LOGIN_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              email,
              password,
              recaptchaToken,
              lastUrl,
              keepSessionForDays,
            },
          },
        }),
      logout: ({}) =>
        ___dispatch({
          type: reducerAuth.actionTypes.LOGOUT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    cart: {
      getMyItemsInCartCount: ({}) =>
        ___dispatch({
          type: reducerCart.actionTypes
            .GET_MY_ITEMS_IN_CART_COUNT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      getViewTrack: ({}) =>
        ___dispatch({
          type: reducerCart.actionTypes.GET_VIEW_TRACK_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      howMuchLeftToGetSplit: ({ carTotalAmountCents }) =>
        ___dispatch({
          type: reducerCart.actionTypes
            .HOW_MUCH_LEFT_TO_GET_SPLIT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { carTotalAmountCents } },
        }),
      listMyItems: ({}) =>
        ___dispatch({
          type: reducerCart.actionTypes.LIST_MY_ITEMS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      v2Get: ({}) =>
        ___dispatch({
          type: reducerCart.actionTypes.V2_GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      v2ItemUpdate: ({
        productPlanId,
        quantity,
        addQuantity,
        removeQuantity,
        valueCentsPerItem,
        productPlanName,
      }) =>
        ___dispatch({
          type: reducerCart.actionTypes.V2_ITEM_UPDATE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              productPlanId,
              quantity,
              addQuantity,
              removeQuantity,
              valueCentsPerItem,
              productPlanName,
            },
          },
        }),
      v2Pay: ({ gateway, payload, affiliateUserId }) =>
        ___dispatch({
          type: reducerCart.actionTypes.V2_PAY_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { gateway, payload, affiliateUserId },
          },
        }),
      vipDiscountList: ({}) =>
        ___dispatch({
          type: reducerCart.actionTypes.VIP_DISCOUNT_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    circleso: {
      getSsoAuthorizationOneTimeUseCode: ({}) =>
        ___dispatch({
          type: reducerCircleso.actionTypes
            .GET_SSO_AUTHORIZATION_ONE_TIME_USE_CODE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    coupon: {
      getDetails: ({ couponId }) =>
        ___dispatch({
          type: reducerCoupon.actionTypes.GET_DETAILS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { couponId } },
        }),
      getForRedeem: ({ couponId }) =>
        ___dispatch({
          type: reducerCoupon.actionTypes.GET_FOR_REDEEM_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { couponId } },
        }),
      markAsRedeemed: ({ couponId, userMarkedAsRedeamedAt }) =>
        ___dispatch({
          type: reducerCoupon.actionTypes.MARK_AS_REDEEMED_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { couponId, userMarkedAsRedeamedAt },
          },
        }),
    },
    coupons: {
      listMy: ({ page, perPage, state, searchQuery }) =>
        ___dispatch({
          type: reducerCoupons.actionTypes.LIST_MY_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { page, perPage, state, searchQuery },
          },
        }),
    },
    currency: {
      listAll: ({}) =>
        ___dispatch({
          type: reducerCurrency.actionTypes.LIST_ALL_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    customGlobalState: {
      set: ({ globalVariable, value }) =>
        ___dispatch({
          type: reducerCustomGlobalState.actionTypes.SET,
          data: { globalVariable, value },
        }),
      remove: ({ globalVariable }) =>
        ___dispatch({
          type: reducerCustomGlobalState.actionTypes.REMOVE,
          data: { globalVariable },
        }),
      removeAll: ({}) =>
        ___dispatch({
          type: reducerCustomGlobalState.actionTypes.REMOVE_ALL,
          data: {},
        }),
    },
    discountCoupon: {
      applyToInvoice: ({ discountCouponCode, invoiceId }) =>
        ___dispatch({
          type: reducerDiscountCoupon.actionTypes
            .APPLY_TO_INVOICE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { discountCouponCode, invoiceId } },
        }),
      removeFromInvoice: ({ discountCouponId, invoiceId }) =>
        ___dispatch({
          type: reducerDiscountCoupon.actionTypes
            .REMOVE_FROM_INVOICE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { discountCouponId, invoiceId } },
        }),
    },
    discussion: {
      editMyDiscussion: ({ comment, discussionId }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes
            .EDIT_MY_DISCUSSION_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { comment, discussionId } },
        }),
      listByProduct: ({ productId, page, perPage, sortBy, sortDirection }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes.LIST_BY_PRODUCT_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { productId, page, perPage, sortBy, sortDirection },
          },
        }),
      listRepliesForDiscussion: ({ parentDiscussionId, page, perPage }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes
            .LIST_REPLIES_FOR_DISCUSSION_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { parentDiscussionId, page, perPage },
          },
        }),
      markHelpful: ({ discussionId }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes.MARK_HELPFUL_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { discussionId } },
        }),
      postNewForProduct: ({ productId, comment }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes
            .POST_NEW_FOR_PRODUCT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId, comment } },
        }),
      postNewReply: ({ parentDiscussionId, comment }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes.POST_NEW_REPLY_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { parentDiscussionId, comment } },
        }),
      reactionAdd: ({ discussionId, reaction }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes.REACTION_ADD_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { discussionId, reaction } },
        }),
      reactionRemove: ({ discussionId, reaction }) =>
        ___dispatch({
          type: reducerDiscussion.actionTypes.REACTION_REMOVE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { discussionId, reaction } },
        }),
    },
    favorite: {
      add: ({ productId }) =>
        ___dispatch({
          type: reducerFavorite.actionTypes.ADD_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
      count: ({}) =>
        ___dispatch({
          type: reducerFavorite.actionTypes.COUNT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      getByProductId: ({ productId }) =>
        ___dispatch({
          type: reducerFavorite.actionTypes.GET_BY_PRODUCT_ID_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
      list: ({}) =>
        ___dispatch({
          type: reducerFavorite.actionTypes.LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      remove: ({ productId }) =>
        ___dispatch({
          type: reducerFavorite.actionTypes.REMOVE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
    },
    globalMessages: {
      addToQueue: ({
        queue,
        message,
        action,
        timeoutSeconds,
        doHideOnActionClick,
      }) => {
        if (
          !queue ||
          [
            "topCenter",
            "topRight",
            "bottomRight",
            "bottomCenter",
            "bottomLeft",
            "topLeft",
          ].includes(queue) === false
        ) {
          queue = "bottomLeft";
        }

        ___dispatch({
          type: reducerGlobalMessages.actionTypes.ADD_TO_QUEUE,
          data: { queue, message, timeoutSeconds, doHideOnActionClick, action },
        });
      },
      removeFromQueue: ({ queue, queueItem }) => {
        if (
          !queue ||
          [
            "topCenter",
            "topRight",
            "bottomRight",
            "bottomCenter",
            "bottomLeft",
            "topLeft",
          ].includes(queue) === false
        ) {
          queue = "bottomLeft";
        }

        ___dispatch({
          type: reducerGlobalMessages.actionTypes.REMOVE_FROM_QUEUE,
          data: { queue, queueItem },
        });
      },
    },
    image: {
      upload: ({ base64, sort, category }) =>
        ___dispatch({
          type: reducerImage.actionTypes.UPLOAD_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { base64, sort, category } },
        }),
    },
    invoice: {
      applyIncentive: ({ invoiceId, userIncentiveId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.APPLY_INCENTIVE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId, userIncentiveId } },
        }),
      automatedRefund: ({ couponId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.AUTOMATED_REFUND_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { couponId } },
        }),
      coinbaseGetCharge: ({ invoiceId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .COINBASE_GET_CHARGE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId } },
        }),
      coinbaseGetChargeInfo: ({ invoiceId, chargeId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .COINBASE_GET_CHARGE_INFO_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId, chargeId } },
        }),
      create: ({
        buyerName,
        buyerEmail,
        buyerAddress,
        buyerCity,
        buyerState,
        buyerPostcode,
        buyerCountry,
        buyerVat,
        affiliateId,
        userAgent,
      }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.CREATE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              buyerName,
              buyerEmail,
              buyerAddress,
              buyerCity,
              buyerState,
              buyerPostcode,
              buyerCountry,
              buyerVat,
              affiliateId,
              userAgent,
            },
          },
        }),
      get: ({ invoiceId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId } },
        }),
      getMyRefundsStats: ({}) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .GET_MY_REFUNDS_STATS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      getStripePayIntent: ({ invoiceId, stripePaymentMethodId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .GET_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { invoiceId, stripePaymentMethodId },
          },
        }),
      listMine: ({ state, searchQuery, page, perPage }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.LIST_MINE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { state, searchQuery, page, perPage },
          },
        }),
      makeStripePayIntent: ({ invoiceId, stripePaymentMethodId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .MAKE_STRIPE_PAY_INTENT_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { invoiceId, stripePaymentMethodId },
          },
        }),
      paypalClassicGetPaymentUrl: ({ invoiceId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .PAYPAL_CLASSIC_GET_PAYMENT_URL_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId } },
        }),
      refundToWallet: ({ couponId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.REFUND_TO_WALLET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { couponId } },
        }),
      removeWalletFundsFromInvoice: ({ invoiceId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .REMOVE_WALLET_FUNDS_FROM_INVOICE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId } },
        }),
      splitOptionsList: ({ invoiceAmountCents }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.SPLIT_OPTIONS_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceAmountCents } },
        }),
      stripeSplitPayIntent: ({
        invoiceId,
        stripePaymentMethodId,
        numberOfSplitParts,
      }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .STRIPE_SPLIT_PAY_INTENT_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { invoiceId, stripePaymentMethodId, numberOfSplitParts },
          },
        }),
      updateBillingDetails: ({
        invoiceId,
        buyerName,
        buyerEmail,
        buyerAddress,
        buyerCity,
        buyerState,
        buyerPostcode,
        buyerCountry,
        buyerVat,
      }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes
            .UPDATE_BILLING_DETAILS_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              invoiceId,
              buyerName,
              buyerEmail,
              buyerAddress,
              buyerCity,
              buyerState,
              buyerPostcode,
              buyerCountry,
              buyerVat,
            },
          },
        }),
      vipDiscountsList: ({ invoiceId }) =>
        ___dispatch({
          type: reducerInvoice.actionTypes.VIP_DISCOUNTS_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId } },
        }),
    },
    klavyio: {
      emailMeBeforeDealEnds: ({ productId }) =>
        ___dispatch({
          type: reducerKlavyio.actionTypes
            .EMAIL_ME_BEFORE_DEAL_ENDS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
      subscribeNewsletter: ({ email, relatedProductId }) =>
        ___dispatch({
          type: reducerKlavyio.actionTypes
            .SUBSCRIBE_NEWSLETTER_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { email, relatedProductId } },
        }),
    },
    meta: {
      getIpCountry: ({}) =>
        ___dispatch({
          type: reducerMeta.actionTypes.GET_IP_COUNTRY_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    notifications: {
      createDemoNotification: ({ relatedProductId, relatedDiscussionId }) =>
        ___dispatch({
          type: reducerNotifications.actionTypes
            .CREATE_DEMO_NOTIFICATION_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { relatedProductId, relatedDiscussionId },
          },
        }),
      getUnreadCount: ({}) =>
        ___dispatch({
          type: reducerNotifications.actionTypes
            .GET_UNREAD_COUNT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      listMine: ({ page, perPage }) =>
        ___dispatch({
          type: reducerNotifications.actionTypes.LIST_MINE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { page, perPage } },
        }),
      markAllAsRead: ({}) =>
        ___dispatch({
          type: reducerNotifications.actionTypes
            .MARK_ALL_AS_READ_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      markAsRead: ({ notificationId }) =>
        ___dispatch({
          type: reducerNotifications.actionTypes.MARK_AS_READ_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { notificationId } },
        }),
    },
    options: {
      get: ({ variable }) =>
        ___dispatch({
          type: reducerOptions.actionTypes.GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { variable } },
        }),
    },
    product: {
      analyticsGetProductSalesStats: ({ productId }) =>
        ___dispatch({
          type: reducerProduct.actionTypes
            .ANALYTICS_GET_PRODUCT_SALES_STATS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
      getForRefund: ({ productId, couponId }) =>
        ___dispatch({
          type: reducerProduct.actionTypes.GET_FOR_REFUND_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId, couponId } },
        }),
      getForReview: ({ productId }) =>
        ___dispatch({
          type: reducerProduct.actionTypes.GET_FOR_REVIEW_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
      getProductDetailsForAnalytics: ({ productId }) =>
        ___dispatch({
          type: reducerProduct.actionTypes
            .GET_PRODUCT_DETAILS_FOR_ANALYTICS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
      getViewTrack: ({ productSlug, at, lastViewedUrl }) =>
        ___dispatch({
          type: reducerProduct.actionTypes.GET_VIEW_TRACK_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productSlug, at, lastViewedUrl } },
        }),
      listMyProducts: ({}) =>
        ___dispatch({
          type: reducerProduct.actionTypes.LIST_MY_PRODUCTS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    productPlan: {
      subscriptionList: ({}) =>
        ___dispatch({
          type: reducerProductPlan.actionTypes
            .SUBSCRIPTION_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    productPlans: {
      listForPublishedProduct: ({ productId }) =>
        ___dispatch({
          type: reducerProductPlans.actionTypes
            .LIST_FOR_PUBLISHED_PRODUCT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
    },
    products: {
      listActiveProducts: ({ searchQuery }) =>
        ___dispatch({
          type: reducerProducts.actionTypes
            .LIST_ACTIVE_PRODUCTS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { searchQuery } },
        }),
    },
    review: {
      create: ({ productId, title, comment, stars, pros, cons }) =>
        ___dispatch({
          type: reducerReview.actionTypes.CREATE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { productId, title, comment, stars, pros, cons },
          },
        }),
      getMyForEdit: ({ discussionId }) =>
        ___dispatch({
          type: reducerReview.actionTypes.GET_MY_FOR_EDIT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { discussionId } },
        }),
      list: ({}) =>
        ___dispatch({
          type: reducerReview.actionTypes.LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      removeMine: ({ discussionId }) =>
        ___dispatch({
          type: reducerReview.actionTypes.REMOVE_MINE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { discussionId } },
        }),
      updateMine: ({ discussionId, title, comment, stars, pros, cons }) =>
        ___dispatch({
          type: reducerReview.actionTypes.UPDATE_MINE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: { discussionId, title, comment, stars, pros, cons },
          },
        }),
    },
    sales: {
      getLatestPurchases: ({}) =>
        ___dispatch({
          type: reducerSales.actionTypes.GET_LATEST_PURCHASES_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    stripeSubscription: {
      list: ({}) =>
        ___dispatch({
          type: reducerStripeSubscription.actionTypes.LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
    },
    submitProduct: {
      auditLogsList: ({ productSubmittedId }) =>
        ___dispatch({
          type: reducerSubmitProduct.actionTypes
            .AUDIT_LOGS_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productSubmittedId } },
        }),
      create: ({ meta }) =>
        ___dispatch({
          type: reducerSubmitProduct.actionTypes.CREATE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { meta } },
        }),
      get: ({ productSubmittedId }) =>
        ___dispatch({
          type: reducerSubmitProduct.actionTypes.GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productSubmittedId } },
        }),
      getCount: ({}) =>
        ___dispatch({
          type: reducerSubmitProduct.actionTypes.GET_COUNT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      imageUpload: ({ base64, sort, category }) =>
        ___dispatch({
          type: reducerSubmitProduct.actionTypes.IMAGE_UPLOAD_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { base64, sort, category } },
        }),
      list: ({ page, perPage }) =>
        ___dispatch({
          type: reducerSubmitProduct.actionTypes.LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { page, perPage } },
        }),
      update: ({ productSubmittedId, meta }) =>
        ___dispatch({
          type: reducerSubmitProduct.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productSubmittedId, meta } },
        }),
    },
    subscriptionTracking: {
      create: ({
        companyId,
        subscriptionType,
        tags,
        purchasedAt,
        repeatEveryTerm,
        repeatEveryNumber,
        isAutoRenewing,
        cost,
        costCents,
        currencyId,
        planName,
      }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .CREATE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              companyId,
              subscriptionType,
              tags,
              purchasedAt,
              repeatEveryTerm,
              repeatEveryNumber,
              isAutoRenewing,
              cost,
              costCents,
              currencyId,
              planName,
            },
          },
        }),
      exportCsv: ({ startAt, endAt }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .EXPORT_CSV_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { startAt, endAt } },
        }),
      get: ({ subscriptionTrackingId }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes.GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { subscriptionTrackingId } },
        }),
      list: ({ searchQuery, page, perPage, state, type, currencyId, tag }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes.LIST_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              searchQuery,
              page,
              perPage,
              state,
              type,
              currencyId,
              tag,
            },
          },
        }),
      remove: ({ subscriptionTrackingId }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .REMOVE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { subscriptionTrackingId } },
        }),
      reports: ({}) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .REPORTS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      spendingsGraphList: ({ startAt, endAt }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .SPENDINGS_GRAPH_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { startAt, endAt } },
        }),
      spendingsList: ({ page, perPage, startAt, endAt }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .SPENDINGS_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { page, perPage, startAt, endAt } },
        }),
      tagList: ({}) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .TAG_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      update: ({
        subscriptionTrackingId,
        companyId,
        subscriptionType,
        tags,
        purchasedAt,
        isAutoRenewing,
        cost,
        costCents,
        currencyId,
        isPaused,
        isCancelled,
        repeatEveryNumber,
        repeatEveryTerm,
        planName,
      }) =>
        ___dispatch({
          type: reducerSubscriptionTracking.actionTypes
            .UPDATE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              subscriptionTrackingId,
              companyId,
              subscriptionType,
              tags,
              purchasedAt,
              isAutoRenewing,
              cost,
              costCents,
              currencyId,
              isPaused,
              isCancelled,
              repeatEveryNumber,
              repeatEveryTerm,
              planName,
            },
          },
        }),
    },
    subscriptionTrackingCompany: {
      search: ({ query }) =>
        ___dispatch({
          type: reducerSubscriptionTrackingCompany.actionTypes
            .SEARCH_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { query } },
        }),
    },
    tipalti: {
      iframeUrlGet: ({ iFrameType }) =>
        ___dispatch({
          type: reducerTipalti.actionTypes.IFRAME_URL_GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { iFrameType } },
        }),
    },
    user: {
      billingDetailsGet: ({}) =>
        ___dispatch({
          type: reducerUser.actionTypes.BILLING_DETAILS_GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      billingDetailsUpdate: ({
        userBillingDetailsId,
        name,
        email,
        address,
        city,
        state,
        postcode,
        country,
        vat,
      }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .BILLING_DETAILS_UPDATE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              userBillingDetailsId,
              name,
              email,
              address,
              city,
              state,
              postcode,
              country,
              vat,
            },
          },
        }),
      confirmEmail: ({ emailConfirmationToken }) =>
        ___dispatch({
          type: reducerUser.actionTypes.CONFIRM_EMAIL_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { emailConfirmationToken } },
        }),
      get: ({}) =>
        ___dispatch({
          type: reducerUser.actionTypes.GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      getIfUserBoughtProduct: ({ productId }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .GET_IF_USER_BOUGHT_PRODUCT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
      getStats: ({}) =>
        ___dispatch({
          type: reducerUser.actionTypes.GET_STATS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      getUserDealsBoughtCount: ({}) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .GET_USER_DEALS_BOUGHT_COUNT_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      listAvailableIncentives: ({}) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .LIST_AVAILABLE_INCENTIVES_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      listInvoiceIncentives: ({ invoiceId }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .LIST_INVOICE_INCENTIVES_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId } },
        }),
      passwordChangeWithToken: ({ passwordRecoveryToken, password }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .PASSWORD_CHANGE_WITH_TOKEN_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { passwordRecoveryToken, password } },
        }),
      requestPasswordRecovery: ({ email, recaptchaToken }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .REQUEST_PASSWORD_RECOVERY_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { email, recaptchaToken } },
        }),
      requestResendEmailConfirmation: ({ email, recaptchaToken }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .REQUEST_RESEND_EMAIL_CONFIRMATION_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { email, recaptchaToken } },
        }),
      signUp: ({
        email,
        password,
        name,
        username,
        emailPublic,
        twitter,
        countryCode,
        dateOfBirth,
        location,
        profileImageBase64,
        biography,
        affiliateUserId,
        productId,
        productPlanId,
        referralMagicCode,
        recaptchaToken,
      }) =>
        ___dispatch({
          type: reducerUser.actionTypes.SIGN_UP_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              email,
              password,
              name,
              username,
              emailPublic,
              twitter,
              countryCode,
              dateOfBirth,
              location,
              profileImageBase64,
              biography,
              affiliateUserId,
              productId,
              productPlanId,
              referralMagicCode,
              recaptchaToken,
            },
          },
        }),
      stripeCardDetach: ({ stripePaymentMethodId }) =>
        ___dispatch({
          type: reducerUser.actionTypes.STRIPE_CARD_DETACH_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { stripePaymentMethodId } },
        }),
      stripeCardsList: ({}) =>
        ___dispatch({
          type: reducerUser.actionTypes.STRIPE_CARDS_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      update: ({
        password,
        name,
        username,
        email,
        twitter,
        dateOfBirth,
        location,
        profileImageBase64,
        biography,
        defaultPaymentMethod,
        countryCode,
        timezone,
      }) =>
        ___dispatch({
          type: reducerUser.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
          data: {
            ___identifier,
            payload: {
              password,
              name,
              username,
              email,
              twitter,
              dateOfBirth,
              location,
              profileImageBase64,
              biography,
              defaultPaymentMethod,
              countryCode,
              timezone,
            },
          },
        }),
      updateProfileTimezone: ({ timezone }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .UPDATE_PROFILE_TIMEZONE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { timezone } },
        }),
      uploadProfilePicture: ({ base64 }) =>
        ___dispatch({
          type: reducerUser.actionTypes
            .UPLOAD_PROFILE_PICTURE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { base64 } },
        }),
    },
    userDraft: {
      create: ({ meta, identifier }) =>
        ___dispatch({
          type: reducerUserDraft.actionTypes.CREATE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { meta, identifier } },
        }),
      get: ({ userDraftId }) =>
        ___dispatch({
          type: reducerUserDraft.actionTypes.GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { userDraftId } },
        }),
      list: ({ page, perPage }) =>
        ___dispatch({
          type: reducerUserDraft.actionTypes.LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { page, perPage } },
        }),
      remove: ({ userDraftId }) =>
        ___dispatch({
          type: reducerUserDraft.actionTypes.REMOVE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { userDraftId } },
        }),
      update: ({ userDraftId, meta }) =>
        ___dispatch({
          type: reducerUserDraft.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { userDraftId, meta } },
        }),
    },
    userMeta: {
      get: ({}) =>
        ___dispatch({
          type: reducerUserMeta.actionTypes.GET_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      update: ({ facebookFbp, facebookFbc }) =>
        ___dispatch({
          type: reducerUserMeta.actionTypes.UPDATE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { facebookFbp, facebookFbc } },
        }),
    },
    vendorPaymentInfo: {
      forceList: ({ productId }) =>
        ___dispatch({
          type: reducerVendorPaymentInfo.actionTypes
            .FORCE_LIST_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
    },
    vendorProduct: {
      getStats: ({ productId }) =>
        ___dispatch({
          type: reducerVendorProduct.actionTypes.GET_STATS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { productId } },
        }),
    },
    wallet: {
      coverInvoice: ({ invoiceId, amountCents }) =>
        ___dispatch({
          type: reducerWallet.actionTypes.COVER_INVOICE_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { invoiceId, amountCents } },
        }),
      getMyOverview: ({}) =>
        ___dispatch({
          type: reducerWallet.actionTypes.GET_MY_OVERVIEW_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: {} },
        }),
      listMyWalletLogs: ({ page, perPage, searchQuery }) =>
        ___dispatch({
          type: reducerWallet.actionTypes.LIST_MY_WALLET_LOGS_TRY_BY_IDENTIFIER,
          data: { ___identifier, payload: { page, perPage, searchQuery } },
        }),
    },
  };
}
