import call from "../../helpers/call";

function setIsDiscountCouponAllowed({
  authToken,
  invoiceId,
  isDiscountCouponAllowed,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/set-is-discount-coupon-allowed`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
      isDiscountCouponAllowed, // (Required) Value is a boolean. True or false.
    },
  });
}

export default setIsDiscountCouponAllowed;
