import { fromJS, Map } from "immutable";
import {
  handleFailed,
  handleFailedByIdentifier,
  handleSuccess,
  handleSuccessByIdentifier,
  handleTry,
  handleTryByIdentifier,
  shape,
} from "../utils/reducerHelpers";

const LIST_ALL_TRY = "currency/listAll/TRY";
const LIST_ALL_SUCCESS = "currency/listAll/SUCCESS";
const LIST_ALL_FAILED = "currency/listAll/FAILED";

const LIST_ALL_TRY_BY_IDENTIFIER = "currency/listAll/TRY_BY_IDENTIFIER";
const LIST_ALL_SUCCESS_BY_IDENTIFIER = "currency/listAll/SUCCESS_BY_IDENTIFIER";
const LIST_ALL_FAILED_BY_IDENTIFIER = "currency/listAll/FAILED_BY_IDENTIFIER";

const initialState = Map({
  listAll: shape.merge(Map({ dataByIdentifier: Map({}) })),
});

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LIST_ALL_TRY:
      return handleTry({ state, action });
    case LIST_ALL_SUCCESS:
      return handleSuccess({ state, action });
    case LIST_ALL_FAILED:
      return handleFailed({ state, action });
    case LIST_ALL_TRY_BY_IDENTIFIER:
      return handleTryByIdentifier({ state, action });
    case LIST_ALL_SUCCESS_BY_IDENTIFIER:
      return handleSuccessByIdentifier({ state, action });
    case LIST_ALL_FAILED_BY_IDENTIFIER:
      return handleFailedByIdentifier({ state, action });

    default:
      return state;
  }
}

const actionTypes = {
  LIST_ALL_TRY,
  LIST_ALL_SUCCESS,
  LIST_ALL_FAILED,
  LIST_ALL_TRY_BY_IDENTIFIER,
  LIST_ALL_SUCCESS_BY_IDENTIFIER,
  LIST_ALL_FAILED_BY_IDENTIFIER,
};

export default {
  actionTypes,
  reducer,
};
