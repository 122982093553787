import useDiscussionListByProductLoadingState from "stateManager/hooks/discussion/useDiscussionListByProductLoadingState";
import useDiscussionListByProductResult from "stateManager/hooks/discussion/useDiscussionListByProductResult";

export default function useIsDiscussionListByProductLoaded({}, ___options) {
  const loadingState = useDiscussionListByProductLoadingState({}, ___options);
  const resultState = useDiscussionListByProductResult({}, ___options);

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      resultState.getIn(["result"]) !== null
    );
  }
  return (
    loadingState.get("isLoading") === false &&
    resultState.getIn(["result"]) !== null
  );
}
