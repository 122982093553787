import call from "../../helpers/call";

function updateAdmin({
  authToken,
  ownerUserId,
  name,
  surname,
  address,
  phoneNumber,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/affiliate-details/update-admin`,
    payload: {
      ownerUserId, // (Required) String 10-30 in length and starting with "u-"
      name, // (Required) String. 1 to 50 symbols in length.
      surname, // (Required) String. 1 to 50 symbols in length.
      address, // (Required) Any string of 0-255 symbols in length.
      phoneNumber, // (Required) Any string of 0-255 symbols in length.
    },
  });
}

export default updateAdmin;
