import usePrevious from "hook-use-previous";
import { useSelector } from "react-redux";
import useDiscussionReactionAddLoadingState from "stateManager/hooks/discussion/useDiscussionReactionAddLoadingState";

export default function useIsDiscussionReactionAddAfterError({}, ___options) {
  const loadingState = useDiscussionReactionAddLoadingState({}, ___options);
  const discussion = useSelector((___state) => ___state.discussion);
  const wasLoading = usePrevious(loadingState.get("isLoading"));

  if (___options && ___options.identifier) {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      discussion.getIn([
        "reactionAdd",
        "dataByIdentifier",
        ___options.identifier,
        "lastResult",
        "wasSuccess",
      ]) === false
    );
  } else {
    return (
      loadingState.get("isLoading") === false &&
      wasLoading === true &&
      discussion.getIn(["reactionAdd", "lastResult", "wasSuccess"]) === false
    );
  }
}
