import call from "../../helpers/call";

function paypalClassicGetPaymentUrl({ authToken, invoiceId }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/invoice/paypal-classic-get-payment-url`,
    payload: {
      invoiceId, // (Required) String 10-30 in length and starting with "in-"
    },
  });
}

export default paypalClassicGetPaymentUrl;
