import call from "../../helpers/call";

function updateMine({
  authToken,
  discussionId,
  title,
  comment,
  stars,
  pros,
  cons,
}) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/review/update-mine`,
    payload: {
      discussionId, // (Required) String 10-30 in length and starting with "d-"
      title, // (Required) Any string of 0-120 symbols in length.
      comment, // (Optional) Any text
      stars, // (Optional) Must be positive integer or zero
      pros, // (Optional) Any text
      cons, // (Optional) Any text
    },
  });
}

export default updateMine;
