import call from "../../helpers/call";

function affiliateFormListAdmin({ authToken, page, perPage }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/user/affiliate-form-list-admin`,
    payload: {
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any positive integer. Range: (0,1000]
    },
  });
}

export default affiliateFormListAdmin;
