import call from "../../helpers/call";

function listMine({ authToken, page, perPage }) {
  return call({
    httpMethod: "POST",
    headers: [{ name: "x-sdk-authorization", value: authToken }],
    endpoint: `/notifications/list-mine`,
    payload: {
      page, // (Required) Must be any integer from 1 and up. First page is "1"
      perPage, // (Required) Any of: 5,10,15,20,25,30,35,40,45,50,100
    },
  });
}

export default listMine;
